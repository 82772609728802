export class DecimatedLog {
    public guid: string;
    public sectionId: number;
    public tag: string;
    public curve: string;
    public uom: string;
    public from: Date;
    public to: Date;
    public points: DecimatedLogPoint[];
    public timestamp: Date;
}

export class DecimatedLogPoint {
    public from: Date;
    public to: Date;
    public count: number;
    public avg: number;
}
