import { ChartMode, ChartType, StateTable, StateValueType, YAxisInfo } from "@/_models";
import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'app-state-details-chart',
    templateUrl: './state-details-chart.component.html',
})
export class StateDetailsChartComponent implements OnInit {
    @Input() public stateTable: StateTable;
    @Input() public isOnScreen: boolean;
    
    public graph: any = {
        data: [],
    };

    private iconToggleLegend = {
        title: 'Toggle legend',
        name: 'iconTL',
        svg: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>'
    };

    public config = {
        displaylogo: false,
        displayModeBar: true,
        modeBarButtonsToRemove: ['toImage', 'autoScale2d', 'select2d', 'lasso2d'],
        modeBarButtonsToAdd: [
            {
                name: 'Toggle legend',
                icon: this.iconToggleLegend,
                click: this.onToggleLegend.bind(this),
            }
        ]
    };

    private onToggleLegend() {
        this.graph.layout.showlegend = !this.graph.layout.showlegend;
    }
    
    async ngOnInit(): Promise<void> {
        let xAxisColIndex = this.stateTable.cols.findIndex(x => x.name === this.stateTable.visualise.xAxisColumnName);
        if (xAxisColIndex < 0) { xAxisColIndex = 0; }
        this.setLayout(xAxisColIndex);

        this.stateTable.visualise.yAxes.forEach((yAxisInfo, yAxisIndex) => {
            const yAxisSuffix =  yAxisIndex > 0 ? `${yAxisIndex + 1}` : '';
            this.graph.layout[`yaxis${yAxisSuffix}`] = this.createYAxis(yAxisInfo.label, yAxisInfo.invert, yAxisInfo.fromZero, yAxisIndex);
            yAxisInfo.columnNames.forEach(colName => {
                const yAxisCol = this.stateTable.cols.findIndex(x => x.name === colName);
                if (yAxisCol >= 0) {
                    this.graph.data.push(this.createTrace(xAxisColIndex, yAxisInfo, yAxisCol, yAxisSuffix));
                }
            });
        });
    }

    private setLayout(xAxisColIndex: number) {
        const xAxisCol = this.stateTable.cols[xAxisColIndex];
        this.graph.layout = { 
            autosize: true,
            xaxis: { type: this.getAxisType(xAxisCol.valueType) },
            margin: { l: 50, r: 50, b: 40, t: 30, pad: 4 },
            showlegend: true,
            legend: {
                orientation: "v",
                x: 1.08,
                xanchor: 'left',
                y: 1,
          }
        };       
    }

    private createTrace(xAxisColIndex: number, yAxisInfo: YAxisInfo, yAxisColIndex: number, yAxisSuffix: string) {
        const filteredRows = this.stateTable.rows.filter(r => r[yAxisColIndex] != null);
        const xAxisCol = this.stateTable.cols[xAxisColIndex];
        const yAxisCol = this.stateTable.cols[yAxisColIndex];
        const trace = {
            name: yAxisCol.name,
            x: filteredRows.map(row => this.parseValue(xAxisCol.valueType, row[xAxisColIndex])),
            y: filteredRows.map(row => this.parseValue(yAxisCol.valueType, row[yAxisColIndex])),
            type: this.getTraceType(yAxisInfo.chartType),
            mode: this.getTraceMode(yAxisInfo.chartMode),
        } as any;
        if (yAxisSuffix !== '') { trace.yaxis = `y${yAxisSuffix}`; }

        return trace;
    }

    private createYAxis(title: string, inverted: boolean, from0: boolean, index: number) {
        const yAxis = {
            title: title,
            showticklabels: true,
            autorange: inverted ? 'reversed' : true,
            rangemode: from0 ? 'tozero' : 'normal',
            range: null,
        } as any;

        if (index > 0) {
            yAxis.side = 'right';
            yAxis.overlaying = 'y';
        }

        return yAxis;
    }

    private getAxisType(valueType: StateValueType): string {
        switch (valueType) {
            case StateValueType.DateTime:
                return 'date';
            case StateValueType.Float:
            case StateValueType.Int:
                return 'number';
            default:
                return '';
        }
    }

    private getTraceType(chartType: ChartType): string {
        switch (chartType) {
            case ChartType.Scatter:
                return 'scatter';
            default:
                return '';
        }
    }

    private getTraceMode(chartMode: ChartMode): string {
        switch (chartMode) {
            case ChartMode.Line:
                return 'line';
            case ChartMode.Markers:
                return 'markers';
            case ChartMode.LineAndMarkers:
                return 'lines+markers';
            default:
                return '';
        }
    }

    private parseValue(valueType: StateValueType, valueAsString: string) {
        switch (valueType) {
            case StateValueType.DateTime:
                return new Date(valueAsString);
            case StateValueType.Float:
            case StateValueType.Int:
                return +valueAsString;
            case StateValueType.String:
                return valueAsString;
            case StateValueType.Bool:
                return valueAsString.toLowerCase() === 'true' ? true : false;
            default:
                return null;
        }
    }
}
