export const MeasureUnits = {
    'time': ['s'],
    'length': ['m', 'ft'],
    'volumePerTime': ['L/min', 'gal[US]/min'],
    'lengthPerTime': ['m/h', 'ft/h']
};

export class Measure {
    constructor(
        public value: number,
        public uom: string
    ) {}
}

export const SiMeasurementSystemCompatibleUnits = {
    'lbmol/(h.ft2)': false,
    'lbmol/(s.ft2)': false,
    'lbmole/h.ft2': false,
    'lbmole/s.ft2': false,
    'mol(lbm)/h.ft2': false,
    'mol(lbm)/s.ft2': false,
    'mol/(s.m2)': true,
    'mol/m2.s': true,
    'mrem/h': false,
    'mSv/h': true,
    'rem/h': true,
    'Sv/h': true,
    'Sv/s': true,
    'g.ft/(cm3.s)': false,
    'g.ft/cm3.s': false,
    'g.m/(cm3.s)': true,
    'kg/(m2.s)': true,
    'kg/m2.s': true,
    'kPa.s/m': true,
    'lbm/(ft2.h)': false,
    'lbm/(ft2.s)': false,
    'lbm/h.ft2': false,
    'lbm/s.ft2': false,
    'MPa.s/m': true,
    'cP': false,
    'dP': false,
    'dyne.s/cm2': false,
    'EP': false,
    'fP': false,
    'GP': false,
    'kg/(m.s)': true,
    'kg/m.s': true,
    'kgf.s/m2': false,
    'kP': false,
    'lbf.s/ft2': false,
    'lbf.s/in2': false,
    'lbm/(ft.h)': false,
    'lbm/(ft.s)': false,
    'lbm/ft.h': false,
    'lbm/ft.s': false,
    'lbm/h.ft': false,
    'lbm/s.ft': false,
    'mP': false,
    'MP': false,
    'mPa.s': true,
    'N.s/m2': true,
    'nP': false,
    'P': false,
    'Pa.s': true,
    'pP': false,
    'psi.s': false,
    'TP': false,
    'uP': false,
    'g/cm4': true,
    'kg/dm4': true,
    'kg/m4': true,
    'lbm/(gal[UK].ft)': false,
    'lbm/(gal[US].ft)': false,
    'lbm/ft4': false,
    'lbm/galUK.ft': false,
    'lbm/galUS.ft': false,
    'Pa.s2/m3': true,
    '1000 bbl.ft/d': false,
    '1000 m4/d': false,
    '1000m4/d': false,
    'm4/s': true,
    'Mbbl.ft/d': false,
    'bbl/(ft.psi.d)': false,
    'bbl/d.ft.psi': false,
    'D/(Pa.s)': false,
    'D/cP': false,
    'ft3/(ft.psi.d)': false,
    'ft3/d.ft.psi': false,
    'm2/(kPa.d)': true,
    'm2/(Pa.s)': true,
    'm2/d.kPa': true,
    'm2/Pa.s': true,
    'mD.ft2/(lbf.s)': false,
    'mD.ft2/lbf.s': false,
    'mD.in2/(lbf.s)': false,
    'mD.in2/lbf.s': false,
    'mD/(Pa.s)': false,
    'mD/cP': false,
    'mD/Pa.s': false,
    'TD[API]/(Pa.s)': true,
    '1000 ft3/(psi.d)': false,
    '1000ft3/psi.d': false,
    'bbl/(kPa.d)': false,
    'bbl/(psi.d)': false,
    'bbl/d.psi': false,
    'bbl/kPa.d': false,
    'bbl/psi.d': false,
    'L/(bar.min)': true,
    'L/bar.min': true,
    'm3/(bar.d)': true,
    'm3/(bar.h)': true,
    'm3/(bar.min)': true,
    'm3/(kPa.d)': true,
    'm3/(kPa.h)': true,
    'm3/(Pa.s)': true,
    'm3/(psi.d)': false,
    'm3/bar.d': true,
    'm3/bar.h': true,
    'm3/bar.min': true,
    'm3/d.kPa': true,
    'm3/kPa.d': true,
    'm3/kPa.h': true,
    'm3/Pa.s': true,
    'm3/Pa/s': true,
    'm3/psi.d': false,
    'bbl/d2': false,
    'bbl/h2': false,
    'bbl/hr2': false,
    'dm3/s2': true,
    'ft3/d2': false,
    'ft3/h2': false,
    'ft3/min2': false,
    'ft3/s2': false,
    'gal[UK]/h2': false,
    'gal[UK]/min2': false,
    'gal[US]/h2': false,
    'gal[US]/min2': false,
    'galUK/hr2': false,
    'galUK/min2': false,
    'galUS/hr2': false,
    'galUS/min2': false,
    'L/s2': true,
    'm3/d2': true,
    'm3/s2': true,
    'kmol': true,
    'lbmol': false,
    'lbmole': false,
    'mmol': true,
    'mol': true,
    'mol(g)': true,
    'mol(kg)': true,
    'mol(lbm)': false,
    'umol': true,
    'mol/m2': true,
    'kmol/h': true,
    'kmol/s': true,
    'lbmol/h': false,
    'lbmol/s': false,
    'lbmole/h': false,
    'lbmole/s': false,
    'mol(kg)/h': true,
    'mol(kg)/s': true,
    'mol(lbm)/h': false,
    'mol(lbm)/s': false,
    'mol/s': true,
    'kmol/m3': true,
    'lbmol/ft3': false,
    'lbmol/gal[UK]': false,
    'lbmol/gal[US]': false,
    'lbmole/ft3': false,
    'lbmole/galUK': false,
    'lbmole/galUS': false,
    'mol(kg)/m3': true,
    'mol(lbm)/ft3': false,
    'mol(lbm)/galUK': false,
    'mol(lbm)/galUS': false,
    'mol/m3': true,
    '0.01 dega/ft': false,
    '1/30 dega/ft': false,
    '1/30 dega/m': false,
    'dega/100ft': false,
    'dega/30ft': false,
    'dega/30m': false,
    'dega/ft': false,
    'dega/ft(100)': false,
    'dega/m': true,
    'dega/m(30)': false,
    'rad/ft': false,
    'rad/m': true,
    'rev/ft': false,
    'rev/m': false,
    'rad/ft3': false,
    'rad/m3': true,
    'rad/s2': true,
    'rpm/s': false,
    'dega/h': true,
    'dega/min': true,
    'dega/s': true,
    'rad/s': true,
    'rev/min': false,
    'rev/s': false,
    'rpm': false,
    '0.01 dm3/km': false,
    '0.01 L/km': false,
    'acre': false,
    'b': true,
    'bbl/ft': false,
    'bbl/in': false,
    'bbl/mi': false,
    'cm2': true,
    'D': false,
    'D[API]': true,
    'dm3/100km': false,
    'dm3/km(100)': false,
    'dm3/m': true,
    'ft2': false,
    'ft3/ft': false,
    'gal[UK]/mi': false,
    'gal[US]/ft': false,
    'gal[US]/mi': false,
    'galUK/mi': false,
    'galUS/ft': false,
    'galUS/mi': false,
    'ha': true,
    'in2': false,
    'in3/ft': false,
    'km2': true,
    'L/100km': false,
    'L/km(100)': false,
    'L/m': true,
    'm2': true,
    'm3/km': true,
    'm3/m': true,
    'mD': false,
    'mi[US]2': false,
    'mi2': false,
    'miUS2': false,
    'mm2': true,
    'section': false,
    'sq ft': false,
    'sq in': false,
    'sq mi': false,
    'sq yd': false,
    'TD[API]': true,
    'um2': true,
    'yd2': false,
    'm2/mol': true,
    'cm2/g': true,
    'ft2/lbm': false,
    'm2/g': true,
    'm2/kg': true,
    '1000 ft3/(d.ft)': false,
    '1000 m3/(d.m)': false,
    '1000 m3/(h.m)': false,
    '1000ft3/d.ft': false,
    '1000m3/d.m': false,
    '1000m3/h.m': false,
    'bbl/(d.ft)': false,
    'bbl/d.ft': false,
    'cm2/s': true,
    'cSt': false,
    'ft2/h': false,
    'ft2/s': false,
    'ft3/(d.ft)': false,
    'gal[UK]/(h.ft)': false,
    'gal[UK]/(h.in)': false,
    'gal[UK]/(min.ft)': false,
    'gal[US]/(h.ft)': false,
    'gal[US]/(h.in)': false,
    'gal[US]/(min.ft)': false,
    'galUK/hr.ft': false,
    'galUK/hr.in': false,
    'galUK/min.ft': false,
    'galUS/hr.ft': false,
    'galUS/hr.in': false,
    'galUS/min.ft': false,
    'in2/s': false,
    'm2/d': true,
    'm2/h': true,
    'm2/s': true,
    'm3/(d.m)': true,
    'm3/(h.m)': true,
    'm3/(s.ft)': false,
    'm3/(s.m)': true,
    'm3/d.m': true,
    'm3/h.m': true,
    'm3/s.ft': false,
    'm3/s.m': true,
    'mm2/s': true,
    'mol.m2/(mol.s)': true,
    'Pa.s.m3/kg': true,
    'St': false,
    'W.m2.deltaK/(J.deltaK)': true,
    'cF': true,
    'dF': true,
    'EF': true,
    'F': true,
    'fF': true,
    'GF': true,
    'kF': true,
    'mF': true,
    'MF': true,
    'nF': true,
    'pF': true,
    'TF': true,
    'uF': true,
    '%': true,
    '%[area]': false,
    '%[mass]': false,
    '%[molar]': false,
    '%[vol]': false,
    '(bbl/d)/(bbl/d)': false,
    '(m3/d)/(m3/d)': true,
    '(m3/s)/(m3/s)': true,
    '0.001 bbl/ft3': false,
    '0.001 bbl/m3': false,
    '0.001 gal[UK]/bbl': false,
    '0.001 gal[UK]/gal[UK]': false,
    '0.001 gal[US]/bbl': false,
    '0.001 gal[US]/ft3': false,
    '0.001 gal[US]/gal[US]': false,
    '0.001 pt[UK]/bbl': false,
    '0.01 bbl/bbl': false,
    '0.01 ft/ft': false,
    '0.1 gal[US]/bbl': false,
    '0.1 L/bbl': false,
    '0.1 pt[US]/bbl': false,
    '1/30 m/m': false,
    '1000 ft3/bbl': false,
    '1000 m3/m3': false,
    '1000ft3/bbl': false,
    '1E6 (ft3/d)/(bbl/d)': false,
    '1E-6 acre.ft/bbl': false,
    '1E6 bbl/(acre.ft)': false,
    '1E-6 bbl/ft3': false,
    '1E-6 bbl/m3': false,
    '1E6 ft3/(acre.ft)': false,
    '1E6 ft3/bbl': false,
    'bbl/(acre.ft)': false,
    'bbl/100bbl': false,
    'bbl/acre.ft': false,
    'bbl/bbl': false,
    'bbl/ft3': false,
    'bbl/k(ft3)': false,
    'bbl/M(ft3)': false,
    'bbl/m3': false,
    'bit': true,
    'Btu/bhp.hr': false,
    'Btu[IT]/(hp.h)': false,
    'byte': false,
    'cEuc': true,
    'cm3/cm3': true,
    'cm3/L': true,
    'cm3/m3': true,
    'dEuc': true,
    'dm3/m3': true,
    'EEuc': true,
    'Euc': true,
    'fEuc': true,
    'ft/100ft': false,
    'ft/ft': false,
    'ft/in': false,
    'ft/m': false,
    'ft/mi': false,
    'ft3/bbl': false,
    'ft3/ft3': false,
    'g/kg': true,
    'g/t': true,
    'gal[UK]/ft3': false,
    'gal[US]/bbl': false,
    'gal[US]/ft3': false,
    'galUK/ft3': false,
    'galUK/kgalUK': false,
    'galUK/Mbbl': false,
    'galUS/10bbl': false,
    'galUS/bbl': false,
    'galUS/ft3': false,
    'galUS/kgalUS': false,
    'galUS/Mbbl': false,
    'GEuc': true,
    'in2/ft2': false,
    'in2/in2': false,
    'kbyte': false,
    'kEuc': true,
    'kg/kg': true,
    'kg/sack[94lbm]': false,
    'kg/sack94': false,
    'kg/t': true,
    'kgf/kgf': false,
    'Kibyte': false,
    'km/cm': true,
    'L/10bbl': false,
    'L/m3': true,
    'lbf/lbf': false,
    'M(ft3)/acre.ft': false,
    'm/30m': false,
    'm/cm': true,
    'm/km': true,
    'm/m': true,
    'm2/m2': true,
    'm3/(ha.m)': true,
    'm3/bbl': false,
    'm3/ha.m': true,
    'm3/m3': true,
    'Mbyte': false,
    'mEuc': true,
    'MEuc': true,
    'mg/g': true,
    'mg/kg': true,
    'mi/in': false,
    'Mibyte': false,
    'mL/gal[UK]': false,
    'mL/gal[US]': false,
    'mL/galUK': false,
    'mL/galUS': false,
    'mL/mL': true,
    'mm2/mm2': true,
    'MMbbl/acre.ft': false,
    'mol/mol': true,
    'ms/s': true,
    'N/N': true,
    'nEuc': true,
    'ng/g': true,
    'ng/mg': true,
    'permil': false,
    'pEuc': true,
    'ppk': false,
    'ppm': false,
    'ppm[mass]': false,
    'ppm[vol]': false,
    'ptUK/Mbbl': false,
    'ptUS/10bbl': false,
    's/s': true,
    'TEuc': true,
    'uEuc': true,
    'ug/g': true,
    'ug/mg': true,
    'volpercent': false,
    'volppm': false,
    'W/kW': true,
    'W/W': true,
    'wtpercent': false,
    'wtppm': false,
    'C.m': true,
    'A.h': true,
    'A.s': true,
    'C': true,
    'cC': true,
    'dC': true,
    'EC': true,
    'fC': true,
    'GC': true,
    'kC': true,
    'mC': true,
    'MC': true,
    'nC': true,
    'pC': true,
    'TC': true,
    'uC': true,
    'C/cm2': true,
    'C/m2': true,
    'C/mm2': true,
    'mC/m2': true,
    'A.s/kg': true,
    'C/g': true,
    'C/kg': true,
    'A.s/m3': true,
    'C/cm3': true,
    'C/m3': true,
    'C/mm3': true,
    'cS': true,
    'dS': true,
    'ES': true,
    'fS': true,
    'GS': true,
    'kS': true,
    'mho': true,
    'mS': true,
    'MS': true,
    'nS': true,
    'pS': true,
    'S': true,
    'TS': true,
    'uS': true,
    'kS/m': true,
    'mho/m': true,
    'mmho/m': true,
    'mS/cm': true,
    'mS/m': true,
    'S/m': true,
    'A': true,
    'cA': true,
    'dA': true,
    'EA': true,
    'fA': true,
    'GA': true,
    'kA': true,
    'mA': true,
    'MA': true,
    'nA': true,
    'pA': true,
    'TA': true,
    'uA': true,
    'A/cm2': true,
    'A/ft2': false,
    'A/m2': true,
    'A/mm2': true,
    'mA/cm2': true,
    'mA/ft2': false,
    'uA/cm2': true,
    'uA/in2': false,
    'mV/ft': false,
    'mV/m': true,
    'uV/ft': false,
    'uV/m': true,
    'V/m': true,
    'cV': true,
    'dV': true,
    'fV': true,
    'GV': true,
    'kV': true,
    'mV': true,
    'MV': true,
    'nV': true,
    'pV': true,
    'TV': true,
    'uV': true,
    'V': true,
    'cohm': true,
    'dohm': true,
    'Eohm': true,
    'fohm': true,
    'Gohm': true,
    'kohm': true,
    'mohm': true,
    'Mohm': true,
    'nohm': true,
    'ohm': true,
    'pohm': true,
    'Tohm': true,
    'uohm': true,
    'ohm/m': true,
    'uohm/ft': false,
    'uohm/m': true,
    'kohm.m': true,
    'nohm.mil2/ft': false,
    'nohm.mm2/m': true,
    'ohm.cm': true,
    'ohm.m': true,
    'ohm.m2/m': true,
    'A.m2': true,
    '1000 lbf.ft': false,
    '1E6 Btu[IT]': false,
    'aJ': true,
    'Btu': false,
    'Btu[IT]': false,
    'Btu[th]': false,
    'Btu[UK]': false,
    'cal': false,
    'cal[IT]': false,
    'cal[th]': false,
    'ccal[th]': false,
    'ceV': true,
    'ch.h': false,
    'cJ': true,
    'CV.h': false,
    'daN.m': true,
    'dcal[th]': false,
    'deV': true,
    'dJ': true,
    'dN.m': true,
    'Ecal[th]': false,
    'EeV': true,
    'EJ': true,
    'erg': false,
    'eV': true,
    'fcal[th]': false,
    'feV': true,
    'fJ': true,
    'ft.lbf': false,
    'Gcal[th]': false,
    'GeV': true,
    'GJ': true,
    'GW.h': true,
    'hp.h': false,
    'hp.hr': false,
    'hp[metric].h': false,
    'J': true,
    'kcal': false,
    'kcal[th]': false,
    'keV': true,
    'kft.lbf': false,
    'kgf.m': false,
    'kJ': true,
    'kN.m': true,
    'kW.h': true,
    'lbf.ft': false,
    'lbf.in': false,
    'lbm.ft2/s2': false,
    'mcal[th]': false,
    'Mcal[th]': false,
    'MeV': true,
    'meV': true,
    'mJ': true,
    'MJ': true,
    'MW.h': true,
    'N.m': true,
    'ncal[th]': false,
    'neV': true,
    'nJ': true,
    'pcal[th]': false,
    'pdl.ft': false,
    'peV': true,
    'pJ': true,
    'quad': false,
    'Tcal[th]': false,
    'TeV': true,
    'therm': false,
    'therm[EC]': false,
    'therm[UK]': false,
    'therm[US]': false,
    'TJ': true,
    'tonf[US].ft': false,
    'tonf[US].mi': false,
    'tonfUS.ft': false,
    'tonfUS.mi': false,
    'TW.h': true,
    'ucal[th]': false,
    'ueV': true,
    'uJ': true,
    '0.01 lbf/ft': false,
    '1/30 lbf/m': false,
    '1/30 N/m': false,
    'dyne/cm': false,
    'erg/cm2': false,
    'J/cm2': true,
    'J/m2': true,
    'kgf.m/cm2': false,
    'kgf/cm': false,
    'kN/m': true,
    'lbf.ft/in2': false,
    'lbf/100ft': false,
    'lbf/30m': false,
    'lbf/ft': false,
    'lbf/in': false,
    'mJ/cm2': true,
    'mJ/m2': true,
    'mN/km': true,
    'mN/m': true,
    'N/30m': false,
    'N/m': true,
    'pdl/cm': false,
    'tonf[UK]/ft': false,
    'tonf[US]/ft': false,
    'tonfUK/ft': false,
    'tonfUS/ft': false,
    'Btu/lbm': false,
    'Btu[IT]/lbm': false,
    'cal/g': false,
    'cal/kg': false,
    'cal/lbm': false,
    'cal[th]/g': false,
    'cal[th]/kg': false,
    'cal[th]/lbm': false,
    'cGy': true,
    'crd': false,
    'dGy': true,
    'drd': false,
    'EGy': true,
    'Erd': false,
    'erg/g': false,
    'erg/kg': false,
    'fGy': true,
    'frd': false,
    'ft.lbf/lbm': false,
    'GGy': true,
    'Grd': false,
    'Gy': true,
    'hp.h/lbm': false,
    'hp.hr/lbm': false,
    'J/g': true,
    'J/kg': true,
    'kcal/g': false,
    'kcal/kg': false,
    'kcal[th]/g': false,
    'kcal[th]/kg': false,
    'kGy': true,
    'kJ/kg': true,
    'krd': false,
    'kW.h/kg': true,
    'lbf.ft/lbm': false,
    'mGy': true,
    'MGy': true,
    'MJ/kg': true,
    'mrd': false,
    'Mrd': false,
    'mrem': false,
    'mSv': true,
    'MW.h/kg': true,
    'nGy': true,
    'nrd': false,
    'pGy': true,
    'prd': false,
    'rd': false,
    'rem': true,
    'Sv': true,
    'TGy': true,
    'Trd': false,
    'uGy': true,
    'urd': false,
    '1E6 Btu[IT]/h': false,
    'Btu(million)/hr': false,
    'Btu/hr': false,
    'Btu/min': false,
    'Btu/s': false,
    'Btu[IT]/h': false,
    'Btu[IT]/min': false,
    'Btu[IT]/s': false,
    'cal[th]/h': false,
    'ch': false,
    'CV': false,
    'cW': true,
    'dW': true,
    'ehp': false,
    'EJ/a': false,
    'erg/a': false,
    'EW': true,
    'ft.lbf/min': false,
    'ft.lbf/s': false,
    'fW': true,
    'GW': true,
    'hhp': false,
    'hp': false,
    'hp[elec]': false,
    'hp[hyd]': false,
    'hp[metric]': false,
    'J/s': true,
    'kcal/h': false,
    'kcal[th]/h': false,
    'kW': true,
    'lbf.ft/min': false,
    'lbf.ft/s': false,
    'MBtu/hr': false,
    'MJ/a': false,
    'mW': true,
    'MW': true,
    'nW': true,
    'pW': true,
    'quad/a': false,
    'TJ/a': false,
    'ton of refrig': false,
    'tonRefrig': false,
    'TW': true,
    'ucal[th]/s': false,
    'uW': true,
    'W': true,
    '0.01 lbf/ft2': false,
    'at': false,
    'atm': false,
    'bar': true,
    'Btu/bbl': false,
    'Btu/ft3': false,
    'Btu/galUK': false,
    'Btu/galUS': false,
    'Btu[IT]/bbl': false,
    'Btu[IT]/ft3': false,
    'Btu[IT]/gal[UK]': false,
    'Btu[IT]/gal[US]': false,
    'cal/cm3': false,
    'cal/mL': false,
    'cal/mm3': false,
    'cal[th]/cm3': false,
    'cal[th]/mL': false,
    'cal[th]/mm3': false,
    'cmH2O(4degC)': false,
    'cmH2O[4degC]': false,
    'cPa': true,
    'dPa': true,
    'dyne/cm2': false,
    'EPa': true,
    'erg/cm3': false,
    'erg/m3': false,
    'fPa': true,
    'ft.lbf/bbl': false,
    'ft.lbf/galUS': false,
    'GPa': true,
    'hbar': false,
    'hp.h/bbl': false,
    'hp.hr/bbl': false,
    'inH2O(39.2F)': false,
    'inH2O(60F)': false,
    'inH2O[39degF]': false,
    'inH2O[60degF]': false,
    'inHg(32F)': false,
    'inHg(60F)': false,
    'inHg[32degF]': false,
    'inHg[60degF]': false,
    'J/dm3': true,
    'J/m3': true,
    'kcal/cm3': false,
    'kcal/m3': false,
    'kcal[th]/cm3': false,
    'kcal[th]/m3': false,
    'kgf/cm2': false,
    'kgf/m2': false,
    'kgf/mm2': false,
    'kJ/dm3': true,
    'kJ/m3': true,
    'kN/m2': true,
    'kPa': true,
    'kpsi': false,
    'kW.h/dm3': true,
    'kW.h/m3': true,
    'lbf.ft/bbl': false,
    'lbf.ft/gal[US]': false,
    'lbf/100ft2': false,
    'lbf/ft2': false,
    'lbf/ft2(100)': false,
    'lbf/in2': false,
    'mbar': false,
    'MJ/m3': true,
    'mmHg(0C)': false,
    'mmHg[0degC]': false,
    'mPa': true,
    'MPa': true,
    'Mpsi': false,
    'MW.h/m3': true,
    'N/m2': true,
    'N/mm2': true,
    'nPa': true,
    'Pa': true,
    'pPa': true,
    'psf': false,
    'psi': false,
    'tonf[UK]/ft2': false,
    'tonf[US].mi/bbl': false,
    'tonf[US]/ft2': false,
    'tonf[US]/in2': false,
    'tonfUK/ft2': false,
    'tonfUS.mi/bbl': false,
    'tonfUS/ft2': false,
    'tonfUS/in2': false,
    'torr': false,
    'TPa': true,
    'ubar': false,
    'umHg(0C)': false,
    'umHg[0degC]': false,
    'uPa': true,
    'upsi': false,
    '10 kN': false,
    'cN': true,
    'daN': true,
    'dN': true,
    'dyne': false,
    'EN': true,
    'fN': true,
    'gf': false,
    'GN': true,
    'hN': true,
    'J.m/m2': true,
    'J/m': true,
    'kcal.m/cm2': false,
    'kcal[th].m/cm2': false,
    'kdyne': false,
    'kgf': false,
    'kgf.m/m': false,
    'klbf': false,
    'kN': true,
    'lbf': false,
    'lbf.ft/in': false,
    'lbf.in/in': false,
    'Mgf': false,
    'MJ/m': true,
    'mN': true,
    'MN': true,
    'N': true,
    'N.m/m': true,
    'nN': true,
    'ozf': false,
    'pdl': false,
    'pN': true,
    'TN': true,
    'tonf[UK]': false,
    'tonf[US]': false,
    'tonf[US].mi/ft': false,
    'tonfUK': false,
    'tonfUS': false,
    'tonfUS.mi/ft': false,
    'uN': true,
    'dyne.cm2': false,
    'kgf.m2': false,
    'kN.m2': true,
    'lbf.in2': false,
    'mN.m2': true,
    'N.m2': true,
    'pdl.cm2': false,
    'tonf[UK].ft2': false,
    'tonf[US].ft2': false,
    'tonfUK.ft2': false,
    'tonfUS.ft2': false,
    '0.001 psi/ft': false,
    '0.01 psi/ft': false,
    'atm/ft': false,
    'atm/hm': false,
    'atm/m': false,
    'bar/km': true,
    'bar/m': true,
    'GPa/cm': true,
    'kPa/100m': true,
    'kPa/hm': true,
    'kPa/m': true,
    'lbf/ft3': false,
    'lbf/gal[US]': false,
    'lbf/galUS': false,
    'MPa/m': true,
    'N/m3': true,
    'Pa/m': true,
    'psi/100ft': false,
    'psi/ft': false,
    'psi/ft(100)': false,
    'psi/kft': false,
    'psi/m': false,
    'J/deltaK': true,
    'J/K': true,
    'Btu/hr.ft2.degF': false,
    'Btu/hr.ft2.degR': false,
    'Btu/hr.m2.degC': false,
    'Btu/s.ft2.degF': false,
    'Btu[IT]/(h.ft2.deltaF)': false,
    'Btu[IT]/(h.ft2.deltaR)': false,
    'Btu[IT]/(h.m2.deltaC)': false,
    'Btu[IT]/(s.ft2.deltaF)': false,
    'cal/h.cm2.degC': false,
    'cal/s.cm2.degC': false,
    'cal[th]/(h.cm2.deltaC)': false,
    'cal[th]/(s.cm2.deltaC)': false,
    'J/(s.m2.deltaC)': true,
    'J/s.m2.degC': true,
    'kcal/h.m2.degC': false,
    'kcal[th]/(h.m2.deltaC)': false,
    'kJ/(h.m2.deltaK)': true,
    'kJ/h.m2.K': true,
    'kW/(m2.deltaK)': true,
    'kW/m2.K': true,
    'W/(m2.deltaK)': true,
    'W/m2.K': true,
    'footcandle': false,
    'klx': true,
    'lm/m2': true,
    'lx': true,
    'cH': true,
    'dH': true,
    'EH': true,
    'fH': true,
    'GH': true,
    'H': true,
    'kH': true,
    'mH': true,
    'MH': true,
    'nH': true,
    'TH': true,
    'uH': true,
    '0.1 ft': false,
    '0.1 ft[US]': false,
    '0.1 in': false,
    '0.1 yd': false,
    '1/16 in': false,
    '1/2 ft': false,
    '1/32 in': false,
    '1/64 in': false,
    '10 ft': false,
    '10 in': false,
    '10 km': false,
    '100 ft': false,
    '100 km': false,
    '1000 ft': false,
    '1E6 bbl/acre': false,
    '30 ft': false,
    '30 m': false,
    'angstrom': true,
    'bbl/acre': false,
    'chain': false,
    'chain[BnA]': false,
    'chain[BnB]': false,
    'chain[Cla]': false,
    'chain[Ind37]': false,
    'chain[Se]': false,
    'chain[SeT]': false,
    'chain[US]': false,
    'chBnA': false,
    'chBnB': false,
    'chCla': false,
    'chSe': false,
    'chUS': false,
    'cm': true,
    'dam': true,
    'dm': true,
    'Em': true,
    'fathom': false,
    'fm': true,
    'ft': false,
    'ft[BnA]': false,
    'ft[BnB]': false,
    'ft[Br36]': false,
    'ft[Br65]': false,
    'ft[Cla]': false,
    'ft[GC]': false,
    'ft[Ind]': false,
    'ft[Ind37]': false,
    'ft[Ind62]': false,
    'ft[Ind75]': false,
    'ft[Se]': false,
    'ft[SeT]': false,
    'ft[US]': false,
    'ft3/ft2': false,
    'ftBnA': false,
    'ftBnB': false,
    'ftBr(65)': false,
    'ftCla': false,
    'ftGC': false,
    'ftInd': false,
    'ftInd(37)': false,
    'ftInd(62)': false,
    'ftInd(75)': false,
    'ftSe': false,
    'ftUS': false,
    'fur[US]': false,
    'Gm': true,
    'hm': true,
    'in': false,
    'in/10': false,
    'in/16': false,
    'in/32': false,
    'in/64': false,
    'in[US]': false,
    'inUS': false,
    'km': true,
    'link': false,
    'link[BnA]': false,
    'link[BnB]': false,
    'link[Cla]': false,
    'link[Se]': false,
    'link[SeT]': false,
    'link[US]': false,
    'lkBnA': false,
    'lkBnB': false,
    'lkCla': false,
    'lkSe': false,
    'lkUS': false,
    'm': true,
    'm[Ger]': false,
    'm3/m2': true,
    'mGer': false,
    'mi': false,
    'mi[naut]': true,
    'mi[nautUK]': false,
    'mi[US]': false,
    'mil': false,
    'miUS': false,
    'Mm': true,
    'mm': true,
    'nautmi': true,
    'nm': true,
    'pm': true,
    'rod[US]': false,
    'Tm': true,
    'um': true,
    'yd': false,
    'yd[BnA]': false,
    'yd[BnB]': false,
    'yd[Cla]': false,
    'yd[Ind]': false,
    'yd[Ind37]': false,
    'yd[Ind62]': false,
    'yd[Ind75]': false,
    'yd[Se]': false,
    'yd[SeT]': false,
    'yd[US]': false,
    'ydBnA': false,
    'ydBnB': false,
    'ydCla': false,
    'ydIm': false,
    'ydInd': false,
    'ydInd(37)': false,
    'ydInd(62)': false,
    'ydInd(75)': false,
    'ydSe': false,
    'ft/lbm': false,
    'm/kg': true,
    'ft/psi': false,
    'm/kPa': true,
    'm/Pa': true,
    'ft/degF': false,
    'ft/deltaF': false,
    'm/deltaK': true,
    'm/K': true,
    '1000 ft/h': false,
    '1000 ft/s': false,
    'cm/a': false,
    'cm/s': true,
    'dm/s': true,
    'ft/d': false,
    'ft/h': false,
    'ft/min': false,
    'ft/ms': false,
    'ft/s': false,
    'ft/us': false,
    'ft3/(min.ft2)': false,
    'ft3/(s.ft2)': false,
    'ft3/min.ft2': false,
    'ft3/s.ft2': false,
    'gal[UK]/(h.ft2)': false,
    'gal[UK]/(h.in2)': false,
    'gal[UK]/(min.ft2)': false,
    'gal[US]/(h.ft2)': false,
    'gal[US]/(h.in2)': false,
    'gal[US]/(min.ft2)': false,
    'galUK/hr.ft2': false,
    'galUK/hr.in2': false,
    'galUK/min.ft2': false,
    'galUS/hr.ft2': false,
    'galUS/hr.in2': false,
    'galUS/min.ft2': false,
    'in/a': false,
    'in/min': false,
    'in/s': false,
    'kft/h': false,
    'kft/s': false,
    'km/h': true,
    'km/s': true,
    'knot': true,
    'm/d': true,
    'm/h': true,
    'm/min': true,
    'm/ms': true,
    'm/s': true,
    'm3/(s.m2)': true,
    'm3/s.m2': true,
    'mi/h': false,
    'mil/a': false,
    'mm/a': false,
    'mm/s': true,
    'nm/s': true,
    'um/s': true,
    'footcandle.s': false,
    'lx.s': true,
    'cm/s2': true,
    'ft/s2': false,
    'Gal': false,
    'gn': false,
    'in/s2': false,
    'm/s2': true,
    'mGal': false,
    'mgn': false,
    'cd/m2': true,
    'lm/W': true,
    'lm': true,
    'cd': true,
    'kcd': true,
    'Wb.m': true,
    'A/m': true,
    'A/mm': true,
    'Oe': false,
    'cWb': true,
    'dWb': true,
    'EWb': true,
    'fWb': true,
    'GWb': true,
    'kWb': true,
    'mWb': true,
    'MWb': true,
    'nWb': true,
    'pWb': true,
    'TWb': true,
    'uWb': true,
    'Wb': true,
    'cgauss': false,
    'cT': true,
    'dgauss': false,
    'dT': true,
    'Egauss': false,
    'ET': true,
    'fgauss': false,
    'fT': true,
    'gauss': false,
    'Ggauss': false,
    'GT': true,
    'kgauss': false,
    'kT': true,
    'mgauss': false,
    'Mgauss': false,
    'mT': true,
    'ngauss': false,
    'nT': true,
    'pgauss': false,
    'pT': true,
    'T': true,
    'Tgauss': false,
    'TT': true,
    'ugauss': false,
    'uT': true,
    'gauss/cm': false,
    'mT/dm': true,
    'T/m': true,
    'H/m': true,
    'uH/m': true,
    'Wb/m': true,
    'Wb/mm': true,
    'ag': true,
    'cg': true,
    'ct': false,
    'cwt[UK]': false,
    'cwt[US]': false,
    'cwtUK': false,
    'cwtUS': false,
    'Eg': true,
    'fg': true,
    'g': true,
    'Gg': true,
    'grain': false,
    'hg': true,
    'kg': true,
    'klbm': false,
    'lbm': false,
    'mg': true,
    'Mg': true,
    'ng': true,
    'oz(av)': false,
    'oz(troy)': false,
    'ozm': false,
    'ozm[troy]': false,
    'pg': true,
    'sack[94lbm]': false,
    'sack94': false,
    't': true,
    'Tg': true,
    'ton[UK]': false,
    'ton[US]': false,
    'tonUK': false,
    'tonUS': false,
    'ug': true,
    'ft.lbm': false,
    'kg.m': true,
    'lbm.ft': false,
    'g/mol': true,
    'kg/mol': true,
    'lbm/lbmol': false,
    '0.01 lbm/ft2': false,
    'kg/m2': true,
    'lbm/100ft2': false,
    'lbm/ft2': false,
    'Mg/m2': true,
    'ton[US]/ft2': false,
    'tonUS/ft2': false,
    'kg/(kW.h)': true,
    'kg/J': true,
    'kg/kW.h': true,
    'kg/MJ': true,
    'lbm/(hp.h)': false,
    'lbm/hp.h': false,
    'mg/J': true,
    'kg.m/cm2': true,
    'kg/m': true,
    'klbm/in': false,
    'lbm/ft': false,
    'Mg/in': false,
    '1E6 lbm/a': false,
    'g/s': true,
    'kg/d': true,
    'kg/h': true,
    'kg/min': true,
    'kg/s': true,
    'lbm/d': false,
    'lbm/h': false,
    'lbm/min': false,
    'lbm/s': false,
    'Mg/a': false,
    'Mg/d': true,
    'Mg/h': true,
    'Mg/min': true,
    't/a': false,
    't/d': true,
    't/h': true,
    't/min': true,
    'ton[UK]/a': false,
    'ton[UK]/d': false,
    'ton[UK]/h': false,
    'ton[UK]/min': false,
    'ton[US]/a': false,
    'ton[US]/d': false,
    'ton[US]/h': false,
    'ton[US]/min': false,
    'tonUK/d': false,
    'tonUK/h': false,
    'tonUK/min': false,
    'tonUS/d': false,
    'tonUS/h': false,
    'tonUS/min': false,
    '0.001 lbm/bbl': false,
    '0.001 lbm/gal[UK]': false,
    '0.001 lbm/gal[US]': false,
    '0.01 grain/ft3': false,
    '0.1 lbm/bbl': false,
    '10 Mg/m3': false,
    '10Mg/m3': false,
    'g/cm3': true,
    'g/dm3': true,
    'g/gal[UK]': false,
    'g/gal[US]': false,
    'g/galUK': false,
    'g/galUS': false,
    'g/L': true,
    'g/m3': true,
    'grain/100ft3': false,
    'grain/ft3': false,
    'grain/ft3(100)': false,
    'grain/gal[US]': false,
    'grain/galUS': false,
    'kg/dm3': true,
    'kg/L': true,
    'kg/m3': true,
    'lbm/1000galUK': false,
    'lbm/1000galUS': false,
    'lbm/10bbl': false,
    'lbm/bbl': false,
    'lbm/ft3': false,
    'lbm/gal[UK]': false,
    'lbm/gal[US]': false,
    'lbm/galUK': false,
    'lbm/galUK(1000)': false,
    'lbm/galUS': false,
    'lbm/galUS(1000)': false,
    'lbm/in3': false,
    'lbm/Mbbl': false,
    'mg/dm3': true,
    'mg/gal[US]': false,
    'mg/galUS': false,
    'mg/L': true,
    'Mg/m3': true,
    'mg/m3': true,
    't/m3': true,
    'ug/cm3': true,
    'Btu/lbmole': false,
    'Btu/mol(lbm)': false,
    'Btu[IT]/lbmol': false,
    'J/mol': true,
    'kcal/mol': false,
    'kcal/mol(g)': false,
    'kcal[th]/mol': false,
    'kJ/kmol': true,
    'kJ/mol(kg)': true,
    'MJ/kmol': true,
    'MJ/mol(kg)': true,
    'Btu/lbmole.F': false,
    'Btu/mol(lbm).F': false,
    'Btu[IT]/(lbmol.deltaF)': false,
    'cal/mol(g).degC': false,
    'cal/mol.degC': false,
    'cal[th]/(mol.deltaC)': false,
    'J/(mol.deltaK)': true,
    'J/mol.K': true,
    'kJ/(kmol.deltaK)': true,
    'kJ/kmol.K': true,
    'kJ/mol(kg).K': true,
    'dm3/kmol': true,
    'dm3/mol(kg)': true,
    'ft3/lbmol': false,
    'ft3/lbmole': false,
    'ft3/mol(lbm)': false,
    'L/kmol': true,
    'L/mol': true,
    'L/mol(g)': true,
    'L/mol(kg)': true,
    'm3/kmol': true,
    'm3/mol': true,
    'm3/mol(kg)': true,
    'kg.m2': true,
    'lbm.ft2': false,
    'kg.m/s': true,
    'lbm.ft/s': false,
    'B': true,
    'B.W': true,
    'B/m': true,
    'B/O': true,
    'dAPI': true,
    'dB': true,
    'dB.mW': true,
    'dB.MW': true,
    'dB.W': true,
    'dB/ft': false,
    'dB/km': true,
    'dB/m': true,
    'dB/O': true,
    'gAPI': true,
    'nAPI': true,
    'O': true,
    'V/B': true,
    'V/dB': true,
    'F/m': true,
    'uF/m': true,
    '0.001 seca': false,
    'c': false,
    'ccgr': false,
    'cgr': false,
    'dega': true,
    'gon': false,
    'gr': false,
    'krad': true,
    'mila': false,
    'mina': true,
    'mrad': true,
    'Mrad': true,
    'mseca': false,
    'deg': true,
    'rad': true,
    'rev': false,
    'seca': true,
    'urad': true,
    'Btu/hr.ft2': false,
    'Btu/s.ft2': false,
    'Btu[IT]/(h.ft2)': false,
    'Btu[IT]/(s.ft2)': false,
    'cal/h.cm2': false,
    'cal[th]/(h.cm2)': false,
    'hhp/in2': false,
    'hp/in2': false,
    'hp[hyd]/in2': false,
    'kW/cm2': true,
    'kW/m2': true,
    'mW/m2': true,
    'ucal/s.cm2': false,
    'ucal[th]/(s.cm2)': false,
    'W/cm2': true,
    'W/m2': true,
    'W/mm2': true,
    '0.001 kPa2/cP': false,
    'atm/h': false,
    'bar/h': true,
    'bar2/cP': false,
    'Btu/hr.ft3': false,
    'Btu/s.ft3': false,
    'Btu[IT]/(h.ft3)': false,
    'Btu[IT]/(s.ft3)': false,
    'cal/h.cm3': false,
    'cal/s.cm3': false,
    'cal[th]/(h.cm3)': false,
    'cal[th]/(s.cm3)': false,
    'hp/ft3': false,
    'kPa/h': true,
    'kPa/min': true,
    'kPa2/cP': false,
    'kPa2/kcP': false,
    'kW/m3': true,
    'MPa/h': true,
    'Pa/h': true,
    'Pa/s': true,
    'Pa2/(Pa.s)': true,
    'psi/h': false,
    'psi/min': false,
    'psi2/cP': false,
    'uW/m3': true,
    'W/m3': true,
    'Pa/m3': true,
    'psi2.d/(cP.ft3)': false,
    'psi2.d/cP.ft3': false,
    'psi2.d/cp.ft3': false,
    'bar2': true,
    'GPa2': true,
    'kPa2': true,
    'kpsi2': false,
    'Pa2': true,
    'psi2': false,
    'Pa.s/m3': true,
    'psi.d/bbl': false,
    'lm.s': true,
    'talbot': true,
    'W/(m2.sr)': true,
    'W/m2.sr': true,
    'W/sr': true,
    '1/(kg.s)': true,
    'Bq/kg': true,
    'pCi/g': false,
    '1/ft2': false,
    '1/km2': true,
    '1/m2': true,
    '1/mi2': false,
    'ft/bbl': false,
    'ft/ft3': false,
    'ft/gal[US]': false,
    'ft/galUS': false,
    'km/dm3': true,
    'km/L': true,
    'm/m3': true,
    'mi/gal[UK]': false,
    'mi/gal[US]': false,
    'mi/galUK': false,
    'mi/galUS': false,
    '1/uV': true,
    '1/V': true,
    '1/lbf': false,
    '1/N': true,
    '1/angstrom': true,
    '1/cm': true,
    '1/ft': false,
    '1/in': false,
    '1/m': true,
    '1/mi': false,
    '1/mm': true,
    '1/nm': true,
    '1/yd': false,
    '1E-9 1/ft': false,
    'b/cm3': true,
    'cu': false,
    'ft2/in3': false,
    'm2/cm3': true,
    'm2/m3': true,
    'sigma': false,
    '1/g': true,
    '1/kg': true,
    '1/lbm': false,
    '1/bar': true,
    '1/kPa': true,
    '1/Pa': true,
    '1/pPa': true,
    '1/psi': false,
    '1/upsi': false,
    'dm3/(kW.h)': true,
    'dm3/kW.h': true,
    'dm3/MJ': true,
    'm3/(kW.h)': true,
    'm3/J': true,
    'm3/kW.h': true,
    'mm3/J': true,
    'pt[UK]/(hp.h)': false,
    'ptUK/hp.hr': false,
    '1/degC': true,
    '1/degF': false,
    '1/degR': false,
    '1/deltaC': true,
    '1/deltaF': false,
    '1/deltaK': true,
    '1/deltaR': false,
    '1/K': true,
    '1E-6 m3/(m3.deltaC)': false,
    '1E-6 m3/(m3.deltaF)': false,
    'in/(in.deltaF)': false,
    'in/in.degF': false,
    'm/(m.deltaK)': true,
    'm/m.K': true,
    'm3/(m3.deltaK)': true,
    'mm/(mm.deltaK)': true,
    'mm/mm.K': true,
    'ppm/degC': false,
    'ppm/degF': false,
    'ppm[vol]/deltaC': false,
    'ppm[vol]/deltaF': false,
    '1/a': false,
    '1/d': true,
    '1/h': true,
    '1/min': true,
    '1/ms': true,
    '1/s': true,
    '1/us': true,
    '1/wk': false,
    'bbl/(d.acre.ft)': false,
    'bbl/d.acre.ft': false,
    'Bd': true,
    'bit/s': true,
    'bps': true,
    'Bq': true,
    'byte/s': false,
    'cHz': true,
    'Ci': true,
    'curie': true,
    'dHz': true,
    'EHz': true,
    'fHz': true,
    'flops': true,
    'GBq': true,
    'GHz': true,
    'Hz': true,
    'kEuc/s': true,
    'kHz': true,
    'm3/(s.m3)': true,
    'MBq': true,
    'mCi': false,
    'mcurie': false,
    'Mflops': true,
    'mHz': true,
    'MHz': true,
    'nCi': false,
    'ncurie': false,
    'nHz': true,
    'pCi': false,
    'pcurie': false,
    'pHz': true,
    'TBq': true,
    'THz': true,
    'uCi': false,
    'ucurie': false,
    'uHz': true,
    '1/bbl': false,
    '1/ft3': false,
    '1/gal[UK]': false,
    '1/gal[US]': false,
    '1/galUK': false,
    '1/galUS': false,
    '1/L': true,
    '1/m3': true,
    '1/H': true,
    'cm4': true,
    'in4': false,
    'm4': true,
    'sr': true,
    'Btu/lbm.degF': false,
    'Btu/lbm.degR': false,
    'Btu[IT]/(lbm.deltaF)': false,
    'Btu[IT]/(lbm.deltaR)': false,
    'cal/g.K': false,
    'cal[th]/(g.deltaK)': false,
    'J/(g.deltaK)': true,
    'J/(kg.deltaK)': true,
    'J/g.K': true,
    'J/kg.K': true,
    'kcal/kg.degC': false,
    'kcal[th]/(kg.deltaC)': false,
    'kJ/(kg.deltaK)': true,
    'kJ/kg.K': true,
    'kW.h/(kg.deltaC)': true,
    'kW.h/kg.degC': true,
    'ddegC': true,
    'ddegF': false,
    'ddegK': true,
    'ddegR': false,
    'deltaC': true,
    'deltaF': false,
    'deltaK': true,
    'deltaR': false,
    '0.01 deltaF/ft': false,
    'degC/100m': true,
    'degC/ft': false,
    'degC/km': true,
    'degC/m': true,
    'degF/100ft': false,
    'degF/ft': false,
    'degF/ft(100)': false,
    'degF/m': false,
    'deltaC/ft': false,
    'deltaC/hm': true,
    'deltaC/km': true,
    'deltaC/m': true,
    'deltaF/ft': false,
    'deltaF/m': false,
    'deltaK/km': true,
    'deltaK/m': true,
    'K/m': true,
    'mK/m': true,
    'deltaC/kPa': true,
    'deltaF/psi': false,
    'deltaK/Pa': true,
    'degC/h': true,
    'degC/min': true,
    'degC/s': true,
    'degF/h': false,
    'degF/min': false,
    'degF/s': false,
    'deltaC/h': true,
    'deltaC/min': true,
    'deltaC/s': true,
    'deltaF/h': false,
    'deltaF/min': false,
    'deltaF/s': false,
    'deltaK/s': true,
    'K/s': true,
    'W/deltaK': true,
    'W/K': true,
    'Btu.in/hr.ft2.F': false,
    'Btu/hr.ft.degF': false,
    'Btu[IT].in/(h.ft2.deltaF)': false,
    'Btu[IT]/(h.ft.deltaF)': false,
    'cal/h.cm.degC': false,
    'cal/s.cm.degC': false,
    'cal[th]/(h.cm.deltaC)': false,
    'cal[th]/(s.cm.deltaC)': false,
    'J.m/(s.m2.deltaK)': true,
    'kcal/h.m.degC': false,
    'kcal[th]/(h.m.deltaC)': false,
    'kJ.m/(h.m2.deltaK)': true,
    'kJ.m/h.m2.K': true,
    'W/(m.deltaK)': true,
    'W/m.K': true,
    'degC.m2.h/kcal': false,
    'degF.ft2.h/Btu': false,
    'deltaC.m2.h/kcal[th]': false,
    'deltaF.ft2.h/Btu[IT]': false,
    'deltaK.m2/kW': true,
    'deltaK.m2/W': true,
    'K.m2/kW': true,
    'K.m2/W': true,
    'deltaK/W': true,
    'K/W': true,
    'degC': true,
    'degF': false,
    'degR': false,
    'K': true,
    '1/2 ms': false,
    '100 ka[t]': false,
    'a': false,
    'a[t]': false,
    'ca': false,
    'cs': true,
    'd': true,
    'ds': true,
    'Ea[t]': false,
    'fa': false,
    'Ga[t]': false,
    'h': true,
    'hs': true,
    'ka[t]': false,
    'Ma[t]': false,
    'min': true,
    'ms': true,
    'ms/2': false,
    'na': false,
    'ns': true,
    'ps': true,
    's': true,
    'Ta[t]': false,
    'us': true,
    'wk': false,
    '0.001 h/ft': false,
    'h/kft': false,
    'h/km': true,
    'min/ft': false,
    'min/m': true,
    'ms/cm': true,
    'ms/ft': false,
    'ms/in': false,
    'ms/m': true,
    'ns/ft': false,
    'ns/m': true,
    's/cm': true,
    's/ft': false,
    's/in': false,
    's/m': true,
    'us/ft': false,
    'us/in': false,
    'us/m': true,
    's/kg': true,
    '0.001 d/ft3': false,
    'd/bbl': false,
    'd/ft3': false,
    'd/k(ft3)': false,
    'd/m3': true,
    'h/ft3': false,
    'h/m3': true,
    's/ft3': false,
    's/L': true,
    's/m3': true,
    's/qt[UK]': false,
    's/qt[US]': false,
    's/qtUK': false,
    's/qtUS': false,
    '1000 bbl': false,
    '1000 ft3': false,
    '1000 gal[UK]': false,
    '1000 gal[US]': false,
    '1000 m3': false,
    '1000ft3': false,
    '1E12 ft3': false,
    '1E6 bbl': false,
    '1E6 ft3': false,
    '1E-6 gal[US]': false,
    '1E6 m3': false,
    '1E9 bbl': false,
    '1E9 ft3': false,
    'acre.ft': false,
    'bbl': false,
    'bcf': false,
    'cm3': true,
    'cu ft': false,
    'cu in': false,
    'cu yd': false,
    'cubem': false,
    'D.ft': false,
    'D.m': false,
    'dm3': true,
    'fl ozUK': false,
    'fl ozUS': false,
    'floz[UK]': false,
    'floz[US]': false,
    'flozUK': false,
    'flozUS': false,
    'ft3': false,
    'gal[UK]': false,
    'gal[US]': false,
    'galUK': false,
    'galUS': false,
    'ha.m': true,
    'hL': true,
    'in3': false,
    'km3': true,
    'L': true,
    'M(ft3)': false,
    'M(m3)': false,
    'm3': true,
    'Mbbl': false,
    'Mcf': false,
    'mD.ft': false,
    'mD.m': false,
    'mi3': false,
    'mL': true,
    'mm3': true,
    'MMbbl': false,
    'MMcf': false,
    'pt[UK]': false,
    'pt[US]': false,
    'ptUK': false,
    'ptUS': false,
    'qt[UK]': false,
    'qt[US]': false,
    'qtUK': false,
    'qtUS': false,
    'tcf': false,
    'TD[API].m': true,
    'um2.m': true,
    'yd3': false,
    '0.01 L/kg': false,
    'bbl/ton[UK]': false,
    'bbl/ton[US]': false,
    'bbl/tonUK': false,
    'bbl/tonUS': false,
    'cm3/g': true,
    'dm3/kg': true,
    'dm3/t': true,
    'ft3/kg': false,
    'ft3/lbm': false,
    'ft3/sack[94lbm]': false,
    'ft3/sack94': false,
    'gal/sack': false,
    'gal[UK]/lbm': false,
    'gal[US]/lbm': false,
    'gal[US]/sack[94lbm]': false,
    'gal[US]/ton[UK]': false,
    'gal[US]/ton[US]': false,
    'galUK/lbm': false,
    'galUS/lbm': false,
    'galUS/sack94': false,
    'galUS/tonUK': false,
    'galUS/tonUS': false,
    'L/100kg': false,
    'L/kg': true,
    'L/t': true,
    'L/ton[UK]': false,
    'L/tonUK': false,
    'm3/g': true,
    'm3/kg': true,
    'm3/t': true,
    'm3/ton[UK]': false,
    'm3/ton[US]': false,
    'm3/tonUK': false,
    'm3/tonUS': false,
    'bbl/psi': false,
    'm3/kPa': true,
    'm3/Pa': true,
    'ft3/rad': false,
    'm3/rad': true,
    'm3/rev': false,
    '1/30 cm3/min': false,
    '1000 bbl/d': false,
    '1000 ft3/d': false,
    '1000 m3/d': false,
    '1000 m3/h': false,
    '1000ft3/d': false,
    '1000m3/d': false,
    '1000m3/h': false,
    '1E6 bbl/d': false,
    '1E6 ft3/d': false,
    '1E6 m3/d': false,
    'bbl/d': false,
    'bbl/h': false,
    'bbl/hr': false,
    'bbl/min': false,
    'cm3/30min': false,
    'cm3/h': true,
    'cm3/min': true,
    'cm3/s': true,
    'dm3/s': true,
    'ft3/d': false,
    'ft3/h': false,
    'ft3/min': false,
    'ft3/s': false,
    'gal[UK]/d': false,
    'gal[UK]/h': false,
    'gal[UK]/min': false,
    'gal[US]/d': false,
    'gal[US]/h': false,
    'gal[US]/min': false,
    'galUK/d': false,
    'galUK/hr': false,
    'galUK/min': false,
    'galUS/d': false,
    'galUS/hr': false,
    'galUS/min': false,
    'kbbl/d': false,
    'L/h': true,
    'L/min': true,
    'L/s': true,
    'M(ft3)/d': false,
    'M(m3)/d': false,
    'm3/d': true,
    'm3/h': true,
    'm3/min': true,
    'm3/s': true,
    'Mbbl/d': false,
    'Btu/hr.ft3.degF': false,
    'Btu/s.ft3.degF': false,
    'Btu[IT]/(h.ft3.deltaF)': false,
    'Btu[IT]/(s.ft3.deltaF)': false,
    'kW/(m3.deltaK)': true,
    'kW/m3.K': true,
    'W/(m3.deltaK)': true,
    'W/m3.K': true
}
