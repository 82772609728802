import { NgModule } from "@angular/core";
import { StateDetailsChartComponent, StateDetailsComponent, StateDetailsGroupComponent, StateDetailsTableComponent } from ".";
import { PlotlyViaWindowModule } from "angular-plotly.js";
import { CommonModule } from "@angular/common";
import { AgGridCustomModule } from "@/_ag-grid/ag-grid.component";

@NgModule({
    declarations: [StateDetailsComponent, StateDetailsChartComponent, StateDetailsTableComponent, StateDetailsGroupComponent],
    exports: [StateDetailsComponent],
    imports: [
        CommonModule,
        PlotlyViaWindowModule,
        AgGridCustomModule.withComponents([ ]),
    ],
  })
  export class StateDetailsModule { }
