import { Measure } from '..';
import { DapDapStatus, DapSectionStatus, DapDocumentStatus } from '../dap-common';
import { DapDocumentChange } from './dap-document-change';

export class DapDocumentDetailsData {
    public activeDocChangeData: ActiveDocChangeData;
    public content: DapDocumentContent;
    public status: DapDocumentStatus;
    public businessState: string;
}

export class ActiveDocChangeData
{
    public changedById: number;
    public changeDate: Date;
    public changeDescription: string;
    public changeTicket: string;
    public isCritical: boolean;
    public changes: DapDocumentChange[];
}

export class DapDocumentContent {
    public title: string;
    public fields: DapDocumentContentField[];
    public tables: DapDocumentContentTable[];
    public sections: DapDocumentContentSection[];
}

export class DapDocumentContentFieldValue {
    public str: string = null;
    public text: string = null;
    public int: number = null;
    public bool: boolean = null;
    public float: number = null;
    public datetime: Date = null;
    public measure: Measure = null;
    public props: DapDocumentContentField[] = null;
}

export class DapDocumentContentFieldValueDisable extends DapDocumentContentFieldValue {
    public disabled?: boolean;
}

export class DapDocumentContentField extends DapDocumentContentFieldValue {
    public key: string;
}

export class DapDocumentContentTable {
    public key: string;
    public columns: string[];
    public rows: DapDocumentContentFieldValue[][];
}

export class DapDocumentContentSection {
    public key: string;
    public name: string;
    public sectionTypeKey: string;
    public fields: DapDocumentContentField[];
    public tables: DapDocumentContentTable[];
    public daps: DapDocumentContentDap[];

    public scoring: DapSectionScoring;
    public status: DapSectionStatus;
}

export class DapSectionScoring {
    public mayOverrideInclusion: boolean;
    public includeInScoring: boolean;
    public mayOverrideWeighting: boolean;
    public sectionWeight: number;
    public disposition: string[];
}

export class DapDocumentContentDap {
    public key: string;
    public name: string;
    public dapTypeKey: string;
    public fields: DapDocumentContentField[];
    public tables: DapDocumentContentTable[];

    public scoring: DapDapScoring;
    public status: DapDapStatus;
    public dapRefNum: number;
    public serviceProviderId: number;
}

export class DapDapScoring {
    public mayOverrideInclusion: boolean;
    public includeInScoring: boolean;
    public mayOverrideWeighting: boolean;
    public dapWeight: number;
    public serviceLevelAgreement: number;
    public delivery: DapDapWeighting;
    public quality: DapDapWeighting;
    public disposition: string[];
}

export class DapDapWeighting {
    public weight: number;
    public breachWeight: number;
}
