import { OrderStatus } from './order-status';

export class OrderWithExtendedData {
    public id: number;
    public name: string;
    public rigName?: string;
    public wellName?: string;
    public wellId?: string;
    public wellboreName?: string;
    public wellboreId?: string;
    public region?: string;
    public country?: string;
    public field?: string;
    public orderStartDate?: Date;
    public orderStatus?: OrderStatus;
    public completedSectionCount?: number;
    public totalSectionCount?: number;
    /** The name of the currently active or the next planned section. */
    public sectionName?: string;
    /** The id of the currently active or the next planned section. */
    public sectionId?: number;
    public isSectionActive?: boolean;
}
