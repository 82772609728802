import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';

import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { RuleManagementService } from '@/_services';
import {
    FetchRuleManagementFilterListsAction, LoadInStateRuleManagementFilterListsAction, FetchOkRuleManagementFilterListsAction,
    FetchFailedRuleManagementFilterListsAction,
    FetchRuleInstanceDataAction, FetchOkRuleInstanceDataAction, LoadInStateRuleInstanceDataAction, FetchFailedRuleInstanceDataAction,
    ClearInStateRuleInstanceParametersAction,
    LoadInStateRuleInstanceParametersAction,
    FetchRuleInstanceParametersAction,
    FetchOkRuleInstanceParametersAction,
    FetchFailedRuleInstanceParametersAction,
    UpdateRuleInstanceParametersAction,
    UpdateOkRuleInstanceParametersAction,
    UpdateFailedRuleInstanceParametersAction,
    ClearInStateRuleInstanceDataAction, FetchCopyFromOrderListAction, LoadInStateCopyFromOrderListAction, FetchOkCopyFromOrderListAction,
    FetchFailedCopyFromOrderListAction, CopyParameterOverridesAction, CopyParameterOverridesOkAction, CopyParameterOverridesFailedAction,
    ClearInStateCopyFromOrderListAction,
    FetchRuleInstanceParametersOverrideIndicatorsAction,
    FetchOkRuleInstanceParametersOverrideIndicatorsAction,
    FetchFailedRuleInstanceParametersOverrideIndicatorsAction,
    UpdateInStateRuleInstanceParametersOverrideIndicatorsAction,
    FetchRuleInstanceParametersOverrideIndicatorsWithDelayAction,
} from './rule-management.actions';

@Injectable()
export class RuleManagementEffects {
    
    public onFetchFilterLists$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchRuleManagementFilterListsAction),
        switchMap(() => this.ruleManagementService.getFilterLists().pipe(
            switchMap(y => [new LoadInStateRuleManagementFilterListsAction(y), new FetchOkRuleManagementFilterListsAction()]),
            catchError(error => {
                this.logger.error('Error while fetch of filter lists', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedRuleManagementFilterListsAction(error));
            })
        ))
    ));

    
    public onFetchRuleInstanceData$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchRuleInstanceDataAction),
        switchMap(x => this.ruleManagementService.getRuleInstanceData(x.filters).pipe(
            switchMap(y => [new LoadInStateRuleInstanceDataAction(y), new FetchOkRuleInstanceDataAction()]),
            catchError(error => {
                this.logger.error('Error while fetch of rule instance data', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedRuleInstanceDataAction(error));
            })
        ))
    ));

    
    public onClearRuleInstanceData$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearInStateRuleInstanceDataAction),
        switchMap(() => [new LoadInStateRuleInstanceDataAction(undefined)])
    ));

    
    public onFetchRuleInstanceParameters$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchRuleInstanceParametersAction),
        switchMap(x => this.ruleManagementService.getRuleInstanceParameters(x.ruleInstances).pipe(
            switchMap(y => [new LoadInStateRuleInstanceParametersAction(y), new FetchOkRuleInstanceParametersAction()]),
            catchError(error => {
                this.logger.error('Error while fetch of rule instance parameters', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedRuleInstanceParametersAction(error));
            })
        ))
    ));

    
    public onFetchRuleInstanceParametersOverrideIndicators$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchRuleInstanceParametersOverrideIndicatorsAction),
        switchMap(x => this.ruleManagementService.getRuleInstanceParametersOverrideIndicators(x.ruleInstances).pipe(
            switchMap(y => [
                new UpdateInStateRuleInstanceParametersOverrideIndicatorsAction(y),
                new FetchOkRuleInstanceParametersOverrideIndicatorsAction()
            ]),
            catchError(error => {
                this.logger.error('Error while fetch of rule instance parameter override indicators', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedRuleInstanceParametersOverrideIndicatorsAction(error));
            })
        ))
    ));

    
    public FetchRuleInstanceParametersOverrideIndicatorsWithDelayAction$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchRuleInstanceParametersOverrideIndicatorsWithDelayAction),
        delay(200),
        switchMap((x) => [new FetchRuleInstanceParametersOverrideIndicatorsAction(x.ruleInstances)])
    ));

    
    public onClearRuleInstanceParameters$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearInStateRuleInstanceParametersAction),
        switchMap(() => [new LoadInStateRuleInstanceParametersAction([])])
    ));

    
    public onUpdateRuleInstanceParameters$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateRuleInstanceParametersAction),
        switchMap(x => this.ruleManagementService.updateRuleInstanceParameters(x.parameters).pipe(
            switchMap(() => [
                new UpdateOkRuleInstanceParametersAction(),
                // request overrides with delay to give some time to the Rule Engine to update actual parameters
                new FetchRuleInstanceParametersOverrideIndicatorsWithDelayAction(x.rules),
            ]),
            catchError(error => {
                this.logger.error('Error while update of rule instance parameters', error);
                this.alertService.error(getMessageFromError(error));
                return of(new UpdateFailedRuleInstanceParametersAction(error));
            })
        ))
    ));

    
    public onFetchCopyFromOrderList$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchCopyFromOrderListAction),
        switchMap(x => this.ruleManagementService.getCopyFromOrderList(x.ruleInstances).pipe(
            switchMap(y => [new LoadInStateCopyFromOrderListAction(y), new FetchOkCopyFromOrderListAction()]),
            catchError(error => {
                this.logger.error('Error while fetching copy from order list', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedCopyFromOrderListAction(error));
            })
        ))
    ));

    
    public onClearCopyFromOrderList$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearInStateCopyFromOrderListAction),
        switchMap(() => [new LoadInStateCopyFromOrderListAction([])])
    ));

    
    public onCopyParameterOverrides$ = createEffect(() => this.actions$.pipe(
        ofAction(CopyParameterOverridesAction),
        switchMap(x => this.ruleManagementService.copyParameterOverrides(
            x.copyOverridesMode, x.copyFromOrderId,
            x.copyToOrderId, x.ruleInstances
        ).pipe(
            switchMap(() => [new CopyParameterOverridesOkAction()]),
            catchError(error => {
                this.logger.error('Error while copying parameter overrides', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CopyParameterOverridesFailedAction(error));
            })
        ))
    ));

    
    public onServerOk$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateOkRuleInstanceParametersAction, CopyParameterOverridesOkAction),
        tap(() => this.alertService.success('Changes applied successfully.'))
    ), { dispatch: false });

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly ruleManagementService: RuleManagementService
    ) { }
}
