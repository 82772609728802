import {
    MonitorOverview, OverviewStatuses, SectionMonitorInfo,
    SectionMonitorStatus, SectionTrendData, SectionMonitorRuleInstanceDataSet,
    Issue, RuleInstanceLastCheckedData, RuleInstanceProblemTime,
    MonitorSectionSettings, DecimatedLog, MatchingIssuesResult,
    DrqKpiScore, DapKpiScore,
    MonitorSelectionInfo,
    KpiScoreTrendCollection
} from '@/_models';
import { IndexedLogMetadata } from '@/_models/live-kpi';

export class MonitorState {
    public sectionId?: number;
    public orderOverviews?: MonitorOverview[];
    public overviewStatuses?: OverviewStatuses[];
    public sectionMonitorInfo?: SectionMonitorInfo;
    public sectionMonitorStatus?: SectionMonitorStatus;
    public sectionTrendData?: SectionTrendData;
    public sectionInstanceData?: SectionMonitorRuleInstanceDataSet;
    public sectionIssues?: Issue[];
    public ruleInstanceLastCheckedData?: RuleInstanceLastCheckedData[];
    public ruleInstancesProblemTime?: RuleInstanceProblemTime[];
    public myRigs?: number[];
    public settings?: MonitorSectionSettings;
    public hDepLog?: DecimatedLog;
    public bDepLog?: DecimatedLog;
    public matchingIssuesResult?: MatchingIssuesResult[];
    public refreshRequest?: Date;
    public plottableLogs?: IndexedLogMetadata[];
    public additionalCurves?: DecimatedLog[];
    public drqKpiScore?: DrqKpiScore;
    public dapKpiScore?: DapKpiScore;
    public selectionInfo?: MonitorSelectionInfo;
    public drqScoreTrends?: KpiScoreTrendCollection;
    public dapScoreTrends?: KpiScoreTrendCollection;
}
