import { IndicatorStatus } from '../indicator-status';
import { ProblemTimeData } from '../problem-time-data';
import { IssueReasonCode } from '../reason-code';
import { RuleSuppressionStatus } from '../rule-management';

export class SectionMonitorRuleInstanceDataSet {
    public sectionId?: number;
    public categoryId?: string;
    public instances: SectionMonitorRuleInstance[];
}

export class SectionMonitorRuleInstance {
    public ruleInstanceId: number;
    public targetId: number;
    public subjectType: string;
    public subjectName: string;
    public subjectMappedName: string;
    public logName: string;
    public curveLabel: string;
    public subjectId: number;
    public monitor: string;

    public service: string;
    public type: string;
    public subject: string;
    public group: string;

    public issueStartRigTime: Date;
    public issueEndRigTime: Date;
    public issueComment: string;
    public lastCheckedDateTime: Date;
    public status: IndicatorStatus;
    public issueCount: number;
    public responsible: string;

    public enabled?: boolean;
    public isRuleActive?: boolean;

    public suppressionStatus: RuleSuppressionStatus;
    public suppressedUntilRigTime?: Date;
    public suppressedReasonCode?: IssueReasonCode;
    public suppressedReasonCodeDescription?: string;

    public problemTimeSuppressionStatus: RuleSuppressionStatus;
    public problemTimeSuppressedUntilRigTime?: Date;
    public problemTimeSuppressedReasonCode?: IssueReasonCode;
    public problemTimeSuppressedReasonCodeDescription?: string;
}

export class RuleInstanceProblemTime extends ProblemTimeData {
    public ruleInstanceId: number;
}
