import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ofAction } from '../ngrx-actions/of-action';

import { NGXLogger } from 'ngx-logger';
import { NgxAlertService, getMessageFromError } from 'ngx-shared';

import { ServerConnectionService } from '@/_services';
import {
    LoadInStateInfraPanelsAction,
    FetchInfraPanelsAction, FetchOkInfraPanelsAction, FetchFailedInfraPanelsAction
} from './infra-panel.actions';

@Injectable()
export class InfraPanelEffects {

    
    public onServerFetchInfraPanels$ = createEffect(() => this.actions$.pipe(
      ofAction(FetchInfraPanelsAction),
      switchMap(() => this.serverConnectionService.getAllAsInfraPanels().pipe(
        switchMap(x => [new LoadInStateInfraPanelsAction(x), new FetchOkInfraPanelsAction()]),
        catchError(error => {
            this.logger.error('Error while Fetch of InfraPanels ', error);
            this.alertService.error(getMessageFromError(error));
            return of(new FetchFailedInfraPanelsAction(error));
        })
      ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly serverConnectionService: ServerConnectionService
    ) { }
}
