import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

import { IndicatorStatus, QUERY_PARAM_ALERT, QUERY_PARAM_SECTION, QUERY_PARAM_TARGET } from '@/_models';
import { CurrentUserService } from '@/_services';

import { issueStartTimeTicksComparator } from '../functions';
import { IndicatorStatusHelper } from '../indicator-status/indicator-status-helper';
import { ModalDialogComponent, ModalAction } from '../modal-dialog/modal-dialog.component';

import { IssueDetailsDialogIssueVm, IssueDetailsDialogVm } from './issue-details-dialog-vm';

@Component({
    selector: 'app-issue-details-dialog',
    templateUrl: './issue-details-dialog.component.html',
    styleUrls: ['./issue-details-dialog.component.css']
})
export class IssueDetailsDialogComponent implements OnChanges {
    @ViewChild('dialog') public dialog: ModalDialogComponent;

    @Input() public data: IssueDetailsDialogVm;

    @Output() public close = new EventEmitter(); // eslint-disable-line @angular-eslint/no-output-native

    public isAlertLinkShown: boolean;
    public dialogActions: ModalAction[] = [{ text: 'Close', cssClass: 'btn-secondary btn-close' }];

    //#region state parameters


    /** If there are any issues */

    /** Sorted issues shown on the UI */
    public issues: IssueDetailsDialogIssueVm[];

    public currentIssue: IssueDetailsDialogIssueVm;
    public alertQueryParams: { [k: string]: any; };
    public currentPage = 1;


    //#endregion state parameters
    constructor(private readonly currentUserService: CurrentUserService) { }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            this.updateIssueData(this.data);
        }
    }

    public onPageChanged(event: PageChangedEvent) {
        this.currentIssue = this.issues[event.page - 1];
        this.updateAlertLinkParameters();
    }

    public onModelClose() {
        this.close.emit();
        this.updateIssueData(null);
    }

    public show() {
        this.dialog.show();
    }

    public getIndicatorStatusText(status: IndicatorStatus) {
        return status == null ? '' : IndicatorStatusHelper.getText(status);
    }

    private getAlertQueryParams() {
        return this.currentIssue && {
            [QUERY_PARAM_TARGET]: this.data.targetId,
            [QUERY_PARAM_SECTION]: this.data.sectionId,
            [QUERY_PARAM_ALERT]: this.currentIssue.alertId
        };
    }

    private checkIsAlertNavigationPossible() {
        return this.currentIssue != null && this.currentIssue.alertId != null
            && this.data != null && this.data.targetId != null && this.data.sectionId != null;
    }

    private updateIssueData(input: IssueDetailsDialogVm) {
        if (input && Array.isArray(input.issues) && input.issues.length) {
            if (input.issues.length) {
                const issuesCopy = [...input.issues];
                issuesCopy.sort((a, b) => issueStartTimeTicksComparator(a.startTimeTicks, b.startTimeTicks));
                this.issues = issuesCopy;

                let selectFirst = true;
                if (this.currentIssue) { // if we have selection just update page and object
                    const newIndex = this.issues.findIndex(x => x.id === this.currentIssue.id);
                    if (newIndex > -1) {
                        this.currentIssue = this.issues[newIndex];
                        this.currentPage = newIndex + 1;
                        selectFirst = false;
                    }
                }

                if (selectFirst) {
                    this.currentIssue = this.issues[0];
                    this.currentPage = 1;
                }
            }
        } else {
            this.issues = [];
            this.currentIssue = undefined;
            this.currentPage = 1;
        }

        this.updateAlertLinkParameters();
    }

    private updateAlertLinkParameters() {
        this.alertQueryParams = this.getAlertQueryParams();
        this.isAlertLinkShown = this.currentUserService.isAlertsRouteAllowed && this.checkIsAlertNavigationPossible();
    }
}
