/***
 * Groups array of objects by a property member in each object
 * @param {T[]} arr
 * @param {keyof T} key
 * @returns {{[p: number]: T[]}}
 */
export function groupBy<T>(arr: T[], keySelectorFn: (x: T) => number): { [key: number]: T[] };
export function groupBy<T>(arr: T[], keySelectorFn: (x: T) => string): { [key: string]: T[] };
/***
 * Groups array of objects by a property member in each object
 * @param {T[]} arr
 * @param {keyof T} key
 * @returns {{[p: string]: T[]}}
 */
// tslint:disable-next-line: unified-signatures
export function groupBy<T>(arr: T[], key: keyof T): { [key: string]: T[] };
export function groupBy<T>(arr: T[], key: keyof T | ((x: T) => number | string)): { [key: string]: T[] } {
    return arr.reduce((prev, curr) => {
        const prevKey = key instanceof Function ? key(curr) : (curr[key] || '').toString();
        (prev[prevKey] = prev[prevKey] || []).push(curr);
        return prev;
    }, {} as { [key: string]: T[] });
}
