import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnChanges, Injector, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardSettings, WidgetSettings } from './dashboard-settings';

export const WIDGET_INPUT = new InjectionToken<any | Observable<any>>('WidgetInputToken');
export const WIDGET_OUTPUT = new InjectionToken<(value: any) => void>('WidgetOutputToken');
export const WIDGET_MAXIMIZE = new InjectionToken<(maximized: boolean) => void>('WidgetMaximizedToken');
type WidgetData = WidgetSettings & { 
    injector?: Injector,
    isMaximized?: boolean,
    maximizeToggleRequest?: () => void
};

@Component({
    selector: 'app-dashboard-container',
    templateUrl: 'dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnChanges {
    public readonly defaultNumberOfColumns = 2;
    public readonly maxColumns = 12;

    @Input() public settings: DashboardSettings;

    public rowData: WidgetData[][];

    public headerWidgetData: WidgetData;

    constructor(private readonly injector: Injector) { }

    public ngOnChanges() {
        if (this.settings && this.settings.totalColumns == null) {
            this.settings.totalColumns = this.defaultNumberOfColumns;
        }

        this.processWidgetSettings();
    }

    public getWidgetSizeClass(widget: WidgetData) {
        let result = '';
        if (!widget.isHeader && this.hasMaximizedWidget) {
            if (widget.isMaximized) {
                result += `col-12 maximized `;
            } else {
                result += 'hidden ';
            }
        } else {
            if (widget.colSpan) {
                result += `col-${this.maxColumns / this.settings.totalColumns * widget.colSpan} `;
            }
            if (widget.colXlSpan) {
                result += `col-xl-${this.maxColumns / this.settings.totalColumns * widget.colXlSpan} `;
            }
            if (widget.colLgSpan) {
                result += `col-lg-${this.maxColumns / this.settings.totalColumns * widget.colLgSpan} `;
            }
            if (widget.colMdSpan) {
                result += `col-md-${this.maxColumns / this.settings.totalColumns * widget.colMdSpan} `;
            }
            if (widget.colSmSpan) {
                result += `col-sm-${this.maxColumns / this.settings.totalColumns * widget.colSmSpan} `;
            }
            if (!result) {
                result = 'col-12 ';
            }
        }

        return result;
    }

    public get hasMaximizedWidget(): boolean {
        return this.rowData.some(r => r.some(w => w.isMaximized));
    }

    public hasMaximizedWidgetInRow(row: WidgetData[]): boolean {
        return row.some(w => w.isMaximized);
    }

    private processWidgetSettings() {
        const newRows: WidgetData[][] = [];

        if (this.settings && Array.isArray(this.settings.widgets)) {
            if (this.settings.widgets.filter(x => x.isHeader).length > 1) {
                return console.error('Can\'t have two header widgets at the same time. ' +
                 '\'isHeader\' property on more then one widget is set to \'true\'');
            }

            let counter = 0;
            this.settings.widgets.forEach(widget => {
                if (widget.rowIndex == null) {
                    widget.rowIndex = ++counter;
                } else {
                    counter = Math.max(widget.rowIndex, counter);
                }

                if (!Array.isArray(newRows[widget.rowIndex])) {
                    newRows[widget.rowIndex] = [];
                }

                const widgetData: WidgetData = widget || {} as WidgetData;

                widgetData.isMaximized = false;
                widgetData.maximizeToggleRequest = () => { 
                    widgetData.isMaximized = !widgetData.isMaximized;
                    //window.dispatchEvent(new Event('resize'));
                    setTimeout(() => {
                        window.dispatchEvent(new Event('resize'));
                    }, 10);
                 };
                widgetData.injector = this.getInjector(widgetData);

                if (widgetData.isHeader) {
                    this.headerWidgetData = widgetData;
                } else {
                    newRows[widget.rowIndex].push(widgetData);
                }
            });
        }

        this.rowData = newRows.filter(x => x.length);
    }

    private getInjector(widget: WidgetData) {
        if (widget.input != null && widget.output != null) {
            return Injector.create({
                providers: [
                    { provide: WIDGET_INPUT, useValue: widget.input },
                    { provide: WIDGET_OUTPUT, useValue: widget.output },
                    { provide: WIDGET_MAXIMIZE, useValue: widget.maximizeToggleRequest },
                ], parent: this.injector
            });
        } else if (widget.input != null) {
            return Injector.create({
                providers: [
                    { provide: WIDGET_INPUT, useValue: widget.input },
                    { provide: WIDGET_MAXIMIZE, useValue: widget.maximizeToggleRequest },
                ], parent: this.injector
            });
        } else if (widget.output != null) {
            return Injector.create({
                providers: [
                    { provide: WIDGET_OUTPUT, useValue: widget.output },
                    { provide: WIDGET_MAXIMIZE, useValue: widget.maximizeToggleRequest },
                ], parent: this.injector
            });
        }
    }
}

@NgModule({
    declarations: [DashboardComponent],
    exports: [DashboardComponent],
    imports: [CommonModule],
})
export class DashboardModule { }
