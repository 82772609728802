import { Action, Store } from '../ngrx-actions';
import { LoadEditingMonitoringAction, LoadEditingMonitoringSessionAction } from './editing-monitoring.actions';
import { EditingMonitoringState } from './editing-monitoring.state';

@Store({} as EditingMonitoringState)
export class EditingMonitoringStore {

    @Action(LoadEditingMonitoringAction)
    public loadEditingMonitoring(state: EditingMonitoringState, { monitoringSessions }: LoadEditingMonitoringAction) {
        return { ...state, monitoringSessions };
    }

    @Action(LoadEditingMonitoringSessionAction)
    public loadEditingMonitoringSession(state: EditingMonitoringState, { currentMonitoringSession }: LoadEditingMonitoringSessionAction) {
        return { ...state, currentMonitoringSession };
    }
}
