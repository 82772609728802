import { CommonModule} from '@angular/common';
import { NgModule, ModuleWithProviders} from '@angular/core';
import { RouterModule } from '@angular/router';

// App Breadcrumb Component
import { NgxBreadcrumbService } from './ngx-breadcrumb.service';
import { NgxBreadcrumbComponent } from './ngx-breadcrumb.component';

// @dynamic
@NgModule({
  imports: [ CommonModule, RouterModule ],
  exports: [ NgxBreadcrumbComponent ],
  declarations: [ NgxBreadcrumbComponent ]
})
export class NgxBreadcrumbModule {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static forRoot(config?: any): ModuleWithProviders<NgxBreadcrumbModule> {
    return {
      ngModule: NgxBreadcrumbModule,
      providers: [
        NgxBreadcrumbService
      ]
    };
  }
}
