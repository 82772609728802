import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { PublicPart } from 'ngx-shared';

@Injectable({ providedIn: 'root' })
export class SystemInformationService {
    constructor(private http: HttpClient) { }

    public getComponentVersions(): Observable<string[]> {
        return this.http.get<string[]>(`${environment.portalWebApi}/deployment`);
    }
}

export class FakeSystemInformationService implements PublicPart<SystemInformationService> {
    public getComponentVersions(): Observable<string[]> {
        throw new Error('Not implemented method');
    }
}

export const fakeSystemInformationService = { provide: SystemInformationService, useClass: FakeSystemInformationService };
