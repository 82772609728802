import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';

import { ofAction } from '../ngrx-actions/of-action';
import { NGXLogger } from 'ngx-logger';
import { of, from } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { ServerConnectionService } from '@/_services';
import {
    LoadInStateServerConnectionsAction, FetchServerConnectionsAction, FetchOkServerConnectionsAction, FetchFailedServerConnectionsAction,
    CreateInStateServerConnectionAction, CreateServerConnectionAction, CreateOkServerConnectionAction, CreateFailedServerConnectionAction,
    UpdateInStateServerConnectionAction, UpdateServerConnectionAction, UpdateOkServerConnectionAction, UpdateFailedServerConnectionAction,
    DeleteInStateServerConnectionAction, DeleteServerConnectionAction, DeleteOkServerConnectionAction, DeleteFailedServerConnectionAction,
    AddServerConnectionAction, CancelAddServerConnectionAction, SetSelectedServerConnectionAction, ResetServerConnectionInStateAction,
    TestSoapAction, TestOkSoapAction, TestFailedSoapAction, SetSoapTestStatusAction,
    LoadInStateSimpleConnectionAction, FetchFailedSimpleConnectionAction, FetchOkSimpleConnectionAction, FetchSimpleConnectionAction,
} from './server-connection.actions';
import { ConnectionTestStatus } from './server-connection.state';

@Injectable()
export class ServerConnectionEffects {

    
    public onServerFetchServerConnections$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchServerConnectionsAction),
        switchMap(() => this.serverConnectionService.getAll().pipe(
            switchMap(x => [new LoadInStateServerConnectionsAction(x), new FetchOkServerConnectionsAction()]),
            catchError(error => {
                this.logger.error('Error while Fetch of ServerConnections ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedServerConnectionsAction(error));
            })
        ))
    ));

    
    public onAddServerConnection$ = createEffect(() => this.actions$.pipe(
        ofAction(AddServerConnectionAction),
        map(x => new CreateInStateServerConnectionAction(x.serverConnection))
    ));

    
    public onCancelAddServerConnection$ = createEffect(() => this.actions$.pipe(
        ofAction(CancelAddServerConnectionAction),
        map(x => new DeleteInStateServerConnectionAction(x.serverConnectionId))
    ));

    
    public onServerCreateServerConnection$ = createEffect(() => this.actions$.pipe(
        ofAction(CreateServerConnectionAction),
        switchMap(({ serverConnection }) => this.serverConnectionService.create(serverConnection).pipe(
            switchMap(x => [
                new CreateInStateServerConnectionAction(x),
                new CreateOkServerConnectionAction(),
                new SetSelectedServerConnectionAction(x.id),
                new CancelAddServerConnectionAction(serverConnection.id)
            ]),
            catchError(error => {
                this.logger.error('Error while Create of ServerConnection', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CreateFailedServerConnectionAction(error));
            })
        ))
    ));

    
    public onServerUpdateServerConnection$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateServerConnectionAction),
        switchMap(({ serverConnection }) => this.serverConnectionService.update(serverConnection).pipe(
            switchMap(x => [new UpdateInStateServerConnectionAction(x), new UpdateOkServerConnectionAction()]),
            catchError(error => {
                this.logger.error('Error while Update of ServerConnection', error);
                this.alertService.error(getMessageFromError(error));
                return from([new UpdateFailedServerConnectionAction(error), new ResetServerConnectionInStateAction(serverConnection.id)]);
            })
        ))
    ));

    
    public onServerDeleteServerConnection$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteServerConnectionAction),
        switchMap(({ serverConnectionId }) => this.serverConnectionService.delete(serverConnectionId).pipe(
            map(() => serverConnectionId),
            switchMap(x => [new DeleteInStateServerConnectionAction(x), new DeleteOkServerConnectionAction()]),
            catchError(error => {
                this.logger.error('Error while Delete of ServerConnection', error);
                this.alertService.error(getMessageFromError(error));
                return of(new DeleteFailedServerConnectionAction(error));
            })
        ))
    ));

    
    public onServerTestSoap$ = createEffect(() => this.actions$.pipe(
        ofAction(TestSoapAction),
        switchMap(x => this.serverConnectionService.checkSoapConnection(x.data).pipe(
            switchMap(y => [
                new SetSoapTestStatusAction(y.isAvailable ? ConnectionTestStatus.Ok : ConnectionTestStatus.Fail, y.message),
                new TestOkSoapAction()
            ]),
            catchError(error => {
                this.logger.error('Could not Test Soap', error);
                this.alertService.error(getMessageFromError(error));
                return from([
                    new TestFailedSoapAction(error),
                    new SetSoapTestStatusAction(ConnectionTestStatus.Fail, 'Could not Test Soap')
                ]);
            })
        ))
    ));

    
    public onServerOk$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteOkServerConnectionAction, CreateOkServerConnectionAction, UpdateOkServerConnectionAction),
        tap(() => this.alertService.success('Action completed.'))
    ), { dispatch: false });

    
    public onServerFetchSimpleConnection$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchSimpleConnectionAction),
        switchMap(() => this.serverConnectionService.getSimpleConnections().pipe(
            switchMap(x => [new LoadInStateSimpleConnectionAction(x), new FetchOkSimpleConnectionAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch SimpleConnection', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedSimpleConnectionAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly serverConnectionService: ServerConnectionService
    ) { }
}
