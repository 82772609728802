export class OrderAsset {
    public id: number;
    public name?: string;
    public assetRolePicklistId?: number;
    public isMainResponsible?: boolean;
    public receiveNotifications?: boolean;
    public phone?: string;
    public email?: string;
    public assetAvailabilityPicklistId?: number;
}
