import { Store, Action } from '../ngrx-actions';
import { LoadSystemConfigurationAction } from './admin.actions';
import { AdminState } from './admin.state';

@Store({} as AdminState)
export class AdminStore {

    @Action(LoadSystemConfigurationAction)
    public loadSystemConfig(state: AdminState, { data }: LoadSystemConfigurationAction) {
        return {
            ...state,
            contactInfoEmail: data && data.contactInfoEmail,
            userDocumentationUrl: data && data.userDocumentationUrl,
            logoImage: data && data.logoImage,
            smallLogoImage: data && data.smallLogoImage,
            forgottenPasswordSubject: data && data.forgottenPasswordSubject,
            forgottenPasswordTemplate: data && data.forgottenPasswordTemplate,
            requestAccessSubject: data && data.requestAccessSubject,
            requestAccessTemplate: data && data.requestAccessTemplate,
            disabledUserSubject: data && data.disabledUserSubject,
            disabledUserTemplate: data && data.disabledUserTemplate,
            operatorId: data && data.operatorId,
            operatorContactEmail: data && data.operatorContactEmail,
            smtpHost: data && data.smtpHost,
            smtpPort: data && data.smtpPort,
            smtpTimeout: data && data.smtpTimeout,
            smtpUsername: data && data.smtpUsername
        } as AdminState;
    }
}
