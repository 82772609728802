import { RuleCategoryType } from './rule-category-type';

export const KpiOverrideNoStartDate = 'Section Activation';
export const KpiOverrideNoEndDate = 'Section Completion';

export class KpiOverride {
    public id: number;
    public name: string; // todo: do we need this?
    public isGreen: boolean;
    public sectionId: number;
    public witsmlServerConnectionId: number;
    public startsWithSection = true;
    public startDateTimeUtc?: Date;
    public endsWithSection = true;
    public endDateTimeUtc?: Date;
    public comment?: string;
    // target issue selection
    public subjectType: string;
    public includeCurves: boolean;
    // todo: rename to indexType
    public type: RuleCategoryType;
    public service: string;
    public rules: number[];
    public subjects: number[];
    public problemTimeRelation: string;
    public problemTimeDuration: number;

    // todo: deprecated; leave them for consistency and no build errors
    /* @deprecated */
    public isConstant: boolean;
    /* @deprecated */
    public weight: number;
    /* @deprecated */
    public ruleId: number;
    /* @deprecated */
    public indexType: RuleCategoryType;
}
