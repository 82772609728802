export enum AutoStartCondition {
    None = 0,
    Time = 1,
    Depth = 2,
    TimeOrDepth = 3,
}

export const autoStartConditionList: { id: number, name: string }[] = [
    { id: AutoStartCondition.None, name: 'None' },
    { id: AutoStartCondition.Time, name: 'Time' },
    { id: AutoStartCondition.Depth, name: 'Depth' },
    { id: AutoStartCondition.TimeOrDepth, name: 'Time or Depth' },
];

export enum AutoCompleteCondition {
    None = 0,
    Time = 1,
    Depth = 2,
    TimeOrDepth = 3,
    TimeAndDepth = 4,
}

export const autoCompleteConditionList: { id: number, name: string }[] = [
    { id: AutoCompleteCondition.None, name: 'None' },
    { id: AutoCompleteCondition.Time, name: 'Time' },
    { id: AutoCompleteCondition.Depth, name: 'Depth' },
    { id: AutoCompleteCondition.TimeOrDepth, name: 'Time or Depth' },
    { id: AutoCompleteCondition.TimeAndDepth, name: 'Both Time and Depth' },
];
