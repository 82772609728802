import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Action } from '@/_models';
import { CurrentUserService } from '@/_services';
import { Routes } from '@/configuration';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NgxAuthorizationGuard {
    constructor(
        private readonly router: Router,
        private readonly currentUserService: CurrentUserService        
    ) { }

    public canActivate(route: ActivatedRouteSnapshot): boolean|Observable<boolean> {        
        const currentUser = this.currentUserService.user;
        if (currentUser) {
            if (this.isUnauthorized(route.data && route.data.actions, currentUser.actions)) {
                // user not authorized so redirect to home page OR maybe we should have a 403 page  
                this.router.navigate([Routes.home]);                
                return false;
            }

            // authorized so return true
            return true;
        }

        return new Observable<boolean>((observer) => {
            this.currentUserService.EnsureCurrentUser()?.subscribe( () => {
                if (!this.currentUserService.user) {
                    this.router.navigate([Routes.home]);
                    observer.next(false);
                    observer.complete();                    
                }
                else
                {
                    const allowed = !this.isUnauthorized(route.data && route.data.actions, this.currentUserService.user.actions);
                    observer.next(allowed);
                    observer.complete();
                }
            });
        });
    }

    private isUnauthorized(routeActions: string[], userActions: Action[]) {
        return Array.isArray(routeActions)
            && (!Array.isArray(userActions) || userActions.every(x => routeActions.every(y => y !== x.name)));
    }
}
