import FilterBuilder, { QueryBuilder } from 'odata-query-builder';
import { BaseFilter, SerializedFilter, SerializedTextFilter, SerializedDateFilter, SerializedNumberFilter } from 'ag-grid-community';

import { SerializedDropdownFilter, DataType } from '../filters/dropdown-filter-renderer/dropdown-filter-renderer.component';
import { SerializedReasonCodeFilter } from '../filters/reason-code-filter/pagination-reason-code-filter.component';
import { GridSortModel, FilterModel } from '../ag-grid.component';
import { CombinedFilter } from '../missing-imports';

class FilterTypedParams {
    operator: string;
    valueFrom: string | number | boolean | Date;
    valueTo: string | number | boolean | Date;
}
// https://docs.microsoft.com/en-us/odata/webapi/in-operator
const ODataInOperator = 'in';

const filterPhraseFieldPattern = '$field$';
const filterPhraseValuePattern = '$value$';
const filterPhrasesPattern = {
    [BaseFilter.CONTAINS]: `contains(${filterPhraseFieldPattern},'${filterPhraseValuePattern}')`,
    [BaseFilter.NOT_CONTAINS]: `not contains(${filterPhraseFieldPattern},'${filterPhraseValuePattern}')`,
    [BaseFilter.STARTS_WITH]: `startswith(${filterPhraseFieldPattern},'${filterPhraseValuePattern}')`,
    [BaseFilter.ENDS_WITH]: `endswith(${filterPhraseFieldPattern},'${filterPhraseValuePattern}')`,
    [ODataInOperator]: `${filterPhraseFieldPattern} in (${filterPhraseValuePattern})`,
};

/** not available in the lib */
enum ODataCompareOperators {
    EQUALS = 'eq',
    NOT_EQUAL = 'ne',
    LESS_THAN = 'lt',
    LESS_THAN_EQUAL = 'le',
    GREATER_THAN = 'gt',
    GREATER_THAN_EQUAL = 'ge',
}

// todo: implement (if needed) extra functionality with the parameters in the comments
const gridToODataCompareOperators = {
    [BaseFilter.EQUALS]: ODataCompareOperators.EQUALS,
    [BaseFilter.NOT_EQUAL]: ODataCompareOperators.NOT_EQUAL,
    // Number, Date
    [BaseFilter.LESS_THAN]: ODataCompareOperators.LESS_THAN, // includeBlanksInLessThan
    [BaseFilter.GREATER_THAN]: ODataCompareOperators.GREATER_THAN, // includeBlanksInGreaterThan
    // special case; not an actual odata operator
    [BaseFilter.IN_RANGE]: BaseFilter.IN_RANGE, // inRangeInclusive; right now always inclusive
};

const edmDateTimMinValue = new Date('01-01-1753');
const edmDateTimeMaxValue = new Date('12-31-9999 23:59:59:999');

export class ODataExpressionHelper {
    public static extractSortQuery(sortModel: GridSortModel): string {
        if (!Array.isArray(sortModel)) {
            return '';
        }

        return sortModel.map(x => `${x.colId} ${x.sort}`).join(',');
    }

    public static extractFilterQuery(filterModel: FilterModel): string {
        if (!filterModel) {
            return '';
        }

        const builder = new QueryBuilder().filter(f => {
            Object.entries(filterModel).map(([field, filter]) => {
                if ((filter as SerializedFilter).filterType) {
                    const query = toQuery(field, filter as SerializedFilter);

                    if (query) {
                        f = buildFilter(f, field, query);
                    }
                } else {
                    const comboFilter = filter as CombinedFilter<SerializedFilter>;
                    const query1 = toQuery(field, comboFilter.condition1);
                    const query2 = toQuery(field, comboFilter.condition2);

                    if (query2) {
                        // avoids the case where the second filter is "in range" NUMBER filter and is passed with null values
                        if (typeof query2 !== 'string' && query2.operator === BaseFilter.IN_RANGE && query2.valueFrom == null) {
                            f = buildFilter(f, field, query1);
                        } else {
                            f = f[comboFilter.operator.toLowerCase()](f1 => buildFilter(buildFilter(f1, field, query1), field, query2));
                        }
                    } else {
                        f = buildFilter(f, field, query1);
                    }
                }
            });

            return f;
        });

        return builder.toQuery().substring(9); // skip '?$filter='; library specific
    }
}

function buildFilter(builder: FilterBuilder, field: string, query: string | FilterTypedParams) {
    let newBuilder: FilterBuilder;

    if (typeof query === 'string') {
        newBuilder = builder.filterPhrase(query);
    } else if (query.valueFrom != null) {
        if (query.operator === BaseFilter.IN_RANGE) {
            newBuilder = builder.and(f1 => f1
                .filterExpression(field, ODataCompareOperators.GREATER_THAN_EQUAL, query.valueFrom)
                .filterExpression(field, ODataCompareOperators.LESS_THAN_EQUAL, query.valueTo)
            );
        } else {
            newBuilder = builder.filterExpression(field, query.operator, query.valueFrom);
        }
    }

    return newBuilder;
}

/***
 * @param {string} field
 * @param {SerializedFilter} filter
 * @returns {string | FilterTypedParams} Returns an object when a compare operator is used, i.e. 'equals', 'greater then',
 * string otherwise
 */
function toQuery(field: string, filter: SerializedFilter): string | FilterTypedParams {
    const params = getFilterTypedParams(filter as SerializedFilter);

    if (params.valueFrom == null || params.valueFrom === '') {
        return null;
    }

    let query: string | FilterTypedParams;

    if (filterPhrasesPattern[params.operator]) {
        query = filterPhrasesPattern[params.operator]
            .replace(filterPhraseFieldPattern, field)
            .replace(filterPhraseValuePattern, String(params.valueFrom));
    } else {
        query = {
            operator: gridToODataCompareOperators[params.operator],
            valueFrom: params.valueFrom,
            valueTo: params.valueTo
        };
    }

    return query;
}

function getFilterTypedParams(filter: SerializedFilter): FilterTypedParams | never {
    let operator: string, valueFrom: string | number | boolean | Date, valueTo: string | number | Date | boolean;

    switch (filter.filterType) {
        case 'text': {
            const txtFilter = filter as SerializedTextFilter;
            [operator, valueFrom, valueTo] = [txtFilter.type, txtFilter.filter, null];
        } break;
        case 'date': {
            const dateFilter = filter as SerializedDateFilter;
            [operator, valueFrom, valueTo] = [
                dateFilter.type,
                toEdmDateTimeRange(new Date(dateFilter.dateFrom)),
                dateFilter.dateTo != null ? toEdmDateTimeRange(new Date(dateFilter.dateTo)) : dateFilter.dateTo
            ];
        } break;
        case 'number': {
            const numberFilter = filter as SerializedNumberFilter;
            [operator, valueFrom, valueTo] = [
                numberFilter.type,
                numberFilter.filter != null ? Number(numberFilter.filter) : numberFilter.filter,
                numberFilter.filterTo != null ? Number(numberFilter.filterTo) : numberFilter.filterTo
            ];
        } break;
        case 'dropdown': { // DropdownFilterRendererComponent
            const dropdownFilter = filter as SerializedDropdownFilter;

            let parsedValueFrom: string | number | Date | boolean;

            switch (dropdownFilter.dataType) {
                case DataType.Text: parsedValueFrom = dropdownFilter.filter; break;
                case DataType.Date: parsedValueFrom = new Date(dropdownFilter.filter); break;
                case DataType.Number: parsedValueFrom = Number(dropdownFilter.filter); break;
                case DataType.Boolean:
                    parsedValueFrom = dropdownFilter.filter === '' ? null : dropdownFilter.filter.toLowerCase() === 'true'; break;
                default: throw new Error(`Unsupported dropdownFilter data type: ${dropdownFilter.dataType}`);
            }

            [operator, valueFrom, valueTo] = [dropdownFilter.type, parsedValueFrom, null];
        } break;
        case 'reasonCode': { // PaginationReasonCodeFilterComponent
            const reasonCodeFilter = filter as SerializedReasonCodeFilter;
            [operator, valueFrom, valueTo] = [reasonCodeFilter.type, reasonCodeFilter.filter, null];
        } break;
        default: throw new Error(`Unsupported filter type: ${filter.filterType}`);
    }

    return { operator: operator, valueFrom, valueTo };
}

/**
 * dates need to be limited to Edm.DateTime min/max ranges to be compatible with odata queries
 * https://www.odata.org/documentation/odata-version-2-0/overview, 6. Primitive Data Types
 */
function toEdmDateTimeRange(date: Date) {
    if (date > edmDateTimeMaxValue) {
        return edmDateTimeMaxValue;
    } else if (date < edmDateTimMinValue) {
        return edmDateTimMinValue;
    } else {
        return date;
    }
}
