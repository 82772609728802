import { DapTemplateContentFunction } from '@/_models/dap-document-details';
import { DapDocumentVM, DapTableRowValue } from '../vm';
import { RefScalar } from './ref-scalar';
import { TemplateFunctions } from './template-functions';
import { PickListItem, PickListType } from '@/_models';

export class RefFunction {
    public static getValue(templateFunction: DapTemplateContentFunction, docVM: DapDocumentVM, sectionIndex: number, dapIndex: number,
        rowValue: DapTableRowValue, picklistMap: Map<PickListType, PickListItem[]>) {
        const paramValues = RefFunction.resolveParams(templateFunction, docVM, sectionIndex, dapIndex, rowValue, picklistMap);
        return new TemplateFunctions(docVM, sectionIndex, dapIndex)[templateFunction.name](paramValues);
    }

    public static resolveParams(templateFunction: DapTemplateContentFunction, docVM: DapDocumentVM, sectionIndex: number, dapIndex: number,
        rowValue: DapTableRowValue, picklistMap: Map<PickListType, PickListItem[]>) {
        const paramValues: any[] = [];
        templateFunction.params.forEach(param => {
            let paramValue = null;
            if (RefScalar.isParamRefScalar(param)) {
                paramValue = RefScalar.getValue(param, docVM, sectionIndex, dapIndex, rowValue, picklistMap);
            } else {
                paramValue = param;
            }

            paramValues.push(paramValue);
        });
        return paramValues;
    }
}
