import { DapScoreSignedOff } from '@/_models/dap-document-status';

export function getSignedOffIcon(status: DapScoreSignedOff): string {
    if (status === DapScoreSignedOff.FullySignedOff) { return 'far fa-check-square'; }
    if (status === DapScoreSignedOff.PartiallySignedOff) { return 'far fa-minus-square'; }
    if (status === DapScoreSignedOff.SignOffNotRequired) { return 'fas fa-times'; }
    return 'far fa-square';
}

export function getSignedOffDescription(status: DapScoreSignedOff): string {
    if (status === DapScoreSignedOff.FullySignedOff) { return 'Signed-off'; }
    if (status === DapScoreSignedOff.PartiallySignedOff) { return 'Partially signed-off'; }
    if (status === DapScoreSignedOff.SignOffNotRequired) { return 'Sign-off not required'; }
    return 'Awaiting first sign-off';
}
