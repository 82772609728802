/* eslint-disable @angular-eslint/directive-selector */

import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { sidebarCssClasses, asideMenuCssClasses } from '../classes';
import { ToggleClasses } from '../toggle-classes';

/**
 * After how many milliseconds the {@link SIDEBAR_TOGGLED_EVENT_NAME} event will be emitted.
 * 250ms is the duration of the side-menu slide animation
 */
const TRIGGER_RESIZE_TIMEOUT = 300;
const SIDEBAR_TOGGLED_EVENT_NAME = 'resize';

/***
 * Emits {@link SIDEBAR_TOGGLED_EVENT_NAME} when sidebar is toggled
 */
function emitSidebarToggledEvent() {
  setTimeout(() => window.dispatchEvent(new Event(SIDEBAR_TOGGLED_EVENT_NAME)), TRIGGER_RESIZE_TIMEOUT);
}

/**
 * Allows the sidebar to be toggled via click.
 */
@Directive({
  selector: '[ngxSidebarToggler]'
})
export class SidebarToggleDirective implements OnInit {
  @Input('ngxSidebarToggler') breakpoint: string;
  public bp;

  ngOnInit(): void {
    this.bp = this.breakpoint;
  }
  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    let cssClass;
    this.bp ? cssClass = `sidebar-${this.bp}-show` : cssClass = sidebarCssClasses[0];
    ToggleClasses(cssClass, sidebarCssClasses);
    emitSidebarToggledEvent();
  }
}

@Directive({
  selector: '[ngxSidebarMinimizer]'
})
export class SidebarMinimizeDirective {

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-minimized');
    emitSidebarToggledEvent();
  }
}

@Directive({
  selector: '[ngxMobileSidebarToggler]'
})
export class MobileSidebarToggleDirective {

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-show');
    emitSidebarToggledEvent();
  }

  // Check if element has class
  private hasClass(target: any, elementClassName: string) {
    return new RegExp('(\\s|^)' + elementClassName + '(\\s|$)').test(target.className);
  }
}

/**
 * Allows the off-canvas sidebar to be closed via click.
 */
@Directive({
  selector: '[ngxSidebarClose]'
})
export class SidebarOffCanvasCloseDirective {

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();

    if (this.hasClass(document.querySelector('body'), 'sidebar-off-canvas')) {
      this.toggleClass(document.querySelector('body'), 'sidebar-opened');
    }
    emitSidebarToggledEvent();
  }

  // Check if element has class
  private hasClass(target: any, elementClassName: string) {
    return new RegExp('(\\s|^)' + elementClassName + '(\\s|$)').test(target.className);
  }

  // Toggle element class
  private toggleClass(elem: any, elementClassName: string) {
    let newClass = ' ' + elem.className.replace( /[\t\r\n]/g, ' ' ) + ' ';
    if (this.hasClass(elem, elementClassName)) {
      while (newClass.indexOf(' ' + elementClassName + ' ') >= 0 ) {
        newClass = newClass.replace( ' ' + elementClassName + ' ' , ' ' );
      }
      elem.className = newClass.replace(/^\s+|\s+$/g, '');
    } else {
      elem.className += ' ' + elementClassName;
    }
    emitSidebarToggledEvent();
  }
}

@Directive({
  selector: '[ngxBrandMinimizer]'
})
export class BrandMinimizeDirective {

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('brand-minimized');
    emitSidebarToggledEvent();
  }
}

/**
 * Allows the aside to be toggled via click.
 */
@Directive({
  selector: '[ngxAsideMenuToggler]',
})
export class AsideToggleDirective implements OnInit {
  @Input('ngxAsideMenuToggler') breakpoint: string;
  public bp;

  ngOnInit(): void {
    this.bp = this.breakpoint;
  }
  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    let cssClass;
    this.bp ? cssClass = `aside-menu-${this.bp}-show` : cssClass = asideMenuCssClasses[0];
    ToggleClasses(cssClass, asideMenuCssClasses);
    emitSidebarToggledEvent();
  }
}
