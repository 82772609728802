import { SimpleConnection } from '../connection/index';
import { RigState } from '../rig-state';

export class SectionMonitorInfo {
    public orderId: number;
    public sectionId: number;
    /** the Section Name of the current Section */
    public sectionName: string;
    public activeDepthRangeName: string;
    /** Targets configured in the Order */
    public targets: SimpleConnection[];

    public rigId: number;
    public rigInstallationName: string;
    /** Information about the Rig */
    public holeDepth: number;
    public holeDepthUom: string;
    public holeDepthTimeIndex: Date;
    public bitDepth: number;
    public bitDepthUom: string;
    public bitDepthTimeIndex: Date;
    /** The Rig Time offset should be calculated from the timezone */
    public rigLocalTime: Date;
    public rigTimeZone: string;
    public rigState: RigState;
    public rigStateTimeIndex: Date;

    public wellId: string;
    public wellName: string;
    /** wellbore field */
    public wellboreName: string;
    /** Field, MWD provider and ML provider */
    public field: string;
    public mwd: string;
    public ml: string;
    public cement: string;
    public trajectory: string;
    /** Start Date and End Date are the planned Start and End Date */
    public sectionStart?: Date;
    public sectionEnd?: Date;
    public activatedDate?: Date;

    public isDapSynced?: boolean;
}
