import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AgFilterComponent } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams, RowNode, SerializedFilter, BaseFilter } from 'ag-grid-community';

import { CombinedFilter } from '../../missing-imports';
import { evaluate, IndexedType } from '../../common';

export enum DataType {
    Text = 'text',
    Number = 'number',
    Boolean = 'boolean',
    Date = 'date',
}

interface IFilterPassParam {
    filterPass: (key: string, params: IDoesFilterPassParams) => boolean;
}
export class DropdownFilterRendererParamsTyped<TOptionType extends IFilterPassParam> {
    constructor(
        public options: ((params: DropdownFilterRendererFilterParams) => TOptionType[]) | TOptionType[],
        public idField: ((params: DropdownFilterRendererFilterParams) => keyof TOptionType) | keyof TOptionType,
        public labelField: ((params: DropdownFilterRendererFilterParams) => keyof TOptionType) | keyof TOptionType,
        public keyDataType: DataType = DataType.Text,
    ) { }
}
export class DropdownFilterRendererParams extends DropdownFilterRendererParamsTyped<IndexedType & IFilterPassParam> { }

export type DropdownFilterRendererFilterParams = IFilterParams & DropdownFilterRendererParams;

class FilterState<T> {
    constructor(
        public idField: keyof T,
        public labelField: keyof T,
        public options: (T & IFilterPassParam)[],
        public defaultOption: string,
    ) { }
}

// add more fields if needed
export interface SerializedDropdownFilter extends SerializedFilter {
    /** the value to filter by */
    filter: string;
    type: string;
    dataType: DataType;
}

@Component({
    selector: 'app-dropdown-filter-renderer',
    template: `
<div class="w-100">
    <select class="w-100" [ngModel]="value" (ngModelChange)="onChange($event)">
        <option [selected]="i===0" [value]="opt[state.idField]" *ngFor="let opt of state?.options; let i=index">
            {{ opt[state.labelField] }}
        </option>
    </select>
</div>
    `, styles: [`
:host {
    display: block;
}
.container1 {
    border: 2px solid #22ff22;
    border-radius: 5px;
    background-color: #bbffbb;
    width: 200px;
    height: 50px
}

input {
    height: 20px
}
        `]
})
export class DropdownFilterRendererComponent implements AgFilterComponent {
    private params: DropdownFilterRendererFilterParams;

    public valueGetter: (rowNode: RowNode) => any;
    public value = '';

    public state: FilterState<IFilterPassParam & { [key: string]: string }>;

    //#region AgFilterComponent

    agInit(params: DropdownFilterRendererFilterParams): void {
        this.params = params;
        this.valueGetter = params.valueGetter;

        if (params) {
            this.state = new FilterState(
                evaluate(params.idField, params),
                evaluate(params.labelField, params),
                evaluate(params.options, params) || [],
                Array.isArray(params.options) && params.options.length ? params.options[0][evaluate(params.idField, params)] : null
            );
        }
    }

    isFilterActive(): boolean {
        return this.value !== this.state.defaultOption;
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        return this.state.options.find(x => x[this.state.idField] === this.value).filterPass(this.value, params);
    }

    getModel(): SerializedDropdownFilter | CombinedFilter<SerializedDropdownFilter> {
        return { filter: this.value, type: BaseFilter.EQUALS, filterType: 'dropdown', dataType: this.params.keyDataType };
    }

    setModel(model: { value: string, state: FilterState<IFilterPassParam & { [key: string]: string }> }): void {
        this.value = model && model.value;
        this.state = model && model.state;
    }

    //#endregion AgFilterComponent

    onChange(newValue: string): void {
        if (this.value !== newValue) {
            this.value = newValue;
            this.params.filterChangedCallback();
        }
    }
}


@NgModule({
    declarations: [DropdownFilterRendererComponent],
    exports: [DropdownFilterRendererComponent],
    imports: [CommonModule, FormsModule]
})
export class DropdownFilterRendererModule { }
