import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { PublicPart } from '../_common/index';
import { ErrorDetails } from '../_models/error-details';

export class AlertMessage {
  constructor(
    public type: 'success' | 'info' | 'warning' | 'danger',
    public message: string | ErrorDetails) { }
}

@Injectable({ providedIn: 'root' })
export class NgxAlertService {
  private subject = new Subject<AlertMessage>();
  private keepAfterNavigationChange = false;

  constructor(router: Router) {
    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next(null);
        }
      }
    });
  }

  success(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(new AlertMessage('success', message));
  }

  error(message: string | ErrorDetails, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(new AlertMessage('danger', message));
  }

  info(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(new AlertMessage('info', message));
  }

  warning(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(new AlertMessage('warning', message));
  }

  getMessage(): Observable<AlertMessage> {
    return this.subject.asObservable();
  }
}

/* eslint-disable */
export class FakeNgxAlertService implements PublicPart<NgxAlertService> {
  success(message: string, keepAfterNavigationChange = false): void {
    throw new Error('Method not implemented.');
  }
  error(message: string, keepAfterNavigationChange = false): void {
    throw new Error('Method not implemented.');
  }
  info(message: string, keepAfterNavigationChange = false): void {
    throw new Error('Method not implemented.');
  }
  warning(message: string, keepAfterNavigationChange = false): void {
    throw new Error('Method not implemented.');
  }
  getMessage(): Observable<any> {
    throw new Error('Method not implemented.');
  }
}

export const fakeAlertServiceProvider = { provide: NgxAlertService, useClass: FakeNgxAlertService };
/* eslint-enable */
