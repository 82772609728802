import { Store, Action } from '../ngrx-actions';

import {
    LoadInStateAlertOverviewsAction,
    LoadInStateAlertDetailsAction,
    UpdateInStateAlertIssuesProblemTimeAction,
    LoadInStateAlertFilterListsAction,
    UpdateInStateAlertStateStatusAction,
} from './alert.actions';
import { AlertState } from './alert.state';

@Store({} as AlertStore)
export class AlertStore {
    @Action(LoadInStateAlertOverviewsAction)
    public loadInStateAlertOverviews(state: AlertState, { alertOverviews }: LoadInStateAlertOverviewsAction) {
        return { ...state, alertOverviews } as AlertState;
    }

    @Action(LoadInStateAlertDetailsAction)
    public loadInStateAlertDetails(state: AlertState, { alertDetails }: LoadInStateAlertDetailsAction) {
        let newOverview = state.alertOverviews;

        if (Array.isArray(newOverview) && alertDetails) {
            const overviewItemIndex = newOverview.findIndex(x => x.id === alertDetails.id && x.state !== alertDetails.state);
            if (overviewItemIndex > -1) {
                newOverview = [...newOverview];
                newOverview[overviewItemIndex] = { ...newOverview[overviewItemIndex], state: alertDetails.state };
            }
        }

        return { ...state, alertDetails, alertOverviews: newOverview } as AlertState;
    }

    @Action(LoadInStateAlertFilterListsAction)
    public loadInStateAlertFilterLists(state: AlertState, { filterLists }: LoadInStateAlertFilterListsAction) {
        return { ...state, filterLists } as AlertState;
    }

    @Action(UpdateInStateAlertIssuesProblemTimeAction)
    public updateAlertIssues(state: AlertState, { alertId, issueIds, problemTime }: UpdateInStateAlertIssuesProblemTimeAction) {
        if (!state.alertDetails) { // extract to contract check
            throw new Error('Alert details state is not defined.');
        }

        if (!Array.isArray(state.alertDetails.issues)) { // extract to contract check
            throw new Error('Alert issues collection is not defined.');
        }

        if (!Array.isArray(issueIds)) { // extract to contract check
            throw new Error('IssueIds collection is not defined.');
        }

        if (typeof problemTime !== 'boolean') {
            throw new Error('ProblemTime not defined or is not boolean.');
        }

        const alertOverviewIndex = state.alertOverviews.findIndex(x => x.id === alertId);

        if (alertOverviewIndex === -1) {
            throw new Error('Alert in overview not found.');
        }

        const newIssues = [...state.alertDetails.issues];

        for (const id of issueIds) {
            const index = state.alertDetails.issues.findIndex(i => i.id === id);

            if (index < 0) {
                throw new Error(`Issue (id: ${id}) not found.`);
            }

            newIssues[index] = { ...newIssues[index], problemTime };
        }

        const newProblemTimeDuration = newIssues.reduce((acc, val) => acc + (val.problemTime ? val.timeProblemDuration : 0), 0);

        const newAlertOverviews = [...state.alertOverviews];
        newAlertOverviews[alertOverviewIndex] = {
            ...newAlertOverviews[alertOverviewIndex],
            timeProblemDuration: newProblemTimeDuration ? newProblemTimeDuration : null,
        };

        const alertDetails = { ...state.alertDetails, issues: newIssues };

        return { ...state, alertDetails, alertOverviews: newAlertOverviews } as AlertState;
    }

    @Action(UpdateInStateAlertStateStatusAction)
    public updateAlertStateStatus(state: AlertState, { alert }: UpdateInStateAlertStateStatusAction) {
        if (!Array.isArray(state.alertOverviews)) {
            throw new Error('Alert overview collection is not defined.');
        }

        if (!alert) {
            throw new Error('Alert is not defined.');
        }

        if (alert.id == null) { // extract to contract check
            throw new Error('Alert id is not defined.');
        }

        if (alert.state == null) { // extract to contract check
            throw new Error('Alert state status is not defined.');
        }

        const index = state.alertOverviews.findIndex(x => x.id === alert.id);

        if (index < 0) {
            throw new Error(`Alert (id: ${alert.id}) not found in alert overview collection`);
        }

        const newAlertOverviews = [...state.alertOverviews];
        newAlertOverviews[index] = { ...state.alertOverviews[index], state: alert.state };

        return { ...state, alertOverviews: newAlertOverviews } as AlertState;
    }
}
