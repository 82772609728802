export class TargetScore {
    public targetId: number;
    public targetShortName: string;
    public score: number;
}

export class DrqKpiScoreTreeItem {
    public dimension: string;
    public categoryKey: string;
    public scores: TargetScore[];
    public children: DrqKpiScoreTreeItem[];
}

export class DrqKpiScore {
    public sectionId: number;
    public tree: DrqKpiScoreTreeItem;
}

export class DrqKpiScoreTrendRequest {
    public sectionId: number;
    public targetId: number;
    public categoryKeys: string[];
    public from: string;
    public to: string;
}

export class KpiScoreTrendPoint {
    public eventDate: Date;
    public score: number;
}

export class KpiScoreTrend {
    public category: string;
    public trend: KpiScoreTrendPoint[];
}

export class KpiScoreTrendCollection {
    public sectionid: number;
    public trends: KpiScoreTrend[];
    public timestamp: Date;
}
