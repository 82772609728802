import { Subject, Observable } from 'rxjs';
import { GridApi } from 'ag-grid-community';

import { PaginationResult } from '@/_models';

// SerializedDateFilter
// SerializedTextFilter
// SerializedNumberFilter
// SerializedDropdownFilter
export interface GetRowsParams {
    // amount of rows to skip
    skip: number;
    // amount of rows to get
    top: number;
    // the sorting of the collection i.e. "col1 asc,col2 asc,col3 desc'
    orderBy?: string;
    // the filtering of the collection
    filter?: string;
}

export interface ServerPaginationParams {
    /** Callback which the grid will call when the paging is changed */
    getRows: (params: GetRowsParams) => void;
    /** Observable which emits the data queried by {@link getRows} */
    data$: Observable<PaginationResult<any>>;
}

export interface PaginationGridParams {
    /** How the pagination is expected to get data */
    paginationType?: PaginationType;
    /** reference to the grid's api */
    gridApi?: GridApi;
    /** reference to a grid's observable emitting when the columns may need to be resized */
    columnResize$?: Subject<boolean>;
    /** should the auto resize logic be triggered */
    autoSize?: boolean;
}

export enum PaginationType {
    Client,
    Server
}
