/* eslint-disable @angular-eslint/component-selector */

import { Component, ElementRef, OnInit  } from '@angular/core';
import { Replace } from '../shared';

@Component({
  selector: 'ngx-sidebar-minimizer',
  template: `
    <button class="sidebar-minimizer" type="button" ngxSidebarMinimizer ngxBrandMinimizer></button>
  `
})
export class NgxSidebarMinimizerComponent implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit() {
    Replace(this.el);
  }
}
