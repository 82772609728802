import {
    SimpleSection, KpiOverride, KpiIssue, IssueReasonCode,
     KpiProblemTimeAggregationPoint, SectionWithRuleInstances, KpiSummary, KPIExportDetails, KPIExportStatus,
     DapKpiSummary
} from '@/_models';

export class KpiState {
    public sectionList: SimpleSection[];
    public sectionExportList: KPIExportDetails[];
    public overrides: KpiOverride[];
    public issueReasonCodes: IssueReasonCode[];
    public chartData: KpiProblemTimeAggregationPoint[];
    public issues: KpiIssue[];
    public allIssues: KpiIssue[];
    public exportSummary: KpiSummary[];
    public dapSummary: DapKpiSummary[];
    public isFetchingIssues: boolean;
    public sectionWithRuleInstances: SectionWithRuleInstances;
    public exportStatus: KPIExportStatus;
}
