import { OrderSection } from './order-section';
import { Order } from './order';
import { CopyOrderParams, CopySectionParams } from './copy-params';
import { OrderTargets } from './order-targets';

export class CopyOrderResult {
    public params: CopyOrderParams;
    public order: Order;
}

export class CopySectionResult {
    public params: CopySectionParams;
    public sections: OrderSection[];
    public targets: OrderTargets;
}
