import { EditingMonitoringSession } from '@/_models/editing-monitoring-session';
import { Action } from '@ngrx/store';

export class LoadEditingMonitoringSessionAction implements Action {
    readonly type = '[EditingMonitoringSession] InState Get Dap Document Session action';
    constructor(public readonly currentMonitoringSession: EditingMonitoringSession) { }
}

export class LoadEditingMonitoringAction implements Action {
    readonly type = '[EditingMonitoringSession] InState Get Dap Document Sessions action';
    constructor(public readonly monitoringSessions: EditingMonitoringSession[]) { }
}

//#region fetch

export class FetchEditingMonitoringAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoring action';
    constructor(public readonly monitoringSession: EditingMonitoringSession) { }
}

export class FetchEditingMonitoringSessionAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoringSession action';
    constructor(public readonly monitoringKey: string) { }
}

export class FetchEditingMonitoringStartViewAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoringStartView action';
    constructor(public readonly monitoringSession: EditingMonitoringSession) { }
}

export class FetchEditingMonitoringStartEditAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoringStartEdit action';
    constructor(public readonly monitoringSession: EditingMonitoringSession) { }
}

export class FetchEditingMonitoringEndSessionAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoringEndSession action';
    constructor(public readonly sessionId: string) { }
}

export class FetchEditingMonitoringUpdateSessionAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoringUpdateSession action';
    constructor(public readonly monitoringSession: EditingMonitoringSession) { }
}

export class FetchEditingMonitoringOkAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoring Ok action';
}

export class FetchEditingMonitoringFailedAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoring Failed action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion fetch
