import { ListItem } from '../list-item';

export class RuleManagementFilterLists {
    constructor(
        public order: ListItem[],
        public rule: ListItem[],
        public subjectType: ListItem[],
        public serviceProvider: ListItem[],
        public service: ListItem[],
        public tool: ListItem[],
        public indexType: ListItem[],
        public isMemory: ListItem[]
    ) { }
}
