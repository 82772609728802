import { Appliance } from '@/_models/appliance';

export enum ApplianceTestStatus {
    Ok = 'Ok',
    Fail = 'Fail',
    Pending = 'Pending'
}

export class ApplianceState {
    public appliances: Appliance[];
    public selectedApplianceId: number;
    public slotsCount: number;
    public applianceTestStatus?: ApplianceTestStatus;
    public applianceTestErrorMessage?: string;
}
