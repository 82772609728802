export class DapDocumentChange {
    public item: string;
    public isCritical: boolean;
    public scope: DapDocumentChangeScope;
    public itemType: DapDocumentChangeType;
    public action: DapDocumentChangeAction;
    public previousValue: string;
    public editedValue: string;
}

export class DapDocumentRecord extends DapDocumentChange {
    public changedById: number;
    public changedByName: string;
    public changeDate: Date;
    public isCritical: boolean;
    public changes: DapDocumentRecord[];
}

export enum DapDocumentChangeScope {
    Document,
    Section,
    Dap
}

export enum DapDocumentChangeType {
    Field,
    Section,
    Dap,
    Row,
    Cell
}

export enum DapDocumentChangeAction {
    Insert,
    Delete,
    Edit
}
