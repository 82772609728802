import { ValidationMessage } from './validation-result';

export enum MnemonicImportMode {
    Default = 0,
    Merge = 1,
    Overwrite = 2,
    ExportValidationFile = 3
}

export class ImportValidationMessage extends ValidationMessage {
    public sheetName: string;
    public mnemonic: string;
}

export class ImportValidationResult {
    public messages: ImportValidationMessage[];
    public isValid: boolean;
}
