import { Action, Store } from '../ngrx-actions';
import {
    LoadDapBaseTemplatesAction, AddOrReplaceDapTemplateAction,
} from './dap-template-management.actions';
import { DapTemplateManagementState } from './dap-template-management.state';

@Store({} as DapTemplateManagementState)
export class DapTemplateManagementStore {

    @Action(LoadDapBaseTemplatesAction)
    public loadBaseTemplates(state: DapTemplateManagementState, { baseTemplates }: LoadDapBaseTemplatesAction) {
        return { ...state, baseTemplates } as DapTemplateManagementState;
    }

    @Action(AddOrReplaceDapTemplateAction)
    public addOrReplaceTemplate(state: DapTemplateManagementState, { template }: AddOrReplaceDapTemplateAction) {
        if (state.baseTemplates != null) {
            const newTemplates = [...state.baseTemplates];

            const index = state.baseTemplates.findIndex(x => x.name === template.name);
            if (index >= 0) {
                if (state.baseTemplates[index].version <= template.version) {
                    newTemplates[index] = { ...template };
                }
            } else {
                newTemplates.push(template);
            }

            return { ...state, baseTemplates: newTemplates } as DapTemplateManagementState;
        }
    }
}
