import { Component, Input, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { sidebarCssClasses } from '../shared';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: 'ngx-sidebar',
  template: `<ng-content></ng-content>`
})
export class NgxSidebarComponent implements OnInit, OnDestroy {
  @Input() compact: boolean;
  @Input() display: any;
  @Input() fixed: boolean;
  @Input() minimized: boolean;
  @Input() offCanvas: boolean;

  @HostBinding('class.sidebar') true;

  ngOnInit(): void {
    this.displayBreakpoint();
    this.isCompact();
    this.isFixed();
    this.isMinimized();
    this.isOffCanvas();
  }

  ngOnDestroy(): void {
    document.body.classList.remove('sidebar-fixed');
  }

  isCompact(): void {
    if (this.compact) { document.querySelector('body').classList.add('sidebar-compact'); }
  }

  isFixed(): void {
    if (this.fixed) { document.querySelector('body').classList.add('sidebar-fixed'); }
  }

  isMinimized(): void {
    if (this.minimized) { document.querySelector('body').classList.add('sidebar-minimized'); }
  }

  isOffCanvas(): void {
    if (this.offCanvas) { document.querySelector('body').classList.add('sidebar-off-canvas'); }
  }

  fixedPosition(): void {
    console.warn('deprecated fixedPosition(), use isFixed() instead');
    if (this.fixed) { document.querySelector('body').classList.add('sidebar-fixed'); }
  }

  displayBreakpoint(): void {
    if (this.display !== false ) {
      let cssClass;
      this.display ? cssClass = `sidebar-${this.display}-show` : cssClass = sidebarCssClasses[0];
      document.querySelector('body').classList.add(cssClass);
    }
  }
}
