import { DapTemplateContentColumn, DapTemplateTableCompositeKey } from "@/_models/dap-document-details";
import { DapDocumentValueValidators, MultiRowValidatorFunc, MultiValueValidatorFunc, ValueValidatorFunc } from "../dap-document-value-validators";
import { RefValidation } from "../eval/ref-validation";
import { DapDocumentVM, DapTableRowValue, DispositionResult } from "../vm";
import { PickListItem, PickListType } from "@/_models";

export class DapTableHelper {

    public static getColumnValueValidatorFuncs(col: DapTemplateContentColumn, columnDispositionResults: Map<string, DispositionResult>,
        docVm: DapDocumentVM, sectionIndex: number, dapIndex: number, rowValue: DapTableRowValue,
        picklistMap: Map<PickListType, PickListItem[]>): ValueValidatorFunc[] {

        const result: ValueValidatorFunc[] = [];
    
        const dispositionResult = columnDispositionResults.get(col.key);
        if (dispositionResult && !dispositionResult.nullable) {
            result.push(DapDocumentValueValidators.valueRequiredFunc(col.format));
        }
    
        if (col.fieldValidation) {
            result.push(...DapTableHelper.getValidatorsFromFunctions(col, docVm, sectionIndex, dapIndex, rowValue, picklistMap));
        }
    
        return result;
    }

    private static getValidatorsFromFunctions(col: DapTemplateContentColumn, docVm: DapDocumentVM, sectionIndex: number, dapIndex: number,
        rowValue: DapTableRowValue, picklistMap: Map<PickListType, PickListItem[]>): ValueValidatorFunc[] {

        const result: ValueValidatorFunc[] = [];
        col.fieldValidation?.forEach(validatorFunc => {
            result.push(...RefValidation.getTableCellValidatorFuncs(validatorFunc, col.format, docVm, sectionIndex, dapIndex, rowValue, picklistMap))
        });

        return result;
    }

    public static getColumnMultiValueValidatorFuncs(col: DapTemplateContentColumn): MultiValueValidatorFunc[] {
        const result: MultiValueValidatorFunc[] = [];
    
        if (col.unique) {
            result.push(DapDocumentValueValidators.valueUniqueFunc(col.format));
        }
    
        return result;
    }

    public static getUniqueRowValidatorFuncs(compositeKeys: DapTemplateTableCompositeKey[]): MultiRowValidatorFunc[] {
        const result: MultiRowValidatorFunc[] = [];
    
        compositeKeys?.forEach(compositeKey => {
            if (compositeKey.unique) {
                result.push(DapDocumentValueValidators.rowUniqueFunc(compositeKey));
            }
        })
    
        return result;
    }
}
