/**
 * Produces bitwise or of all numbers in a collection
 * @param {T[]} collection
 * @returns {T} Returns the bitwise or of all items
 */
 export function bitwiseOr(collection: number[]): number {
    if (collection && Array.isArray(collection)) {
        let result = 0;
        for (let i = 0; i < collection.length; i++) {
            // tslint:disable-next-line: no-bitwise
            result |= collection[i];
        }
        return result;
    }

    return null;
}
