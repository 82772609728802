export enum PickListType {
    Region = 1,
    Rig_Installation = 2,
    Field = 3,
    AssetContactRole = 4,
    ServiceProvider = 5,
    SectionSize = 7,
    SectionType = 8,
    AssetContactAvailability = 9,
    SectionStatus = 10,
    Service = 11,
    UserRoles = 13,
    LogNameType = 14,
    CurveIndexType = 15,
    Operator = 16,
    Country = 17,
    CurveGroup = 18,
    DapDocumentSubjectType = 19,
    Requirement = 21,
    FluidType = 22,
    TripForFailureReasoning = 24,
    DapDocumentScopeType = 25,
    ConditionGroup = 26,
    ReasoningForInclusion = 27,
    DepthRange = 28,
}

const picklistAliases: Map<string, string> = new Map([
    ['RigInstallation', 'Rig_Installation'],
    ['Rig Installation', 'Rig_Installation'],
    ['Trip For Failure Reasoning', 'TripForFailureReasoning']
]);

export function resolvePickListType(picklistName: string) {
    let picklistType: PickListType = PickListType[picklistName];
    if (!picklistType) {
        picklistType = PickListType[picklistAliases.get(picklistName)];
    }
    return picklistType;
}

export class PickList {
    public id: PickListType;
    public name: string;
    /** This indicates if the picklist is system defined. This means that you can't add new items to it. */
    public isDefault: boolean;
    /** Link to the pick list item from the parent pick list */
    public parentPicklistId?: PickListType;
}

export enum CurveIndexType {
    DateTime = 'Date Time',
    MeasuredDepth = 'Measured Depth'
}
