import { DapTemplateContentField } from '@/_models/dap-document-details/dap-template-content';
import { UntypedFormGroup } from '@angular/forms';
import { DapDispositionItem } from './dap-disposition-item';
import { DapDocumentVM } from './dap-document-vm';

export class DapFieldVM extends DapDispositionItem {
    public templateContent: DapTemplateContentField;
    public form: UntypedFormGroup;
    public isAlwaysReadonly = false;
    // backwards reference to the doc VM
    public docVM: DapDocumentVM;
}
