/**
 * When an initial id/key is not defined or is not a valid id/key in a collection return the first(if available) element's key
 * @param selectedId the initial selection key
 * @param arr the selectedId will be validated against this array
 * @param setSelectedFunc function to update (if required)the selectedId/key
 * @param setOnEmptyKey whether to call setSelectedFunc if there is not a valid key to be selected
 */
export function selectFirstElementIfNotSelected<T extends { id: number }>(
    selectedId: number,
    arr: T[],
    setSelectedFunc: (x: number) => void,
    setOnEmptyKey = false
) {
    if (!arr) { arr = []; }
    if (arr.every(y => y.id !== selectedId)) {
        const newSelectedId = arr[0] && arr[0].id;
        if (newSelectedId != null || setOnEmptyKey) {
            setSelectedFunc(newSelectedId);
        }
    }
}
