// todo: rename
export enum RuleCategoryType {
    Other = 0,
    Time = 1,
    Depth = 2,
    WITSML = 3
}

export enum RuleCategoryTypeNames {
    Other = 'Other',
    Time = 'Time',
    Depth = 'Depth',
    WITSML = 'WITSML'
}
