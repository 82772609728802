import {
    MnemonicTool, Order, OrderSectionCurve, OrderSimple, OrderListItem, CopyOrderResult, OrderValidation,
    CopySectionResult, OrderWithExtendedData, IdNamePair, WitsmlObject,
    OrderAsset,
} from '@/_models';
import { DapOrder } from '@/_models/dap-order-sync/dap-order';

export class OrderState {
    public simpleOrders?: OrderSimple[];
    public orderExtendedList?: OrderWithExtendedData[];
    public orderDetails?: Order;
    public orderValidation?: OrderValidation;
    public tools?: MnemonicTool[];
    public catalog?: OrderSectionCurve[];
    public orderList: OrderListItem[];
    public copyOrderResult?: CopyOrderResult;
    public copySectionResult?: CopySectionResult;
    public dapDocuments?: IdNamePair[];
    public syncOrder?: DapOrder;
    public targetWitsmlObjects?: WitsmlObject[];
    public activeWellWellbores?: WitsmlObject[];
    public myRigs?: number[];
    public assetContacts?: OrderAsset[];
}
