import { Store, Action } from '../ngrx-actions';
import { LoadDapMnemonicCatalogAction } from './dap-mnemonic-catalog.actions';
import { DapMnemonicState } from './dap-mnemonic-catalog.state';

@Store({} as DapMnemonicState)
export class DapMnemonicStore {

    @Action(LoadDapMnemonicCatalogAction)
    public loadMnemonicCatalog(state: DapMnemonicState, { mnemonics }: LoadDapMnemonicCatalogAction) {
        return { ...state, mnemonicCatalog: mnemonics } as DapMnemonicState;
    }
}
