import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PublicPart } from 'ngx-shared';

import { ServerConnection, SoapConnection, InfraPanel, SimpleConnection } from '@/_models';
import { tap } from 'rxjs/operators';

class SoapTestResponse {
    public isAvailable: boolean;
    public message?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ServerConnectionService {

    constructor(private http: HttpClient) { }

    getAll(): Observable<ServerConnection[]> {
        return this.http.get<ServerConnection[]>(`${environment.portalWebApi}/Connections`);
    }

    getSimpleConnections(): Observable<SimpleConnection[]> {
        return this.http.get<SimpleConnection[]>(`${environment.portalWebApi}/Connections/simple`);
    }

    getAllAsInfraPanels(): Observable<InfraPanel[]> {
        return this.http.get<InfraPanel[]>(`${environment.portalWebApi}/Connections/status`).pipe(
            tap(infraPanels => infraPanels &&
                infraPanels.forEach(ip => {
                        ip.isAvailableLastChangeTimestamp =
                            ip.isAvailableLastChangeTimestamp ?
                            new Date(ip.isAvailableLastChangeTimestamp) :
                            ip.isAvailableLastChangeTimestamp;

                        ip.lastCheckedTimestamp =
                            ip.lastCheckedTimestamp ?
                            new Date(ip.lastCheckedTimestamp) :
                            ip.lastCheckedTimestamp;
                })
            )
        );
    }

    update(serverConnection: ServerConnection): Observable<ServerConnection> {
        return this.http.post<ServerConnection>(`${environment.portalWebApi}/Connections/update`, serverConnection);
    }

    create(serverConnection: ServerConnection): Observable<ServerConnection> {
        return this.http.post<ServerConnection>(`${environment.portalWebApi}/Connections/create`, serverConnection);
    }

    delete(connectionId: number): Observable<void> {
        return this.http.delete<void>(`${environment.portalWebApi}/Connections/delete/${connectionId}`);
    }

    checkSoapConnection(data: SoapConnection): Observable<SoapTestResponse> {
        return this.http.post<SoapTestResponse>(`${environment.portalWebApi}/Connections/testSoapConnection`, data);
    }
}


/* eslint-disable */
export class FakeServerConnectionService implements PublicPart<ServerConnectionService> {
    getAll(): Observable<ServerConnection[]> {
        throw new Error('Method not implemented.');
    }

    getSimpleConnections(): Observable<SimpleConnection[]> {
        throw new Error('Method not implemented.');
    }

    getAllAsInfraPanels(): Observable<InfraPanel[]> {
        throw new Error('Method not implemented.');
    }

    update(serverConnection: ServerConnection): Observable<ServerConnection> {
        throw new Error('Method not implemented.');
    }

    create(serverConnection: ServerConnection): Observable<ServerConnection> {
        throw new Error('Method not implemented.');
    }

    delete(connectionId: number): Observable<void> {
        throw new Error('Method not implemented.');
    }

    checkSoapConnection(data: SoapConnection): Observable<SoapTestResponse> {
        throw new Error('Method not implemented.');
    }
}

export const fakeServerConnectionServiceProvider = { provide: ServerConnectionService, useClass: FakeServerConnectionService };
/* eslint-enable */
