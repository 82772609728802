export enum WellDatumCode {
    Unknown,
    CF,
    CV,
    DF,
    GL,
    KB,
    RB,
    RT,
    SF,
    LAT,
    SL,
    MHHW,
    MHW,
    MLLW,
    MLW,
    MTL,
    KO,
}
