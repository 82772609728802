import { Store, Action } from '../ngrx-actions';
import { LoadRolesAction } from './role.actions';
import { RoleState as RoleState } from './role.state';

@Store({} as RoleState)
export class RoleStore {

    @Action(LoadRolesAction)
    public loadRoles(state: RoleState, { roles }: LoadRolesAction) {
        return { ...state, roles: roles } as RoleState;
    }
}
