import { HttpErrorResponse } from '@angular/common/http';
import { ErrorDetails } from '../_models/error-details';

export function getMessageFromError(error: Error | HttpErrorResponse | string | any): string | ErrorDetails {
    if (error instanceof Error) {
        return error.message;
    } else if (error instanceof HttpErrorResponse) {
        if (isNginxError(error.error)) {
            return getNginxError(error.error);
        } else if (typeof error.error === 'string') {
            return error.error;
        } else if (typeof error.error === 'object') {
            if (isValidationResult(error.error)) {
                return getMessagesFromValidationObject(error.error);
            } else if (hasErrorRef(error.error)) {
                return error.error as ErrorDetails;
            } else {
                return error.error.title || error.statusText || error.status.toString();
            }
        }
    } else if (typeof error === 'object' && isValidationResult(error)) {
        return getMessagesFromValidationObject(error);
    }

    return error;
}

function isNginxError(error: any | string) {
    if (typeof error === 'string') {
        return error.indexOf('<center>nginx/') > -1;
    } else {
        return false;
    }
}

function getNginxError(error: string) {
    const match = error.match(/<title>(.*)<\/title>/);
    return match && match[1];
}

function isValidationResult(obj: any) {
    return obj != null && 'isValid' in obj && Array.isArray(obj.messages);
}

function hasErrorRef(obj: any) {
    return obj != null && 'errorRef' in obj;
}

function getMessagesFromValidationObject(error: { messages: { message: string }[] }) {
    return error.messages.reduce((prev, curr) => prev + (prev.length ? ' ' : '') + curr.message, '');
}
