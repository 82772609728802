import { AlertStateStatus } from './alert-state-status';
import { ProblemTimeData } from '../problem-time-data';

export class AlertOverview extends ProblemTimeData {
    public id: number;
    public state: AlertStateStatus;
    public startTime: Date;
    public severity: number;
    public ruleName: string;
    public subjectName: string;
    public cause: string;
    public targetId: number;
    public targetName: string;
    public wellId: string;
    public wellBoreId: string;
    public sectionId: number;
    public rigLocalTime: Date;
}
