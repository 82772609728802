import { TimeZoneGmtZero } from '@/_helpers';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RigTimeService {
    public time$ = new Subject<Date>();

    private interval: NodeJS.Timeout;
    private _timeZone: string = TimeZoneGmtZero;

    public get timeZone(): string { return this._timeZone; }
    public applyTimeZone(timeZone: string) {
        this._timeZone = timeZone ?? TimeZoneGmtZero;
    }

    public get currentTime(): Date {
        return this.resolveDate();
    }

    public startTimer() {
        this.time$.next(this.resolveDate());
        this.interval = setInterval(() => {
            this.time$.next(this.resolveDate());
          },1000)
    }

    public stopTimer() {
        clearInterval(this.interval);
    }

    public toRigTime(date: Date): Date {
        return new Date(moment.tz(moment(date), this._timeZone).format('YYYY-MM-DDTHH:mm:ss'));
    }

    public toUtc(date: Date): Date {
        return new Date(moment.tz(moment(date, this._timeZone), TimeZoneGmtZero).format('YYYY-MM-DDTHH:mm:ss'));
    }

    private resolveDate(): Date {
        return new Date(moment.tz(moment(), this._timeZone).format('YYYY-MM-DDTHH:mm:ss'));
    }
}
