import { ActiveSectionVM } from '@/_models';
import { ApplianceJobVM, ApplianceServiceVM } from '@/_models/appliance-common';
import { ApplianceFileDetailsVM } from '@/_models/appliance-common/appliance-file-details-vm';
import { ApplianceFileVM } from '@/_models/appliance-common/appliance-file-vm';

export class TransferManagementState {
    public activeSections: ActiveSectionVM[];
    public jobs: ApplianceJobVM[];
    public services: ApplianceServiceVM[];
    public files: ApplianceFileVM[];
    public fileDetails: ApplianceFileDetailsVM[];
}
