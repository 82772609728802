export class DapDocumentContact {
    public id: number;
    public dapDocumentId: number;
    public name: string;
    public dapRoleId: number;
    public isResponsible: boolean;
    public isApprover: boolean;
    public isDrqContact: boolean;
    public hasDrqTightWellAccess: boolean;
    public wantsDapNotifications: boolean;
    public wantsDrqNotifications: boolean;
    public phone: string;
    public email: string;
    public availabilityId: number;
}
