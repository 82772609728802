import { Action } from '@ngrx/store';
import { AlertOverview, AlertDetails, AlertFilterLists, AlertStateStatus } from '@/_models';

export class LoadInStateAlertOverviewsAction implements Action {
    public type = '[Alert] Load AlertOverviews action';

    constructor(public readonly alertOverviews: AlertOverview[]) {}
}

// #region async Fetch AlertOverview

export class FetchAlertOverviewsAction implements Action {
    public type = '[Alert] Fetch AlertOverviews action';
    constructor(
        public readonly sectionId: number,
        public readonly targetId?: number,
    ) { }
}
export class FetchOkAlertOverviewsAction implements Action {
    public type = '[Alert] Fetch OK AlertOverviews action';
}
export class FetchFailedAlertOverviewsAction implements Action {
    public type = '[Alert] Fetch Cancel AlertOverviews action';
    constructor(public readonly error: Error | string | any) { }
}

// #endregion async Fetch AlertOverview

export class LoadInStateAlertDetailsAction implements Action {
    public type = '[Alert] Load AlertDetails action';

    constructor(public readonly alertDetails: AlertDetails) {}
}
export class ClearInStateAlertDetailsAction implements Action {
    public type = '[Alert] Clear AlertDetails action';
}

// #region async Fetch AlertDetails

export class FetchAlertDetailsAction implements Action {
    public type = '[Alert] Fetch AlertDetails action';
    constructor(public readonly alertId: number) { }
}
export class FetchOkAlertDetailsAction implements Action {
    public type = '[Alert] Fetch OK AlertDetails action';
}
export class FetchFailedAlertDetailsAction implements Action {
    public type = '[Alert] Fetch Cancel AlertDetails action';
    constructor(public readonly error: Error | string | any) { }
}

// #endregion async Fetch AlertDetails


// #region async Update AlertIssues ProblemTime

export class UpdateInStateAlertIssuesProblemTimeAction implements Action {
    readonly type = '[Alert] Update InState AlertIssue ProblemTime action';
    constructor(public readonly alertId: number, public readonly issueIds: number[], public readonly problemTime: boolean) { }
}

export class UpdateAlertIssuesProblemTimeAction implements Action {
    public type = '[Alert] Update AlertIssues ProblemTime action';
    constructor(public readonly alertId: number, public readonly issueIds: number[], public readonly problemTime: boolean) { }
}

export class UpdateOkAlertIssuesProblemTimeAction implements Action {
    readonly type = '[Alert] Update OK AlertIssues ProblemTime action';
}

export class UpdateFailedAlertIssuesProblemTimeAction implements Action {
    readonly type = '[Alert] Update Failed AlertIssues ProblemTime action';
    constructor(public readonly error: Error | string | any) { }
}

// #endregion async Update AlertIssues ProblemTime

export class LoadInStateAlertFilterListsAction implements Action {
    readonly type = '[Alert] Load InState AlertFilterLists action';
    constructor(public readonly filterLists: AlertFilterLists) { }
}

export class FetchAlertFilterListsAction implements Action {
    readonly type = '[Alert] Fetch AlertFilterLists action';
}

export class FetchOkAlertFilterListsAction implements Action {
    readonly type = '[Alert] Fetch OK AlertFilterLists action';
}

export class FetchFailedAlertFilterListsAction implements Action {
    readonly type = '[Alert] Fetch Failed AlertFilterLists action';
    constructor(public readonly error: Error | string | any) { }
}

// #region async Update Alert state

export class UpdateInStateAlertStateStatusAction implements Action {
    readonly type = '[Alert] Update InState Alert state status action';
    constructor(public readonly alert: AlertDetails) { }
}

export class UpdateAlertStateStatusAction implements Action {
    public type = '[Alert] Update Alert state status action';
    constructor(public readonly id, public readonly state: AlertStateStatus, public readonly comment) { }
}

export class UpdateOkAlertStateStatusAction implements Action {
    readonly type = '[Alert] Update OK Alert state status action';
}

export class UpdateFailedAlertStateStatusAction implements Action {
    readonly type = '[Alert] Update Failed Alert state status action';
    constructor(public readonly error: Error | string | any) { }
}

export class AlertStateTransitionValidationFailAction implements Action {
    readonly type = '[Alert] Validation Failed of alert state transition';
    constructor(public readonly message: string) { }
}

// #endregion async Update Alert state
