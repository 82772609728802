export class DapDispositionItem {
    public dispositionResult: DispositionResult;
    public hasReadonlyParent = false;
    public hasCriticalParent = false;
}

export class DispositionResult {
    public mutable: boolean;
    public mutableSetBy: string;
    public visible: boolean;
    public visibleSetBy: string;
    public nullable: boolean;
    public nullableSetBy: string;
    public fixed: boolean;
    public fixedSetBy: string;
    public critical: boolean;
    public criticalSetBy: string;
}

export class DispositionResultGridItem extends DispositionResult {
    public name: string;
    public itemType: string;
    public children: DispositionResultGridItem[];
}
