import { CommonModule} from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutModule } from '../shared/layout';

import { NgxAsideComponent } from './ngx-aside.component';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule
  ],
  exports: [
    NgxAsideComponent,
    LayoutModule
  ],
  declarations: [
    NgxAsideComponent
  ]
})
export class NgxAsideModule {}
