import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TooltipComponent } from '@/tooltips/tooltip.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [TooltipComponent],
  imports: [CommonModule , FormsModule, TooltipModule.forRoot()],
  exports: [TooltipModule, TooltipComponent]
})
export class TooltipsModule { }
