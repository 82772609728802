import { PickListItem, PickListType } from "@/_models";
import { DapDapVM } from "../vm";
import { getPickListItem } from "@state";

export class DapDapHelper {
    public static getDapServiceProvider(dapVm: DapDapVM, picklistMap: Map<PickListType, PickListItem[]>): string {
        if (dapVm?.serviceProviderId >= 0) {
            return getPickListItem(picklistMap, PickListType.ServiceProvider, dapVm.serviceProviderId);
        } else {
            return 'Unspecified';
        }
    }
}
