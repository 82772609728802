import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { PublicPart } from 'ngx-shared';

import { Role } from '@/_models';

@Injectable({ providedIn: 'root' })
export class RoleService {
    constructor(private http: HttpClient) { }

    getAll(): Observable<Role[]> {
        return this.http.get<Role[]>(`${environment.portalWebApi}/Roles`);
    }
}

export class FakeRoleService implements PublicPart<RoleService> {
    getAll(): Observable<Role[]> {
        throw new Error('Method not implemented.');
    }
}

export const fakeRoleServiceProvider = { provide: RoleService, useClass: FakeRoleService };
