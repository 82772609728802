import { DapBaseTemplate } from '@/_models/dap-template-management';
import { PublicPart } from '@/_shared';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DapTemplateManagementService {

    constructor(private http: HttpClient) { }

    getBaseTemplates(): Observable<DapBaseTemplate[]> {
        return this.http.get<DapBaseTemplate[]>(`${environment.dapApi}/DapTemplateManagement/basetemplates`);
    }

    deprecateTemplate(templateId: number): Observable<DapBaseTemplate> {
        return this.http.post<DapBaseTemplate>(`${environment.dapApi}/DapTemplateManagement/${templateId}/deprecate`, {});
    }

    deleteTemplate(templateId: number): Observable<DapBaseTemplate[]> {
        return this.http.delete<DapBaseTemplate[]>(`${environment.dapApi}/DapTemplateManagement/${templateId}/delete`);
    }
}

/* eslint-disable */
export class FakeDapTemplateManagementService implements PublicPart<DapTemplateManagementService> {
    getBaseTemplates(): Observable<DapBaseTemplate[]> {
        throw new Error('Method not implemented.');
    }

    deprecateTemplate(templateId: number): Observable<DapBaseTemplate> {
        throw new Error('Method not implemented.');
    }

    deleteTemplate(templateId: number): Observable<DapBaseTemplate[]> {
        throw new Error('Method not implemented.');
    }
}
/* eslint-enable */
