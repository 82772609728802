import { DapApiLicense, DapApiLicenseInfo, DapApiProfile } from '@/_models/dap-api-license';

export class LicenseState {
    public licenses: DapApiLicense[];
}

export class ProfileState {
    public profiles: DapApiProfile[];
}

export class LicenseInfoState {
    public licenseInfo: DapApiLicenseInfo;
}
