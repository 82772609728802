export enum StateValueType
{
    String = 0,
    Float = 1,
    Int = 2,
    Bool = 3,
    DateTime = 4,
}

export enum StateTableVisualisationType
{
    Table = 0,
    Chart = 1,
}

export enum ChartType
{
    Scatter = 0,
}

export enum ChartMode
{
    Line = 0,
    Markers = 1,
    LineAndMarkers = 2,
}

export class StateDetails {
    public values: StateValue[];
    public tables: StateTable[];
}

export class StateValue
{
    public group: string;
    public name: string;
    public valueType: StateValueType;
    public valueAsString: string;
}

export class StateTable
{
    public name: string;
    public excel: boolean;
    public visualise: StateTableVisualiseInfo;
    public cols: StateTableColumnDefinition[];
    public rows: string[][];
}

export class StateTableColumnDefinition
{
    public name: string;
    public valueType: StateValueType;
}

export class StateTableVisualiseInfo
{
    public visualisationType: StateTableVisualisationType;
    public xAxisColumnName: string;
    public yAxes: YAxisInfo[];
}

export class YAxisInfo
{
    public chartType: ChartType;
    public chartMode: ChartMode;
    public label: string;
    public fromZero: boolean;
    public invert: boolean;
    public columnNames: string[];
}

export class StateGroup {
    name: string;
    stateValues: StateValue[];
}
