import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import {
    LoadDapMnemonicCatalogAction, FetchDapMnemonicCatalogAction, FetchFailedDapMnemonicCatalogAction, FetchOkDapMnemonicCatalogAction,
} from './dap-mnemonic-catalog.actions';
import { DapCatalogService } from '@/_services/dap-catalog.service';

@Injectable()
export class DapMnemonicEffects {

    public onFetchDapMnemonicCatalog$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapMnemonicCatalogAction),
        switchMap(x => this.dapCatalogService.getAllCatalog(x.serviceProviderId).pipe(
            switchMap(y => [new LoadDapMnemonicCatalogAction(y), new FetchOkDapMnemonicCatalogAction()]),
            catchError(error => {
                this.logger.error('Error while fetching MnemonicCatalog', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedDapMnemonicCatalogAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly dapCatalogService: DapCatalogService,
    ) { }
}
