import { Action } from '@ngrx/store';
import { User, SimpleUser } from '@/_models';

export class LoadUsersAction implements Action {
    public type = '[User] Load users action';

    constructor(public readonly users: User[]) { }
}

export class FetchUsersAction implements Action {
    public type = '[User] Fetch users action';
}

export class FetchOkUsersAction implements Action {
    public type = '[User] Fetch OK users action';
}

export class FetchFailedUsersAction implements Action {
    public type = '[User] Fetch Cancel users action';
    constructor(public readonly error: Error | string | any) { }
}

export class LoadSimpleUsersAction implements Action {
    public type = '[User] Load simple users action';
    constructor(public readonly simpleUsers: SimpleUser[]) { }
}

export class FetchSimpleUsersAction implements Action {
    public type = '[User] Fetch simple users action';
}

export class FetchOkSimpleUsersAction implements Action {
    public type = '[User] Fetch OK simple users action';
}

export class FetchFailedSimpleUsersAction implements Action {
    public type = '[User] Fetch Cancel simple users action';
    constructor(public readonly error: Error | string | any) { }
}

export class CreateUserInStateAction implements Action {
    public type = '[User] Create user in state action';
    constructor(public readonly user: User) { }
}

export class CreateUserOnServerAction implements Action {
    public type = '[User] Create user on server action';
    constructor(public readonly user: User) { }
}

export class CreateUserAction implements Action {
    public type = '[User] Create user action';
    constructor(public readonly user: User) { }
}

export class CreateOkUserAction implements Action {
    public type = '[User] Create OK user action';
}

export class DeleteUserAction implements Action {
    public type = '[User] Delete user id action';
    constructor(public readonly userId: number) { }
}

export class DeleteOkUserAction implements Action {
    public type = '[User] Delete OK user id action';
}

export class DeleteFailedUserAction implements Action {
    public type = '[User] Delete Failed user id action';
    constructor(public readonly error: Error | string | any) { }
}

export class DeleteUserFromStateAction implements Action {
    public type = '[User] Delete user id from state action';
    constructor(public readonly userId: number) { }
}

export class DeleteUserOnServerAction implements Action {
    public type = '[User] Delete user id on server action';
    constructor(public readonly userId: number) { }
}

export class DisableUserAction implements Action {
    public type = '[User] Disable user id action';
    constructor(public readonly userId: number) { }
}

export class DisableOkUserAction implements Action {
    public type = '[User] Disable OK user id action';
}

export class DisableFailedUserAction implements Action {
    public type = '[User] Disable Failed user id action';
    constructor(public readonly error: Error | string | any) { }
}

export class DisableUserOnServerAction implements Action {
    public type = '[User] Disable user id on server action';
    constructor(public readonly userId: number) { }
}

export class DisableUserInStateAction implements Action {
    public type = '[User] Disable user id in state action';
    constructor(public readonly user: User) { }
}

export class EnableUserAction implements Action {
    public type = '[User] Enable user id action';
    constructor(public readonly userId: number) { }
}

export class EnableOkUserAction implements Action {
    public type = '[User] Ensable OK user id action';
}

export class EnableFailedUserAction implements Action {
    public type = '[User] Enable Failed user id action';
    constructor(public readonly error: Error | string | any) { }
}

export class EnableUserOnServerAction implements Action {
    public type = '[User] Enable user id on server action';
    constructor(public readonly userId: number) { }
}

export class EnableUserInStateAction implements Action {
    public type = '[User] Enable user id in state action';
    constructor(public readonly user: User) { }
}

export class CreateFailedUserAction implements Action {
    public type = '[User] Create Cancel user action';
    constructor(public readonly error: Error | string | any) { }
}

export class UpdateUserInStateAction implements Action {
    public type = '[User] Update user in state action';
    constructor(public readonly user: User) { }
}

export class UpdateUserOnServerAction implements Action {
    public type = '[User] Update user on server action';
    constructor(public readonly user: User) { }
}


export class UpdateUserAction implements Action {
    public type = '[User] Update user action';
    constructor(public readonly user: User) { }
}

export class UpdateOkUserAction implements Action {
    public type = '[User] Update OK user action';
}

export class UpdateFailedUserAction implements Action {
    public type = '[User] Update Cancel user action';
    constructor(public readonly error: Error | string | any) { }
}
