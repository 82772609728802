import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class SystemParametersEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly logger: NGXLogger,
    ) { }
}
