export class SimpleSection {
    public id: number;
    public name: string;
    public orderId: number;
    public startTime: Date;
    public endTime: Date;
    public orderName: string;
    public targets: number[];
    public active: boolean;
    public hasDapLink: boolean;
}

export class SimpleOrder {
    public id: number;
    public name: string;
}
