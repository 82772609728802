export function toMap<TData>(grouped: { [key: number]: TData[] }): Map<number, TData[]>;
export function toMap<TData, TOut>(grouped: { [key: number]: TData[] }, resultSelectorFn: (x: TData) => TOut): Map<number, TOut[]>;
export function toMap<TData>(grouped: { [key: string]: TData[] }): Map<string, TData[]>;
export function toMap<TData, TOut>(grouped: { [key: string]: TData[] }, resultSelectorFn: (x: TData) => TOut): Map<string, TOut[]>;
export function toMap<TData, TOut>(grouped: { [key: string]: TData[] }, resultSelectorFn: (x: TData) => TOut = x => x as any
): Map<any, TOut[]> {
    const map = new Map<number | string, TOut[]>();
    Object.keys(grouped).forEach(key => {
        const numberKey = Number(key);
        // tslint:disable-next-line: triple-equals
        const mapKey = (numberKey as any) != key ? key : numberKey;
        const mapValue = grouped[key] && grouped[key].map(x => resultSelectorFn(x));
        map.set(mapKey, mapValue);
    });
    return map;
}
