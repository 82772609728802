import { PickListItem } from '@/_models';
import { DapDocumentStatus } from '@/_models/dap-common';
import {
    DapDocumentApproval, DapDocumentContact, DapDocumentContent, ActiveDocChangeData, DapDocumentRecord, DapScoringModelDefaults, DapTemplateContent
} from '@/_models/dap-document-details';

export class DapDocumentDetailsState {
    public templateContent: DapTemplateContent;
    public activeDocChangeData: ActiveDocChangeData;
    public documentContent: DapDocumentContent;
    public approvals: DapDocumentApproval[];
    public contacts: DapDocumentContact[];
    public status: DapDocumentStatus;
    public businessState: string;
    public picklistItems: PickListItem[];
    public adGroups: string[];
    public scoringModelDefaults: DapScoringModelDefaults;
    public documentChanges: DapDocumentRecord[];
}
