import { DapDapScoring, DapDocumentContentFieldValue, DapSectionScoring } from './dap-document-content';

export class DapTemplateContentEntityBaseDto {
    public key: string;
    public name: string;
}

export class DapTemplateContentContainer extends DapTemplateContentEntityBaseDto {
    public groups: DapTemplateContentGroup[];
    public fields: DapTemplateContentField[];
    public tables: DapTemplateContentTable[];
}

export class DapTemplateContent extends DapTemplateContentContainer {
    public sectionTypes: DapTemplateContentSectionType[];
    public approvals: string[];
    public docLinkBase: string;
    public businessStates: DapTemplateContentBusinessState[];
    public documentRoles: DapTemplateContentDocumentRole[];
    public changeControl: string;
    public contextTests: DapTemplateContentContextTest[];
    public dispositionRules: DapTemplateContentDispositionRule[];
}

export class DapTemplateContentGroup {
    public name: string;
    public title: string;
    public docLink: string;
    public collapsible: boolean;
    public initiallyCollapsed: boolean;
    public collapseSummary: DapTemplateContentComputedValue;
    public disposition: string[];
}

export class DapTemplateContentField extends DapTemplateContentEntityBaseDto {
    public label: string;
    public tooltip: string;
    public format: string;
    public enum: string[];
    public units: string[];
    public picker: DapTemplateContentPicker;
    public documentation: string;
    public docLink: string;
    public computedValue: DapTemplateContentComputedValue;
    public default: DapDocumentContentFieldValue;
    public group: string;
    public ordinal: number;
    public fieldWidth: number;
    public textSize: string;
    public disposition: string[];
    public fieldValidation: DapTemplateContentFunction[];
}

export class DapTemplateContentPicker {
    public type: string;
    public picklist: string;
    public context: string[];
}

export class DapTemplateContentComputedValue {
    public type: string;
    public reference: string;
    public function: DapTemplateContentFunction;
}

export class DapTemplateContentFunction {
    public name: string;
    public params: any[];
}

export class DapTemplateContentTable extends DapTemplateContentEntityBaseDto {
    public label: string;
    public tooltip: string;
    public isCategoryTable: boolean;
    public columns: DapTemplateContentColumn[];
    public compositeKeys: DapTemplateTableCompositeKey[];
    public parentKeys: DapTemplateTableParentKey[];
    public customTableEditors: DapTemplateTableCustomTableEditor[];
    public documentation: string;
    public docLink: string;
    public group: string;
    public ordinal: number;
    public disposition: string[];
}

export class DapTemplateContentColumn extends DapTemplateContentField {
    public colWidth: number;
    public unique: boolean;
    public isCategoryLabel: boolean;
    public isCategoryDeliveryWeight: boolean;
    public isCategoryQualityWeight: boolean;
    public validation: DapTemplateContentFunction[];
}

export class DapTemplateTableCompositeKey {
    public name: string;
    public columns: string[];
    public ordered: boolean;
    public unique: boolean;
}

export class DapTemplateTableParentKey {
    public table: string;
    public compositeKeyName: string;
    public makeDistinct: boolean;
}

export class DapTemplateTableCustomTableEditor {
    public label: string;
    public component: string;
    public context: string[];
    public disposition: string[];
}

export class DapTemplateContentSectionType extends DapTemplateContentContainer {
    public scoring: DapSectionScoring;
    public dapTypes: DapTemplateContentDapType[];
    public disposition: string[];
}

export class DapTemplateContentDapType extends DapTemplateContentContainer {
    public optional: boolean;
    public scoring: DapDapScoring;
    public allowMultiple: boolean;
    public disposition: string[];
    public allowedServiceProviders: string[];
    public mayChangeServiceProvider: boolean;
}

export class DapTemplateContentBusinessState {
    public name: string;
    public description: string;
    public initial: boolean;
    public inDocState: string[];
    public inBusinessState: string[];
    public onDocStateChange: string[];
}

export class DapTemplateContentDocumentRole {
    public name: string;
    public userIsServiceProvider: boolean;
    public userIsClient: boolean;
    public adGroups: string[];
    public contactRoles: string[];
    public dapRoles: string[];
}

export class DapTemplateContentContextTest {
    public name: string;
    public match: string;
    public documentRoles: string[];
    public userIsClient: boolean;
    public serviceProviders: string[];
    public businessStates: string[];
    public documentStates: string[];
    public sectionStates: string[];
    public dapStates: string[];
}

export class DapTemplateContentDispositionRule {
    public name: string;
    public conditions: DapTemplateContentDispositionRuleCondition[];
}

export class DapTemplateContentDispositionRuleCondition {
    public need: string;
    public negate: boolean;
    public scope: string[];
    public when: string[];
    public mutable: boolean;
    public visible: boolean;
    public nullable: boolean;
    public fixed: boolean;
    public critical: boolean;
}
