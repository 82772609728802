import { IndicatorStatus } from '../indicator-status';

export class ConnectionStatuses {
    public targetId: number;
    public status: IndicatorStatus;
    public total: number;
    public valid: number;
}

export class SectionMonitorStatusItem {
    public id: string;

    public categoryService?: string;
    public categoryType?: string;
    public categorySubject?: string;
    public subjectType?: string;
    public subjectId?: string;
    public monitorId?: string;

    public text: string;
    public curveLabel: string;
    public hasChildren: boolean;
    public targetStatuses: ConnectionStatuses[];
    public children: SectionMonitorStatusItem[];
    public isTime: boolean;
    public isDepth: boolean;
    public isMonitor: boolean;
    public isCurve: boolean;
}

export class SectionMonitorStatus {
    public sectionId: number;
    public items: SectionMonitorStatusItem[];
}
