import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { catchError, switchMap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import {
    LoadDapBaseTemplatesAction, AddOrReplaceDapTemplateAction,
    FetchDapBaseTemplatesAction, FetchDapBaseTemplatesOkAction, FetchDapBaseTemplatesFailedAction,
    DeprecateDapTemplateAction, DeprecateDapTemplateOkAction, DeprecateDapTemplateFailedAction,
    DeleteDapTemplateAction, DeleteDapTemplateOkAction, DeleteDapTemplateFailedAction
} from './dap-template-management.actions';
import { of } from 'rxjs';
import { DapTemplateManagementService } from '@/_services/dap-template-management.service';

@Injectable()
export class DapTemplateManagementEffects {

    
    public onFetchDapBaseTemplates$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapBaseTemplatesAction),
        switchMap(() => this.dapTemplateManagementService.getBaseTemplates().pipe(
            switchMap(x => [new LoadDapBaseTemplatesAction(x), new FetchDapBaseTemplatesOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchDapBaseTemplates ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapBaseTemplatesFailedAction(error));
            })
        ))
    ));
    
    public onDeprecateDapTemplate$ = createEffect(() => this.actions$.pipe(
        ofAction(DeprecateDapTemplateAction),
        switchMap(x => this.dapTemplateManagementService.deprecateTemplate(x.templateId).pipe(
            switchMap(template => [new AddOrReplaceDapTemplateAction(template), new DeprecateDapTemplateOkAction()]),
            catchError(error => {
                this.logger.error('Error in DeprecateDapTemplate ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new DeprecateDapTemplateFailedAction(error));
            })
        ))
    ));

    
    public onDeleteDapTemplate$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteDapTemplateAction),
        switchMap(x => this.dapTemplateManagementService.deleteTemplate(x.templateId).pipe(
            switchMap(() => [new FetchDapBaseTemplatesAction(), new DeleteDapTemplateOkAction()]),
            catchError(error => {
                this.logger.error('Error in DeleteDapTemplate ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new DeleteDapTemplateFailedAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly dapTemplateManagementService: DapTemplateManagementService
    ) { }
}
