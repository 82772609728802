import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

import { IndicatorStatus, MatchingIssuesFilter, MatchingIssuesResult, QUERY_PARAM_ALERT, QUERY_PARAM_SECTION, QUERY_PARAM_TARGET } from '@/_models';
import { CurrentUserService } from '@/_services';

import { issueStartTimeTicksComparator } from '../functions';
import { IndicatorStatusHelper } from '../indicator-status/indicator-status-helper';
import { ModalDialogComponent, ModalAction } from '../modal-dialog/modal-dialog.component';

import { IssueDetailsDialogIssueVm, IssueDetailsDialogVm } from './issue-details-dialog-vm';
import { AppStore, FetchMatchingIssuesAction } from '@state';
import { Subject, takeUntil, tap } from 'rxjs';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { formatDurationFromSeconds } from '../format-duration';
import { formatIssueReason } from '../format-issue-reason';
import { SuppressionTabComponent } from './suppression-tab.component';
import { KeyValuePair } from '@/_models/key-value-pair';

const LOCAL_STORAGE_ITEM = 'rule-instance-issue-details-filter-my-rigs';

@Component({
    selector: 'app-rule-instance-issue-details',
    templateUrl: './rule-instance-issue-details.component.html',
    styleUrls: ['./rule-instance-issue-details.component.css']
})
export class RuleInstanceIssueDetailsComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('dialog') public dialog: ModalDialogComponent;
    @ViewChild('tabSet', { static: true }) tabSet: TabsetComponent;
    @ViewChild(SuppressionTabComponent) private suppressionTab: SuppressionTabComponent;

    @Input() public data: IssueDetailsDialogVm;

    @Output() public close = new EventEmitter(); // eslint-disable-line @angular-eslint/no-output-native

    private destroy$ = new Subject();

    public isAlertLinkShown: boolean;
    public dialogActions: ModalAction[] = [{ text: 'Close', cssClass: 'btn-secondary btn-close' }];

    /** Sorted issues shown on the UI */
    public issues: IssueDetailsDialogIssueVm[];

    public currentIssue: IssueDetailsDialogIssueVm;
    public alertQueryParams: { [k: string]: any; };
    public currentPage = 1;
    public activeTab = 0;

    public get isLastIssueOpen(): boolean {
        return this.issues && this.issues.length > 0 && this.issues[0].endTime == null;
    }
    public filterMyRigs = false;
    public matchingIssuesResult: MatchingIssuesResult[] = [];

    public ruleParamtersInfo: { 
        ruleSpecificInfos: KeyValuePair[],
        ruleParams: KeyValuePair[],
        conditions: KeyValuePair[],
        drillingConditions: KeyValuePair[]
    };

    constructor(private readonly currentUserService: CurrentUserService, private store: AppStore) { }

    ngOnInit(): void {
        const filterMyRigsValue = localStorage.getItem(LOCAL_STORAGE_ITEM);
        if (filterMyRigsValue?.toLowerCase() === 'true') { this.filterMyRigs = true; }

        this.store.select('monitorState', 'matchingIssuesResult').pipe(
            tap(x => this.matchingIssuesResult = x),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            if (this.suppressionTab) { this.suppressionTab.ruleInstanceIds = this.data?.ruleInstanceIds; }
            this.updateIssueData(this.data);
        }
    }

    public onPageChanged(event: PageChangedEvent) {
        this.currentIssue = this.issues[event.page - 1];
        this.updateAlertLinkParameters();
        this.parseRuleParamsInfo();
    }

    public anyIssueWithStaleData(): boolean {

        return this.issues?.some(x => x?.stateDetails != null);
    }

    public parseRuleParamsInfo() {
        const sanitize = (str) => {
            return str?.endsWith('; ') ? str.replace('; ', '') : str;
        }

        const parseKeyValuePairs = (str) => {
            const keyValue = sanitize(str)?.split(': ')

            return {
                key: keyValue[0],
                value: keyValue[1]
            } as KeyValuePair
        }
        
        const parseCondition = (str) => {
            const regex = /^(.+?)\s*\(([^)]+)\)$/; //Condition (state)
            const match = str.match(regex);

            return {
                key: match != null ? match[1] : '',
                value: match != null ? match[2] : ''
            } as KeyValuePair
        }

        const toList = (str, prop) => {
            return str
                .replace(`${prop}: `, '')
                .split('; ')
                .filter(x => !!x);
        }

        this.ruleParamtersInfo = {
            ruleSpecificInfos: [],
            ruleParams: [],
            conditions: [],
            drillingConditions: []
        };

        if (this.currentIssue?.ruleParametersInfo != null) {
            this.currentIssue.ruleParametersInfo
                .replace(/\r/g, '')
                .split('\n')
                .filter(x => !!x)
                .forEach((x) => {
                    if (x.startsWith('[')) {
                        this.ruleParamtersInfo.ruleParams.push(parseKeyValuePairs(x));
                    } else if (x.startsWith('Conditions: ')) {                       
                        toList(x, 'Conditions')
                            .forEach(c => this.ruleParamtersInfo.conditions.push(parseCondition(c)));
                    } else if (x.startsWith('DrillingConditions: ')) {
                        toList(x, 'DrillingConditions')
                            .forEach(c => this.ruleParamtersInfo.drillingConditions.push(parseKeyValuePairs(c)));
                    } else {
                        this.ruleParamtersInfo.ruleSpecificInfos.push(parseKeyValuePairs(x));
                    }
                });
        }
    }

    public onModelClose() {
        this.suppressionTab.onClose();
        this.close.emit();
        this.updateIssueData(null);
    }

    public show(selectTab?: string) {
        let tab: TabDirective;
        if (selectTab) {
            tab = this.tabSet.tabs.find(t => t.heading === selectTab);
        } else {
            tab = this.tabSet.tabs[0];
        }
        tab.active = true;
        this.dialog.show();
    }

    public getIndicatorStatusText(status: IndicatorStatus) {
        return status == null ? '' : IndicatorStatusHelper.getText(status);
    }

    public onFilterMyRigsChange() {
        this.filterMyRigs = !this.filterMyRigs;
        localStorage.setItem(LOCAL_STORAGE_ITEM, this.filterMyRigs.toString());
    }

    public onMatchingIssuesClick() {
        const filter = {
            issueId: this.issues[0].id,
            sectionId: this.data.sectionId,
            myRigs: this.filterMyRigs,
        } as MatchingIssuesFilter;
        this.store.dispatch(new FetchMatchingIssuesAction(filter));
    }

    public getIconColor(matchingIssuesResult: MatchingIssuesResult) {
        if (!matchingIssuesResult.hasMatchingRuleInstance) { return 'grey'; }
        else if (matchingIssuesResult.issueReasonCode == null) { return 'green'; }
        else if (matchingIssuesResult.issueReasonCode !== this.issues[0].reasonCode) { return 'orange'; }
        else { return '#ffc7ce'; }
    }

    public getIssueDuration(issueTimeProblemDuration: number): string {
        return formatDurationFromSeconds(issueTimeProblemDuration);
    }

    public getIssueReason(issueReasonCode: number, issueReasonCodeDescription: string): string {
        return issueReasonCode == null || issueReasonCodeDescription == null
            ? ''
            : ' : ' + formatIssueReason(issueReasonCode, issueReasonCodeDescription);
    }

    public getMonitorQueryParams(categoryService: string) {
        if (categoryService) {
            return {
                ['service']: categoryService
            };
        } else {
            return {};
        }
    }

    private getAlertQueryParams() {
        return this.currentIssue && {
            [QUERY_PARAM_TARGET]: this.data.targetId,
            [QUERY_PARAM_SECTION]: this.data.sectionId,
            [QUERY_PARAM_ALERT]: this.currentIssue.alertId
        };
    }

    private checkIsAlertNavigationPossible() {
        return this.currentIssue != null && this.currentIssue.alertId != null
            && this.data != null && this.data.targetId != null && this.data.sectionId != null;
    }

    private updateIssueData(input: IssueDetailsDialogVm) {
        if (input?.rigTimeZone) { this.suppressionTab.applyTimeZone(input.rigTimeZone); }
        if (input && Array.isArray(input.issues) && input.issues.length) {
            if (input.issues.length) {
                const issuesCopy = [...input.issues];
                issuesCopy.sort((a, b) => issueStartTimeTicksComparator(a.startTimeTicks, b.startTimeTicks));
                this.issues = issuesCopy;

                let selectFirst = true;
                if (this.currentIssue) { // if we have selection just update page and object
                    const newIndex = this.issues.findIndex(x => x.id === this.currentIssue.id);
                    if (newIndex > -1) {
                        this.currentIssue = this.issues[newIndex];
                        this.currentPage = newIndex + 1;
                        selectFirst = false;
                    }
                }

                if (selectFirst) {
                    this.currentIssue = this.issues[0];
                    this.currentPage = 1;
                    this.parseRuleParamsInfo();
                }
            }
        } else {
            this.issues = [];
            this.currentIssue = undefined;
            this.currentPage = 1;
        }

        this.updateAlertLinkParameters();
    }

    private updateAlertLinkParameters() {
        this.alertQueryParams = this.getAlertQueryParams();
        this.isAlertLinkShown = this.currentUserService.isAlertsRouteAllowed && this.checkIsAlertNavigationPossible();
    }
}
