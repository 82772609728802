export class Role {
    public id: number;
    public roleName: string;
    public hasServiceProvider?: boolean;
}

export enum Roles {
    SystemAdmin = 1,
    OrderManager = 2,
    OperatorUser = 3,
    ServiceCoAdmin = 4,
    ServiceCoUser = 5,
    SubscriberUser = 6,
    ServiceCoOrderManager = 7,
    ChangeManager = 8,
    // DapAdmin = 9,
    DapManager = 10,
    DapAuthor = 11,
    DapScorer = 12,
    DapReader = 13,
    SurveillanceSupportEngineer = 14,
    //SurveillanceEngineer = 15,
    DapProvider = 16,
    SuperUser = 17,
}
