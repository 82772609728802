import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface IHasUnsavedChangesGuard {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({ providedIn: 'root' })
export class HasUnsavedChangesGuard implements CanDeactivate<IHasUnsavedChangesGuard> {
    canDeactivate(component: IHasUnsavedChangesGuard): boolean {
        if (!component.canDeactivate()) {
            return confirm('You have unsaved changes! If you leave, your changes will be lost.');
        }

        return true;
    }
}
