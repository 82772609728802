import { Measure } from '../measure';

export class DapCurve {
    public id: number;
    public serviceId: number;
    public serviceProviderId: number;
    public tool: string;
    public curveIndexTypeId: number;
    public mnemonic: string;
    public logName: string;
    public comments: string;
    public globalMnemonic: string;
    public globalLogName: string;
    public isCommon: boolean;
    public required: boolean;
    public dataPointFrequency: Measure;
    public receiveFrequency: Measure;
    public toolUpdatePeriod: Measure;
    public label: string;
    public indexType: string;
    public lagType: string;
    public uom: string;
    public memoryMnemonic: string;
    public memoryLogName: string;
    public tags: string;
}

export enum DapCurveProps {
    CatalogId = 'catalog-id',
    ServiceId = 'service-id',
    ServiceProviderId = 'service-provider-id',
    Tool = 'tool',
    CurveIndexTypeId = 'curve-index-type-id',
    Mnemonic = 'mnemonic',
    LogName = 'log-name',
    Comments = 'comments',
    GlobalMnemonic = 'global-mnemonic',
    GlobalLogName = 'global-log-name',
    IsCommon = 'is-common',
    Required = 'required',
    DataPointFrequency = 'density',
    ReceiveFrequency = 'receive-frequency',
    ToolUpdatePeriod = 'tool-update-period',
    Label = 'label',
    IndexType = 'index-type',
    LagType = 'lag-type',
    Uom = 'uom',
    MemoryMnemonic = 'memory-mnemonic',
    MemoryLogName = 'memory-log-name',
    Tag = 'tag',
    CurveGroups = 'curve-groups'
}

// Used for sending post requests to an endpoint with the body of the request containing just a string value
export class StringWrapper {
    public stringValue: string;
}
