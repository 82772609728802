import { ValidationMessage } from '../validation-result';

export enum OrderValidationStatus {
    success = 'success',
    warning = 'warning',
    error = 'error'
}

export class OrderValidation {
    validationMessages: ValidationMessage[];
    validationStatus: OrderValidationStatus;
}
