import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { of } from 'rxjs';
import { DapDocumentManagementService } from '@/_services/dap-document-management.service';
import {
    CopyDocumentAction, CopyDocumentFailedAction, CopyDocumentOkAction,
    CreateDocumentAction, CreateDocumentFailedAction, CreateDocumentOkAction,
    CreateNewRevisionAction, CreateNewRevisionFailedAction, CreateNewRevisionOkAction,
    CreateNewVersionAction, CreateNewVersionFailedAction, CreateNewVersionOkAction,
    DeleteDocumentAction, DeleteDocumentFailedAction, DeleteDocumentOkAction, FetchDapDocumentFilterDataAction,
    FetchDapDocumentFilterDataFailedAction, FetchDapDocumentFilterDataOkAction,
    FetchDapDocumentsAction, FetchDapDocumentScoreDiagramsAction, FetchDapDocumentScoreDiagramsFailedAction,
    FetchDapDocumentScoreDiagramsOkAction, FetchDapDocumentsFailedAction, FetchDapDocumentsOkAction,
    FetchDapTemplatesAction, FetchDapTemplatesFailedAction, FetchDapTemplatesOkAction,
    FetchNextRevisionVersionsAction, FetchNextRevisionVersionsFailedAction, FetchNextRevisionVersionsOkAction,
    LoadDapDocumentFilterDataAction, LoadDapDocumentsAction, LoadDapTemplatesAction,
    LoadNextRevisionVersionsAction,
    RouteToDetailsAction,
    ToggleDapDocumentFavouriteAction, ToggleDapDocumentFavouriteFailedAction,
    ToggleDapDocumentFavouriteInStoreAction, ToggleDapDocumentFavouriteOkAction,
    UpdateDeletedDocumentInStoreAction
} from './dap-document-management.actions';
import { Router } from '@angular/router';
import { Routes } from '@/configuration';

@Injectable()
export class DapDocumentManagementEffects {

    
    public onFetchDapDocumentFilterData$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapDocumentFilterDataAction),
        switchMap(() => this.dapDocumentManagementService.getDocumentFilterData().pipe(
            switchMap(x => [new LoadDapDocumentFilterDataAction(x), new FetchDapDocumentFilterDataOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchDapDocumentFilterData ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapDocumentFilterDataFailedAction(error));
            })
        ))
    ));

    
    public onFetchDapDocuments$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapDocumentsAction),
        switchMap((a) => this.dapDocumentManagementService.getDocuments(a.filter).pipe(
            switchMap(x => [new LoadDapDocumentsAction(x), new FetchDapDocumentsOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchDapDocuments ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapDocumentsFailedAction(error));
            })
        ))
    ));

    
    public onFetchDapDocumentScoreDiagrams$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapDocumentScoreDiagramsAction),
        switchMap((a) => this.dapDocumentManagementService.getScoreDiagrams(a.documents).pipe(
            switchMap(x => [new LoadDapDocumentsAction(x), new FetchDapDocumentScoreDiagramsOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchDapDocumentScoreDiagrams ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapDocumentScoreDiagramsFailedAction(error));
            })
        ))
    ));

    
    public onToggleDapDocumentFavourite$ = createEffect(() => this.actions$.pipe(
        ofAction(ToggleDapDocumentFavouriteAction),
        switchMap((a) => this.dapDocumentManagementService.toggleFavouriteStatus(a.dapHeaderId).pipe(
            switchMap(x => [new ToggleDapDocumentFavouriteInStoreAction(a.dapHeaderId, x), new ToggleDapDocumentFavouriteOkAction()]),
            catchError(error => {
                this.logger.error('Error in ToggleDapDocumentFavourite ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new ToggleDapDocumentFavouriteFailedAction(error));
            })
        ))
    ));

    
    public onCreateDocument$ = createEffect(() => this.actions$.pipe(
        ofAction(CreateDocumentAction),
        switchMap((a) => this.dapDocumentManagementService.createDocument(a.templateId, a.subject).pipe(
            switchMap(x => [
                new CreateDocumentOkAction(),
                new RouteToDetailsAction(x)
            ]),
            catchError(error => {
                this.logger.error('Error in CreateDocument ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CreateDocumentFailedAction(error));
            })
        ))
    ));

    
    public onCopyDocument$ = createEffect(() => this.actions$.pipe(
        ofAction(CopyDocumentAction),
        switchMap((a) => this.dapDocumentManagementService.copyDocument(a.documentId, a.subject).pipe(
            switchMap(x => [
                new CopyDocumentOkAction(),
                new RouteToDetailsAction(x)
            ]),
            catchError(error => {
                this.logger.error('Error in CopyDocument ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CopyDocumentFailedAction(error));
            })
        ))
    ));

    
    public onNewRevision$ = createEffect(() => this.actions$.pipe(
        ofAction(CreateNewRevisionAction),
        switchMap((a) => this.dapDocumentManagementService.createNewRevision(a.documentId).pipe(
            switchMap(x => [
                new CreateNewRevisionOkAction(),
                new RouteToDetailsAction(x)
            ]),
            catchError(error => {
                this.logger.error('Error in CreateNewRevision ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CreateNewRevisionFailedAction(error));
            })
        ))
    ));

    
    public onNewVersion$ = createEffect(() => this.actions$.pipe(
        ofAction(CreateNewVersionAction),
        switchMap((a) => this.dapDocumentManagementService.createNewVersion(a.documentId).pipe(
            switchMap(x => [
                new CreateNewVersionOkAction(),
                new RouteToDetailsAction(x)
            ]),
            catchError(error => {
                this.logger.error('Error in CreateNewVersion ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CreateNewVersionFailedAction(error));
            })
        ))
    ));

    
    public onDeleteDocument$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteDocumentAction),
        switchMap((a) => this.dapDocumentManagementService.deleteDocument(a.documentId).pipe(
            switchMap(x => [
                new DeleteDocumentOkAction(),
                new UpdateDeletedDocumentInStoreAction(x, a.updateInStore)
            ]),
            catchError(error => {
                this.logger.error('Error in DeleteDocument ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new DeleteDocumentFailedAction(error));
            })
        ))
    ));

    
    public onRouteToDetailsAction$ = createEffect(() => this.actions$.pipe(
        ofAction(RouteToDetailsAction),
        tap((x) => this.router.navigate([Routes.dapDocumentDetails], { queryParams: { id: x.documentId } }))
    ), { dispatch: false });

    
    public onFetchDapTemplates$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapTemplatesAction),
        switchMap(() => this.dapDocumentManagementService.getTemplatesWithScope().pipe(
            switchMap(x => [
                new FetchDapTemplatesOkAction(),
                new LoadDapTemplatesAction(x)
            ]),
            catchError(error => {
                this.logger.error('Error in FetchDapTemplates ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapTemplatesFailedAction(error));
            })
        ))
    ));

    
    public onFetchNextRevisionVersions$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchNextRevisionVersionsAction),
        switchMap((a) => this.dapDocumentManagementService.getNextRevisionVersion(a.documentId).pipe(
            switchMap(x => [
                new FetchNextRevisionVersionsOkAction(),
                new LoadNextRevisionVersionsAction(x)
            ]),
            catchError(error => {
                this.logger.error('Error in FetchNextRevisionVersions ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchNextRevisionVersionsFailedAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly router: Router,
        private readonly dapDocumentManagementService: DapDocumentManagementService
    ) { }
}
