import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { tap, takeUntil, map } from 'rxjs/operators';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { PaginationChangedEvent } from 'ag-grid-community';

import { GridSortModel, FilterModel } from '../ag-grid.component';
import { PaginationType, PaginationGridParams, ServerPaginationParams } from './params';
import { IPaginationService } from './pagination-service.interface';
import { ODataExpressionHelper } from './odata-expression-helper';

@Injectable()
export class PaginationService implements OnDestroy, IPaginationService {
    public readonly smallItemCountPerPage = 15;
    public readonly mediumItemCountPerPage = 50;
    public readonly largeItemCountPerPage = 100;

    public itemsPerPage = this.smallItemCountPerPage;
    public totalPages: number;

    public pageSizeChanged = new Subject<number>();

    private serverParams: ServerPaginationParams;
    // Note: set the default paginationType to Client so that the initial pagination can be calculated correctly.
    // This is needed because ag-grid.gridReady is may be called after ag-grid.paginationChanged
    // which will result in keeping the default totalPages value
    public gridParams: PaginationGridParams = { paginationType: PaginationType.Client };
    /** if the service should react to events */
    private isServerSideActive = true;

    private currentPage = 1;

    private destroy$ = new Subject();

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public init(params: PaginationGridParams) {
        if (params && params.paginationType === PaginationType.Server) {
            params.gridApi.setRowData([]);
        }

        this.gridParams = params;
    }

    public activate(params: ServerPaginationParams): void {
        if (this.gridParams.paginationType === PaginationType.Client) {
            return console.warn(`Can't activate server side pagination with pagination type Client`);
        }

        if (!params) {
            return console.warn('params are not defined');
        }

        this.serverParams = params;
        this.isServerSideActive = true;

        params.data$.pipe(
            map(x => x ? x : { '@odata.count': 0, value: [] }),
            map(x => ({ ...x, value: x.value.map(y => ({...y})) })),
            tap(x => {
                this.gridParams.gridApi.setRowData(x.value);
                // ceil because we are letting the remainder overflow on another page
                this.totalPages = Math.ceil(x['@odata.count'] / this.itemsPerPage);
                if (x['@odata.count'] > 0) {
                    this.gridParams.gridApi.hideOverlay();
                } else {
                    this.gridParams.gridApi.showNoRowsOverlay();
                }
            }),
            takeUntil(this.destroy$)
        ).subscribe();

        this.fetchData(this.itemsPerPage, 0);
    }

    public deactivate() {
        this.isServerSideActive = false;
        this.serverParams = null;
        this.destroy$.next(true); // unsubscribe from the store
    }

    public onPageChanged(event: PageChangedEvent) {
        this.currentPage = event.page;

        if (this.gridParams.paginationType === PaginationType.Client && this.gridParams.gridApi) {
            this.gridParams.gridApi.paginationGoToPage(event.page - 1);
        } else if (this.gridParams.paginationType === PaginationType.Server && this.isServerSideActive) {
            this.fetchData();
        }
    }

    public onPageSizeChanged(newItemsPerPage: number) {
        this.itemsPerPage = newItemsPerPage;
        this.gridParams.gridApi.paginationSetPageSize(newItemsPerPage);

        if (this.gridParams.paginationType === PaginationType.Server && this.isServerSideActive) {
            this.fetchData();
        }

        // TODO trigger resize ONLY when the user did not use manual resize
        if (this.gridParams.autoSize) {
            this.gridParams.columnResize$.next(true);
        }

        this.pageSizeChanged.next(this.itemsPerPage);
    }

    /**
     * The displayed page for pagination has changed.
     * For example the data was filtered or sorted, or the user has moved to a different page.
     */
    public onPaginationChanged(event: PaginationChangedEvent) {
        if (!this.gridParams || !this.isServerSideActive) {
            return;
        }

        if (this.gridParams.paginationType === PaginationType.Client
            && (this.totalPages !== event.api.paginationGetTotalPages() || event.newPage)) {
            this.totalPages = event.api.paginationGetTotalPages();
        }
        if (this.gridParams.columnResize$ && this.gridParams.autoSize) {
            this.gridParams.columnResize$.next(false);
        }
    }

    public onFilterChanged() {
        if (this.gridParams.paginationType === PaginationType.Server && this.isServerSideActive) {
            this.fetchData();
        }
    }

    public onSortChanged() {
        if (this.gridParams.paginationType === PaginationType.Server && this.isServerSideActive) {
            this.fetchData();
        }
    }

    private fetchData(top = this.itemsPerPage, skip = (this.currentPage - 1) * this.itemsPerPage) {
        if (!this.gridParams.gridApi || !this.isServerSideActive) {
            return;
        }

        const sortModel = this.gridParams.gridApi.getSortModel() as GridSortModel;
        const filterModel = this.gridParams.gridApi.getFilterModel() as FilterModel;

        this.gridParams.gridApi.showLoadingOverlay();

        this.serverParams.getRows({
            skip: skip,
            top: top,
            orderBy: ODataExpressionHelper.extractSortQuery(sortModel),
            filter: ODataExpressionHelper.extractFilterQuery(filterModel)
        });
    }
}

export class FakePaginationService {
    public itemsPerPage: number;
    public largeItemCountPerPage: number;
    public mediumItemCountPerPage: number;
    public smallItemCountPerPage: number;
    public pageSizeChanged = new Subject<number>();

    public onFilterChanged: () => void;
    public onPageSizeChanged: (newItemsPerPage: number) => void;
    public onPaginationChanged: (event: any) => void;
    public onSortChanged: () => void;
    public totalPages: number;
}
export const fakeAPaginationServiceProvider = { provide: PaginationService, useClass: FakePaginationService };
