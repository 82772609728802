import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { PublicPart } from '@/_shared';
import { DapDocumentStatusData } from '@/_models/dap-document-status/dap-document-status-data';
import { DapScoreChanges } from '@/_models/dap-document-status/dap-score-change';
import { DapDapHistoricalAverage, DapHistoryScoreFilterData } from '@/_models/dap-document-status';
import { formatJustDateInUtc } from '@/_helpers/utc-date-formatter';

@Injectable({
    providedIn: 'root'
})
export class DapDocumentStatusService {

    constructor(private http: HttpClient) { }

    getDocumentStatusData(id: number, linkTemplate: string): Observable<DapDocumentStatusData> {
        const query = [];
        if (linkTemplate) { query.push(`linkTemplate=${encodeURIComponent(linkTemplate)}`); }
        if (query.length === 0) {
            return this.http.get<DapDocumentStatusData>(`${environment.dapApi}/DapDocumentStatus/documentContent/${id}`);
        }
        return this.http.get<DapDocumentStatusData>(`${environment.dapApi}/DapDocumentStatus/documentContent/${id}?${query.join('&')}`);
    }

    reactivateDocument(id: number): Observable<DapDocumentStatusData> {
        return this.http.post<DapDocumentStatusData>(`${environment.dapApi}/DapDocumentStatus/document/${id}/reactivate`, null);
    }

    completeDocument(id: number): Observable<DapDocumentStatusData> {
        return this.http.post<DapDocumentStatusData>(`${environment.dapApi}/DapDocumentStatus/document/${id}/complete`, null);
    }

    abandonDocument(id: number): Observable<DapDocumentStatusData> {
        return this.http.post<DapDocumentStatusData>(`${environment.dapApi}/DapDocumentStatus/document/${id}/abandon`, null);
    }

    reactivateSection(id: number): Observable<DapDocumentStatusData> {
        return this.http.post<DapDocumentStatusData>(`${environment.dapApi}/DapDocumentStatus/section/${id}/reactivate`, null);
    }

    completeSection(id: number): Observable<DapDocumentStatusData> {
        return this.http.post<DapDocumentStatusData>(`${environment.dapApi}/DapDocumentStatus/section/${id}/complete`, null);
    }

    abandonSection(id: number): Observable<DapDocumentStatusData> {
        return this.http.post<DapDocumentStatusData>(`${environment.dapApi}/DapDocumentStatus/section/${id}/abandon`, null);
    }

    reactivateDap(id: number): Observable<DapDocumentStatusData> {
        return this.http.post<DapDocumentStatusData>(`${environment.dapApi}/DapDocumentStatus/dap/${id}/reactivate`, null);
    }

    completeDap(id: number): Observable<DapDocumentStatusData> {
        return this.http.post<DapDocumentStatusData>(`${environment.dapApi}/DapDocumentStatus/dap/${id}/complete`, null);
    }

    abandonDap(id: number): Observable<DapDocumentStatusData> {
        return this.http.post<DapDocumentStatusData>(`${environment.dapApi}/DapDocumentStatus/dap/${id}/abandon`, null);
    }

    saveScores(scores: DapScoreChanges, linkTemplate: string): Observable<DapDocumentStatusData> {
        const query = [];
        if (linkTemplate) { query.push(`linkTemplate=${encodeURIComponent(linkTemplate)}`); }
        if (query.length === 0) {
            return this.http.post<DapDocumentStatusData>(`${environment.dapApi}/DapDocumentStatus/document/score`, scores);
        }
        return this.http.post<DapDocumentStatusData>(
            `${environment.dapApi}/DapDocumentStatus/document/score?${query.join('&')}`, scores);
    }

    getFilterFields(documentId: number, from: Date, to: Date): Observable<DapHistoryScoreFilterData> {
        const query = [];
        if (from) { query.push(`from=${formatJustDateInUtc(from)}`); }
        if (to) { query.push(`from=${formatJustDateInUtc(to)}`); }
        let link = `${environment.dapApi}/DapDocumentStatus/document/${documentId}/history/fields`;
        if (query.length > 0) {
            link += `?${query.join('&')}`;
        }
        return this.http.get<DapHistoryScoreFilterData>(link);
    }

    getHistoricalAverage(documentId: number, filter: DapHistoryScoreFilterData): Observable<DapDapHistoricalAverage[]> {
        const copy = {
            ...filter,
            from: formatJustDateInUtc(filter.from),
            to: formatJustDateInUtc(filter.to),
        };
        return this.http.post<DapDapHistoricalAverage[]>(
            `${environment.dapApi}/DapDocumentStatus/document/${documentId}/history/averages`, copy);
    }
}

/* eslint-disable */
export class FakeDapDocumentStatusService implements PublicPart<DapDocumentStatusService> {
    getDocumentStatusData(id: number, linkTemplate: string): Observable<DapDocumentStatusData> {
        throw new Error('Method not implemented.');
    }
    reactivateDocument(id: number): Observable<DapDocumentStatusData> {
        throw new Error('Method not implemented.');
    }
    completeDocument(id: number): Observable<DapDocumentStatusData> {
        throw new Error('Method not implemented.');
    }
    abandonDocument(id: number): Observable<DapDocumentStatusData> {
        throw new Error('Method not implemented.');
    }
    reactivateSection(id: number): Observable<DapDocumentStatusData> {
        throw new Error('Method not implemented.');
    }
    completeSection(id: number): Observable<DapDocumentStatusData> {
        throw new Error('Method not implemented.');
    }
    abandonSection(id: number): Observable<DapDocumentStatusData> {
        throw new Error('Method not implemented.');
    }
    reactivateDap(id: number): Observable<DapDocumentStatusData> {
        throw new Error('Method not implemented.');
    }
    completeDap(id: number): Observable<DapDocumentStatusData> {
        throw new Error('Method not implemented.');
    }
    abandonDap(id: number): Observable<DapDocumentStatusData> {
        throw new Error('Method not implemented.');
    }
    saveScores(scores: DapScoreChanges, linkTemplate: string): Observable<DapDocumentStatusData> {
        throw new Error('Method not implemented.');
    }
    getFilterFields(documentId: number, from: Date, to: Date): Observable<DapHistoryScoreFilterData> {
        throw new Error('Method not implemented.');
    }
    getHistoricalAverage(documentId: number, filter: DapHistoryScoreFilterData): Observable<DapDapHistoricalAverage[]> {
        throw new Error('Method not implemented.');
    }
}
/* eslint-enable */
