import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { PublicPart } from 'ngx-shared';

import { Tooltip } from '@/_models';

@Injectable({ providedIn: 'root' })
export class TooltipService {
    constructor(private http: HttpClient) { }

    get(key: string): Observable<Tooltip[]> {
        return this.http.get<Tooltip[]>(`${environment.portalWebApi}/Tooltips/${key}`);
    }
}

/* eslint-disable */
export class FakeTooltipService implements PublicPart<TooltipService> {

    get(key: string): Observable<Tooltip[]> {
        throw new Error('Method not implemented.');
    }
}

export const fakeTooltipServiceProvider = { provide: TooltipService, useClass: FakeTooltipService };
/* eslint-enable */
