import { DapDapStatus } from '@/_models/dap-common';
import { DapDapStatusData } from '@/_models/dap-document-status/dap-document-status-data';

export function isDapDapScorable(dap: DapDapStatusData): boolean {
    if (dap.status === DapDapStatus.Design ||
        dap.status === DapDapStatus.Abandoned ||
        dap.status === DapDapStatus.Completed) {
            return false;
    }
    return dap.includeInScoring;
}

export function isDapDapScorableOrCompleted(dap: DapDapStatusData): boolean {
    if (dap.status === DapDapStatus.Design ||
        dap.status === DapDapStatus.Abandoned) {
            return false;
    }
    return dap.includeInScoring;
}
