import { DapSectionStatus } from '@/_models/dap-common';
import { DapSectionStatusData } from '@/_models/dap-document-status/dap-document-status-data';

export function isDapSectionScorableOrCompleted(section: DapSectionStatusData): boolean {
    if (section.status === DapSectionStatus.Design ||
        section.status === DapSectionStatus.Abandoned) {
            return false;
    }
    return section.includeInScoring;
}
