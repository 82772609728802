import { StateDetails, StateGroup, StateTableVisualisationType } from "@/_models";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: 'app-state-details',
    templateUrl: './state-details.component.html',
    styleUrls: ['./state-details.component.css']
})
export class StateDetailsComponent implements OnChanges {
    @Input() public stateDetails: StateDetails;
    @Input() public isOnScreen: boolean;

    public stateGroups: StateGroup[] = [];
    public enums = {
        tableType: StateTableVisualisationType,
    };
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.stateDetails) {
            this.setGroups();
        }
    }

    private setGroups() {
        if (!this.stateDetails?.values) {
            this.stateGroups = [];
            return;
        }

        const grouped = this.stateDetails.values.reduce((prev: StateGroup[], curr) => {
            const match = prev.find(x => x.name === curr.group);
            if (match) {
                match.stateValues.push(curr);
            } else {
                prev.push({ name: curr.group, stateValues: [curr] } as StateGroup);
            }
            return prev;
        }, []);
        this.stateGroups = grouped;
    }
}
