import { AbstractControl } from '@angular/forms';

export function enableControl(control: AbstractControl): void;
export function enableControl(controls: { [key: string]: AbstractControl; }, field: string): void;
export function enableControl(controlOrListOfControls: AbstractControl | { [key: string]: AbstractControl; }, fieldName?: string): void {
    if (controlOrListOfControls instanceof AbstractControl) {
        if (controlOrListOfControls.disabled) {
            controlOrListOfControls.enable({ emitEvent: false });
        }
    } else if (fieldName != null && controlOrListOfControls && controlOrListOfControls[fieldName]) {
        if (controlOrListOfControls[fieldName].disabled) {
            controlOrListOfControls[fieldName].enable({ emitEvent: false });
        }
    }
}
export function disableControl(control: AbstractControl): void;
export function disableControl(controls: { [key: string]: AbstractControl; }, field: string): void;
export function disableControl(controlOrListOfControls: AbstractControl | { [key: string]: AbstractControl; }, fieldName?: string): void {
    if (controlOrListOfControls instanceof AbstractControl) {
        if (controlOrListOfControls.enabled) {
            controlOrListOfControls.disable({ emitEvent: false });
        }
    } else if (fieldName != null && controlOrListOfControls && controlOrListOfControls[fieldName]) {
        if (controlOrListOfControls[fieldName].enabled) {
            controlOrListOfControls[fieldName].disable({ emitEvent: false });
        }
    }
}

export function trimFormControlInput(control: AbstractControl) {
    if (control?.getRawValue()){
        control.setValue(control.getRawValue().trim());
    }
}
