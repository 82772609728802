export function readFile(file: File, timeoutInMs = 20000): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e: ProgressEvent) {
            const resultFileLink = (e.target as FileReader).result as string;
            resolve(resultFileLink);
        };
        reader.readAsDataURL(file);

        setTimeout(() => {
            reject('It took too long to read file');
        }, timeoutInMs);
    });
}

export function getFileExtension(filename: string): string {
    return filename.split('.').pop();
}
