import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PickListItem, PickListType } from '@/_models';

export function getPicklistItemMap(items: PickListItem[]) {
    const result = new Map<PickListType, PickListItem[]>();

    if (Array.isArray(items)) {
        items.forEach(item => {
            if (result.has(item.picklistId)) {
                result.get(item.picklistId).push(item);
            } else {
                result.set(item.picklistId, [item]);
            }
        });
    }

    result.forEach(listItems => {
        listItems.sort((a, b) => a.ordinal - b.ordinal);
    });

    return result;
}

export function getPickListItemMapPerType(items$: Observable<PickListItem[]>): Observable<Map<PickListType, PickListItem[]>> {
    return items$.pipe(
        map(getPicklistItemMap),
        shareReplay(1)
    );
}

export function getPicklistType(map$: Observable<Map<PickListType, PickListItem[]>>, type: PickListType): Observable<PickListItem[]> {
    return map$.pipe(
        map(x => x.get(type)),
        shareReplay(1)
    );
}

export function getPicklistItemsByType(items$: Observable<PickListItem[]>, type: PickListType): Observable<PickListItem[]> {
    const map$ = getPickListItemMapPerType(items$);
    return getPicklistType(map$, type);
}

export function getPickListItem(picklistMap: Map<PickListType, PickListItem[]>, type: PickListType, id: number) {
    const plList = picklistMap && picklistMap.get(type);
    const plItem = plList && plList.find(x => x.id === id);
    return plItem && plItem.name;
}

export function getPickListItems(picklistMap: Map<PickListType, PickListItem[]>, type: PickListType) {
    return picklistMap && picklistMap.get(type);
}

export function getPickListItemIdByName(picklistMap: Map<PickListType, PickListItem[]>, type: PickListType, name: string) {
    const plList = picklistMap && picklistMap.get(type);
    const plItem = plList && plList.find(x => x.name === name);
    return plItem && plItem.id;
}
