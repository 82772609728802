export class OrderTargets {
    public upstreamTargets: OrderTarget[];
    public mainTarget: OrderTarget;
    public downstreamTargets: OrderTarget[];
}

export class OrderTarget {
    public id: number;
    public latency: number;
    public dataResponsibility: boolean;
    public useGlobalMnemonics: boolean;
    public wellUid: string;
    public wellboreUid: string;
    public trajectoryName: string;
    public curveGroups: number[];
}
