import { DapDocumentContentFieldValue } from '@/_models/dap-document-details';
import { DapDocumentContextKeys, DapDocumentVM, DapTableRowValue } from '../vm';
import { PickListItem, PickListType } from '@/_models';
import { DapDapHelper } from '../helpers';

const RefScalarPattern = '\
^((?<frLevel>doc|dap|sec):(?<frFieldKey>([a-z][a-z0-9]*)(-[a-z0-9]*)*)(\\#(?<frProp>([a-z][a-z0-9]*)(-[a-z0-9]*)*))?)$|\
^(\
(?<crLevel>doc|dap|sec):(?<crTableKey>([a-z][a-z0-9]*)(-[a-z0-9]*)*):(?<crColKey>([a-z][a-z0-9]*)(-[a-z0-9]*)*)\
((\\((?<crRownum>\\d+)\\))|\
(:(?<crIndexKey>([a-z][a-z0-9]*)(-[a-z0-9]*)*)\\((?<crIndexVal>[^\\)]*)\\))|\
(:(?<crFunc>first|last|all))\
)(\\#(?<crProp>([a-z][a-z0-9]*)(-[a-z0-9]*)*))?\
)$|\
^(col:(?<colKey>([a-z][a-z0-9]*)(-[a-z0-9]*)*)(\\#(?<colProp>([a-z][a-z0-9]*)(-[a-z0-9]*)*))?)$|\
^(val:\\((?<literal>[^\\)]*)\\))$';

export class RefScalar {
    public static getValue(reference: string, docVM: DapDocumentVM, sectionIndex: number, dapIndex: number, rowValue: DapTableRowValue,
        picklistMap: Map<PickListType, PickListItem[]>) {
        if (!reference) { return null; }

        const refScalarRegex = new RegExp(RefScalarPattern);
        const match = refScalarRegex.exec(reference);
        if (!match || !match['groups']) { return null; }

        const groups = match['groups'];
        if (groups.frLevel && groups.frFieldKey) {
            if (groups.frFieldKey === 'meta') {
                return this.getMetaValue(docVM, sectionIndex, dapIndex, groups.frLevel, groups.frProp, picklistMap);
            }

            const fieldVM = docVM.getFieldVM(groups.frLevel, groups.frFieldKey, sectionIndex, dapIndex);
            if (!fieldVM) { return null; }

            const fieldValue = fieldVM.form.getRawValue() as DapDocumentContentFieldValue;
            return this.getPropertyOrValueFromField(fieldValue, groups.frProp);
        } else if (groups.crLevel && groups.crTableKey && groups.crColKey) {
            const tableVM = docVM.getTableVM(groups.crLevel, groups.crTableKey, sectionIndex, dapIndex);
            if (!tableVM) { return null; }

            const rowValues = tableVM.rowData;
            if (rowValues.length <= 0) { return null; }

            if (groups.crRownum) {
                const cellValue = rowValues[+groups.crRownum][groups.crColKey];
                return this.getPropertyOrValueFromField(cellValue, groups.crProp);
            } else if (groups.crFunc) {
                if (groups.crFunc === 'first') {
                    const cellValue = rowValues[0][groups.crColKey];
                    return this.getPropertyOrValueFromField(cellValue, groups.crProp);
                }
                if (groups.crFunc === 'last') {
                    const cellValue = rowValues[rowValues.length - 1][groups.crColKey];
                    return this.getPropertyOrValueFromField(cellValue, groups.crProp);
                }
                if (groups.crFunc === 'all') {
                    return rowValues.map(x => this.getPropertyOrValueFromField(x[groups.crColKey], groups.crProp));
                }
            } // else if... wth is crIndexKey and crIndexVal???
        } else if (groups.colKey) {
            return this.getPropertyOrValueFromField(rowValue[groups.colKey], groups.colProp);
        } else if (groups.literal) {
            return groups.literal;
        }

        return null;
    }

    public static getPropertyOrValueFromField(fieldValue: DapDocumentContentFieldValue, propKey: string) {
        if (propKey) {
            if (fieldValue.measure != null && (propKey === 'value' || propKey === 'uom')) {
                return fieldValue.measure[propKey];
            }
            const prop = fieldValue.props?.find(x => x.key === propKey);
            return this.getFieldValue(prop);
        } else {
            return this.getFieldValue(fieldValue);
        }
    }

    public static getFieldValue(field: DapDocumentContentFieldValue) {
        if (field == null) { return null; }
        if (field.str != null) { return field.str; }
        if (field.text != null) { return field.text; }
        if (field.float != null) { return field.float; }
        if (field.int != null) { return field.int; }
        if (field.bool != null) { return field.bool; }
        if (field.datetime != null) { return field.datetime; }
        if (field.measure != null) { return field.measure; }

        return null;
    }

    public static isFieldEmpty(field: DapDocumentContentFieldValue): boolean {
        const value = RefScalar.getFieldValue(field);
        if (value == null) { return true; }
        if (typeof value === 'string' && value === '') { return true; }
        return false;
    }

    public static isFieldEmptyOrWhitespace(field: DapDocumentContentFieldValue): boolean {
        const value = RefScalar.getFieldValue(field);
        if (value == null) { return true; }
        if (typeof value === 'string' && !/\S/.test(value)) { return true; }
        return false;
    }

    private static getMetaValue(docVM: DapDocumentVM, sectionIndex: number, dapIndex: number, level: string, propName: string,
        picklistMap: Map<PickListType, PickListItem[]>) {

        switch (level) {
            case DapDocumentContextKeys.document:
                break;
            case DapDocumentContextKeys.section:
                if (propName === 'service-providers') {
                    return docVM.sectionVMs[sectionIndex]?.dapVMs.map(dapVm => DapDapHelper.getDapServiceProvider(dapVm, picklistMap));
                }
                break;
            case DapDocumentContextKeys.dap:
                if (propName === 'service-provider') {
                    return DapDapHelper.getDapServiceProvider(docVM.sectionVMs[sectionIndex]?.dapVMs[dapIndex], picklistMap);
                }
                break;
            default:
                break;
        }

        return null;
    }

    public static isParamRefScalar(param: any): boolean {
        return typeof param === 'string'
            && (param.startsWith('doc:')
                || param.startsWith('sec:')
                || param.startsWith('dap:')
                || param.startsWith('col:')
                || param.startsWith('val:'));
    }
}
