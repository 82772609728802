import { Action } from '@ngrx/store';
import { Appliance } from '@/_models/appliance';
import { ApplianceTestStatus } from './appliance.state';

export class LoadInStateAppliancesAction implements Action {
    readonly type = '[Appliance] InState Load Appliance action';

    constructor(public readonly appliances: Appliance[]) { }
}

export class LoadInStateAppliancesSlotsCountAction implements Action {
    readonly type = '[Appliance] InState Load Appliance slots count action';

    constructor(public readonly slotsCount: number) { }
}

export class SetSelectedApplianceAction implements Action {
    public type = '[Appliance] Set Selected Appliance action';
    constructor(public readonly applianceId: number) { }
}

export class ResetApplianceInStateAction implements Action {
    public type = '[Appliance] Reset Appliance in state action';
    constructor(public readonly applianceId: number) { }
}

export class SetApplianceTestStatusAction implements Action {
    public type = '[Appliance] Set Appliance test status action';
    constructor(public readonly status: ApplianceTestStatus, public readonly errorMessage?: string) { }
}

//#region async Test Appliance

export class TestApplianceAction implements Action {
    public type = '[Appliance] Test Appliance action';
    constructor(public readonly appliance: Appliance) { }
}
export class TestOkApplianceAction implements Action {
    public type = '[Appliance] Test OK Appliance action';
}
export class TestFailedApplianceAction implements Action {
    public type = '[Appliance] Test Cancel Appliance action';
    constructor(public readonly error: Error | string | any) {}
}

//#region fetch

export class FetchAppliancesAction implements Action {
    public type = '[Appliance] Fetch Appliances action';
}
export class FetchOkAppliancesAction implements Action {
    public type = '[Appliance] Fetch OK Appliances action';
}
export class FetchFailedAppliancesAction implements Action {
    public type = '[Appliance] Fetch Cancel Appliances action';
    constructor(public readonly error: Error | string | any) { }
}
export class FetchApplianceSlotsCountAction implements Action {
    public type = '[Appliance] Fetch Appliances slots count action';
}
export class FetchOkAppliancesSlotsCountAction implements Action {
    public type = '[Appliance] Fetch OK Appliances slots count action';
}
export class FetchFailedAppliancesSlotsCountAction implements Action {
    public type = '[Appliance] Fetch Cancel Appliances slots count action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion fetch

//#region add

export class AddApplianceAction implements Action {
    readonly type = '[Appliance] Add Appliance action';

    constructor(public readonly appliance: Appliance) { }
}

export class CancelAddApplianceAction implements Action {
    readonly type = '[Appliance] Cancel Add Appliance action';

    constructor(public readonly applianceId: number) { }
}

//#endregion add

//#region create

export class CreateInStateApplianceAction implements Action {
    readonly type = '[Appliance] Create InState Appliance action';

    constructor(public readonly appliance: Appliance) { }
}

export class CreateApplianceAction implements Action {
    public type = '[Appliance] Create Appliance action';
    constructor(public readonly appliance: Appliance) { }
}

export class CreateOkApplianceAction implements Action {
    public type = '[Appliance] Create OK Appliance action';
}

export class CreateFailedApplianceAction implements Action {
    public type = '[Appliance] Create Cancel Appliance action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion create

//#region update

export class UpdateInStateApplianceAction implements Action {
    readonly type = '[Appliance] Update InState Appliance action';

    constructor(public readonly appliance: Appliance) { }
}

export class UpdateApplianceAction implements Action {
    public type = '[Appliance] Update Appliance action';

    constructor(public readonly appliance: Appliance) { }
}

export class UpdateOkApplianceAction implements Action {
    public type = '[Appliance] Update OK Appliance action';
}

export class UpdateFailedApplianceAction implements Action {
    public type = '[Appliance] Update Cancel Appliance action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion update

//#region delete

export class DeleteInStateApplianceAction implements Action {
    readonly type = '[Appliance] Delete InState Appliance action';

    constructor(public readonly applianceId: number) { }
}

export class DeleteApplianceAction implements Action {
    public type = '[Appliance] Delete Appliance action';

    constructor(public readonly applianceId: number) { }
}

export class DeleteOkApplianceAction implements Action {
    public type = '[Appliance] Delete OK Appliance action';
}

export class DeleteFailedApplianceAction implements Action {
    public type = '[Appliance] Delete Cancel Appliance action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion delete
