export class MatchingIssuesFilter {
    public issueId: number;
    public sectionId: number;
    public myRigs: boolean;
}

export class MatchingIssuesResult
{
    public sectionId: number;
    public orderName: string;
    public sectionName: string;
    public targetName: string;
    public hasMatchingRuleInstance: boolean;
    public issueStatus: number;
    public issueReasonCode: number;
    public issueReasonCodeDescription: string;
    public issueTimeProblemDuration: number;
    public categoryService: string;
}
