import { DapDocumentContentFieldValue, DapTemplateContentFunction } from "@/_models/dap-document-details";
import { RefScalar } from "./ref-scalar";
import { DapDocumentVM, DapFieldVM, DapTableRowValue } from "../vm";
import { ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { DapDocumentValueValidators, ValueValidatorFunc } from "../dap-document-value-validators";
import { RefFunction } from "./ref-function";
import { PickListItem, PickListType } from "@/_models";

export class RefValidation {
    public static getAllRefKeys(functions: DapTemplateContentFunction[]): string[] {
        const result: string[] = [];
        if (!functions) { return result; }

        functions.forEach(validationFunc => {
            validationFunc.params.forEach(funcParam => {
                if (RefScalar.isParamRefScalar(funcParam)) {
                    result.push(funcParam);
                }
            });
        });

        return result;
    }

    public static getFieldValidatorFuncs(field: DapFieldVM, validationFunc: DapTemplateContentFunction, docVM: DapDocumentVM,
        sectionIndex: number, dapIndex: number, rowValue: DapTableRowValue, picklistMap: Map<PickListType, PickListItem[]>): ValidatorFn[] {

        const result: ValidatorFn[] = [];
        switch (validationFunc.name) {
            case 'requiredIfTrue':
                if (RefScalar.getValue(validationFunc.params[0], docVM, sectionIndex, dapIndex, rowValue, picklistMap) === true) {
                    result.push(Validators.required);
                }
                break;
            case 'unique':
                result.push(RefValidation.getUniqueFieldValidatorFunc(field, docVM, sectionIndex, dapIndex));
                break;
            default:
                break;
        }

        return result;
    }

    public static getUniqueFieldValidatorFunc(field: DapFieldVM, docVM: DapDocumentVM,
        sectionIndex: number, dapIndex: number): ValidatorFn {
        
        if (!field?.templateContent?.key) { return; }

        const fieldsToCompare: DapFieldVM[] = [];
        const fieldKey = field.templateContent.key;
        if (dapIndex != null) {
            const section = docVM.sectionVMs[sectionIndex];
            section.dapVMs.forEach((dap, index) => {
                if (index === dapIndex) { return; }
                const sameField = dap.getFieldVMs().find(f => f.templateContent.key === fieldKey);
                if (sameField) {
                    fieldsToCompare.push(sameField);
                }
            });
        } else {
            docVM.sectionVMs.forEach((section, index) => {
                if (index === sectionIndex) { return; }
                const sameField = section.getFieldVMs().find(f => f.templateContent.key === fieldKey);
                if (sameField) {
                    fieldsToCompare.push(sameField);
                }
            });
        }
        return (): ValidationErrors | null => {
            if (fieldsToCompare.length == 0) { return null; }
            const fieldValue = RefScalar.getFieldValue(field.form.getRawValue());
            const match = fieldsToCompare.find(f => {
                const value = f.form.getRawValue() as DapDocumentContentFieldValue;
                if (RefScalar.isFieldEmptyOrWhitespace(value)) { return false; }
                return RefScalar.getFieldValue(value) === fieldValue;
            });
            if (match) {
                return {
                    [field.templateContent.name]: 'the value is not unique'
                };
            }
            return null;
        };
    }

    public static getTableCellValidatorFuncs(validationFunc: DapTemplateContentFunction, format: string, docVM: DapDocumentVM, sectionIndex: number,
        dapIndex: number, rowValue: DapTableRowValue, picklistMap: Map<PickListType, PickListItem[]>): ValueValidatorFunc[] {

        const result: ValueValidatorFunc[] = [];
        const paramValues = RefFunction.resolveParams(validationFunc, docVM, sectionIndex, dapIndex, rowValue, picklistMap);
        switch (validationFunc.name) {
            case 'requiredIfTrue':
                if (paramValues[0] === true) {
                    result.push(DapDocumentValueValidators.valueRequiredFunc(format));
                }
                break;
            default:
                break;
        }

        return result;
    }
}
