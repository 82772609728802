import { Action } from '@ngrx/store';
import { MnemonicCatalog } from '@/_models';

export class LoadMnemonicCatalogAction implements Action {
    public type = '[Mnemonic] Load MnemonicCatalog action';

    constructor(public readonly mnemonics: MnemonicCatalog[]) { }
}

//#region Fetch MnemonicCatalog

export class FetchMnemonicCatalogAction implements Action {
    public type = '[Mnemonic] Fetch MnemonicCatalog action';
    constructor(public readonly serviceProviderId?: number) { }
}

export class FetchOkMnemonicCatalogAction implements Action {
    public type = '[Mnemonic] Fetch OK MnemonicCatalog action';
}

export class FetchFailedMnemonicCatalogAction implements Action {
    public type = '[Mnemonic] Fetch Cancel MnemonicCatalog action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion Fetch MnemonicCatalog
