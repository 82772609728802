import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { ModalDialogModule } from '../modal-dialog/modal-dialog.component';
import { IssueDetailsDialogComponent } from './issue-details-dialog.component';

@NgModule({
  declarations: [IssueDetailsDialogComponent],
  exports: [IssueDetailsDialogComponent, PaginationModule],
    imports: [ ModalDialogModule, RouterModule, CommonModule, PaginationModule.forRoot(), FormsModule ],
})
export class IssueDetailsDialogModule { }
