import { Action } from '@ngrx/store';
import { Role } from '@/_models';

export class LoadRolesAction implements Action {
    public type = '[Role] Load roles action';

    constructor(public readonly roles: Role[]) { }
}

export class FetchRolesAction implements Action {
    public type = '[Role] Fetch roles action';
}

export class FetchOkRolesAction implements Action {
    public type = '[Role] Fetch OK roles action';
}

export class FetchFailedRolesAction implements Action {
    public type = '[Role] Fetch Cancel roles action';
    constructor(public readonly error: Error | string | any) { }
}
