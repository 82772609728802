import * as XLSX from 'xlsx';

export const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const EXCEL_EXTENSION = 'xlsx';

export class Exporter {
    public static toExcel(json: any[], sheetName: string, dateNF: string): XLSX.WorkBook {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { dateNF } as XLSX.JSON2SheetOpts);

        const wb: XLSX.WorkBook = { Sheets: {}, SheetNames: [] } as XLSX.WorkBook;
        wb.Sheets[sheetName] = worksheet;
        wb.SheetNames = [sheetName];

        return wb;
    }

    public static writeToExcel(wb: XLSX.WorkBook) {
        return XLSX.write(wb, { bookType: EXCEL_EXTENSION, type: 'array' });
    }
}
