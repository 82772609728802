export class DapKpiScoreTreeItem {
    public dimension: string;
    public categoryKey: string;
    public deliveryScore?: number;
    public qualityScore?: number;
    public totalScore?: number;
    public children: DapKpiScoreTreeItem[];
}

export class DapKpiScore {
    public sectionId: number;
    public tree: DapKpiScoreTreeItem;
}

export class DapKpiScoreTrendRequest {
    public sectionId: number;
    public categoryKeys: string[];
    public from: string;
    public to: string;
}
