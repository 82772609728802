import { StateGroup } from "@/_models";
import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-state-details-group',
    templateUrl: './state-details-group.component.html',
})
export class StateDetailsGroupComponent {
    @Input() public stateGroup: StateGroup;
}
