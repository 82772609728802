export class RuleManagementFilters {
    // some of the filters aren't actual records in the db and are identified by their name
    constructor(
        public orderId?: string,
        public ruleId?: string,
        public parameterNameStartsWith?: string,
        public subjectTypeId?: number,
        public mnemonicNameStartsWith?: string,
        public serviceProviderId?: string,
        public serviceId?: string,
        public toolId?: string,
        public indexTypeId?: string,
        public isMemory?: string
    ) {}
}
