export enum RuleSuppressionStatus {
    NotSuppressed = 0,
    CurrentIssue = 1,
    ByReasonCode = 2,
    All = 3
}

export class RuleSuppressionInfo {
    public status: RuleSuppressionStatus;
    public limitInMinutes: number|null;
    public reasonCode: number|null;
}
