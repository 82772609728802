import { AlertStateStatusName } from '../alert-state-status-name';
import { getAlertState } from './get-alert-state';

export function stateNameComparator(stateName1: AlertStateStatusName, stateName2: AlertStateStatusName): number {
    const state1 = getAlertState(stateName1);
    const state2 = getAlertState(stateName2);

    if (state1 === null && state2 === null) {
        return 0;
    }
    if (state1 === null) {
        return -1;
    }
    if (state2 === null) {
        return 1;
    }
    return state1 - state2;
}
