import { Store, Action } from '../ngrx-actions';
import { LoadInStateInfraPanelsAction } from './infra-panel.actions';
import { InfraPanelState } from './infra-panel.state';

@Store({} as InfraPanelState)
export class InfraPanelStore {

    @Action(LoadInStateInfraPanelsAction)
    public loadInfraPanels(state: InfraPanelState, { infraPanels }: LoadInStateInfraPanelsAction) {
        return {
            ...state,
            infraPanels
        } as InfraPanelState;
    }
}
