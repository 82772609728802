export enum RigState {
    Drilling = 0,
    Reaming = 1,
    Sliding = 2,
    Pumping = 3,
    MakingConnection = 4,
    OnSurface = 5,
    TrippingIn = 6,
    TrippingOut = 7,
    Other = 8,
}
