export enum RuleParameterCategory {
    Control = 'Control',
    Business = 'Business',
    Alerts = 'Alerts',
    System = 'System',
}

export enum RuleCategoryOrder {
    Control,
    Business,
    Alerts,
    System
}
