import { Action } from '@ngrx/store';
import {
    RuleManagementFilterLists, RuleManagementFilters, RuleManagementRuleInstance, RuleManagementRuleInstanceData,
    RuleInstanceParameterWithOverrides, ListItem, CopyOverridesMode
} from '@/_models';

export class LoadInStateRuleManagementFilterListsAction implements Action {
    public type = '[Rule management] Load RuleManagementFilterLists action';
    constructor(public readonly filterLists: RuleManagementFilterLists) { }
}

export class FetchRuleManagementFilterListsAction implements Action {
    public type = '[Rule management] Fetch RuleManagementFilterLists action';
}

export class FetchOkRuleManagementFilterListsAction implements Action {
    public type = '[Rule management] Fetch OK RuleManagementFilterLists action';
}

export class FetchFailedRuleManagementFilterListsAction implements Action {
    public type = '[Rule management] Fetch Cancel RuleManagementFilterLists action';
    constructor(public readonly error: Error | string | any) { }
}

export class ClearInStateRuleInstanceDataAction implements Action {
    public type = '[Rule management] Clear RuleInstanceData action';
}

// fetch rule instances

export class LoadInStateRuleInstanceDataAction implements Action {
    public type = '[Rule management] Load RuleInstanceData action';
    constructor(public readonly ruleInstanceData: RuleManagementRuleInstanceData) {}
}

export class FetchRuleInstanceDataAction implements Action {
    public type = '[Rule management] Fetch RuleInstanceData action';
    constructor(public readonly filters: RuleManagementFilters) {}
}

export class FetchOkRuleInstanceDataAction implements Action {
    public type = '[Rule management] Fetch OK RuleInstanceData action';
}

export class FetchFailedRuleInstanceDataAction implements Action {
    public type = '[Rule management] Fetch Cancel RuleInstanceData action';
    constructor(public readonly error: Error | string | any) { }
}

// fetch rule instance parameters

export class LoadInStateRuleInstanceParametersAction implements Action {
    public type = '[Rule management] Load RuleInstance parameters action';
    constructor(public readonly ruleInstanceParameters: RuleInstanceParameterWithOverrides[]) {}
}

export class ClearInStateRuleInstanceParametersAction implements Action {
    public type = '[Rule management] Clear RuleInstanceParameters action';
}

export class FetchRuleInstanceParametersAction implements Action {
    public type = '[Rule management] Fetch RuleInstance parameters action';
    constructor(public readonly ruleInstances: RuleManagementRuleInstance[]) {}
}

export class FetchOkRuleInstanceParametersAction implements Action {
    public type = '[Rule management] Fetch OK RuleInstance parameters action';
}

export class FetchFailedRuleInstanceParametersAction implements Action {
    public type = '[Rule management] Fetch Cancel RuleInstance parameters action';
    constructor(public readonly error: Error | string | any) { }
}

// fetch rule instance parameters override indicators
export class FetchRuleInstanceParametersOverrideIndicatorsAction implements Action {
    public type = '[Rule management] Fetch RuleInstance parameters override indicators action';
    constructor(public readonly ruleInstances: RuleManagementRuleInstance[]) {}
}

export class FetchRuleInstanceParametersOverrideIndicatorsWithDelayAction implements Action {
    public type = '[Rule management] Fetch RuleInstance parameters override indicators after a small delay action';
    constructor(public readonly ruleInstances: RuleManagementRuleInstance[]) {}
}

export class FetchOkRuleInstanceParametersOverrideIndicatorsAction implements Action {
    public type = '[Rule management] Fetch OK RuleInstance parameters override indicators action';
}

export class FetchFailedRuleInstanceParametersOverrideIndicatorsAction implements Action {
    public type = '[Rule management] Fetch Cancel RuleInstance parameters override indicators action';
    constructor(public readonly error: Error | string | any) { }
}

export class UpdateInStateRuleInstanceParametersOverrideIndicatorsAction implements Action {
    public type = '[Rule management] Update RuleInstance parameters override indicators action';
    constructor(public readonly ruleInstances: RuleManagementRuleInstance[]) {}
}

// update rule instance parameters

export class UpdateRuleInstanceParametersAction implements Action {
    public type = '[Rule management] Update RuleInstance parameters action';
    constructor(public readonly parameters: RuleInstanceParameterWithOverrides[], public readonly rules: RuleManagementRuleInstance[]) {}
}

export class UpdateOkRuleInstanceParametersAction implements Action {
    public type = '[Rule management] Update OK RuleInstance parameters action';
}

export class UpdateFailedRuleInstanceParametersAction implements Action {
    public type = '[Rule management] Update Cancel RuleInstance parameters action';
    constructor(public readonly error: Error | string | any) { }
}

// fetch copy from order list

export class LoadInStateCopyFromOrderListAction implements Action {
    public type = '[Rule management] Load CopyFrom order list action';
    constructor(public readonly ordersToCopyFromList: ListItem[]) {}
}

export class ClearInStateCopyFromOrderListAction implements Action {
    public type = '[Rule management] Clear CopyFrom order list action';
}

export class FetchCopyFromOrderListAction implements Action {
    public type = '[Rule management] Fetch CopyFrom order list';
    constructor(public readonly ruleInstances: {subjectName: string, ruleImplementationId: number}[]) { }
}

export class FetchOkCopyFromOrderListAction implements Action {
    public type = '[Rule management] Fetch OK CopyFrom order list';
}

export class FetchFailedCopyFromOrderListAction implements Action {
    public type = '[Rule management] Fetch Cancel CopyFrom order list';
    constructor(public readonly error: Error | string | any) { }
}

// copy overrides action

export class CopyParameterOverridesAction implements Action {
    public type = '[Rule management] Copy parameter overrides';
    constructor(
        public readonly copyOverridesMode: CopyOverridesMode,
        public readonly copyFromOrderId: number,
        public readonly copyToOrderId: number,
        public readonly ruleInstances: {subjectName: string, ruleImplementationId: number}[]
    ) { }
}

export class CopyParameterOverridesOkAction implements Action {
    public type = '[Rule management] OK Copy parameter overrides';
}

export class CopyParameterOverridesFailedAction implements Action {
    public type = '[Rule management] Failed Copy parameter overrides';
    constructor(public readonly error: Error | string | any) { }
}
