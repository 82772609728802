export class CopyOrderParams {
    public fromOrderId: number;
    public toOrderId: number;
    public copyDetails: boolean;
    public copyTrajectories: boolean;
    public copyTargets: boolean;
    public copyAssetContacts: boolean;
    public copySections: boolean;
    public overwriteCurveParams?: boolean;
    public useTemplate: boolean;
}

export class CopySectionParams {
    public fromSectionId: number;
    public toSectionId: number;
    public toOrderId: number;
    public copyDetails: boolean;
    public copyMnemonics: boolean;
    public overwriteCurveParams?: boolean;
}
