import { RefScalar } from '@/dap/document-details/eval/ref-scalar';
import { DapDocumentVM, DapTableRowValue } from '@/dap/document-details/vm';
import { PickListItem, PickListType } from '@/_models';

export function resolveContext(contexts: string[], docVM: DapDocumentVM, sectionIndex: number, dapIndex: number,
    rowValue: DapTableRowValue, picklistMap: Map<PickListType, PickListItem[]>): Map<string, any> {
    const dapContextMap: Map<string, any> = new Map<string, any>();

    if (!contexts) { return dapContextMap; }

    contexts.forEach(context => {
        context = context.trim();
        dapContextMap.set(context, RefScalar.getValue(context, docVM, sectionIndex, dapIndex, rowValue, picklistMap));
    });
    return dapContextMap;
}
