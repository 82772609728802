import { Action } from '@ngrx/store';
import { Tooltip } from '@/_models';

export class LoadTooltipsAction implements Action {
  public type = '[Tooltip] Load tooltips action';
  constructor(public readonly tooltips: Tooltip[]) {}
}

export class FetchTooltipsAction implements Action {
  public type = '[Tooltip] Fetch tooltips action';
  constructor(public readonly key: string) {}
}

export class FetchOkTooltipsAction implements Action {
  public type = '[Tooltip] Fetch OK tooltips action';
}

export class FetchFailedTooltipsAction implements Action {
  public type = '[Tooltip] Fetch Failed tooltips action';
  constructor(public readonly error: Error | string | any) {}
}
