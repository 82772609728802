import { Store, Action } from '../ngrx-actions';
import { LoadTooltipsAction } from './tooltip.actions';
import { TooltipState } from './tooltip.state';

@Store({} as TooltipState)
export class TooltipStore {

    @Action(LoadTooltipsAction)
    public loadTooltips(state: TooltipState, { tooltips }: LoadTooltipsAction) {
        return { ...state, tooltips: tooltips } as TooltipState;
    }
}
