import { Store, Action } from '../ngrx-actions';
import {
    LoadUsersAction, DeleteUserFromStateAction,
    CreateUserInStateAction, UpdateUserInStateAction,
    DisableUserInStateAction, EnableUserInStateAction, LoadSimpleUsersAction,
} from './user.actions';
import { UserState } from './user.state';

@Store({} as UserState)
export class UserStore {

    @Action(LoadUsersAction)
    public loadUsers(state: UserState, { users }: LoadUsersAction) {
        return { ...state, users: users } as UserState;
    }

    @Action(LoadSimpleUsersAction)
    public loadSimpleUsers(state: UserState, { simpleUsers }: LoadSimpleUsersAction) {
        return { ...state, simpleUsers } as UserState;
    }

    @Action(DeleteUserFromStateAction)
    public deleteUser(state: UserState, { userId }: DeleteUserFromStateAction) {
        if (!state.users) { // TODO extract to contract check
            throw new Error('Users state is not defined.');
        }
        const index = state.users.findIndex(x => x.id === userId);
        if (index < 0) {
            throw new Error(`User with id "${userId}" does not exist in the state.`);
        }

        const newUsers = state.users.filter(x => x.id !== userId);
        return { ...state, users: newUsers } as UserState;
    }

    @Action(CreateUserInStateAction)
    public createUser(state: UserState, { user }: CreateUserInStateAction) {
        if (!state.users) { // extract to contract check
            throw new Error('Users state is not defined.');
        }
        if (!user) { // extract to contract check
            throw new Error('User is not defined.');
        }

        const newUsers = [...state.users];
        newUsers.push(user);
        return { ...state, users: newUsers } as UserState;
    }

    @Action(UpdateUserInStateAction)
    public updateUser(state: UserState, { user }: UpdateUserInStateAction) {
        if (!state.users) { // extract to contract check
            throw new Error('Users state is not defined.');
        }
        if (!user) { // extract to contract check
            throw new Error('User is not defined.');
        }

        const index = state.users.findIndex(x => x.id === user.id);
        const newUsers = [...state.users];
        newUsers[index] = { ...user };
        return { ...state, users: newUsers } as UserState;
    }

    @Action(DisableUserInStateAction)
    public disableUser(state: UserState, { user }: DisableUserInStateAction) {
        if (!state.users) { // extract to contract check
            throw new Error('Users state is not defined.');
        }
        if (!user) { // extract to contract check
            throw new Error('User is not defined.');
        }

        const index = state.users.findIndex(x => x.id === user.id);
        if (index < 0) {
            throw new Error(`User (id: ${user.id}) not found: `);
        }
        const newUsers = [...state.users];
        newUsers[index] = { ...user };
        return { ...state, users: newUsers } as UserState;
    }

    @Action(EnableUserInStateAction)
    public enableUser(state: UserState, { user }: EnableUserInStateAction) {
        if (!state.users) { // extract to contract check
            throw new Error('Users state is not defined.');
        }
        if (!user) { // extract to contract check
            throw new Error('User is not defined.');
        }

        const index = state.users.findIndex(x => x.id === user.id);
        if (index < 0) {
            throw new Error(`User (id: ${user.id}) not found: `);
        }
        const newUsers = [...state.users];
        newUsers[index] = { ...user };
        return { ...state, users: newUsers } as UserState;
    }
}
