import { Action } from '@ngrx/store';
import { SystemParameters } from '@/_models';

export class LoadInStateSysParametersAction implements Action {
  public type = '[SysParameters] Load in state system parameters action';
  constructor(public readonly parameters: SystemParameters) {}
}

//#region async Fetch SystemParameters

export class FetchSystemParametersAction implements Action {
    public type = '[SysParameters] Fetch SystemParameters action';
}
export class FetchOkSystemParametersAction implements Action {
    public type = '[SysParameters] Fetch OK SystemParameters action';
}
export class FetchFailedSystemParametersAction implements Action {
    public type = '[SysParameters] Fetch Fail SystemParameters action';
    constructor(public readonly error: Error | string | any) {}
}

//#endregion async Fetch SystemParameters
