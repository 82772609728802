import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, ChangeDetectorRef } from '@angular/core';

export type FadeState = 'visible' | 'hidden';

@Component({
    selector: 'app-fade',
    template: `
<div
    *ngIf="show"
    class="fade"
    [@state]="state"
    (@state.done)="animationDone($event)"
>
    <ng-content #fadeableContent></ng-content>
</div>
`,
    styles: [`
:host {
  display: block;
}
.fade {
  opacity: 0;
}
    `],
    animations: [
        trigger('state', [
            state(
                'visible',
                style({
                    opacity: '1'
                })
            ),
            state(
                'hidden',
                style({
                    opacity: '0'
                })
            ),
            transition('* => visible', [animate('250ms ease-out')]),
            transition('visible => hidden', [animate('250ms ease-out')])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FadeComponent {
    state: FadeState;
    private _show: boolean;
    get show() {
        return this._show;
    }
    @Input()
    set show(value: boolean) {
        if (value) {
            this._show = value;
            this.state = 'visible';
        } else {
            this.state = 'hidden';
        }
    }

    constructor(private readonly cd: ChangeDetectorRef) { }

    animationDone(event: AnimationEvent) {
        if (event.fromState === 'visible' && event.toState === 'hidden') {
            this._show = false;
            this.cd.detectChanges();
        }
    }
}
