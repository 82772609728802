import { EditingMonitoringService } from '@/_services/editing-monitoring.service';
import { getMessageFromError, NgxAlertService } from '@/_shared';
import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { FetchEditingMonitoringAction,  FetchEditingMonitoringSessionAction, FetchEditingMonitoringStartViewAction,
         FetchEditingMonitoringEndSessionAction, FetchEditingMonitoringFailedAction, FetchEditingMonitoringStartEditAction,
         FetchEditingMonitoringOkAction, FetchEditingMonitoringUpdateSessionAction,
         LoadEditingMonitoringAction, LoadEditingMonitoringSessionAction
} from './editing-monitoring.actions';

@Injectable()
export class EditingMonitoringEffects {

    
    public onFetchEditingMonitoringSession$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchEditingMonitoringSessionAction),
        switchMap(x => this.editingMonitoringService.createSession(x.monitoringKey).pipe(
            switchMap(content => [
                new LoadEditingMonitoringSessionAction(content),
                new FetchEditingMonitoringStartViewAction(content),
                new FetchEditingMonitoringAction(content),
                new FetchEditingMonitoringOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchEditingMonitoringSession ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchEditingMonitoringFailedAction(error));
            })
        ))
    ));

    
    public onFetchEditingMonitoring$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchEditingMonitoringAction),
        switchMap(x => this.editingMonitoringService.refreshSession(x.monitoringSession).pipe(
            switchMap(content => [new LoadEditingMonitoringAction(content), new FetchEditingMonitoringOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchEditingMonitoring ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchEditingMonitoringFailedAction(error));
            })
        ))
    ));

    
    public onFetchEditingMonitoringStartView$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchEditingMonitoringStartViewAction),
        switchMap(x => this.editingMonitoringService.startView(x.monitoringSession).pipe(
            switchMap(() => [new FetchEditingMonitoringOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchEditingMonitoringStartView ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchEditingMonitoringFailedAction(error));
            })
        ))
    ));

    
    public onFetchEditingMonitoringStarEdit$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchEditingMonitoringStartEditAction),
        switchMap(x => this.editingMonitoringService.startEdit(x.monitoringSession).pipe(
            switchMap(() => [new FetchEditingMonitoringOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchEditingMonitoringStartEdit ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchEditingMonitoringFailedAction(error));
            })
        ))
    ));

    
    public onFetchEditingMonitoringEndSession$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchEditingMonitoringEndSessionAction),
        switchMap(x => this.editingMonitoringService.endSession(x.sessionId).pipe(
            switchMap(() => [new FetchEditingMonitoringOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchEditingMonitoringEndSession ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchEditingMonitoringFailedAction(error));
            })
        ))
    ));

    
    public onFetchEditingMonitoringUpdateSession$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchEditingMonitoringUpdateSessionAction),
        switchMap(x => this.editingMonitoringService.updateSession(x.monitoringSession).pipe(
            switchMap(session => [
                new LoadEditingMonitoringSessionAction(session),
                new FetchEditingMonitoringOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchEditingMonitoringUpdateSession ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchEditingMonitoringFailedAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly editingMonitoringService: EditingMonitoringService
    ) { }
}
