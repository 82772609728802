import { NgxUser } from 'ngx-shared';
import { Action } from './actions';

export class User implements NgxUser {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    phone: string;
    serviceProvider?: number;
    roles: number[];
    token?: string;
    actions?: Action[];
    enabled: boolean;
    // Azure ActiveDirectory objectId is obtained during the authentication process
    // and used by the API to match the locally stored user
    aadObjectId: string;
}
