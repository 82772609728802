import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ErrorDetails } from '../_models/error-details';
import { NgxAlertService } from '../_services/index';

/* eslint-disable @angular-eslint/component-selector */
@Component({
    selector: 'ngx-alert',
    templateUrl: './ngx-alert.component.html',
    styleUrls: ['./ngx-alert.component.css']
})
export class NgxAlertComponent implements OnDestroy {

    @Input() type: string;
    @Input() message: string;
    @Input() alertVisible: boolean;
    @Input() dismissOnTimeout = 4000;
    @Input() filterByType: null | string | string[];

    public errorDetails: ErrorDetails;

    private destroy$ = new Subject();

    constructor(alertService: NgxAlertService) {
        alertService.getMessage().pipe(
            takeUntil(this.destroy$),
            filter(x => x != null && this.isAllowedType(x.type)), // skip the first null message or not allowed types
            tap(x => {
                this.type = x.type;
                this.alertVisible = true;
                if (typeof x.message === 'string') {
                    this.message = x.message;
                    this.errorDetails = null;
                    this.dismissOnTimeout = 4000;
                } else { // ErrorDetails
                    this.message = null;
                    this.errorDetails = x.message;
                    this.dismissOnTimeout = undefined;
                }
            })
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    private isAllowedType(type: string) {
        // no filtration
        return !this.filterByType
            // single value filtration
            || this.filterByType === type
            // array of possible values
            || Array.isArray(this.filterByType) && this.filterByType.some(x => x === type);
    }
}
