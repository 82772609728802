import { IndicatorStatus } from '../indicator-status';

export class SectionTrendData {
    public sectionId?: number;
    public lines: SectionTrendDataLine[];
}

export class SectionTrendDataLine {
    public name: string;
    public nodeId: string;
    public points: SectionTrendDataItem[];
}

export class SectionTrendDataItem {
    public dateTimeUtc: Date;
    public totalInstances: number;
    public validInstances: number;
    public status: IndicatorStatus;
}
