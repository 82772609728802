import { Measure } from "@/_models";

export class DapDocContainerDiff {
    public key: string;
    public name: string;
    public isCritical: boolean;
    public fieldsDiff: DapDocFieldDiff[];
    public tablesDiff: DapDocTableDiff[];
    public onlyInDoc1 = false;
    public onlyInDoc2 = false;
}

export class DapDocDiff extends DapDocContainerDiff {
    public sectionsDiff: DapDocSectionDiff[];
}

export class DapDocFieldDiff {
    public key: string;
    public name: string;
    public isCritical: boolean;
    public doc1Value: string | number | Date | Measure | boolean | null;
    public doc2Value: string | number | Date | Measure | boolean | null;
}

export class DapDocTableDiff {
    public key: string;
    public name: string;
    public rowsDiff: DapDocRowDiff[];
}

export class DapDocRowDiff {
    public index: number;
    public isCritical: boolean;
    public onlyInDoc1 = false;
    public onlyInDoc2 = false;
    public fieldsDiff: DapDocFieldDiff[];
}

export class DapDocSectionDiff extends DapDocContainerDiff {
    public dapsDiff: DapDocContainerDiff[];
}
