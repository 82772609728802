import { Store, Action } from '../ngrx-actions';
import {
    LoadLicensesAction, DeleteLicenseFromStateAction,
    CreateLicenseInStateAction, UpdateLicenseInStateAction, LoadProfilesAction, LoadLicenseInfoAction
} from './dap-license-management.actions';
import { LicenseInfoState, LicenseState, ProfileState } from './dap-license-management.state';

@Store({} as LicenseState)
export class LicenseStore {

    @Action(LoadLicensesAction)
    public loadLicenses(state: LicenseState, { licenses }: LoadLicensesAction) {
        return { ...state, licenses: licenses } as LicenseState;
    }

    @Action(DeleteLicenseFromStateAction)
    public deleteLicense(state: LicenseState, { licenseId }: DeleteLicenseFromStateAction) {
        if (!state.licenses) {
            throw new Error('Licenses state is not defined.');
        }
        const index = state.licenses.findIndex(x => x.id === licenseId);
        if (index < 0) {
            throw new Error(`License with id "${licenseId}" does not exist in the state.`);
        }

        const newLicenses = state.licenses.filter(x => x.id !== licenseId);
        return { ...state, licenses: newLicenses } as LicenseState;
    }

    @Action(CreateLicenseInStateAction)
    public createLicense(state: LicenseState, { license }: CreateLicenseInStateAction) {
        if (!state.licenses) {
            throw new Error('Licenses state is not defined.');
        }
        if (!license) {
            throw new Error('License is not defined.');
        }

        const newLicenses = [...state.licenses];
        newLicenses.push(license);
        return { ...state, licenses: newLicenses } as LicenseState;
    }

    @Action(UpdateLicenseInStateAction)
    public updateLicense(state: LicenseState, { license }: UpdateLicenseInStateAction) {
        if (!state.licenses) {
            throw new Error('Licenses state is not defined.');
        }
        if (!license) {
            throw new Error('License is not defined.');
        }

        const index = state.licenses.findIndex(x => x.id === license.id);
        const newLicenses = [...state.licenses];
        newLicenses[index] = { ...license };
        return { ...state, licenses: newLicenses } as LicenseState;
    }
}

@Store({} as ProfileState)
export class ProfileStore {
    @Action(LoadProfilesAction)
    public loadProfiles(state: ProfileState, { profiles }: LoadProfilesAction) {
        return { ...state, profiles: profiles } as ProfileState;
    }
}

@Store({} as LicenseInfoState)
export class LicenseInfoStore {

    @Action(LoadLicenseInfoAction)
    public loadLicenseInfo(state: LicenseInfoState, { licenseInfo }: LoadLicenseInfoAction) {
        return { ...state, licenseInfo } as LicenseInfoState;
    }
}
