export enum InfraPanelState {
    NotMonitored = 'Not Monitored',
    Active = 'Active',
    Down = 'Down',
    Up = 'Up',
}

export class InfraPanel {
    name: string;
    shortName: string;
    /** Informally <i>isMonitored</i>, represents if the Target is currently being monitored*/
    isActive: boolean;
    /** The result of the monitoring: if the Target is Up or Down */
    isAvailable: boolean;
    /** If Target has an active section */
    inActiveSection: boolean;
    /** UTC Date on which the state (isAvailable) was changed */
    isAvailableLastChangeTimestamp: Date;
    /** UTC Date on which the monitor service has checked the target */
    lastCheckedTimestamp: Date;
}
