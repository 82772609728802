import {
    DapDocumentChange, DapDocumentChangeAction, DapDocumentChangeScope, DapDocumentChangeType, DapDocumentContent, DapDocumentContentDap,
    DapDocumentContentField, DapDocumentContentFieldValue, DapDocumentContentSection, DapDocumentContentTable,
} from "@/_models/dap-document-details";
import {
    DapDataContainerVM, DapDocContainerDiff, DapDocDiff, DapDocFieldDiff, DapDocRowDiff, DapDocSectionDiff, DapDocTableDiff, DapDocumentVM,
    DapSectionVM
} from "../vm";
import { dateFormatter } from "@/_helpers";
import { Measure } from "@/_models";

export class DapDocDiffHelper {
    // region Get Diff

    public static getDiff(doc1: DapDocumentContent, doc2: DapDocumentContent, docVm: DapDocumentVM): DapDocDiff {
        const result = new DapDocDiff();
        result.fieldsDiff = DapDocDiffHelper.getFieldsDiff(doc1.fields, doc2.fields, docVm);
        result.tablesDiff = DapDocDiffHelper.getTablesDiff(doc1.tables, doc2.tables, docVm);
        result.sectionsDiff = DapDocDiffHelper.getSectionsDiff(doc1.sections, doc2.sections, docVm);
        return result;
    }

    private static getFieldsDiff(fields1: DapDocumentContentField[], fields2: DapDocumentContentField[],
        containerVm: DapDataContainerVM): DapDocFieldDiff[] {
        const result: DapDocFieldDiff[] = [];
        const fieldVms = containerVm.getFieldVMs();

        fields1?.forEach(field1 => {
            const field2 = fields2?.find(x => field1.key === x.key);
            const fieldDiff = DapDocDiffHelper.getFieldDiff(field1, field2);
            if (fieldDiff != null) {
                const diff = new DapDocFieldDiff();
                diff.key = field1.key;
                const fieldVm = fieldVms.find(x => x.templateContent.key === field1.key);
                diff.name = fieldVm?.templateContent.name;
                diff.isCritical = fieldVm != null && (fieldVm.dispositionResult.critical || fieldVm.hasCriticalParent);
                diff.doc1Value = fieldDiff[0];
                diff.doc2Value = fieldDiff[1];
                result.push(diff);
            }
        });

        fields2?.forEach(field2 => {
            if (fields1 == null || fields1.some(x => x.key === field2.key) === false) {
                const fieldDiff = DapDocDiffHelper.getFieldDiff(undefined, field2);
                if (fieldDiff != null) {
                    const diff = new DapDocFieldDiff();
                    diff.key = field2.key;
                    const fieldVm = fieldVms.find(x => x.templateContent.key === field2.key);
                    diff.name = fieldVm?.templateContent.name;
                    diff.isCritical = fieldVm != null && (fieldVm.dispositionResult.critical || fieldVm.hasCriticalParent);
                    diff.doc1Value = fieldDiff[0];
                    diff.doc2Value = fieldDiff[1];
                    result.push(diff);
                }
            }
        })

        return result;
    }

    private static getFieldDiff(field1: DapDocumentContentFieldValue, field2: DapDocumentContentFieldValue) {
        if ((field1?.str != null || field2?.str != null) && field1?.str !== field2?.str) {
            return [field1?.str, field2?.str];
        }
        if ((field1?.text != null || field2?.text != null) && field1?.text !== field2?.text) {
            return [field1?.text, field2?.text];
        }
        if ((field1?.int != null || field2?.int != null) && field1?.int !== field2?.int) {
            return [field1?.int, field2?.int];
        }
        if ((field1?.float != null || field2?.float != null) && field1?.float !== field2?.float) {
            return [field1?.float, field2?.float];
        }
        if ((field1?.datetime != null || field2?.datetime != null) && field1?.datetime?.getTime() !== field2?.datetime?.getTime()) {
            return [field1?.datetime, field2?.datetime];
        }
        if ((field1?.measure != null || field2?.measure != null)
            && (field1?.measure?.value !== field2?.measure?.value || field1?.measure?.uom !== field2?.measure?.uom)) {
            return [field1?.measure, field2?.measure];
        }
        if ((field1?.bool != null || field2?.bool != null) && field1?.bool !== field2?.bool) {
            return [field1?.bool, field2?.bool];
        }

        return null;
    }

    private static getTablesDiff(tables1: DapDocumentContentTable[], tables2: DapDocumentContentTable[],
        containerVm: DapDataContainerVM): DapDocTableDiff[] {
        const result: DapDocTableDiff[] = [];
        const tableVms = containerVm.getTableVMs();

        tables1?.forEach(table1 => {
            const table2 = tables2?.find(x => x.key === table1.key);
            if (table2 != null) {
                const tableDiff = new DapDocTableDiff();
                tableDiff.key = table1.key;
                const tableVm = tableVms.find(x => x.templateContent.key === table1.key);
                tableDiff.name = tableVm?.templateContent.name;
                const rowsDiff: DapDocRowDiff[] = [];
                for (let rowIndex = 0; rowIndex < table1.rows.length || rowIndex < table2.rows.length; rowIndex++) {
                    if (table1.rows[rowIndex] == null) {
                        const rowDiff = new DapDocRowDiff();
                        rowDiff.index = rowIndex;
                        rowDiff.isCritical = tableVm != null && (tableVm.dispositionResult.critical || tableVm.hasCriticalParent);
                        rowDiff.onlyInDoc2 = true;
                        rowsDiff.push(rowDiff);
                    } else if (table2.rows[rowIndex] == null) {
                        const rowDiff = new DapDocRowDiff();
                        rowDiff.index = rowIndex;
                        rowDiff.isCritical = tableVm != null && (tableVm.dispositionResult.critical || tableVm.hasCriticalParent);
                        rowDiff.onlyInDoc1 = true;
                        rowsDiff.push(rowDiff);
                    } else {
                        const fieldsDiff: DapDocFieldDiff[] = [];
                        for (let colIndex = 0; colIndex < table1.columns.length; colIndex++) {
                            const fieldDiff = DapDocDiffHelper.getFieldDiff(table1.rows[rowIndex][colIndex], table2.rows[rowIndex][colIndex]);
                            if (fieldDiff != null) {
                                const diff = new DapDocFieldDiff();
                                diff.key = table1.columns[colIndex];
                                diff.name = tableVm?.templateContent.columns.find(x => x.key === diff.key)?.name;
                                diff.isCritical = tableVm != null && (tableVm.columnDispositionResults.get(diff.key).critical
                                    || tableVm.dispositionResult.critical || tableVm.hasCriticalParent);
                                diff.doc1Value = fieldDiff[0];
                                diff.doc2Value = fieldDiff[1];
                                fieldsDiff.push(diff);
                            }
                        }

                        if (fieldsDiff.length > 0) {
                            const rowDiff = new DapDocRowDiff();
                            rowDiff.index = rowIndex;
                            rowDiff.fieldsDiff = fieldsDiff;
                            rowsDiff.push(rowDiff);
                        }
                    }
                }
                tableDiff.rowsDiff = rowsDiff;
                result.push(tableDiff);
            }
        });

        return result;
    }

    private static getSectionsDiff(doc1Sections: DapDocumentContentSection[], doc2Sections: DapDocumentContentSection[],
        docVm: DapDocumentVM): DapDocSectionDiff[] {
        const result: DapDocSectionDiff[] = [];
        const sectionVms = docVm.sectionVMs;

        doc1Sections?.forEach(doc1Section => {
            const diff = new DapDocSectionDiff();
            diff.key = doc1Section.key;
            diff.name = doc1Section.name;

            const doc2Section = doc2Sections?.find(x => x.key === doc1Section.key);
            if (doc2Section != null) {
                const sectionVm = sectionVms.find(x => x.key === doc1Section.key);
                diff.fieldsDiff = DapDocDiffHelper.getFieldsDiff(doc1Section.fields, doc2Section.fields, sectionVm);
                diff.tablesDiff = DapDocDiffHelper.getTablesDiff(doc1Section.tables, doc2Section.tables, sectionVm);
                diff.dapsDiff = DapDocDiffHelper.getDapsDiff(doc1Section.daps, doc2Section.daps, sectionVm);
            } else {
                // adding or deleting Section is always a critical change
                diff.isCritical = true;
                diff.onlyInDoc1 = true;
            }
            
            result.push(diff);
        });

        doc2Sections?.forEach(doc2Section => {
            if (doc1Sections == null || doc1Sections.some(x => x.key === doc2Section.key) === false) {
                const diff = new DapDocSectionDiff();
                diff.key = doc2Section.key;
                diff.name = doc2Section.name;
                // adding or deleting Section is always a critical change
                diff.isCritical = true;
                diff.onlyInDoc2 = true;
                result.push(diff);
            }
        });

        return result;
    }

    private static getDapsDiff(doc1Daps: DapDocumentContentDap[], doc2Daps: DapDocumentContentDap[],
        sectionVm: DapSectionVM): DapDocContainerDiff[] {
        const result: DapDocContainerDiff[] = [];
        const dapVms = sectionVm.dapVMs;

        doc1Daps?.forEach(doc1Dap => {
            const diff = new DapDocContainerDiff();
            diff.key = doc1Dap.key;
            diff.name = doc1Dap.name

            const doc2Dap = doc2Daps?.find(x => x.key === doc1Dap.key);
            if (doc2Dap != null) {
                const dapVm = dapVms.find(x => x.key === doc1Dap.key);
                diff.fieldsDiff = DapDocDiffHelper.getFieldsDiff(doc1Dap.fields, doc2Dap.fields, dapVm);
                diff.tablesDiff = DapDocDiffHelper.getTablesDiff(doc1Dap.tables, doc2Dap.tables, dapVm);
            } else {
                // adding or deleting DAP is always a critical change
                diff.isCritical = true;
                diff.onlyInDoc1 = true;
            }
            
            result.push(diff);
        });

        doc2Daps?.forEach(doc2Dap => {
            if (doc1Daps == null || doc1Daps.some(x => x.key === doc2Dap.key) === false) {
                const diff = new DapDocContainerDiff();
                diff.key = doc2Dap.key;
                diff.name = doc2Dap.name;
                // adding or deleting DAP is always a critical change
                diff.isCritical = true;
                diff.onlyInDoc2 = true;
                result.push(diff);
            }
        });

        return result;
    }

    // endregion Get Diff

    // region Extract changes

    public static extractChanges(docDiff: DapDocDiff, locale: any): DapDocumentChange[] {
        const result: DapDocumentChange[] = [];
        result.push(...this.extractFieldChanges(docDiff.fieldsDiff, DapDocumentChangeScope.Document, DapDocumentChangeType.Field, '', locale));
        result.push(...this.extractTableChanges(docDiff.tablesDiff, DapDocumentChangeScope.Document, '', locale));
        result.push(...this.extractSectionChanges(docDiff.sectionsDiff, locale));
        return result;
    }

    private static extractFieldChanges(fieldsDiff: DapDocFieldDiff[], scope: DapDocumentChangeScope,
        changeType: DapDocumentChangeType, parentItem: string, locale: any): DapDocumentChange[] {
        if (fieldsDiff == null || fieldsDiff.length <= 0) { return []; }

        return fieldsDiff.map(fieldDiff => {
            const change = new DapDocumentChange();
            change.item = `Field ${fieldDiff.name} ${parentItem}`;
            change.isCritical = fieldDiff.isCritical;
            change.scope = scope;
            change.itemType = changeType;
            change.action = DapDocumentChangeAction.Edit;
            change.previousValue = this.getStringValue(fieldDiff.doc2Value, locale);
            change.editedValue = this.getStringValue(fieldDiff.doc1Value, locale);
            return change;
        });
    }

    private static getStringValue(value: string | number | Date | Measure | boolean | null, locale: any): string {
        if (value == null) { return null; }
        if (typeof value === 'string') {
            return value as string;
        } else if (typeof value === 'number') {
            return ''+value;
        } else if (value instanceof Date) {
            return dateFormatter(value, 'short', locale);
        } else if (value instanceof Measure) {
            const measureVal = value as Measure;
            return `${measureVal.value} ${measureVal.uom}`;
        } else if (typeof value === 'boolean') {
            return String(value);
        } else {
            return null;
        }
    }

    private static extractTableChanges(tablesDiff: DapDocTableDiff[], scope: DapDocumentChangeScope, parentItem: string, locale: any): DapDocumentChange[] {
        if (tablesDiff == null || tablesDiff.length <= 0) { return []; }
        const result: DapDocumentChange[] = [];

        tablesDiff.forEach(tableDiff => {
            tableDiff.rowsDiff.forEach(rowDiff => {
                if (rowDiff.onlyInDoc1 === true || rowDiff.onlyInDoc2 === true) {
                    const change = new DapDocumentChange();
                    change.item = `Row ${rowDiff.index} in Table ${tableDiff.name} ${parentItem}`;
                    change.isCritical = rowDiff.isCritical;
                    change.scope = scope;
                    change.itemType = DapDocumentChangeType.Row;
                    change.action = rowDiff.onlyInDoc1 ? DapDocumentChangeAction.Insert : DapDocumentChangeAction.Delete;
                    result.push(change);
                } else {
                    const rowParentItem = `in Row ${rowDiff.index} in Table ${tableDiff.name} ${parentItem}`;
                    result.push(...this.extractFieldChanges(rowDiff.fieldsDiff, scope, DapDocumentChangeType.Cell, rowParentItem, locale));
                }
            });
        });

        return result;
    }

    private static extractSectionChanges(sectionsDiff: DapDocSectionDiff[], locale: any): DapDocumentChange[] {
        if (sectionsDiff == null || sectionsDiff.length <= 0) { return []; }
        const result: DapDocumentChange[] = [];

        sectionsDiff.forEach(sectionDiff => {
            if (sectionDiff.onlyInDoc1 === true || sectionDiff.onlyInDoc2 === true) {
                const change = new DapDocumentChange();
                change.item = `Section ${sectionDiff.name}`;
                change.isCritical = sectionDiff.isCritical;
                change.scope = DapDocumentChangeScope.Document;
                change.itemType = DapDocumentChangeType.Section;
                change.action = sectionDiff.onlyInDoc1 ? DapDocumentChangeAction.Insert : DapDocumentChangeAction.Delete;
                result.push(change);
            } else {
                const parentItem = `in Section ${sectionDiff.name}`
                result.push(...this.extractFieldChanges(sectionDiff.fieldsDiff, DapDocumentChangeScope.Section, DapDocumentChangeType.Field,
                    parentItem, locale));
                result.push(...this.extractTableChanges(sectionDiff.tablesDiff, DapDocumentChangeScope.Section, parentItem, locale));
                result.push(...this.extractDapChanges(sectionDiff.dapsDiff, parentItem, locale));
            }
        });

        return result;
    }

    private static extractDapChanges(dapsDiff: DapDocContainerDiff[], parentItem: string, locale: any): DapDocumentChange[] {
        if (dapsDiff == null || dapsDiff.length <= 0) { return []; }
        const result: DapDocumentChange[] = [];

        dapsDiff.forEach(dapDiff => {
            if (dapDiff.onlyInDoc1 === true || dapDiff.onlyInDoc2 === true) {
                const change = new DapDocumentChange();
                change.item = dapDiff.name;
                change.item = `DAP ${dapDiff.name} ${parentItem}`;
                change.isCritical = dapDiff.isCritical;
                change.scope = DapDocumentChangeScope.Section;
                change.itemType = DapDocumentChangeType.Dap;
                change.action = dapDiff.onlyInDoc1 ? DapDocumentChangeAction.Insert : DapDocumentChangeAction.Delete;
                result.push(change);
            } else {
                const dapParentItem = `in DAP ${dapDiff.name} ${parentItem}`;
                result.push(...this.extractFieldChanges(dapDiff.fieldsDiff, DapDocumentChangeScope.Dap, DapDocumentChangeType.Field,
                    dapParentItem, locale));
                result.push(...this.extractTableChanges(dapDiff.tablesDiff, DapDocumentChangeScope.Dap, dapParentItem, locale));
            }
        });

        return result;
    }

    // endregion Extract changes
}
