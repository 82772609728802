import { AlertStateStatus } from './alert-state-status';
import { IndicatorStatus } from '../indicator-status';
import { ProblemTimeData } from '../problem-time-data';

export class AlertDetails extends ProblemTimeData {
    public id: number;
    public state: AlertStateStatus;
    public ruleName: string; // rule of the top level issue
    public subjectName: string;  // subject name of the top level issue
    public severity: number;
    /** The Issue which caused this Alert instance to be created */
    public firstIssueStartTime: Date;
    /** The most-recently added Issue */
    public lastIssueEndTime: Date;
    public acknowledgedTime: Date;
    public acknowledgedBy: string;
    public acknowledgementComment: string;
    public resolvedTime: Date;
    public resolvedBy: string;
    public resolvedComment: string;
    public closedTime: Date;
    public closedBy: string;
    public closedComment: string;
    public targetId: number;
    public targetName: string;
    public wellId: string;
    public wellName: string;
    public wellBoreId: string;
    public wellBoreName: string;
    public sectionId: number;
    public sectionName: string;
    public sectionStatusName: string;
    /** The descriptive cause for the Alert - generated by the Root Cause Analyser. */
    public cause: string;
    public rigLocalTime: Date;
    public issues: AlertIssue[];
}

/** an issue associated with an alert (by alertId) */
export class AlertIssue extends ProblemTimeData {
    public id: number;
    public alertId: number;

    public startTime: Date;
    public startTimeTicks: string;
    public endTime?: Date;
    public startTimeIndex?: Date;
    public endTimeIndex?: Date;
    public startDepthIndex?: number;
    public startDepthIndexUom?: string;
    public endDepthIndex?: number;
    public endDepthIndexUom?: string;

    public status: IndicatorStatus;
    public reasonCode: number;
    public reasonCodeDescription: string;
    public comment: string;
    public ruleName: string;
    public subjectType: string;
    public subjectName: string;
    public problemTime: boolean;
}
