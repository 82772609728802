import { CommonModule} from '@angular/common';
import { NgModule } from '@angular/core';

import { NgxFooterComponent } from './ngx-footer.component';

@NgModule({
  imports: [ CommonModule ],
  exports: [ NgxFooterComponent ],
  declarations: [ NgxFooterComponent ]
})
export class NgxFooterModule {}
