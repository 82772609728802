

<div class="row">
    <div *ngFor="let stateGroup of stateGroups" class="col-6">
        <div class="card d-flex">
            <div class="card-header card-header-small">{{stateGroup.name}}</div>
            <div class="card-body p-3">
                <app-state-details-group [stateGroup]="stateGroup"></app-state-details-group>
            </div>
        </div>
    </div>
</div>
    
<div class="row" *ngFor="let stateTable of stateDetails?.tables">
    <div class="col-12">
        <div class="card d-flex">
            <div class="card-header card-header-small">{{stateTable.name}}</div>
            <div class="card-body" style="padding: 0;">
                <app-state-details-chart *ngIf="stateTable.visualise.visualisationType === enums.tableType.Chart"
                    [stateTable]="stateTable" [isOnScreen]="isOnScreen">
                </app-state-details-chart>
                <app-state-details-table *ngIf="stateTable.visualise.visualisationType === enums.tableType.Table"
                    [stateTable]="stateTable" [isOnScreen]="isOnScreen">
                </app-state-details-table>
            </div>
        </div>
    </div>

</div>