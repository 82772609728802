import { Component, OnDestroy, ViewChild, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Subject, Observable } from 'rxjs';
import { filter, tap, takeUntil, skip } from 'rxjs/operators';
import { defineLocale } from 'ngx-bootstrap/chronos';
import * as locales from 'ngx-bootstrap/locale';

import { environment } from 'environments/environment';
import { NgxAlertService } from 'ngx-shared';

import { User } from '@/_models';
import { NavItem, navigationMenuItems } from '@/configuration/nav-config';
import { AuthorizationService, CodeVersionService, AuthenticationService, CurrentUserService } from './_services';
import { ModalDialogComponent, NGX_BOOTSTRAP_LOCALE_ID } from './_helpers';
import { AppStore, FetchSystemConfigurationAction, FetchPublicSystemConfigurationAction } from '@state';
import { registerLocaleData } from '@angular/common';
import { ConfigurationService } from './_services/configuration.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild('logoutWarning') public logoutWarning: ModalDialogComponent;

    public navItems$: Observable<NavItem[]>;

    public user: User;

    public get isLogged() { return this.user != null; }

    public isProduction = environment.production;

    private destroy$ = new Subject();

    constructor(
        @Inject(LOCALE_ID) private readonly locale: string,
        @Inject(NGX_BOOTSTRAP_LOCALE_ID) private readonly ngxLocale: string,
        private authService: AuthenticationService,
        alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly store: AppStore,
        authorizationService: AuthorizationService,
        userService: CurrentUserService,
        public serverVersionService: CodeVersionService,
        private readonly configService: ConfigurationService
    ) {
        this.log(`Dr.Q Web ${environment.version}`);

        authService.completeAuthentication();

        serverVersionService.codeVersion$.pipe(
            takeUntil(this.destroy$),
            filter(x => x != null),
            tap(x => this.log(`Deployment Versions: ${x}`))
        ).subscribe();

        userService.currentUser
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: user => {
                    this.user = user as User;
                    if (this.user && authService.isAuthenticated) {
                        this.store.dispatch(new FetchSystemConfigurationAction());
                    } else {
                        this.store.dispatch(new FetchPublicSystemConfigurationAction());
                    }
                },
                error: err => logger.error(err)});

        this.setupAlertsAndNotifications(alertService, logger, userService);

        const filteredNavigationMenuItems = this.filterByFeatureFlag(navigationMenuItems);
        this.navItems$ = authorizationService.getAllowedNavigationItems(filteredNavigationMenuItems);
    }

    ngOnInit(): void {
        loadApplicationLocaleResources(this.locale);
        loadNgxBootstrapLocaleResources(this.ngxLocale);
    }

    ngOnDestroy(): void {
        this.authService.unsubscribe();
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    private log(msg: string) {
        if (this.isProduction) {
            // tslint:disable-next-line: no-console
            console.info(msg);
        }
        this.logger.info(msg);
    }

    private setupAlertsAndNotifications(alertService: NgxAlertService, logger: NGXLogger, userService: CurrentUserService) {
        alertService.getMessage().pipe(
            takeUntil(this.destroy$),
            filter(x => x != null), // skip the first null message
            tap(x => logger.debug('Alert message: ', x))
        ).subscribe();

        /** For demonstration purposes only!**/
        if (!environment.production) {
            userService.currentUser.pipe(
                takeUntil(this.destroy$),
                skip(1), // skip initial value
                filter(x => x != null),
                tap(x => alertService.success((x as User).firstName + ' successfully logged in.'))
            ).subscribe();

            userService.currentUser.pipe(
                takeUntil(this.destroy$),
                skip(1), // skip initial value
                filter(x => x == null),
                tap(() => alertService.info('You successfully logged out.')),
                tap(x => logger.debug('Alert message: ', x))
            ).subscribe();
        }
    }

    private filterByFeatureFlag(navItems: NavItem[]) {
        const filteredNavs = navItems.filter(x => x.disabledByFeatureFlag
            ? this.configService.getSettings(x.disabledByFeatureFlag) === false : true);

        filteredNavs.forEach(n => {
            if (n?.children?.length) n.children = this.filterByFeatureFlag(n.children);
        });

        return filteredNavs;
    }
}

function loadApplicationLocaleResources(locale: string) {
    // https://github.com/angular/angular/issues/28794
    // "There's no en-US as it's the default locale for en, and en.js is dedicated for US locale."    
    const defaultLocale = 'en-US';
    const angularDefaultLocale = 'en';

    let fileName: string;

    if (locale === defaultLocale) {
        fileName = angularDefaultLocale;
    } else {
        const [parent, child] = locale.toLowerCase().split('-');
        fileName = parent === child ? parent : locale;
    }

    import(
        // https://webpack.js.org/api/module-methods/
        // A regular expression that will be matched against during import resolution. Only modules that match will be bundled
        /* webpackInclude: /.+\.mjs$/ */
        // Generates a lazy-loadable chunk for each import()ed module.
        /* webpackMode: "lazy-once" */
        // use this template to generate each chunk name, i.e "locale-123.js"
        /* webpackChunkName: "angular-locales" */
        `node_modules/@angular/common/locales/${fileName}`
    )
        .then(x => registerLocaleData(x.default, locale))
        .catch(err => console.error('Something went wrong during loading of locale resources: ', err));
}

function loadNgxBootstrapLocaleResources(ngxLocale: string) {
    for (const locale in locales) {
        if (locales[locale].abbr === ngxLocale) {
            defineLocale(ngxLocale, locales[locale]);
            break;
        }
    }
}
