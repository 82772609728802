export class KpiSummary {

    public rangeType: string;
    public service: string;
    public startTime: Date;
    public endTime: Date;
    public hours: number;
    public colCount: number;
    public rowCount: number;
    public redBlockCount: number;
    public kpi: number;
}

export class DapKpiSummary {

    public rangeType: string;
    public wellKnownServiceName: string;
    public tool: string;
    public class: string;
    public startTime: Date;
    public endTime: Date;
    public hours: number;
    public colCount: number;
    public rowCount: number;
    public redBlockCount: number;
    public kpi: number;
}

