import { environment } from 'environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { User, Role } from '@/_models';
import { AppStore, FetchRolesAction } from '@state';
import { AuthenticationService, CurrentUserService } from '@/_services';

@Component({
    selector: 'app-user-dropdown',
    templateUrl: './user-dropdown.component.html',
    styleUrls: ['./user-dropdown.component.css']
})
export class UserDropdownComponent implements OnInit, OnDestroy {
    public operatorDocumentationLink$ = this.store.select('adminState', 'userDocumentationUrl');
    public userGuide = environment.wikiURL + environment.wikiUserGuide;
    public releaseNotesLink = environment.wikiURL + environment.wikiReleaseNotes;
    public helpDesk = environment.wikiURL + environment.wikiHelpDesk;

    public user: User;

    public get isLogged() {
        return this.user != null;
    }

    private destroy$ = new Subject();
    private roles: Role[];

    constructor(
        private authService: AuthenticationService,
        private userService: CurrentUserService,
        private readonly store: AppStore
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    ngOnInit() {
        this.userService.currentUser
            .pipe(
                takeUntil(this.destroy$),
                tap(x => {
                    // get roles only when we have user(logged in)
                    if (x != null && this.authService.isAuthenticated) {
                        this.user = x as User;
                        this.store.dispatch(new FetchRolesAction());
                    }
                })
            ).subscribe();
        this.store.select('roleState', 'roles')
            .pipe(takeUntil(this.destroy$))
            .subscribe(x => this.roles = x);
    }

    onLogout() {
        this.authService.logout();
    }

    getRoles(): string {
        return this.user && this.user.roles && this.roles
            && this.user.roles.map(x => this.roles.find(r => r.id === x).roleName).join(', ');
    }
}
