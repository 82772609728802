import { DapBreachType } from './dap-document-status-data';

export enum DapScoreType {
    Delivery,
    Quality
}

export enum DapScoreTargetType {
    Dap,
    Category
}

export class DapScoreChange {
    public id: number;
    public target: DapScoreTargetType;
    public type: DapScoreType;
    public score: number | null;
    public signedOff: boolean;
    public signedOffComment: string;
}

export class DapBreachChange {
    public id: number;
    public dapId: number;
    public type: DapBreachType;
    public value: number;
    public description: string;
    public deleted: boolean;
    public signedOff: boolean;
    public signedOffComment: string;
}

export class DapScoreChanges {
    public dapDocumentId: number;
    public scores: DapScoreChange[];
    public breaches: DapBreachChange[];
    public preview: boolean;
}
