import { DapDocumentStatus } from '@/_models/dap-common';
import { DapDocumentContact, DapTemplateContent } from '@/_models/dap-document-details';
import { DapDataContainerVM } from './dap-data-container-vm';
import { DapFieldVM } from './dap-field-vm';
import { DapSectionVM } from './dap-section-vm';
import { DapTableVM } from './dap-table-vm';

export class DapDocumentContextKeys {
    public static document = 'doc';
    public static section = 'sec';
    public static dap = 'dap';

    public static mapContextKey(key: string): string {
        switch(key) {
            case DapDocumentContextKeys.document:
                return 'document';
            case DapDocumentContextKeys.section:
                return 'section';
            case DapDocumentContextKeys.dap:
                return 'dap';
            default:
                return undefined;
        }
    }
}

export class DapDocumentVM extends DapDataContainerVM {
    public title: string;
    public status: DapDocumentStatus;
    public templateContent: DapTemplateContent;
    public sectionVMs: DapSectionVM[] = [];
    public contacts: DapDocumentContact[] = [];
    public businessState: string;
    public adGroups: string[] = [];

    public getTableVM(context: string, key: string, sectionIndex: number = null, dapIndex: number = null): DapTableVM {
        let foundVMs: DapTableVM[] = null;
        switch (context) {
            case DapDocumentContextKeys.document:
                foundVMs = this.getTableVMs();
                break;
            case DapDocumentContextKeys.section:
                foundVMs = this.sectionVMs[sectionIndex]?.getTableVMs();
                break;
            case DapDocumentContextKeys.dap:
                foundVMs = this.sectionVMs[sectionIndex]?.dapVMs[dapIndex]?.getTableVMs();
                break;
            default:
                foundVMs = null;
        }

        return foundVMs?.find(x => x.templateContent.key === key);
    }

    public getFieldVM(context: string, key: string, sectionIndex: number, dapIndex: number): DapFieldVM {
        let foundVMs: DapFieldVM[] = null;
        switch (context) {
            case DapDocumentContextKeys.document:
                foundVMs = this.getFieldVMs();
                break;
            case DapDocumentContextKeys.section:
                foundVMs = this.sectionVMs[sectionIndex]?.getFieldVMs();
                break;
            case DapDocumentContextKeys.dap:
                foundVMs = this.sectionVMs[sectionIndex]?.dapVMs[dapIndex]?.getFieldVMs();
                break;
            default:
                foundVMs = null;
        }

        return foundVMs?.find(x => x.templateContent.key === key);
    }
}
