import { RigState } from '../rig-state';
import { SectionStatus } from './section-status';
export class MonitorOverview {
    public targetId: number;
    public wellId: string;
    public wellBoreId: string;
    public wellBoreName: string;
    public orderId: number;
    public sectionName: string;
    public sectionId: number;
    public sectionType: string;
    public status: SectionStatus;
    public rigOrInstallationId: number;
    public rigName: string;
    public partnerName: string;
    public fieldName: string;
    public mwdProvider: string;
    public mlProvider: string;
    public isTightWell: boolean;
    public startDepth: number;
    public startDepthUoM: string;
    public targetDepth: number;
    public targetDepthUoM: string;
    public startDate: Date;
    public rigState: RigState;
    public rigStateTimeIndex: Date;
    public rigLocalTime: Date;
    public holeDepth: number;
    public holeDepthUom: string;
    public holeDepthTimeIndex: Date;
    public bitDepth: number;
    public bitDepthUom: string;
    public bitDepthTimeIndex: Date;
}

export class MonitorOverviewUpdate {
    public sectionId: number;
    public newState: SectionStatus;
}
