export class AlertFilterLists {
    constructor(
        public orders: FilterList[],
        public sections: FilterList[],
        public targets: FilterList[]
    ) {}
}

export class FilterList {
    constructor(
        public id: string,
        public name: string,
        public active: boolean,
        public parentId?: string
    ) {}
}
