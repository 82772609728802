import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, NgModule, OnChanges, SimpleChanges, ViewChild,
    ChangeDetectorRef, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerComponent, TimepickerModule} from 'ngx-bootstrap/timepicker';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { NGX_BOOTSTRAP_LOCALE_ID } from '../ngx-bootstrap-locale-token';
import { Observable, Subscription, fromEvent } from 'rxjs';

@Component({
    selector: 'app-datetime-picker',
    template: `
<div class="w-100">
    <input class="form-control flex-grow-1"
        [attr.id]="labelForId"
        bsDatepicker
        [bsConfig]="bsConfig"
        type="text"
        [attr.placeholder]="placeholder"
        [disabled]="isDisabled"
        [class.is-invalid]="isInvalid"
        [(ngModel)]="date"
        (ngModelChange)="onDateChange($event)"
        (click)="onTouched()"
        (onShown)="onDatePickerShown()"
        (onHidden)="onDatePickerHidden()"
    />
</div>
<timepicker *ngIf="showTime" class="ml-2 text-nowrap"
    #timepicker
    [showMeridian]="false"
    [showSpinners]="false"
    [disabled]="isDisabled"
    [showMinutes]="showMinutes"
    [showSeconds]="showSeconds"
    [ngClass]="{ 'is-invalid': isInvalid }"
    (click)="onTouched()"
    [(ngModel)]="time"
    (ngModelChange)="onTimeChange($event)"
></timepicker>
<!-- TODO: complex to overcome; impl if someone complains or remove if not needed -->
<!--<span *ngIf="time" class="position-absolute hour-label">h</span>
<span *ngIf="time" class="position-absolute minute-label">m</span>-->
`,
    styles: [`
:host {
    display: flex;
    align-items: center;
    position: relative;
}

:host > timepicker ::ng-deep input.bs-timepicker-field {
    background-image: none;
    padding-right: 12px;
}

.hour-label {
    right: 73px;
}

.minute-label {
    right: 5px;
}
`],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatetimePickerComponent),
            multi: true
        }
    ]
})
export class DatetimePickerComponent implements ControlValueAccessor, OnChanges, OnDestroy {
    @Input() public isInvalid = false;
    @Input() public placeholder: string = null;
    @Input() public dateTheme = 'theme-default';
    @Input() public labelForId: string = null;
    @Input() public showTime = true;
    @Input() public showMinutes = true;
    @Input() public showSeconds = false;
    @Output() selectedDateChange = new EventEmitter<Date>();

    public date: Date;
    public time: Date;
    public isDisabled = false;
    public bsConfig = { containerClass: this.dateTheme };

    @ViewChild('timepicker') private timepicker: TimepickerComponent;
    private skipOnChangeNotification = false;

    private dateTimePickerFooterHtml = `<div class="bs-datepicker-head date-picker-footer"><button id="dpTodayButton" class="btn dpCustomButton current"><span>Today</span></button></div>`;
    private customButtonsSubscription : Subscription;
    private customButtons$: Observable<Event>;
  
    constructor(private readonly cd: ChangeDetectorRef, @Inject(NGX_BOOTSTRAP_LOCALE_ID) ngxLocale, bsLocaleService: BsLocaleService) {
        bsLocaleService.use(ngxLocale);
    }

    ngOnDestroy(){
        if(this.customButtonsSubscription){
            this.customButtonsSubscription.unsubscribe();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.isInvalid) {
            this.setTimepickerValidState();
        }

        if (changes.dateTheme) {
            this.bsConfig.containerClass = this.dateTheme;
        }
    }

    public onDateChange(date: Date) {
        if (date) {
            if (!this.showSeconds) {
                date.setSeconds(0);
            }
            if (!this.showTime) {
                date.setHours(0, 0, 0, 0);
                this.date = date;
                this.time = this.date;
            } else if (this.time) {
                this.time.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
                date.setHours(this.time.getHours(), this.time.getMinutes(), this.time.getSeconds());
            } else {
                this.time = date;
            }
        } else {
            this.time = null;
        }

        this.setTimepickerValidState();

        this.onChange(this.date && new Date(this.date));
    }

    public onTimeChange(time: Date) {
        if (time) {
            if (!this.showSeconds) {
                time.setSeconds(0);
            }
            if (this.date) {
                this.date.setHours(time.getHours(), time.getMinutes(), time.getSeconds());
            } else {
                this.date = time;
            }
        } else {
            this.date = null;
        }

        this.setTimepickerValidState();

        if (this.skipOnChangeNotification) {
            this.skipOnChangeNotification = false;
        } else {
            this.onChange(this.date && new Date(this.date));
        }
    }

    public onChange = (value: Date) => { this.selectedDateChange.next(value); };
    public onTouched = () => { }; // eslint-disable-line @typescript-eslint/no-empty-function

    //#region ControlValueAccessor methods

    public writeValue(value: Date): void {
        value = (typeof value === 'string') ? new Date(value) : value;
        this.date = value;
        if (value) {
            this.skipOnChangeNotification = !this.time || this.time !== value;
        } else {
            this.skipOnChangeNotification = !!this.time;
        }

        this.time = value;
    }
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public onDatePickerShown() {
    
        const d1 = document.querySelector('.bs-datepicker-container')
        d1.insertAdjacentHTML('beforeend', this.dateTimePickerFooterHtml);
    
        const dpTodayButton = document.getElementById('dpTodayButton');
        this.customButtons$ = fromEvent(dpTodayButton, 'click')
    
        if(this.customButtonsSubscription){
            this.customButtonsSubscription.unsubscribe();
        }
        this.customButtonsSubscription = this.customButtons$.subscribe( ()=> {
            this.date = new Date();
            this.onDateChange(this.date);
        });
    }

    public onDatePickerHidden() {
        if(this.customButtonsSubscription){
            this.customButtonsSubscription.unsubscribe();
        }
    }

    //#endregion

    private setTimepickerValidState() {
        if (!this.timepicker) { return; }

        this.timepicker.invalidHours = this.timepicker.invalidMinutes = this.timepicker.invalidSeconds = !!this.isInvalid;
        this.cd.detectChanges();
    }
}

@NgModule({
    declarations: [DatetimePickerComponent],
    exports: [DatetimePickerComponent],
    imports: [
        CommonModule,
        FormsModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
    ]
})
export class DatetimePickerModule { }
