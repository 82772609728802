import { Action } from '@ngrx/store';
import { ApplianceJobVM, ApplianceServiceVM } from '@/_models/appliance-common';
import { ActiveSectionVM } from '@/_models';
import { ApplianceFileVM } from '@/_models/appliance-common/appliance-file-vm';
import { ApplianceFileDetailsVM } from '@/_models/appliance-common/appliance-file-details-vm';

export class LoadAllActiveSectionsAction implements Action {
    readonly type = '[TransferManagement] InState Load All Active Sections action';

    constructor(public readonly activeSections: ActiveSectionVM[]) { }
}

export class LoadApplianceJobsAction implements Action {
    readonly type = '[TransferManagement] InState Load Appliance Jobs action';

    constructor(public readonly jobs?: ApplianceJobVM[]) { }
}

export class LoadApplianceServicesAction implements Action {
    readonly type = '[TransferManagement] InState Load Appliance Services action';

    constructor(public readonly services?: ApplianceServiceVM[]) { }
}

export class LoadTransferFilesAction implements Action {
    readonly type = '[TransferManagement] InState Load Transfer Files action';

    constructor(public readonly files?: ApplianceFileVM[]) { }
}

export class LoadTransferFileDetailsAction implements Action {
    readonly type = '[TransferManagement] InState Load Transfer File Details action';

    constructor(public readonly fileDetails: ApplianceFileDetailsVM[]) { }
}

export class ClearTransferManagementDataAction implements Action {
    public type = '[TransferManagement] Clear Appliance Jobs and Services action';
}

//#region fetch

export class FetchAllActiveSectionsAction implements Action {
    public type = '[TransferManagement] FetchAllActiveSections action';
}
export class FetchAllActiveSectionsOkAction implements Action {
    public type = '[TransferManagement] FetchAllActiveSections Ok action';
}
export class FetchAllActiveSectionsFailedAction implements Action {
    public type = '[TransferManagement] FetchAllActiveSections Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchApplianceJobsAction implements Action {
    public type = '[TransferManagement] FetchApplianceJobs action';
    constructor(public readonly slot: number) { }
}
export class FetchApplianceJobsOkAction implements Action {
    public type = '[TransferManagement] FetchApplianceJobs Ok action';
}
export class FetchApplianceJobsFailedAction implements Action {
    public type = '[TransferManagement] FetchApplianceJobs Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchApplianceServicesAction implements Action {
    public type = '[TransferManagement] FetchApplianceServices action';
    constructor(public readonly slot: number) { }
}
export class FetchApplianceServicesOkAction implements Action {
    public type = '[TransferManagement] FetchApplianceServices Ok action';
}
export class FetchApplianceServicesFailedAction implements Action {
    public type = '[TransferManagement] FetchApplianceServices Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchTransferFilesAction implements Action {
    public type = '[TransferManagement] FetchTransferFiles action';
    constructor(public readonly slot: number) { }
}
export class FetchTransferFilesOkAction implements Action {
    public type = '[TransferManagement] FetchTransferFiles Ok action';
}
export class FetchTransferFilesFailedAction implements Action {
    public type = '[TransferManagement] FetchTransferFiles Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchTransferFileDetailsAction implements Action {
    public type = '[TransferManagement] FetchTransferFileDetails action';
    constructor(public readonly applianceId: number, public readonly fileNames: string[]) { }
}
export class FetchTransferFileDetailsOkAction implements Action {
    public type = '[TransferManagement] FetchTransferFileDetails Ok action';
}
export class FetchTransferFileDetailsFailedAction implements Action {
    public type = '[TransferManagement] FetchTransferFileDetails Failed action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion fetch

//#region Run service command

export class RunServiceCommandAction implements Action {
    public type = '[TransferManagement] RunServiceCommand action';
    constructor(public readonly slot: number, public readonly serviceId: string, public readonly commandName: string) { }
}
export class RunServiceCommandOkAction implements Action {
    public type = '[TransferManagement] RunServiceCommand Ok action';
}
export class RunServiceCommandFailedAction implements Action {
    public type = '[TransferManagement] RunServiceCommand Failed action';
    constructor(public readonly error: Error | string | any) { }
}
export class UpdateInStateApplianceServiceAction implements Action {
    readonly type = '[TransferManagement] InState Update Appliance Service action';

    constructor(public readonly service?: ApplianceServiceVM) { }
}

//#endregion Run service command
