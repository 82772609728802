export class WitsmlObject {
    public uid: string;
    public name: string;
    public isActive: boolean;
    public children: WitsmlObject[];
    public childrenLoaded: boolean;
}

export class WellboreData {
    public uid: string;
    public name: string;
    public wellUid: string;
    public wellName: string;
}
