import { RuleParameterCategory, RuleCategoryOrder } from '@/_models';

export function ruleCategoryComparator(stateName1: RuleParameterCategory, stateName2: RuleParameterCategory): number {
    const state1 = RuleCategoryOrder[stateName1];
    const state2 = RuleCategoryOrder[stateName2];

    if (state1 === undefined && state2 === undefined) {
        return 0;
    }
    if (state1 === undefined) {
        return -1;
    }
    if (state2 === undefined) {
        return 1;
    }
    return state1 - state2;
}
