import { Action } from '@ngrx/store';
import { MnemonicCatalog } from '@/_models';

export class LoadDapMnemonicCatalogAction implements Action {
    public type = '[Mnemonic] Load Dap MnemonicCatalog action';
    constructor(public readonly mnemonics: MnemonicCatalog[]) { }
}

export class FetchDapMnemonicCatalogAction implements Action {
    public type = '[Mnemonic] Fetch Dap MnemonicCatalog action';
    constructor(public readonly serviceProviderId?: number) { }
}

export class FetchOkDapMnemonicCatalogAction implements Action {
    public type = '[Mnemonic] Fetch OK Dap MnemonicCatalog action';
}

export class FetchFailedDapMnemonicCatalogAction implements Action {
    public type = '[Mnemonic] Fetch Cancel Dap MnemonicCatalog action';
    constructor(public readonly error: Error | string | any) { }
}
