export class DapSectionTypeScoringDefaults {
    public key: string;
    public includeInScoring: boolean;
    public weight: number;
}

export class DapTypeScoringDefaults
{
    public key: string;
    public includeInScoring: boolean;
    public weight: number;
    public deliveryWeight: number;
    public qualityWeight: number;
    public deliveryBreachLimit: number;
    public qualityBreachLimit: number;
    public serviceLevelAgreement: number;
}

export class DapScoringModelDefaults {
    public sectionTypeDefaults: DapSectionTypeScoringDefaults[];
    public dapTypeDefaults: DapTypeScoringDefaults[];
}
