import { IndicatorStatus } from '../indicator-status';

export class OverviewStatuses {
    public wellName: string;
    public wellBoreName: string;
    public sectionName: string;
    public sectionId: number;

    public statuses: OverviewStatus[];
}

export class OverviewStatus {
    public name: string;
    public status: IndicatorStatus;
}
