import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PublicPart } from 'ngx-shared';
import { User } from '@/_models';
import { NavItem } from '@/configuration/nav-config';
import { CurrentUserService } from './current-user.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
    constructor(private readonly userService: CurrentUserService) { }

    public getAllowedNavigationItems(navItems: NavItem[]) {
        return this.userService.currentUser.pipe(
            map(x => x as User),
            map(user => extractAllowedNavItems(user, navItems))
        );
    }
}

function extractAllowedNavItems(user: User, navItems: NavItem[]) {
    let resultNavItems: NavItem[] = [];

    if (user) {
        const userActions = new Set(user.actions && user.actions.map(x => x.name));
        resultNavItems = filterNavItem(userActions, navItems);
    }

    return resultNavItems;
}

function filterNavItem(userActions: Set<string>, navItems: NavItem[]) {
    const resultNavItems = navItems.filter(x =>
        !Array.isArray(x.restrictToActions)
        || !x.restrictToActions.length
        || x.restrictToActions.some(r => userActions.has(r)));

    resultNavItems.forEach((navItem, index) => {
        if (Array.isArray(navItem.children)) {
            resultNavItems[index] = { ...navItem, children: filterNavItem(userActions, navItem.children) };
        }
    });

    return resultNavItems;
}

/* eslint-disable */
export class FakeAuthorizationService implements PublicPart<AuthorizationService> {
    getAllowedNavigationItems(navItems: NavItem[]): Observable<NavItem[]> {
        throw new Error('Method not implemented.');
    }
}

export const fakeAuthorizationServiceProvider = { provide: AuthorizationService, useClass: FakeAuthorizationService };
/* eslint-enable */
