import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';

import { ofAction } from '../ngrx-actions/of-action';
import { NGXLogger } from 'ngx-logger';
import { of, from } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import {
FetchAppliancesAction, LoadInStateAppliancesAction, FetchOkAppliancesAction, FetchFailedAppliancesAction, AddApplianceAction,
CreateInStateApplianceAction, DeleteInStateApplianceAction, CancelAddApplianceAction, CreateApplianceAction, CreateOkApplianceAction,
SetSelectedApplianceAction, CreateFailedApplianceAction, UpdateApplianceAction, UpdateInStateApplianceAction, UpdateOkApplianceAction,
UpdateFailedApplianceAction, ResetApplianceInStateAction, DeleteApplianceAction, DeleteOkApplianceAction, DeleteFailedApplianceAction,
FetchApplianceSlotsCountAction, LoadInStateAppliancesSlotsCountAction, FetchOkAppliancesSlotsCountAction,
FetchFailedAppliancesSlotsCountAction,
TestApplianceAction,
SetApplianceTestStatusAction,
TestOkApplianceAction,
TestFailedApplianceAction,
} from './appliance.actions';
import { ApplianceService } from '@/_services/appliance.service';
import { ApplianceTestStatus } from './appliance.state';

@Injectable()
export class ApplianceEffects {

    
    public onServerFetchAppliances$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchAppliancesAction),
        switchMap(() => this.applianceService.getAll().pipe(
            switchMap(x => [new LoadInStateAppliancesAction(x), new FetchOkAppliancesAction()]),
            catchError(error => {
                this.logger.error('Error while Fetch of Appliances ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedAppliancesAction(error));
            })
        ))
    ));

    
    public onServerFetchAppliancesSlotsCount$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchApplianceSlotsCountAction),
        switchMap(() => this.applianceService.getSlotsCount().pipe(
            switchMap(x => [new LoadInStateAppliancesSlotsCountAction(x), new FetchOkAppliancesSlotsCountAction()]),
            catchError(error => {
                this.logger.error('Error while Fetch of Appliances slots count', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedAppliancesSlotsCountAction(error));
            })
        ))
    ));

    
    public onAddAppliance$ = createEffect(() => this.actions$.pipe(
        ofAction(AddApplianceAction),
        map(x => new CreateInStateApplianceAction(x.appliance))
    ));

    
    public onCancelAddAppliance$ = createEffect(() => this.actions$.pipe(
        ofAction(CancelAddApplianceAction),
        map(x => new DeleteInStateApplianceAction(x.applianceId))
    ));

    
    public onServerCreateAppliance$ = createEffect(() => this.actions$.pipe(
        ofAction(CreateApplianceAction),
        switchMap(({ appliance }) => this.applianceService.create(appliance).pipe(
            switchMap(x => [
                new CreateInStateApplianceAction(x),
                new CreateOkApplianceAction(),
                new SetSelectedApplianceAction(x.id),
                new CancelAddApplianceAction(appliance.id)
            ]),
            catchError(error => {
                this.logger.error('Error while Create of Appliance', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CreateFailedApplianceAction(error));
            })
        ))
    ));

    
    public onServerUpdateAppliance$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateApplianceAction),
        switchMap(({ appliance }) => this.applianceService.update(appliance).pipe(
            switchMap(x => [new UpdateInStateApplianceAction(x), new UpdateOkApplianceAction()]),
            catchError(error => {
                this.logger.error('Error while Update of Appliance', error);
                this.alertService.error(getMessageFromError(error));
                return from([new UpdateFailedApplianceAction(error), new ResetApplianceInStateAction(appliance.id)]);
            })
        ))
    ));

    
    public onServerDeleteAppliance$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteApplianceAction),
        switchMap(({ applianceId }) => this.applianceService.delete(applianceId).pipe(
            map(() => applianceId),
            switchMap(x => [new DeleteInStateApplianceAction(x), new DeleteOkApplianceAction()]),
            catchError(error => {
                this.logger.error('Error while Delete of Appliance', error);
                this.alertService.error(getMessageFromError(error));
                return of(new DeleteFailedApplianceAction(error));
            })
        ))
    ));

    
    public onServerTestAppliance$ = createEffect(() => this.actions$.pipe(
        ofAction(TestApplianceAction),
        switchMap(x => this.applianceService.checkApplianceConnection(x.appliance).pipe(
            switchMap(y => [
                new SetApplianceTestStatusAction(y.isAvailable ? ApplianceTestStatus.Ok : ApplianceTestStatus.Fail, y.message),
                new TestOkApplianceAction()
            ]),
            catchError(error => {
                this.logger.error('Could not Test Appliance', error);
                this.alertService.error(getMessageFromError(error));
                return from([
                    new TestFailedApplianceAction(error),
                    new SetApplianceTestStatusAction(ApplianceTestStatus.Fail, 'Could not Test Appliance')
                ]);
            })
        ))
    ));

    
    public onServerOk$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteOkApplianceAction, CreateOkApplianceAction, UpdateOkApplianceAction),
        tap(() => this.alertService.success('Action completed.'))
    ), { dispatch: false });

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly applianceService: ApplianceService
    ) { }
}
