import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { catchError, switchMap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { of } from 'rxjs';
import { DapDocumentManagementService } from '@/_services/dap-document-management.service';
import { FetchDapStatusFilterDataAction, FetchDapStatusFilterDataFailedAction, FetchDapStatusFilterDataOkAction,
    FetchDapDocumentsAction, FetchDapDocumentScoreDiagramsAction, FetchDapDocumentScoreDiagramsFailedAction,
    FetchDapDocumentScoreDiagramsOkAction, FetchDapDocumentsFailedAction, FetchDapDocumentsOkAction,
    LoadDapStatusFilterDataAction, LoadDapDocumentsAction, MarkDapDocumentsAsDiagramLoadingAction,
    ToggleDapDocumentFavouriteAction, ToggleDapDocumentFavouriteInStoreAction, ToggleDapDocumentFavouriteOkAction,
    ToggleDapDocumentFavouriteFailedAction } from './dap-status-management.actions';

@Injectable()
export class DapStatusManagementEffects {

    
    public onFetchDapStatusFilterData$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapStatusFilterDataAction),
        switchMap(() => this.dapDocumentManagementService.getStatusFilterData().pipe(
            switchMap(x => [new LoadDapStatusFilterDataAction(x), new FetchDapStatusFilterDataOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchDapDocumentFilterData ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapStatusFilterDataFailedAction(error));
            })
        ))
    ));

    
    public onFetchDapDocuments$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapDocumentsAction),
        switchMap((a) => this.dapDocumentManagementService.getDocuments(a.filter).pipe(
            switchMap(x => [new LoadDapDocumentsAction(x), new FetchDapDocumentsOkAction(),
                new MarkDapDocumentsAsDiagramLoadingAction(x), new FetchDapDocumentScoreDiagramsAction(x)]),
            catchError(error => {
                this.logger.error('Error in FetchDapDocuments ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapDocumentsFailedAction(error));
            })
        ))
    ));

    
    public onFetchDapDocumentScoreDiagrams$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapDocumentScoreDiagramsAction),
        switchMap((a) => this.dapDocumentManagementService.getScoreDiagrams(a.documents).pipe(
            switchMap(x => [new LoadDapDocumentsAction(x), new FetchDapDocumentScoreDiagramsOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchDapDocumentScoreDiagrams ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapDocumentScoreDiagramsFailedAction(error));
            })
        ))
    ));

    
    public onToggleDapDocumentFavourite$ = createEffect(() => this.actions$.pipe(
        ofAction(ToggleDapDocumentFavouriteAction),
        switchMap((a) => this.dapDocumentManagementService.toggleFavouriteStatus(a.dapHeaderId).pipe(
            switchMap(x => [new ToggleDapDocumentFavouriteInStoreAction(a.dapHeaderId, x), new ToggleDapDocumentFavouriteOkAction()]),
            catchError(error => {
                this.logger.error('Error in ToggleDapDocumentFavourite ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new ToggleDapDocumentFavouriteFailedAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly dapDocumentManagementService: DapDocumentManagementService
    ) { }
}
