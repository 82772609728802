import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { TooltipService } from '@/_services';
import {
    LoadTooltipsAction,
    FetchTooltipsAction,
    FetchFailedTooltipsAction,
    FetchOkTooltipsAction,
 } from './tooltip.actions';

@Injectable()
export class TooltipEffects {

    
    public onFetch$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchTooltipsAction),
        switchMap(tooltip => this.tooltipService.get(tooltip.key).pipe(
            switchMap(results => [new LoadTooltipsAction(results), new FetchOkTooltipsAction()]),
            catchError(error => {
                this.logger.error('Error while getting specific tooltips', error);
                return of(new FetchFailedTooltipsAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly logger: NGXLogger,
        private readonly tooltipService: TooltipService
    ) { }
}
