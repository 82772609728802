import { Store, Action } from '../ngrx-actions';
import { LoadInStateSysParametersAction } from './system-parameters.actions';
import { SystemParametersState } from './system-parameters.state';

@Store({ parameters: { kpiTimePeriodInSeconds: 300 } } as SystemParametersState)
export class SystemParametersStore {

    @Action(LoadInStateSysParametersAction)
    public loadInStateSysParameters(state: SystemParametersState, { parameters }: LoadInStateSysParametersAction) {
        return { ...state, parameters: parameters } as SystemParametersState;
    }
}
