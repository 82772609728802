import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { ModalDialogModule } from '../modal-dialog/modal-dialog.component';
import { RuleInstanceIssueDetailsComponent } from './rule-instance-issue-details.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PeriodSelectorModule } from '../period-selector/period-selector.component';
import { SuppressionTabComponent } from './suppression-tab.component';
import { StateDetailsModule } from '../state-details/state-details.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [RuleInstanceIssueDetailsComponent, SuppressionTabComponent],
  exports: [RuleInstanceIssueDetailsComponent, PaginationModule],
  imports: [
      ModalDialogModule,
      RouterModule,
      CommonModule,
      PaginationModule.forRoot(),
      TabsModule.forRoot(),
      TooltipModule.forRoot(),
      FormsModule,
      PeriodSelectorModule,
      StateDetailsModule,
    ],
})
export class RuleInstanceIssueDetailsModule { }
