import { PickListItem } from '@/_models';
import { Store, Action } from '../ngrx-actions';
import {
    LoadInStatePickListsAction, LoadInStatePickListItemsAction, CreateInStatePickListItemAction,
    UpdateInStatePickListItemAction, DeleteInStatePickListItemAction, UpdateInStatePickListItemsOrderAction,
    DeprecateInStatePickListItemAction
} from './pick-list.actions';
import { PickListState } from './pick-list.state';

@Store({} as PickListState)
export class PickListStore {

    @Action(LoadInStatePickListsAction)
    public loadPickLists(state: PickListState, { pickLists }: LoadInStatePickListsAction) {
        return { ...state, pickLists: pickLists } as PickListState;
    }

    @Action(LoadInStatePickListItemsAction)
    public loadPickListItems(state: PickListState, { pickListItems }: LoadInStatePickListItemsAction) {
        return { ...state, pickListItems: pickListItems } as PickListState;
    }

    @Action(CreateInStatePickListItemAction)
    public createPickListItem(state: PickListState, { pickListItem }: CreateInStatePickListItemAction) {
        if (!state.pickListItems) { // extract to contract check
            throw new Error('PickListItems state is not defined.');
        }
        if (!pickListItem) { // extract to contract check
            throw new Error('PickListItem is not defined.');
        }

        const newPickLists = [...state.pickListItems];
        newPickLists.push(pickListItem);
        return { ...state, pickListItems: newPickLists } as PickListState;
    }

    @Action(UpdateInStatePickListItemAction)
    public updatePickListItem(state: PickListState, { pickListItem }: UpdateInStatePickListItemAction) {
        if (!state.pickListItems) { // extract to contract check
            throw new Error('PickListItems state is not defined.');
        }
        if (!pickListItem) { // extract to contract check
            throw new Error('PickListItem is not defined.');
        }

        const index = state.pickListItems.findIndex(x => x.id === pickListItem.id);

        if (index < 0) {
            throw new Error(`PickListItem (id: ${pickListItem.id}) not found: `);
        }

        const newPickLists = [...state.pickListItems];
        newPickLists[index] = { ...pickListItem }  as PickListItem;
        return { ...state, pickListItems: newPickLists } as PickListState;
    }

    @Action(DeleteInStatePickListItemAction)
    public deletePickListItem(state: PickListState, { pickListItemId }: DeleteInStatePickListItemAction) {
        if (!state.pickListItems) { // extract to contract check
            throw new Error('PickListItems state is not defined.');
        }

        if (state.pickListItems.every(x => x.id !== pickListItemId)) {
            throw new Error(`PickListItem with id "${pickListItemId}" does not exist in the state.`);
        }

        const newPickLists = state.pickListItems.filter(x => x.id !== pickListItemId);
        return { ...state, pickListItems: newPickLists } as PickListState;
    }

    @Action(DeprecateInStatePickListItemAction)
    public deprecatePickListItem(state: PickListState, { pickListItemId }: DeprecateInStatePickListItemAction) {
        if (!state.pickListItems) {
            throw new Error('PickListItems state is not defined.');
        }

        if (state.pickListItems.every(x => x.id !== pickListItemId)) {
            throw new Error(`PickListItem with id "${pickListItemId}" does not exist in the state.`);
        }

        const newPickLists = state.pickListItems.filter(x => x.id !== pickListItemId);
        return { ...state, pickListItems: newPickLists } as PickListState;
    }

    @Action(UpdateInStatePickListItemsOrderAction)
    public updatePickListItemsOrder(state: PickListState, { pickListItems }: UpdateInStatePickListItemsOrderAction) {

        if (!state.pickListItems) { // extract to contract check
            throw new Error('PickListItems state is not defined.');
        }

        const itemsInState = [...state.pickListItems];
        pickListItems.forEach(item => {
            const itemIndex = itemsInState.findIndex(i => i.id === item.id);
            if (itemIndex > -1) {
                itemsInState[itemIndex] = item;
            } else {
                itemsInState.push(item);
            }
        });

        return { ...state, pickListItems: itemsInState } as PickListState;
    }
}
