export class SystemConfiguration {
    public logoImage: string;
    public smallLogoImage: string;
    public contactInfoEmail: string;
    public operatorContactEmail: string;
    public operatorId?: number;
    public userDocumentationUrl: string;
    public forgottenPasswordSubject: string;
    public forgottenPasswordTemplate: string;
    public requestAccessSubject: string;
    public requestAccessTemplate: string;
    public disabledUserSubject: string;
    public disabledUserTemplate: string;

    public smtpHost: string;
    public smtpPort: number;
    public smtpTimeout: number;
    public smtpUsername: string;
    public smtpPassword: string;
}
