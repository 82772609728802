import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit, ModuleWithProviders, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AgGridModule } from 'ag-grid-angular';
import { Subject } from 'rxjs';
import { debounceTime, delay, filter, tap, takeUntil } from 'rxjs/operators';
import {
    ColDef, ColGroupDef, ColumnApi, GridApi, GridOptions, ICellEditorParams, ICellRendererParams, ModelUpdatedEvent,
    SerializedFilter, ViewportChangedEvent,
} from 'ag-grid-community';

import { CombinedFilter } from './missing-imports';
import { PaginationService, PaginationType, ServerPaginationParams } from './pagination-service';

export interface IAgCellRendererParams<TCellData, TContext> extends ICellRendererParams {
    data: TCellData;
    context: TContext;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAgCellRendererDefaultParams<TCellData, TParentComponent>
    extends IAgCellRendererParams<TCellData, IAgGridContext<TParentComponent>> { }
export type IAgCellRendererDefaultParamsWithCustomParams<TCellData, TParentComponent, TCustomCellParams>
    = IAgCellRendererDefaultParams<TCellData, TParentComponent> & TCustomCellParams;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type IAgCellEditorDefaultParamsWithCustomParams<TCellEditorData, TCustomCellEditorParams>
    = ICellEditorParams & TCustomCellEditorParams;

export interface IAgGridContext<T> {
    componentParent: T;
}
/** Needed for grid version 20.1.0 because of missing members in the interface */
// todo: check if this is still needed in later versions
export interface GridOptionsExtra extends GridOptions {
    noRowsOverlayComponentParams?: any;
}

export type IAgColumnDefinition = (ColDef | ColGroupDef)[];

export enum AgGridFilters {
    Number = 'agNumberColumnFilter',
    Text = 'agTextColumnFilter',
    Date = 'agDateColumnFilter'
}

export enum AgGridCellEditor {
    Text = 'agTextCellEditor',
    PopupText = 'agPopupTextCellEditor',
    LargeText = 'agLargeTextCellEditor',
    Select = 'agSelectCellEditor',
    PopupSelect = 'agPopupSelectCellEditor',
}

/***
 * There are three DOM Layout values the grid can have 'normal', 'autoHeight' and 'print'.
 * <b>normal</b>: This is the default if nothing is specified.
 * The grid fits the width and height of the div you provide and scrolls in both directions.
 * <b>autoHeight</b>: The grid's height is set to fit the number of rows so no vertical scrollbar is provided by the grid.
 * The grid horizontally as normal.
 * <b>print</b>: No scroll bars are used and the grid renders all rows and columns. This layout is explained in Printing.
 */
export enum DomLayout {
    Normal = 'normal',
    AutoHeight = 'autoHeight',
    Print = 'print'
}

export enum GridSize {
    Regular = 'ag-grid-size-regular',
    Small = 'ag-grid-size-small'
}

export class GridSortEntry {
    constructor(public colId: string, public sort: 'desc' | 'asc') { }
}

export type GridSortModel = GridSortEntry[];
export interface FilterModel {
    [key: string]: SerializedFilter | CombinedFilter<SerializedFilter>;
}

/** Ag-Grid root node id, holding all top level rows */
export const ROOT_NODE_ID = 'ROOT_NODE_ID';
// TODO: introduce a grouping feature in the grid
@Component({
    selector: 'app-ag-grid',
    template: `
<div #gridContainerElement class="grid-container" [style.width]="width" [style.height]="height" [ngClass]="gridSize">
    <ag-grid-angular
        style="width: 100%; height: 100%"
        class="ag-theme-bootstrap"
        [domLayout]="domLayout"

        [gridOptions]="gridOptions"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [frameworkComponents]="frameworkComponents"
        [context]="context"
        [animateRows]="true"
        [quickFilterText]="quickFilterText"

        [pagination]="pagination"
        [paginationPageSize]="paginationService.itemsPerPage"
        [suppressScrollOnNewData]="true"
        [suppressPaginationPanel]="true"

        multiSortKey="ctrl"

        [suppressDragLeaveHidesColumns]="true"
        [enableCellTextSelection]="enableCellTextSelection"

        [overlayNoRowsTemplate]="overlayNoRowsTemplate"

        [headerHeight]="getHeight(gridSize)"
        [rowHeight]="getHeight(gridSize)"

        groupSelectsFiltered="true"

        (gridReady)="onGridReady($event)"
        (modelUpdated)="onModelUpdated($event)"
        (rowGroupOpened)="onRowGroupOpened()"
        (firstDataRendered)="onFirstDataRendered()"
        (paginationChanged)="paginationService.onPaginationChanged($event)"
        (sortChanged)="onSortChanged()"
        (filterChanged)="paginationService.onFilterChanged()"
        >
    </ag-grid-angular>
</div>

<hr *ngIf="pagination" />

<nav *ngIf="pagination">
    <ul *ngIf="canChangePageSize" class="pagination pull-right float-sm-right mb-1">
        <li class="page-item small-page-size"
            [ngClass]="{'active' : paginationService.itemsPerPage==paginationService.smallItemCountPerPage}">
            <a class="page-link" (click)="paginationService.onPageSizeChanged(paginationService.smallItemCountPerPage)">
              {{paginationService.smallItemCountPerPage}}
            </a>
        </li>
        <li class="page-item medium-page-size"
            [ngClass]="{'active' : paginationService.itemsPerPage==paginationService.mediumItemCountPerPage}">
            <a class="page-link" (click)="paginationService.onPageSizeChanged(paginationService.mediumItemCountPerPage)">
              {{paginationService.mediumItemCountPerPage}}
            </a>
        </li>
        <li class="page-item large-page-size"
            [ngClass]="{'active' : paginationService.itemsPerPage==paginationService.largeItemCountPerPage}">
          <a class="page-link" (click)="paginationService.onPageSizeChanged(paginationService.largeItemCountPerPage)">
            {{paginationService.largeItemCountPerPage}}
          </a>
        </li>
    </ul>
    <pagination #paging
        class="paging"
        [totalItems]="paginationService.totalPages * paginationService.itemsPerPage"
        [maxSize]="10"
        [rotate]="false"
        [itemsPerPage]="paginationService.itemsPerPage"
        [previousText]="'Prev'"
        (pageChanged)="paginationService.onPageChanged($event)">
    </pagination>
</nav>
    `,
    styles: [`
    :host {
        display: block;
    }

    :host > pagination ::ng-deep ul.pagination {
        margin-bottom: 0;
    }
`],
    providers: [PaginationService]
})
export class AgGridComponent implements OnInit, OnDestroy {
    @ViewChild('gridContainerElement') gridContainerElement: ElementRef;

    //#region fields

    public static readonly REGULAR_HEIGHT = 40;
    public static readonly SMALL_HEIGHT = 28;
    public static readonly PADDING_BOTTOM = 2;

    //#region @Input

    @Input() public rowData: any[];
    @Input() public columnDefs: (ColDef | ColGroupDef)[];
    @Input() public gridOptions: GridOptions;

    @Input() public frameworkComponents: { [key: string]: any };
    @Input() public context?: IAgGridContext<any>;

    @Input() public pagination = true;
    @Input() public paginationType = PaginationType.Client;
    @Input() public set paginationParams(value: ServerPaginationParams) {
        if (value) {
            this.paginationService.activate(value);
        } else {
            this.paginationService.deactivate();
        }
    }
    @Input() public canChangePageSize = true;

    @Input() public quickFilterText: string;
    @Input() public overlayNoRowsTemplate: string;

    /** Missing property for sort model in ag-grid {@link GridApi.setSortModel}*/
    @Input() public sortModel: GridSortModel;

    /**
     * Standardised Row and Header height
     * @type {GridSize}
     */
    @Input() public gridSize = GridSize.Regular;

    /** Setting to <i>AutoHeight<i> will ignore {@link width} and {@link height} and display no scrollbar - {@link DomLayout}*/
    @Input() public domLayout: DomLayout = DomLayout.AutoHeight;
    /**
     * Width the grid will take
     * @type {string}
     */
    @Input() public width = '100%';
    /**
     * Height the grid will take
     * @type {string}
     */
    @Input() public height = '100%';

    /**
     * Visible number of rows before a scroll appears.
     * Only works with DomLayout.Normal.
     * @type {number}
     */
    @Input() public rowCount = 15;
    @Input() public enableCellTextSelection = true;
    @Input() public addExtraDelayToResize: number;

    /***
     * Columns take the space of the content
     * @type {boolean}
     */
    @Input() public autoSizeColumns = true;
    /***
     * Column widths are recalculated according the content on every resize
     * @type {boolean}
     */
    @Input() public reevaluateColumnSizesOnResize = false;
    /***
     * If the auto size logic should be triggered
     * @type {boolean}
     */
    @Input() public set autoSize(value: boolean) {
        if (!this._autoSize && value) {
            this.autoResize(10);
        }

        this._autoSize = value;
        this.paginationService.gridParams.autoSize = this._autoSize;
    }

    //#endregion @Input

    @Output() public gridApiChange = new EventEmitter<GridApi>();

    public get api() { return this._gridApi; }
    public get columnApi() { return this._columnApi; }

    //#region privates

    private _gridApi: GridApi;
    private _columnApi: ColumnApi;
    private _autoSize = true;

    private resizeHandler: EventListener;

    /**
     * pass a boolean flag if the resized should trigger **only** _fit grid to width_
     * or trigger also _resize column to fit content_
     * Note: the 2nd part is also dependent on the input parameter @see autoSizeColumns
     * */
    private columnResize$ = new Subject<boolean>();

    private destroy$ = new Subject();

    //#endregion privates

    //#endregion fields

    constructor(public readonly paginationService: PaginationService) {
    }

    public ngOnInit(): void {
        if (this.domLayout === DomLayout.Normal && this.rowCount) {
            this.height =
                `${(this.getHeight(this.gridSize) + this.getHeight(this.gridSize) * this.rowCount) + AgGridComponent.PADDING_BOTTOM}px`;
        }

        this.columnResize$.pipe(
            filter(() => this.paginationType === PaginationType.Server || Array.isArray(this.rowData)),
            filter(() => this._autoSize),
            debounceTime(10),
            delay(this.addExtraDelayToResize ? this.addExtraDelayToResize : 0),
            // because of the debounceTime and the delay we need to terminate the observable if the page changes too quickly
            takeUntil(this.destroy$),
            tap(x => this.resize(x))
        ).subscribe();
    }

    public ngOnDestroy() {
        window.removeEventListener('resize', this.resizeHandler);
        this.columnResize$.complete();
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    //#region ag-grid event handler @see https://www.ag-grid.com/javascript-grid-events/

    /** Displayed rows have changed. Happens following sort, filter or tree expand / collapse events. */
    public onModelUpdated(event: ModelUpdatedEvent) {
        this._columnApi = event.columnApi;
        this._gridApi = event.api;
    }

    /** A row group was opened or closed. */
    public onRowGroupOpened() {
        // TODO trigger resize ONLY when the user did not use manual resize
        this.columnResize$.next(true);
    }

    /**
     * ag-Grid has initialised. The name 'ready' was influenced by the authors time programming the Commodore 64. Use this event if,
     * for example, you need to use the grid's API to fix the columns to size.
     */
    public onGridReady(params: ViewportChangedEvent) {
        this._gridApi = params.api;
        this._columnApi = params.columnApi;
        this.gridApiChange.emit(this._gridApi);

        this.columnResize$.next(this.reevaluateColumnSizesOnResize);

        this.resizeHandler = () => setTimeout(() => this.columnResize$.next(this.reevaluateColumnSizesOnResize));

        if (this.sortModel) {
            params.api.setSortModel(this.sortModel);
        }

        this.paginationService.init({
            paginationType: this.paginationType,
            gridApi: params.api,
            columnResize$: this.columnResize$,
            autoSize: this._autoSize
        });

        window.addEventListener('resize', this.resizeHandler);
    }

    /** Fired the first time data is rendered into the grid. */
    public onFirstDataRendered() {
        this.columnResize$.next(true);
    }

    public onSortChanged() {
        // TODO trigger resize ONLY when the user did not use manual resize
        this.columnResize$.next(true);
        this.paginationService.onSortChanged();
    }

    //#endregion

    public autoResize(delayMs = 0) {
        if (delayMs) {
            setTimeout(() => {
                this.columnResize$.next(true);
            }, delayMs);
        } else {
            this.columnResize$.next(true);
        }
    }

    public getHeight(gridSize: GridSize) {
        let height: number;

        switch (gridSize) {
            case GridSize.Regular: height = AgGridComponent.REGULAR_HEIGHT; break;
            case GridSize.Small: height = AgGridComponent.SMALL_HEIGHT; break;
        }

        return height;
    }

    private resize(shouldResizeColumns = false) {
        if (this.gridContainerElement.nativeElement.offsetWidth == 0) { return; }

        if (shouldResizeColumns && this.autoSizeColumns) {
            // resize columns based on the (visible!) content in the column
            this._columnApi.autoSizeAllColumns();
        }

        // resize columns to fit the current view port width
        this._gridApi.sizeColumnsToFit();
    }
}

@NgModule({
    declarations: [AgGridComponent],
    exports: [AgGridComponent],
    imports: [
        CommonModule,
        AgGridModule,
        PaginationModule.forRoot()
    ]
})
export class AgGridCustomModule {
    public static withComponents(component: any[]): ModuleWithProviders<AgGridCustomModule> {
        return { providers: AgGridModule.withComponents(component).providers, ngModule: AgGridCustomModule };
    }
}
