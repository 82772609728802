export class AdminState {
    public operatorContactEmail?: string;
    public contactInfoEmail?: string;
    public userDocumentationUrl?: string;
    public logoImage?: string;
    public smallLogoImage?: string;
    public forgottenPasswordSubject?: string;
    public forgottenPasswordTemplate?: string;
    public requestAccessSubject?: string;
    public requestAccessTemplate?: string;
    public disabledUserSubject?: string;
    public disabledUserTemplate?: string;
    public operatorId?: number;

    public smtpHost?: string;
    public smtpPort?: number;
    public smtpTimeout?: number;
    public smtpUsername?: string;
}
