import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { AlertOverview, AlertDetails, AlertFilterLists, AlertStateStatus } from '@/_models';
import { PublicPart } from 'ngx-shared';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AlertService {
    constructor(private http: HttpClient) { }

    getAlertOverviews(sectionId: number, targetId: number): Observable<AlertOverview[]> {
        const targetParam = targetId == null ? '' : `?targetId=${targetId}`;
        const url = `${environment.portalWebApi}/Alerts/overview/${sectionId}${targetParam}`;
        return this.http.get<AlertOverview[]>(url).pipe(
            map(x => {
                if (Array.isArray(x)) {
                    x.forEach(y => {
                        if (y.startTime) { y.startTime = new Date(y.startTime); }
                        if (y.rigLocalTime) { y.rigLocalTime = new Date(y.rigLocalTime); }
                    });
                }
                return x;
            })
        );
    }

    getAlertDetails(alertId: number): Observable<AlertDetails> {
        return this.http.get<AlertDetails>(`${environment.portalWebApi}/Alerts/${alertId}`).pipe(
            map(x => {
                if (x) {
                    if (x.firstIssueStartTime) { x.firstIssueStartTime = new Date(x.firstIssueStartTime); }
                    if (x.lastIssueEndTime) { x.lastIssueEndTime = new Date(x.lastIssueEndTime); }
                    if (x.acknowledgedTime) { x.acknowledgedTime = new Date(x.acknowledgedTime); }
                    if (x.resolvedTime) { x.resolvedTime = new Date(x.resolvedTime); }
                    if (x.closedTime) { x.closedTime = new Date(x.closedTime); }
                    if (x.rigLocalTime) { x.rigLocalTime = new Date(x.rigLocalTime); }
                    if (Array.isArray(x.issues)) {
                        x.issues.forEach(y => {
                            y.startTime = new Date(y.startTime);
                            if (y.endTime) { y.endTime = new Date(y.endTime); }
                        });
                    }
                    return x;
                }
            })
        );
    }

    getFilterLists(): Observable<AlertFilterLists> {
        return this.http.get<AlertFilterLists>(`${environment.portalWebApi}/Alerts/filterLists`);
    }

    updateIssuesProblemTime(issueIds: number[], problemTime: boolean): Observable<object> {
        const body = {
            issueId: issueIds,
            problemTime: problemTime
        };
        return this.http.post(`${environment.portalWebApi}/Alerts/updateProblemTime`, body);
    }

    updateState(alertId: number, state: AlertStateStatus, comment: string, verifyResolved: boolean): Observable<object> {
        if (verifyResolved) {
            return this.http.post(`${environment.portalWebApi}/Alerts/updateStateVerifyResolved`, { alertId, state, comment });
        } else {
            return this.http.post(`${environment.portalWebApi}/Alerts/updateStateResolve`, { alertId, state, comment });
        }
    }
}

/* eslint-disable */
export class FakeAlertService implements PublicPart<AlertService> {
    getAlertOverviews(): Observable<AlertOverview[]> {
        throw new Error('Method not implemented.');
    }

    getAlertDetails(alertId: number): Observable<AlertDetails> {
        throw new Error('Method not implemented.');
    }

    updateIssuesProblemTime(issueIds: number[], problemTime: boolean): Observable<object> {
        throw new Error('Method not implemented.');
    }

    getFilterLists(): Observable<AlertFilterLists> {
        throw new Error('Method not implemented.');
    }

    updateState(alertId: number, state: AlertStateStatus): Observable<object> {
        throw new Error('Method not implemented.');
    }
}
/* eslint-enable */
