import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class LoadingIndicatorService {
    isLoading$ = new Subject<boolean>();

    /**
     * Stores all currently active requests
     */
    private requests: HttpRequest<any>[] = [];

    /**
     * Adds request to the storage and notifies observers
     */
    onStarted(req: HttpRequest<any>): void {
        this.requests.push(req);
        this.notify();
    }

    /**
     * Removes request from the storage and notifies observers
     */
    onFinished(req: HttpRequest<any>): void {
        const index = this.requests.indexOf(req);
        if (index !== -1) {
            this.requests.splice(index, 1);
        }
        this.notify();
    }

    /**
     * Notifies observers about whether there are any requests on fly
     */
    private notify(): void {
        this.isLoading$.next(this.requests.length !== 0);
    }

}

@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {
    constructor(private loadingIndicatorService: LoadingIndicatorService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // emit onStarted event before request execution
        this.loadingIndicatorService.onStarted(req);

        return new Observable(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.loadingIndicatorService.onFinished(req);
                            observer.next(event);
                        }
                    },
                    err => {
                        this.loadingIndicatorService.onFinished(req);
                        observer.error(err);
                    },
                    () => {
                        this.loadingIndicatorService.onFinished(req);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.loadingIndicatorService.onFinished(req);
                subscription.unsubscribe();
            };
        });
    }
}
