import { UntypedFormGroup } from '@angular/forms';

export class ConfirmPasswordValidator {
    static check(passwordKey: string, confirmPasswordKey: string) {
        return (group: UntypedFormGroup) => {
            const passwordInput = group.controls[passwordKey];
            const confirmPasswordInput = group.controls[confirmPasswordKey];
            if (this.isPasswordFieldDifferent(passwordInput.value, confirmPasswordInput.value)) {
                return confirmPasswordInput.setErrors({ notEquivalent: true });
            } else {
                return confirmPasswordInput.setErrors(null);
            }
        };
    }

    private static isPasswordFieldDifferent(password: string, confirmPassword: string) {
        // Note: this first check is required as a IE fix.
        // On user click in the field the IE automatically changes the null value to empty string.
        // This will fails the 2nd check.
        return !(!password && !confirmPassword)
            && password !== confirmPassword;
    }
}
