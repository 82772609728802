export class KpiRange {
    public id: number;
    public startUtc: Date;
    public endUtc: Date;
    public type: SectionKpiRangeType;
}

export enum SectionKpiRangeType {
    Section,
    Drilling
}
