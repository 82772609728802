export class NgxChartLine {
    constructor(
        public name: string,
        public series: NgxChartLineSeries[]
    ) { }
}

export class NgxChartLineSeries {
    constructor(
        public name: Date,
        public value: number
    ) { }
}
