import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { PublicPart } from 'ngx-shared';

import { PickListItem, PickList, PickListType } from '@/_models';

class UpdatePicklistOrderBody {
    picklistId: number;
    pickListIds: number[];
}

@Injectable({ providedIn: 'root' })
export class PicklistService {
    constructor(private http: HttpClient) { }

    getPicklists(): Observable<PickList[]> {
        return this.http.get<PickList[]>(`${environment.portalWebApi}/Picklists`);
    }

    getPicklistItems(pickListIds: PickListType[]): Observable<PickListItem[]> {
        return this.http.post<PickListItem[]>(`${environment.portalWebApi}/Picklists/picklistItems`, pickListIds);
    }

    createPicklistItem(pickListItem: PickListItem): Observable<PickListItem> {
        return this.http.post<PickListItem>(`${environment.portalWebApi}/Picklists/createItem`, pickListItem);
    }

    updatePicklistItem(pickListItem: PickListItem): Observable<PickListItem> {
        return this.http.post<PickListItem>(`${environment.portalWebApi}/Picklists/updateItem`, pickListItem);
    }

    deletePicklistItem(pickListId: PickListType): Observable<void> {
        return this.http.delete<void>(`${environment.portalWebApi}/Picklists/deleteItem/${pickListId}`);
    }

    deprecatePicklistItem(pickListItemId: number, pickListItemName: string): Observable<void> {
        return this.http.post<void>(`${environment.portalWebApi}/Picklists/deprecateItem`, {
            id: pickListItemId,
            name: pickListItemName
        });
    }

    updatePickListItemsOrder(pickListIds: number[], picklistId: number): Observable<PickListItem[]> {
        const body: UpdatePicklistOrderBody = {
            picklistId: picklistId,
            pickListIds: pickListIds,
        };
        return this.http.post<PickListItem[]>(`${environment.portalWebApi}/Picklists/updateOrder`,
        body);
    }
}

/* eslint-disable */
export class FakePicklistService implements PublicPart<PicklistService> {
    getPicklists(): Observable<PickList[]> {
        throw new Error('Method not implemented: FakePicklistService.getPicklists');
    }

    getPicklistItems(pickListIds: PickListType[]): Observable<PickListItem[]> {
        throw new Error('Method not implemented: FakePicklistService.getPicklistItems');
    }

    createPicklistItem(pickListItem: PickListItem): Observable<PickListItem> {
        throw new Error('Method not implemented: FakePicklistService.createPicklistItem');
    }

    updatePicklistItem(pickListItem: PickListItem): Observable<PickListItem> {
        throw new Error('Method not implemented: FakePicklistService.updatePicklistItem');
    }

    deletePicklistItem(pickListId: PickListType): Observable<void> {
        throw new Error('Method not implemented: FakePicklistService.deletePicklistItem');
    }

    deprecatePicklistItem(pickListItemId: number, pickListItemName: string): Observable<void> {
        throw new Error('Method not implemented: FakePicklistService.deprecatePicklistItem');
    }

    updatePickListItemsOrder(pickListIds: number[], picklistId: number): Observable<PickListItem[]> {
        throw new Error('Method not implemented: FakePicklistService.updatePickListItemOrder');
    }
}

export const fakePicklistServiceProvider = { provide: PicklistService, useClass: FakePicklistService };
/* eslint-enable */
