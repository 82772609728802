import { DapTemplateContentGroup } from '@/_models/dap-document-details';
import { DapDispositionItem } from './dap-disposition-item';
import { DapDocumentVM } from './dap-document-vm';
import { DapFieldVM } from './dap-field-vm';
import { DapTableVM } from './dap-table-vm';

export class DapGroupVM extends DapDispositionItem {
    public templateContent: DapTemplateContentGroup;
    public controls: DapControlVM[];
    // backwards reference to the doc VM
    public docVM: DapDocumentVM;

    public getFieldVMs(): DapFieldVM[] {
        return this.controls
            .filter(x => x.field != null)
            .map(x => x.field);
    }

    public getTableVMs(): DapTableVM[] {
        return this.controls
            .filter(x => x.table != null)
            .map(x => x.table);
    }
}

export class DapControlVM {
    public ordinal: number;
    public field: DapFieldVM;
    public table: DapTableVM;
}
