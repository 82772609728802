import { IndicatorStatus } from '@/_models';
import { IndicatorStatusColors } from './indicator-status-color';
import { IndicatorStatusText } from './indicator-status-text';

export class IndicatorStatusHelper {
    /**
     *
     * Changes the status ordering to be different from the unit value. The new asc order is:
     *  1. **Invalid** (enum - _2_),
     *  2. **Undetermined** (enum - _3_),
     *  3. **NotApplicable** (enum - _4_),
     *  4. **Valid** (enum - _1_),
     *  5. **Disabled** (enum - _5_)
     */
    private static readonly indicatorStatusOrder = [
        IndicatorStatus.Invalid,
        IndicatorStatus.NotApplicable,
        IndicatorStatus.Undetermined,
        IndicatorStatus.Valid,
        IndicatorStatus.Disabled
    ];

    /**
     * Aggregation priority
     *
     *      VALID | INVALID | NOT APPLICABLE | UNDETERMINED | DISABLED
     * ALL    1   |    2    |       3        |      4       |    5
     * ANY    8   |    6    |       9        |      7       |    10
     *
     */
    private static _aggregate(statuses: IndicatorStatus[]): IndicatorStatus {
        if (statuses.some(status => status === IndicatorStatus.Invalid)) {
            return IndicatorStatus.Invalid;
        }

        if (statuses.some(status => status === IndicatorStatus.Undetermined)) {
            return IndicatorStatus.Undetermined;
        }

        if (statuses.some(status => status === IndicatorStatus.Valid)) {
            return IndicatorStatus.Valid;
        }

        if (statuses.some(status => status === IndicatorStatus.NotApplicable)) {
            return IndicatorStatus.NotApplicable;
        }

        return IndicatorStatus.Disabled;
    }

    public static aggregate(statuses: IndicatorStatus[]): IndicatorStatus | null | never {
        if (statuses == null || !Array.isArray(statuses)) {
            throw new TypeError(`Invalid input array of statuses: (${statuses})`);
        }

        if (!statuses.length) {
            return null;
        }

        return IndicatorStatusHelper._aggregate(statuses);
    }

    public static getColor(status: IndicatorStatus) {
        let _statusColor: IndicatorStatusColors;

        switch (status) {
            case IndicatorStatus.Valid: _statusColor = IndicatorStatusColors.Valid; break;
            case IndicatorStatus.Invalid: _statusColor = IndicatorStatusColors.Invalid; break;
            case IndicatorStatus.NotApplicable: _statusColor = IndicatorStatusColors.NotApplicable; break;
            case IndicatorStatus.Disabled: _statusColor = IndicatorStatusColors.Disabled; break;
            case IndicatorStatus.Undetermined:
            default: _statusColor = IndicatorStatusColors.Undetermined; break;
        }

        return _statusColor;
    }

    public static getText(status: IndicatorStatus): IndicatorStatusText {
        let text: IndicatorStatusText;

        switch (status) {
            case IndicatorStatus.Valid: text = IndicatorStatusText.Valid; break;
            case IndicatorStatus.Invalid: text = IndicatorStatusText.Invalid; break;
            case IndicatorStatus.Disabled: text = IndicatorStatusText.Disabled; break;
            case IndicatorStatus.NotApplicable: text = IndicatorStatusText.NotApplicable; break;
            case IndicatorStatus.Undetermined:
            default: text = IndicatorStatusText.Undetermined; break;
        }

        return text;
    }

    public static getValue(status: IndicatorStatusText): IndicatorStatus {
       const mapping = {
           [IndicatorStatusText.Valid]: IndicatorStatus.Valid,
           [IndicatorStatusText.Invalid]: IndicatorStatus.Invalid,
           [IndicatorStatusText.Disabled]: IndicatorStatus.Disabled,
           [IndicatorStatusText.NotApplicable]: IndicatorStatus.NotApplicable,
           [IndicatorStatusText.Undetermined]: IndicatorStatus.Undetermined,
       };

        return mapping[status];
    }

    public static comparator(status1: IndicatorStatus, status2: IndicatorStatus): number {
        if (status1 == null && status2 == null) {
            return 0;
        }

        if (status1 == null) {
            return -1;
        }

        if (status2 == null) {
            return 1;
        }

        return IndicatorStatusHelper.indicatorStatusOrder.findIndex(x => x === status1)
            - IndicatorStatusHelper.indicatorStatusOrder.findIndex(x => x === status2);
    }
}
