export class OrderWithCurvesDto {
    public id: number;
    public name: string;
    public sections: SectionWithCurvesDto[];
}

export class SectionWithCurvesDto {
    public id: number;
    public name: string;
    public curves: CurvesDto[];
}

export class CurvesDto {
    public id: number;
    public sectionId: number;
    public serviceProviderId: number;
    public serviceProviderName: string;
    public serviceId: number;
    public serviceName: string;
    public tool: string;
    public mnemonic: string;
    public curveIndexTypeId: number;
    public curveIndexTypeName: string;
    public logName: string;
}
