export class OrderSectionCurve {
    public mnemonicId: number;
    public catalogId: number;

    public serviceId: number;
    public serviceProviderId: number;
    public tool: string;
    public curveIndexTypeId: number;
    public mnemonic: string;
    public logName: string;
    public globalMnemonic: string;
    public globalLogName: string;

    public tags: string;
    public tagSource: string;
    public tagPriority: number;
    
    public comments: string;

    public kpiCurve?: boolean;
    public isCommon?: boolean;

    public dataPointFrequency: number;
    public dataPointFrequencyUom: string;
    public receiveFrequency: number;
    public receiveFrequencyUom: string;
    public toolUpdatePeriod: number;
    public toolUpdatePeriodUom: string;
    public sensorOffset: number;
    public sensorOffsetUom: string;
    public curveGroups: number[];
}
