/* eslint-disable @angular-eslint/component-selector */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-error',
  templateUrl: './ngx-error.component.html',
  styleUrls: ['./ngx-error.component.css']
})
export class NgxErrorComponent {

  @Input() statusCode: number;
  @Input() title: string;
  @Input() message: string;
}
