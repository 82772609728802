import { DapDapHistoricalAverage, DapDocumentStatusData, DapHistoryScoreFilterData } from '@/_models/dap-document-status';
import { DapScoreChanges } from '@/_models/dap-document-status/dap-score-change';
import { Action } from '@ngrx/store';

export class LoadDapDocumentContentAction implements Action {
    readonly type = '[DapDocumentStatus] InState Load Dap Document Content action';

    constructor(public readonly documentStatus: DapDocumentStatusData) { }
}

export class FetchDapDocumentContentAction implements Action {
    public type = '[DapDocumentStatus] Fetch DapDocumentContent action';
    constructor(public readonly id: number, public readonly linkTemplate: string) { }
}
export class FetchDapDocumentContentOkAction implements Action {
    public type = '[DapDocumentStatus] Fetch DapDocumentContent Ok action';
}
export class FetchDapDocumentContentFailedAction implements Action {
    public type = '[DapDocumentStatus] Fetch DapDocumentContent Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class ReactivateDocumentAction implements Action {
    public type = '[DapDocumentStatus] Reactivate document action';
    constructor(public readonly id: number) { }
}
export class ReactivateDocumentOkAction implements Action {
    public type = '[DapDocumentStatus] Reactivate document Ok action';
}
export class ReactivateDocumentFailedAction implements Action {
    public type = '[DapDocumentStatus] Reactivate document Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class ReactivateSectionAction implements Action {
    public type = '[DapDocumentStatus] Reactivate section action';
    constructor(public readonly id: number) { }
}
export class ReactivateSectionOkAction implements Action {
    public type = '[DapDocumentStatus] Reactivate section Ok action';
}
export class ReactivateSectionFailedAction implements Action {
    public type = '[DapDocumentStatus] Reactivate section Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class ReactivateDapAction implements Action {
    public type = '[DapDocumentStatus] Reactivate DAP action';
    constructor(public readonly id: number) { }
}
export class ReactivateDapOkAction implements Action {
    public type = '[DapDocumentStatus] Reactivate DAP Ok action';
}
export class ReactivateDapFailedAction implements Action {
    public type = '[DapDocumentStatus] Reactivate DAP Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class CompleteDocumentAction implements Action {
    public type = '[DapDocumentStatus] Complete document action';
    constructor(public readonly id: number) { }
}
export class CompleteDocumentOkAction implements Action {
    public type = '[DapDocumentStatus] Complete document Ok action';
}
export class CompleteDocumentFailedAction implements Action {
    public type = '[DapDocumentStatus] Complete document Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class CompleteSectionAction implements Action {
    public type = '[DapDocumentStatus] Complete section action';
    constructor(public readonly id: number) { }
}
export class CompleteSectionOkAction implements Action {
    public type = '[DapDocumentStatus] Complete section Ok action';
}
export class CompleteSectionFailedAction implements Action {
    public type = '[DapDocumentStatus] Complete section Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class CompleteDapAction implements Action {
    public type = '[DapDocumentStatus] Complete DAP action';
    constructor(public readonly id: number) { }
}
export class CompleteDapOkAction implements Action {
    public type = '[DapDocumentStatus] Complete DAP Ok action';
}
export class CompleteDapFailedAction implements Action {
    public type = '[DapDocumentStatus] Complete DAP Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class AbandonDocumentAction implements Action {
    public type = '[DapDocumentStatus] Abandon document action';
    constructor(public readonly id: number) { }
}
export class AbandonDocumentOkAction implements Action {
    public type = '[DapDocumentStatus] Abandon document Ok action';
}
export class AbandonDocumentFailedAction implements Action {
    public type = '[DapDocumentStatus] Abandon document Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class AbandonSectionAction implements Action {
    public type = '[DapDocumentStatus] Abandon section action';
    constructor(public readonly id: number) { }
}
export class AbandonSectionOkAction implements Action {
    public type = '[DapDocumentStatus] Abandon section Ok action';
}
export class AbandonSectionFailedAction implements Action {
    public type = '[DapDocumentStatus] Abandon section Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class AbandonDapAction implements Action {
    public type = '[DapDocumentStatus] Abandon DAP action';
    constructor(public readonly id: number) { }
}
export class AbandonDapOkAction implements Action {
    public type = '[DapDocumentStatus] Abandon DAP Ok action';
}
export class AbandonDapFailedAction implements Action {
    public type = '[DapDocumentStatus] Abandon DAP Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class SaveScoresAction implements Action {
    public type = '[DapDocumentStatus] Save Scores action';
    constructor(public readonly scores: DapScoreChanges, public readonly linkTemplate: string) { }
}
export class SaveScoresOkAction implements Action {
    public type = '[DapDocumentStatus] Save Scores Ok action';
}
export class SaveScoresFailedAction implements Action {
    public type = '[DapDocumentStatus] Save Scores Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class UpdateFilterInStoreAction implements Action {
    public type = '[DapDocumentStatus] Update the filter in store';
    constructor(public readonly filter: DapHistoryScoreFilterData) { }
}

export class UpdateFilterPeriodInStore implements Action {
    public type = '[DapDocumentStatus] Update the period of the filter in store';
    constructor(public readonly from: Date, public readonly to: Date) { }
}

export class UpdateFilterFromInStore implements Action {
    public type = '[DapDocumentStatus] Update From date of the filter in store';
    constructor(public readonly date: Date) { }
}

export class UpdateFilterToInStore implements Action {
    public type = '[DapDocumentStatus] Update To date of the filter in store';
    constructor(public readonly date: Date) { }
}

export class GetHistoricalAverageAction implements Action {
    public type = '[DapDocumentStatus] Gets the historical average for all DAPs in the document';
    constructor(public readonly documentId: number, public readonly filter: DapHistoryScoreFilterData) { }
}

export class GetHistoricalAverageOkAction implements Action {
    public type = '[DapDocumentStatus] GetHistoricalAverageAction success';
}

export class GetHistoricalAverageFailedAction implements Action {
    public type = '[DapDocumentStatus] GetHistoricalAverageAction failure';
    constructor(public readonly error: Error | string | any) { }
}
export class LoadHistoricalAverageInStoreAction implements Action {
    public type = '[DapDocumentStatus] Updates historical scores in store';
    constructor(public readonly scores: DapDapHistoricalAverage[]) { }
}
export class ClearDocumentStatusStoreAction implements Action {
    public type = '[DapDocumentStatus] Clear all status data';
}

export class RequestFilterFieldsUpdateAction implements Action {
    public type = '[DapDocumentStatus] Request historical score filter fields update';
}

export class ClearFilterFieldsUpdateRequestAction implements Action {
    public type = '[DapDocumentStatus] Clears the historical score filter fields update request';
}
