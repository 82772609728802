import { UntypedFormGroup, ValidationErrors, AbstractControl, UntypedFormArray } from '@angular/forms';

export interface AllValidationErrors {
    control_name: string;
    error_name: string;
    error_value: any;
}

export function getFormValidationErrors(control: AbstractControl, name: string = null): AllValidationErrors[] {
    let errors: AllValidationErrors[] = [];
    extractErrors(name, control.errors, errors);

    if (control instanceof UntypedFormArray || control instanceof UntypedFormGroup) {
        Object.keys(control.controls).forEach(key => {
            const child = control.controls[key];
            errors = errors.concat(getFormValidationErrors(child, key));
        });
    }

    return errors;
}

function extractErrors(controlKey: string, controlErrors: ValidationErrors, errors: AllValidationErrors[]) {
    if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
            errors.push({
                control_name: controlKey,
                error_name: keyError,
                error_value: controlErrors[keyError]
            });
        });
    }
}
