export class DapHistoryFilterFieldData {
    public name: string;
    public availableValues: string[];
    public selectedValues: string[];
}

export class DapHistoryScoreFilterData {
    public historyFilterCompatibility: string;
    public from: Date;
    public to: Date;
    public fields: DapHistoryFilterFieldData[];
}
