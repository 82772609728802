import { DapDocumentBase } from '@/_models/dap-document';
import { Action, Store } from '../ngrx-actions';
import {
    LoadDapDocumentFilterDataAction, LoadDapDocumentsAction, LoadDapTemplatesAction,
    LoadNextRevisionVersionsAction,
    MarkDapDocumentsAsDiagramLoadingAction,
    ToggleDapDocumentFavouriteInStoreAction,
    UpdateDeletedDocumentInStoreAction,
} from './dap-document-management.actions';
import { DapDocumentManagementState } from './dap-document-management.state';

@Store({} as DapDocumentManagementState)
export class DapDocumentManagementStore {

    @Action(LoadDapDocumentFilterDataAction)
    public loadDapDocumentFilterData(state: DapDocumentManagementState, { filterData }: LoadDapDocumentFilterDataAction) {
        return { ...state, filterData } as DapDocumentManagementState;
    }

    @Action(LoadDapDocumentsAction)
    public loadDapDocuments(state: DapDocumentManagementState, { documents }: LoadDapDocumentsAction) {
        return { ...state, documents } as DapDocumentManagementState;
    }

    @Action(MarkDapDocumentsAsDiagramLoadingAction)
    public markDapDocumentsToLoadDiagram(state: DapDocumentManagementState, { documents }: MarkDapDocumentsAsDiagramLoadingAction) {
        documents = documents.map(d => ({ ...d, scoreDiagram: 'PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCiAgdmlld0JveD0iMCAwIDEwMCAxMDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDAgMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CiAgPGc+CiAgICA8dGl0bGU+TG9hZGluZy4gUGxlYXNlIHdhaXQuLi48L3RpdGxlPgogIDxyZWN0IGlkPSJwYXRoLTEiIHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjMDAwMCI+PC9yZWN0PgogIDxjaXJjbGUgZmlsbD0iIzAwMCIgc3Ryb2tlPSJub25lIiBjeD0iNiIgY3k9IjUwIiByPSI2Ij4KICAgIDxhbmltYXRlCiAgICAgIGF0dHJpYnV0ZU5hbWU9Im9wYWNpdHkiCiAgICAgIGR1cj0iMXMiCiAgICAgIHZhbHVlcz0iMDsxOzAiCiAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIgogICAgICBiZWdpbj0iMC4xIi8+ICAgIAogIDwvY2lyY2xlPgogIDxjaXJjbGUgZmlsbD0iIzAwMCIgc3Ryb2tlPSJub25lIiBjeD0iMjYiIGN5PSI1MCIgcj0iNiI+CiAgICA8YW5pbWF0ZQogICAgICBhdHRyaWJ1dGVOYW1lPSJvcGFjaXR5IgogICAgICBkdXI9IjFzIgogICAgICB2YWx1ZXM9IjA7MTswIgogICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgCiAgICAgIGJlZ2luPSIwLjIiLz4gICAgICAgCiAgPC9jaXJjbGU+CiAgPGNpcmNsZSBmaWxsPSIjMDAwIiBzdHJva2U9Im5vbmUiIGN4PSI0NiIgY3k9IjUwIiByPSI2Ij4KICAgIDxhbmltYXRlCiAgICAgIGF0dHJpYnV0ZU5hbWU9Im9wYWNpdHkiCiAgICAgIGR1cj0iMXMiCiAgICAgIHZhbHVlcz0iMDsxOzAiCiAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAKICAgICAgYmVnaW49IjAuMyIvPiAgICAgCiAgPC9jaXJjbGU+CiAgPC9nPgo8L3N2Zz4K' }));
        return { ...state, documents } as DapDocumentManagementState;
    }

    @Action(ToggleDapDocumentFavouriteInStoreAction)
    public toggleDapDocumentFavourite(state: DapDocumentManagementState,
        { dapHeaderId, newState }: ToggleDapDocumentFavouriteInStoreAction) {
        const documents = state.documents.map(d => ({ ...d, isMyFavourite: d.dapHeaderId === dapHeaderId ? newState : d.isMyFavourite }));
        return { ...state, documents } as DapDocumentManagementState;
    }

    @Action(UpdateDeletedDocumentInStoreAction)
    public updateDeletedDocument(state: DapDocumentManagementState,
        { document, updateInStore }: UpdateDeletedDocumentInStoreAction) {
        let documents: DapDocumentBase[];
        if (updateInStore) {
            documents = state.documents.map(d => d.id !== document.id ?
                ({ ...d }) :
                (document));
        } else {
            documents = state.documents.filter(d => d.id !== document.id).map(d => ({ ...d }));
        }
        return { ...state, documents } as DapDocumentManagementState;
    }

    @Action(LoadDapTemplatesAction)
    public loadDapTemplates(state: DapDocumentManagementState, { templates }: LoadDapTemplatesAction) {
        return { ...state, templates } as DapDocumentManagementState;
    }

    @Action(LoadNextRevisionVersionsAction)
    public loadNextRevisionVersions(state: DapDocumentManagementState,
        { nextRevisionVersion }: LoadNextRevisionVersionsAction) {
        const nextRevisionString = nextRevisionVersion.nextRevision;
        const nextVersionString = nextRevisionVersion.nextVersion;
        const documents = state.documents.map(d => d.id !== nextRevisionVersion.documentId ?
            ({ ...d }) :
            ({ ...d, nextRevisionString, nextVersionString }));
        return { ...state, documents } as DapDocumentManagementState;
    }
}
