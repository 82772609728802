import { DapDapStatus, DapDocumentStatus, DapSectionStatus } from '../dap-common/dap-status';
import { SimpleUser } from '../simple-user';

export enum DapScoreLevel {
    NoScore = 0,
    BelowMin = 1,
    AboveMin = 2,
    AboveSla = 3
}

export enum DapBreachType {
    Delivery = 0,
    Quality = 1
}

export enum DapScoreSignedOff {
    AwaitingFirstSignOff = 0,
    PartiallySignedOff = 1,
    FullySignedOff = 2,
    SignOffNotRequired = 3,
}

export class DapCategoryScoreData {
    public id: number;
    public label: string;
    public deliveryScore: number;
    public qualityScore: number;
    public deliverySignedOff: boolean;
    public deliverySignedOffBy: string;
    public deliverySignedOffComment: string;
    public deliverySignedOffInitial: Date;
    public deliverySignedOffUpdated: Date;
    public qualitySignedOff: boolean;
    public qualitySignedOffBy: string;
    public qualitySignedOffComment: string;
    public qualitySignedOffInitial: Date;
    public qualitySignedOffUpdated: Date;
    public signOffRequired: boolean;
    public canCurrentUserSignOff: boolean;
    public signOffRequiredProvider: string;
    public signOffRequiredRoles: string[];
}

export class DapBreachData {
    public id: number;
    public dapDapId: number;
    public dapDapName: string;
    public type: DapBreachType;
    public value: number;
    public description: string;
    public created: Date;
    public createdBy: SimpleUser;
    public updated: Date;
    public updatedBy: SimpleUser;
    public signedOff: boolean;
    public signedOffBy: string;
    public signedOffComment: string;
    public signedOffInitial: Date;
    public signedOffUpdated: Date;
    public signOffRequired: boolean;
    public canCurrentUserSignOff: boolean;
    public signOffRequiredProvider: string;
    public signOffRequiredRoles: string[];
}

export class DapDapStatusData {
    public id: number;
    public key: string;
    public name: string;
    public includeInScoring: boolean;
    public status: DapDapStatus;
    public capturedDeliveryScore: number;
    public capturedQualityScore: number;
    public deliveryBreaches: number;
    public qualityBreaches: number;
    public calculatedDeliveryScore: number;
    public calculatedQualityScore: number;
    public calculatedTotalScore: number;
    public serviceLevelAgreement: number;
    public normalizedWeightInDocument: number;
    public scoreLevel: DapScoreLevel;
    public perfectValue: number;
    public realizedValue: number;
    public slaValue: number;
    public historicalScore: number;
    public hasHistoricalFilter: boolean;
    public closedOrCompleted: Date;
    public deliverySignedOff: boolean;
    public deliverySignedOffBy: string;
    public deliverySignedOffComment: string;
    public deliverySignedOffInitial: Date;
    public deliverySignedOffUpdated: Date;
    public qualitySignedOff: boolean;
    public qualitySignedOffBy: string;
    public qualitySignedOffComment: string;
    public qualitySignedOffInitial: Date;
    public qualitySignedOffUpdated: Date;
    public signOffRequired: boolean;
    public summarySignedOff: DapScoreSignedOff;
    public canCurrentUserSignOff: boolean;
    public signOffRequiredProvider: string;
    public signOffRequiredRoles: string[];
    public categories: DapCategoryScoreData[];
}

export class DapSectionStatusData {
    public id: number;
    public key: string;
    public name: string;
    public includeInScoring: boolean;
    public canBeScored: boolean;
    public status: DapSectionStatus;
    public calculatedDeliveryScore: number;
    public calculatedQualityScore: number;
    public calculatedTotalScore: number;
    public daps: DapDapStatusData[];
    public breaches: DapBreachData[];
    public summarySignedOff: DapScoreSignedOff;
}

export class DapDocumentStatusData {
    public id: number;
    public title: string;
    public status: DapDocumentStatus;
    public canBeScored: boolean;
    public sections: DapSectionStatusData[];

    public calculatedDeliveryScore: number;
    public calculatedQualityScore: number;
    public calculatedTotalScore: number;
    public scoreGraphic: string;

    public scoreLevel: DapScoreLevel;
    public value: number;
    public valueUom: string;

    public isPreview: boolean;

    public historyFilterCompatibility: string;

    public summarySignedOff: DapScoreSignedOff;
}
