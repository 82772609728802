import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, ViewChild, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ModalModule, ModalOptions, ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil, filter, tap } from 'rxjs/operators';
import { TooltipsModule } from '@/tooltips';

export class ModalAction {
    /** For examples see
     * https://coreui.io/demo/#buttons/buttons.html
     */
    public cssClass?: string;
    public text: string;
    /**
     * A callback function that will be called when the action button is clicked.
     * The function can have no return value or Promise.
     * When there is no return value the modal is closed right after the function completed.
     * When there is a Promise the modal is closed the the promise is resolved with null/undefined or with true.
     */
    public callback?: (context?: any) => void | boolean | Promise<void | boolean>;
    public isDisabled?: (context?: any) => true  | false | null;
    public title?: (context?: any) => string;
    public suspendWhileActionExecuted?: true  | false | null = true;
}

export interface ModalBehavior {
    show(): void;
    hide(): void;
}

@Component({
    selector: 'app-modal-dialog',
    template: `
<div class="modal fade app-action-dialog" tabindex="-1" role="dialog"
    bsModal [config]="modalConfig" #modalDialog="bs-modal" (onShown)="onShown()">
    <div class="modal-dialog" [ngClass]="modalClass" [style]="style">
        <div class="modal-content">

            <!-- header -->
            <div class="modal-header flex-row justify-content-center">
                <h4 class="white-space-preserve">{{headerText}}</h4>
                <app-tooltip
                    *ngIf="tooltipKey"
                    [placement]="'bottom'"
                    [tooltipKey]="tooltipKey"
                    [position]="'tooltip-icon-outside'"
                ></app-tooltip>
            </div>

            <!-- body -->
            <div class="modal-body word-break">
                {{bodyText}}
                <ng-content></ng-content>
            </div>

            <!-- footer -->
            <div class="modal-footer">
                <ng-container *ngFor="let action of actions">
                    <button
                        class="btn btn-md"
                        [ngClass]="action.cssClass"
                        type="submit"
                        [disabled]="isActionDisabled(action)"
                        [title]="(action.title ? action.title(context) : '')"
                        (click)="onClick(action)">
                        {{action.text}}
                    </button>
                </ng-container>
            </div>

        </div>
    </div>
</div>`,
    exportAs: 'bs-modal'
})
export class ModalDialogComponent implements OnDestroy, OnInit {
    @Input() public headerText = 'Dialog';
    /** see the links below for styles
     * https://getbootstrap.com/docs/4.0/components/modal/#modal-components
     * https://coreui.io/demo/#notifications/modals.html
     */
    @Input() public modalClass: string;
    @Input() public style: string;
    @Input() public bodyText: string;
    @Input() public tooltipKey: string;
    @Input() public actions: ModalAction[];
    @Input() public actionInProgress = false;
    @Input() public actionProgressExternallyManaged = false;
    @Output() public close = new EventEmitter(); // eslint-disable-line @angular-eslint/no-output-native
    @ViewChild('modalDialog', { static: true }) modalDialog: ModalDirective;

    public modalConfig = { ignoreBackdropClick: true } as ModalOptions;

    public isVisible = false;
    public isShown = false;

    /** This is an context value that will be passed to the onClick callback and will be discarded automatically after that */
    public context: any;

    private destroy$ = new Subject();

    public ngOnInit(): void {
        this.modalDialog.onHidden.pipe(
            filter(() => this.isVisible),
            tap(() => {
                this.isVisible = false;
                this.isShown = false;
                this.close.emit();
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public show(context?: any) {
        this.modalDialog.show();
        this.isVisible = true;
        this.context = context;
    }
    public hide() {
        this.modalDialog.hide();
        this.isVisible = false;
        this.isShown = false;
        this.context = null;
        this.close.emit();
    }

    public isActionDisabled(action: ModalAction): boolean {
        return (action.isDisabled && action.isDisabled(this.context)) ||
                (action.suspendWhileActionExecuted && this.actionInProgress);
    }

    public onClick(action: ModalAction) {
        if (action && action.callback) {
            if (!this.actionProgressExternallyManaged) { this.actionInProgress = true; }
            const result = action.callback(this.context);
            if (result instanceof Promise) {
                result.then(x => {
                    if (!this.actionProgressExternallyManaged) { this.actionInProgress = false; }
                    if (x === true || x == null) { this.hide(); }
                });
            } else if (typeof result === 'boolean') {
                if (!this.actionProgressExternallyManaged) { this.actionInProgress = false; }
                if (result) {
                    this.hide();
                }
            } else {
                if (!this.actionProgressExternallyManaged) { this.actionInProgress = false; }
                this.hide();
            }
        } else {
            this.hide();
        }
    }

    public onShown() {
        this.isShown = true;
    }
}

@NgModule({
    declarations: [ModalDialogComponent],
    exports: [ModalDialogComponent],
    imports: [CommonModule, TooltipsModule, ModalModule.forRoot()]
})
export class ModalDialogModule { }
