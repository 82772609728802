export function mapMany<TIn, TOut>(input: TIn[], selectorFn: (t: TIn) => TOut[]): TOut[];
export function mapMany<TIn, TOut, TInner>(
    input: TIn[],
    selectorFn: (t: TIn) => TInner[],
    resultSelectorFn: (outer: TIn, inner: TInner) => TOut
): TOut[];
export function mapMany<TIn, TOut, TInner>(
    input: TIn[],
    selectorFn: ((t: TIn) => (TInner)[]) | ((t: TIn) => (TOut)[]),
    resultSelectorFn?: (outer: TIn, inner: TInner) => TOut
): TOut[] {
    return resultSelectorFn ? selectManyOut(input, selectorFn as (t: TIn) => (TInner)[], resultSelectorFn)
        : selectMany(input, selectorFn as (t: TIn) => (TOut)[]);
}

function flatten<T>(a: T[], b: T[]) {
    return a.concat(b);
}

function selectMany<TIn, TOut>(input: TIn[], selectorFn: (t: TIn) => TOut[]): TOut[] {
    return input.map(selectorFn).reduce(flatten, []);
}

function selectManyOut<TIn, TInner, TOut>(
    input: TIn[],
    selectorFn: (t: TIn) => TInner[],
    resultSelectorFn: (outer: TIn, inner: TInner) => TOut
): TOut[] {
    const flattened = input
        .map(x => ({ out: x, inner: selectorFn(x) }))
        .reduce((prev, cur) => {
            if (cur.inner) {
                cur.inner.forEach(inner => prev.push(resultSelectorFn(cur.out, inner)));
            }
            return prev;
        }, [] as TOut[]);
    return flattened;
}
