import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

import { getMessageFromError, PublicPart } from 'ngx-shared';

import { CurrentUserService } from './current-user.service';
import { SystemInformationService } from './system-information.service';

@Injectable({ providedIn: 'root' })
export class CodeVersionService implements OnDestroy {
    private readonly checkIntervalMs = 300000; // 5 minutes
    private readonly webVersionPattern = /Dr.Q Web.*/;
    private readonly apiVersionPattern = /Dr.Q WebApi.*/;

    private webVersion: string;
    private apiVersion: string;

    private destroy$ = new Subject();
    private _codeVersion$ = new BehaviorSubject(null as string[]);

    public hasCodeVersionChangedSinceLastLoad = false;
    public codeVersion$: Observable<string[]> = this._codeVersion$;

    constructor(
        private readonly sysInfoService: SystemInformationService,
        private readonly logger: NGXLogger,
        private readonly currentUser: CurrentUserService
    ) {
        this.codeVersion$ = this._codeVersion$;
        const getServerVersion$ = this.sysInfoService.getComponentVersions().pipe(
            takeUntil(this.destroy$),
            catchError(e => {
                this.logger.error('Error while checking server version:', getMessageFromError(e));
                const fakeServerVersionValue = [this.webVersion, this.apiVersion];
                return [fakeServerVersionValue];
            })
        );

        timer(0, this.checkIntervalMs).pipe(
            takeUntil(this.destroy$),
            switchMap(() => getServerVersion$),
            tap(x => this.checkForNewCodeVersion(x)),
        ).subscribe();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
        this._codeVersion$.complete();
    }

    private checkForNewCodeVersion(versions: string[]) {
        if (Array.isArray(versions) && versions.length) {
            const hasNewApiVersion = checkForNewVersion(this.apiVersion, this.apiVersionPattern, versions, x => this.apiVersion = x);
            const hasNewWebVersion = checkForNewVersion(this.webVersion, this.webVersionPattern, versions, x => this.webVersion = x);

            if (hasNewApiVersion || hasNewWebVersion) {
                this.hasCodeVersionChangedSinceLastLoad = true;

                // if we have active user leave the reload to the user
                // reload the page if there is active user
                if (!this.currentUser.user) {
                    location.reload();
                }
                // TODO maybe start a timer (when there is active user) that will force a page reload regardless of the user's actions

                this._codeVersion$.next(versions);
            } else if (!this._codeVersion$.value) {
                this._codeVersion$.next(versions);
            }

        }
    }
}

function checkForNewVersion(currentVersion: string, pattern: RegExp, versions: string[], onNewVersionGn: (x: string) => void) {
    const newVersion = versions.find(x => pattern.test(x));
    let hasNewVersion = false;
    if (newVersion && currentVersion !== newVersion) {
        if (currentVersion) {
            hasNewVersion = true;
        }

        onNewVersionGn(newVersion);
    }

    return hasNewVersion;
}

/* eslint-disable */
@Injectable({ providedIn: 'root' })
export class FakeCodeVersionService implements OnDestroy, PublicPart<CodeVersionService> {
    public hasCodeVersionChangedSinceLastLoad = false;
    public codeVersion$ = new BehaviorSubject([] as string[]);

    public ngOnDestroy(): void { }
}

export const fakeVersionService = { provide: CodeVersionService, useClass: FakeCodeVersionService };
/* eslint-enable */
