import { Store, Action } from '../ngrx-actions';
import { LoadMnemonicCatalogAction } from './mnemonic.actions';
import { MnemonicState as MnemonicState } from './mnemonic.state';

@Store({} as MnemonicState)
export class MnemonicStore {

    @Action(LoadMnemonicCatalogAction)
    public loadMnemonicCatalog(state: MnemonicState, { mnemonics }: LoadMnemonicCatalogAction) {
        return { ...state, mnemonicCatalog: mnemonics } as MnemonicState;
    }
}
