import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { PublicPart } from 'ngx-shared';
import { SystemConfiguration, SystemParameters } from '@/_models/index';

@Injectable({ providedIn: 'root' })
export class SystemConfigService {
    constructor(private http: HttpClient) { }

    public getPublic(): Observable<SystemConfiguration> {
        return this.http.get<SystemConfiguration>(`${environment.portalWebApi}/SystemConfig/public`);
    }

    public get(): Observable<SystemConfiguration> {
        return this.http.get<SystemConfiguration>(`${environment.portalWebApi}/SystemConfig`);
    }

    public update(config: SystemConfiguration | any): Observable<SystemConfiguration> {
        return this.http.post<SystemConfiguration>(`${environment.portalWebApi}/SystemConfig/update`, config);
    }

    public getSystemParameters(): Observable<SystemParameters> {
        return this.http.get<SystemParameters>(`${environment.portalWebApi}/SystemConfig/systemParameters`);
    }
}

/* eslint-disable */
export class FakeSystemConfigService implements PublicPart<SystemConfigService> {
    public getPublic(): Observable<SystemConfiguration> {
        throw new Error('Not implemented method');
    }
    public get(): Observable<SystemConfiguration> {
        throw new Error('Not implemented method');
    }
    public update(config: SystemConfiguration): Observable<SystemConfiguration> {
        throw new Error('Not implemented method');
    }

    public getSystemParameters(): Observable<SystemParameters> {
        throw new Error('Not implemented method');
    }
}
/* eslint-enable */
