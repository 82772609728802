import { Store, Action } from '../ngrx-actions';
import {
    LoadOrderOverviewsAction, LoadInStateOverviewStatusesAction,
    LoadInStateSectionMonitorInfoAction, LoadInStateSectionMonitorStatusAction,
    LoadInStateSectionTrendDataAction, LoadInStateSectionInstanceDataAction,
    UpdateInStateRuleInstanceStateAction,
    LoadInStateRuleInstanceLastCheckedDataAction,
    LoadInStateSectionIssuesAction,
    LoadInStateRuleInstanceProblemTimeAction,
    UpdateOrderOverviewsAction,
    UpdateInStateRuleInstanceAlertStateAction,
    LoadInStateMonitorMyRigsAction,
    LoadSettingsStateAction,
    LoadHDepDecimatedLogInStateAction,
    LoadBDepDecimatedLogInStateAction,
    LoadInStateMatchingIssuesAction,
    RequestDataRefreshAction,
    SaveSectionIdInStoreAction,
    SavePlottableLogsInStateAction,
    SaveAdditionalDecimatedCurveInStateAction,
    ClearMonitorStateAction,
    SaveAdditionalDecimatedCurvesInStateAction,
    SaveDrqKpiScoresInStateAction,
    SaveDapKpiScoresInStateAction,
    SaveSelectionInfoInStateAction,
    SaveDrqKpiTrendInStateAction,
    SaveDapKpiTrendInStateAction,
    ChangeSettingsTrendPeriod,
} from './monitor.actions';
import { MonitorState } from './monitor.state';
import { SectionMonitorRuleInstance, IndicatorStatus, RuleInstanceLastCheckedData, DecimatedLog } from '@/_models';

@Store({} as MonitorState)
export class MonitorStore {

    @Action(SaveSectionIdInStoreAction)
    public saveSectionIdInStore(state: MonitorState, { sectionId }: SaveSectionIdInStoreAction) {
        return { ...state, sectionId } as MonitorState;
    }
    
    @Action(LoadOrderOverviewsAction)
    public loadOrderOverviews(state: MonitorState, { orderOverviews }: LoadOrderOverviewsAction) {
        return { ...state, orderOverviews } as MonitorState;
    }

    @Action(UpdateOrderOverviewsAction)
    public updateOrderOverviews(state: MonitorState, { sectionId, newState }: UpdateOrderOverviewsAction) {
        const orderOverviews = state.orderOverviews.map(x => ({...x}));
        const section = orderOverviews.find(o => o.sectionId === sectionId);
        if (!section) {
            return { ...state } as MonitorState;
        }

        section.status = newState;

        return { ...state, orderOverviews };
    }

    @Action(LoadInStateOverviewStatusesAction)
    public loadOverviewsStatuses(state: MonitorState, { statuses }: LoadInStateOverviewStatusesAction) {
        return { ...state, overviewStatuses: statuses } as MonitorState;
    }

    @Action(LoadInStateSectionMonitorInfoAction)
    public loadSectionMonitorInfo(state: MonitorState, { data }: LoadInStateSectionMonitorInfoAction) {
        return { ...state, sectionMonitorInfo: data } as MonitorState;
    }

    @Action(LoadInStateSectionMonitorStatusAction)
    public loadInStateSectionMonitorStatus(state: MonitorState, { data }: LoadInStateSectionMonitorStatusAction) {
        return { ...state, sectionMonitorStatus: data } as MonitorState;
    }

    @Action(LoadInStateSectionTrendDataAction)
    public loadInStateSectionTrendData(state: MonitorState, { data }: LoadInStateSectionTrendDataAction) {
        return { ...state, sectionTrendData: data } as MonitorState;
    }

    @Action(LoadInStateSectionInstanceDataAction)
    public loadInStateSectionInstanceData(state: MonitorState, { data }: LoadInStateSectionInstanceDataAction) {
        return { ...state, sectionInstanceData: data } as MonitorState;
    }

    @Action(LoadInStateSectionIssuesAction)
    public loadInStateSectionIssues(state: MonitorState, { issues }: LoadInStateSectionIssuesAction) {
        return { ...state, sectionIssues: issues } as MonitorState;
    }

    @Action(LoadInStateRuleInstanceProblemTimeAction)
    public loadInStateRuleInstanceProblemTime(state: MonitorState, { ruleInstancesProblemTime }: LoadInStateRuleInstanceProblemTimeAction) {
        return { ...state, ruleInstancesProblemTime } as MonitorState;
    }

    @Action(UpdateInStateRuleInstanceStateAction)
    public updateInStateRuleInstanceState(state: MonitorState, { ids, isEnabled }: UpdateInStateRuleInstanceStateAction) {
        if (!Array.isArray(ids)) {
            throw new Error('Could not update rule instance state: Invalid rule instance collection.');
        }
        if (!state || !state.sectionInstanceData || !Array.isArray(state.sectionInstanceData.instances)) {
            throw new Error('Could not update rule instance state: Invalid state.');
        }

        const updateIssue = (x: SectionMonitorRuleInstance) => {
            // note: the case where instance is disabled and has open issue is an invalid case.
            // we will not explicitly handle it here, but we will not show warning (for now) either
            const shouldCloseIssueAndDisable = !isEnabled && !x.issueEndRigTime && x.issueStartRigTime;
            let status = x.status;

            if (shouldCloseIssueAndDisable) {
                status = IndicatorStatus.Disabled;
            } else {
                if (isEnabled) { // new enabled status is true
                    if (!x.enabled) { // old enabled status is false
                        status = IndicatorStatus.Valid; // it was disabled before, now is valid
                    }
                    // else - if old and new are the same - do nothing
                } else {
                    status = IndicatorStatus.Disabled;
                }
            }

            return {
                ...x,
                enabled: isEnabled,
                issueEndRigTime: shouldCloseIssueAndDisable ? state.sectionMonitorInfo.rigLocalTime : x.issueEndRigTime,
                status
            };
        };

        const newInstances = state.sectionInstanceData.instances
            .map(x => ids.some(id => id === x.ruleInstanceId) ? updateIssue(x) : x);

        return { ...state, sectionInstanceData: { ...state.sectionInstanceData, instances: newInstances } } as MonitorState;
    }

    @Action(UpdateInStateRuleInstanceAlertStateAction)
    public updateInStateRuleInstanceAlertState(state: MonitorState, { ids, isEnabled }: UpdateInStateRuleInstanceStateAction) {
        if (!Array.isArray(ids)) {
            throw new Error('Could not update rule instance state: Invalid rule instance collection.');
        }
        if (!state || !state.sectionInstanceData || !Array.isArray(state.sectionInstanceData.instances)) {
            throw new Error('Could not update rule instance state: Invalid state.');
        }

        const updateIssue = (x: RuleInstanceLastCheckedData) => {
            return {
                ...x,
                alertShouldCreate: isEnabled,
            };
        };

        const newData = state.ruleInstanceLastCheckedData
            .map(x => ids.some(id => id === x.id) ? updateIssue(x) : x);

        return { ...state, ruleInstanceLastCheckedData: newData } as MonitorState;
    }


    @Action(LoadInStateRuleInstanceLastCheckedDataAction)
    public loadInStateRuleInstanceLastCheckedData(state: MonitorState, { data }: LoadInStateRuleInstanceLastCheckedDataAction) {
        return { ...state, ruleInstanceLastCheckedData: data } as MonitorState;
    }

    @Action(LoadInStateMonitorMyRigsAction)
    public loadInStateMyRigs(state: MonitorState, { myRigs }: LoadInStateMonitorMyRigsAction) {
        return { ...state, myRigs: myRigs } as MonitorState;
    }

    @Action(LoadSettingsStateAction)
    public loadSettings(state: MonitorState, { settings }: LoadSettingsStateAction) {
        return { ...state, settings } as MonitorState;
    }

    @Action(ChangeSettingsTrendPeriod)
    public changeSettingsTrendPeriod(state: MonitorState, { trendPeriod }: ChangeSettingsTrendPeriod) {
        return { ...state, settings: { ...state.settings, trendPeriod: trendPeriod } } as MonitorState;
    }

    @Action(LoadHDepDecimatedLogInStateAction)
    public loadHDepDecimatedLog(state: MonitorState, { log }: LoadHDepDecimatedLogInStateAction) {
        return { ...state, hDepLog: log } as MonitorState;
    }

    @Action(LoadBDepDecimatedLogInStateAction)
    public loadBDepDecimatedLog(state: MonitorState, { log }: LoadBDepDecimatedLogInStateAction) {
        return { ...state, bDepLog: log } as MonitorState;
    }
    
    @Action(LoadInStateMatchingIssuesAction)
    public loadInStateMatchingIssues(state: MonitorState, { matchingIssuesResult }: LoadInStateMatchingIssuesAction) {
        return { ...state, matchingIssuesResult: matchingIssuesResult } as MonitorState;
    }

    @Action(RequestDataRefreshAction)
    public requestDataRefresh(state: MonitorState, { timestamp }: RequestDataRefreshAction) {
        return { ...state, refreshRequest: timestamp } as MonitorState;
    }

    @Action(SavePlottableLogsInStateAction)
    public savePlottableLogsInState(state: MonitorState, { plottableLogs }: SavePlottableLogsInStateAction) {
        return { ...state, plottableLogs } as MonitorState;
    }

    @Action(SaveAdditionalDecimatedCurveInStateAction)
    public saveAdditionalDecimatedCurveInState(state: MonitorState, { log }: SaveAdditionalDecimatedCurveInStateAction) {
        if (log == null) { return; }
        const logs = state.additionalCurves?.map(l => ({...l} as DecimatedLog)) ?? [];
        const index = logs.findIndex(l => l.tag === log.tag && l.sectionId === log.sectionId);
        if (index === -1) { logs.push(log); }
        else { logs[index] = log; }
        return { ...state, additionalCurves: logs } as MonitorState;
    }

    @Action(SaveAdditionalDecimatedCurvesInStateAction)
    public saveAdditionalDecimatedCurvesInState(state: MonitorState, { logs }: SaveAdditionalDecimatedCurvesInStateAction) {
        if (logs == null) { return; }
        const currentLogs = state.additionalCurves?.map(l => ({...l} as DecimatedLog)) ?? [];
        logs.forEach(log => {
            const index = currentLogs.findIndex(l => l.tag === log.tag && l.sectionId === log.sectionId);
            if (index === -1) { currentLogs.push(log); }
            else { currentLogs[index] = log; }
        });
        return { ...state, additionalCurves: currentLogs } as MonitorState;
    }

    @Action(SaveDrqKpiScoresInStateAction)
    public saveDrqKpiScoresInState(state: MonitorState, { scores }: SaveDrqKpiScoresInStateAction) {
        return { ...state, drqKpiScore: scores } as MonitorState;
    }

    @Action(SaveDapKpiScoresInStateAction)
    public saveDapKpiScoresInState(state: MonitorState, { scores }: SaveDapKpiScoresInStateAction) {
        return { ...state, dapKpiScore: scores } as MonitorState;
    }

    @Action(SaveSelectionInfoInStateAction)
    public saveSelectionInfoInState(state: MonitorState, { selectionInfo }: SaveSelectionInfoInStateAction) {
        return { ...state, selectionInfo: selectionInfo } as MonitorState;
    }

    @Action(SaveDrqKpiTrendInStateAction)
    public saveDrqKpiTrendInState(state: MonitorState, { trends }: SaveDrqKpiTrendInStateAction) {
        return { ...state, drqScoreTrends: trends } as MonitorState;
    }

    @Action(SaveDapKpiTrendInStateAction)
    public saveDapKpiTrendInState(state: MonitorState, { trends }: SaveDapKpiTrendInStateAction) {
        return { ...state, dapScoreTrends: trends } as MonitorState;
    }

    @Action(ClearMonitorStateAction)
    public clearMonitorState() {
        return {} as MonitorState;
    }

}
