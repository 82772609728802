export enum Severity {
    info = 1,
    warning = 2,
    error = 3
}

export class ValidationMessage {
    public field?: string;
    public rowIndex?: number;
    public message: string;
    public severity: Severity;
    public originalValue?: any;
    public context?: any;
}
export class ValidationResult {
    public messages: ValidationMessage[];
    public isValid: boolean;
}
