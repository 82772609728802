export class PickListItem {
    public picklistId: number;
    public id: number;
    public name: string;
    /** This indicates if the picklistItem is system defined. This means that you can't rename or delete it. */
    public isDefault: boolean;
    public priority: number;
    public scopingPicklistItemId: number;
    public ordinal: number;
    public isHidden: boolean;
    public disabled: boolean;

    public static New(id: number, name: string, picklistId?: number) {
        return {
            id,
            name,
            picklistId
        } as PickListItem;
    }
}
