function hex(c: number): string {
    const s = '0123456789abcdef';
    let i = c;
    if (i === 0 || isNaN(c)) {
        return '00';
    }
    i = Math.round(Math.min(Math.max(0, i), 255));
    return s.charAt((i - i % 16) / 16) + s.charAt(i % 16);
}

function convertToHex(rgb: number[]): string {
    return '#' + hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);
}

function trim(s: string) { return (s.charAt(0) === '#') ? s.substring(1, 7) : s; }

function convertToRGB(hexColor: string): number[] {
    const color = [];
    color[0] = parseInt((trim(hexColor)).substring(0, 2), 16);
    color[1] = parseInt((trim(hexColor)).substring(2, 4), 16);
    color[2] = parseInt((trim(hexColor)).substring(4, 6), 16);
    return color;
}

export function calculateGradientColor(startColorHex: string, endColorHex: string, position: number): string {
    const start = convertToRGB(startColorHex);
    const end = convertToRGB(endColorHex);

    const c = [];

    c[0] = end[0] * position + (1 - position) * start[0];
    c[1] = end[1] * position + (1 - position) * start[1];
    c[2] = end[2] * position + (1 - position) * start[2];

    return convertToHex(c);
}
