export class KpiIssue {
    public id: number;

    public startTimeUtc: Date;
    public endTimeUtc: Date;

    public categoryService: string;
    public categoryTypeName: string;
    public subjectType: string;
    public subjectName: string;
    public ruleId: number;
    public ruleName: string;

    public isTimeIssue: boolean;
    public isDepthIssue: boolean;
    public isProblemTime: boolean;
    public problemTimeDuration: number;

    // text because of how the odata works and handles easier comparisons with enums
    public reasonCode: number;
    public reasonCodeDescription: string;
    public comment: string;
    public status: string;
}
