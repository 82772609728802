import { AlertStateStatus } from '@/_models';
import { AlertStateStatusName } from '../alert-state-status-name';

export function getAlertState(stateName: AlertStateStatusName): AlertStateStatus {
    let state: AlertStateStatus;

    switch (stateName) {
        case AlertStateStatusName.Created: state = AlertStateStatus.Created; break;
        case AlertStateStatusName.Acknowledged: state = AlertStateStatus.Acknowledged; break;
        case AlertStateStatusName.Resolved: state = AlertStateStatus.Resolved; break;
        case AlertStateStatusName.Closed: state = AlertStateStatus.Closed; break;
        default: {
            throw new Error('Unknown state: ' + state);
        }
    }

    return state;
}
