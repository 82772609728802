import { DapDapStatus } from '@/_models/dap-common';
import { DapTemplateContentDapType } from '@/_models/dap-document-details';
import { DapDataContainerVM } from './dap-data-container-vm';
import { DapDocumentVM } from './dap-document-vm';


export class DapDapVM extends DapDataContainerVM {
    public isNew = false; // indicates that the DAP has just been added and not yet saved
    public key: string;
    public name: string;
    public templateContent: DapTemplateContentDapType;
    public status: DapDapStatus;
    public dapRefNum: number;
    public serviceProviderId: number;
    // backwards reference to the doc VM
    public docVM: DapDocumentVM;
}
