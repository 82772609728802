export enum Routes {
    default = '',
    serverError = 'server-error',  

    loginFailed = 'login-failed',
    postLogout = 'logout',
    
    home = 'home',
    alerts = 'alerts',
    orderManagement = 'order',
    monitor = 'monitor',
    monitorSection = 'monitor/section',
    kpi = 'kpi',
    mnemonics = 'mnemonics',
    rules = 'rules',
    transferManagement = 'transfer-management',
    merlinManagement = 'merlin-management',
    admin = 'admin',
    adminSystemConfig = 'admin/sys-config',
    adminUsers = 'admin/users',
    adminPickLists = 'admin/pick-lists',
    adminConnections = 'admin/targets',
    adminAppliances = 'admin/appliances',
    editingMonitoring = 'editing-monitoring',
    // DAP
    dapTemplateManagement = 'dap/dap-template-management',
    dapDocumentOverview = 'dap/document-overview',
    dapStatusOverview = 'dap/status-overview',
    dapDocumentDetails = 'dap/dap-document-details',
    dapDocumentStatus = 'dap/document-status',
    dapLicenseManagement = 'dap/dap-license-management'
}
