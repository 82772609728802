import { Type } from '@angular/core';
import { Observable } from 'rxjs';

export class WidgetSettings {
    colSpan?: 1 | 2 | 3 | 4 | 6 | 12;
    colXlSpan?: 1 | 2 | 3 | 4 | 6 | 12;
    colLgSpan?: 1 | 2 | 3 | 4 | 6 | 12;
    colMdSpan?: 1 | 2 | 3 | 4 | 6 | 12;
    colSmSpan?: 1 | 2 | 3 | 4 | 6 | 12;
    isHeader?: boolean;
    rowIndex?: number;
    componentClass: Type<any>;
    input?: any | Observable<any>;
    output?: (value: any) => void;
}

export class DashboardSettings {
    totalColumns?: 1 | 2 | 3 | 4 | 6 | 12;
    widgets: WidgetSettings[];
}
