/**
 * Checks if the array consists of identical values
 * @param {T[]} collection
 * @returns {T} Returns the matching value or <i>null</i> on inconsistent values or empty/undefined array
 */
export function everyOrNone<T>(collection: T[]): T {
    if (collection && Array.isArray(collection)) {
        return new Set(collection).size === 1 ? collection[0] : null;
    }

    return null;
}
