import { Action } from '@ngrx/store';
import { InfraPanel } from '@/_models';

export class LoadInStateInfraPanelsAction implements Action {
    readonly type = '[InfraPanels] InState Load StateInfraPanels action';

    constructor(public readonly infraPanels: InfraPanel[]) { }
}

export class FetchInfraPanelsAction implements Action {
    public type = '[InfraPanels] Fetch InfraPanels action';
}
export class FetchOkInfraPanelsAction implements Action {
    public type = '[InfraPanels] Fetch OK InfraPanels action';
}
export class FetchFailedInfraPanelsAction implements Action {
    public type = '[InfraPanels] Fetch Cancel InfraPanels action';
    constructor(public readonly error: Error | string | any) { }
}
