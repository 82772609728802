import { DapSectionStatus } from '@/_models/dap-common';
import { DapTemplateContentSectionType } from '@/_models/dap-document-details';
import { DapDapVM } from './dap-dap-vm';
import { DapDataContainerVM } from './dap-data-container-vm';
import { DapDocumentVM } from './dap-document-vm';

export class DapSectionVM extends DapDataContainerVM {
    public key: string;
    public name: string;
    public templateContent: DapTemplateContentSectionType;
    public dapVMs: DapDapVM[] = [];
    public status: DapSectionStatus;
    // backwards reference to the doc VM
    public docVM: DapDocumentVM;
}
