import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPickerDialogComponent } from './user-picker-dialog.component';
import { ModalDialogModule } from '../modal-dialog/modal-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [UserPickerDialogComponent],
  exports: [UserPickerDialogComponent],
    imports: [ ModalDialogModule, CommonModule, ReactiveFormsModule ],
})
export class UserPickerDialogModule { }
