export class RuleManagementRuleInstance {
    constructor(
        public ruleInstanceId: number,
        public ruleImplementationId: number,
        public subjectType: string,
        public subjectName: string,
        public ruleName: string,
        public serviceProvider: string,
        public service: string,
        public tool: string,
        public indexType: string,
        public isMemory: boolean,
        public lagType: string,
        public uom: string,
        public orderId: number,
        public target: string,
        public overrides: number,
        public overriddenTargets: number[],
    ) {}
}

export class RuleManagementRuleInstanceData {
    constructor(
        public items: RuleManagementRuleInstance[],
        public areTooMany: boolean,
        public maxCount: number,
    ) {}
}

export enum CopyOverridesMode {
    ActiveSection = 1,
    Order = 2,
    Global = 3
}
