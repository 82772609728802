import { AlertStateStatusName } from '../alert-state-status-name';
import { AlertStateStatus } from '@/_models';

export function getAlertStateName(state: AlertStateStatus): AlertStateStatusName {
    let text: AlertStateStatusName;

    switch (state) {
        case AlertStateStatus.Created: text = AlertStateStatusName.Created; break;
        case AlertStateStatus.Acknowledged:  text = AlertStateStatusName.Acknowledged; break;
        case AlertStateStatus.Resolved: text = AlertStateStatusName.Resolved; break;
        case AlertStateStatus.Closed:  text = AlertStateStatusName.Closed; break;
        default: {
            throw new Error('Unknown state: ' + state);
        }
    }

    return text;
}
