/***
 * Rounds to the most X digit, if necessary
 * @param {number} value
 * @param {number} precision Greater than 0
 * @returns {number}
 * @throws {Error} on missing value or invalid precision
 */
export function roundWithPrecision(value: number, precision: number): number | never {
    if (precision == null) {
        throw new Error('Precision is not provided');
    }

    if (precision <= 0) {
        throw new Error('Cannot have precision of less than 1');
    }

    if (value == null) {
        throw new Error('Value is not provided');
    }

    const roundPrecision = 10 ** precision;
    return Math.round((value + Number.EPSILON) * roundPrecision) / roundPrecision;
}
