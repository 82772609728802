import { DapCurve, DapCurveProps, DapDocumentContentField, DapDocumentContentFieldValue } from "@/_models/dap-document-details";

export class DapCurveHelper {
    public static mapDapCurveToFieldValue(curve: DapCurve) {
        const fieldValue = new DapDocumentContentFieldValue();
        fieldValue.str = curve.mnemonic;
        fieldValue.props = fieldValue.props || [];
        fieldValue.props.push({key: DapCurveProps.CatalogId, int: curve.id} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.ServiceId, int: curve.serviceId} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.ServiceProviderId, int: curve.serviceProviderId} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.Tool, str: curve.tool} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.CurveIndexTypeId, int: curve.curveIndexTypeId} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.Mnemonic, str: curve.mnemonic} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.LogName, str: curve.logName} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.Comments, str: curve.comments} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.GlobalMnemonic, str: curve.globalMnemonic} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.GlobalLogName, str: curve.globalLogName} as DapDocumentContentField);

        fieldValue.props.push({key: DapCurveProps.Label, str: curve.label} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.IndexType, str: curve.indexType} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.IsCommon, bool: curve.isCommon} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.Required, bool: curve.required} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.LagType, str: curve.lagType} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.Uom, str: curve.uom} as DapDocumentContentField);
        fieldValue.props.push(
            {key: DapCurveProps.DataPointFrequency, measure: curve.dataPointFrequency} as DapDocumentContentField);
        fieldValue.props.push(
            {key: DapCurveProps.ReceiveFrequency, measure: curve.receiveFrequency} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.ToolUpdatePeriod, measure: curve.toolUpdatePeriod} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.MemoryMnemonic, str: curve.memoryMnemonic} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.MemoryLogName, str: curve.memoryLogName} as DapDocumentContentField);
        fieldValue.props.push({key: DapCurveProps.Tag, str: curve.tags} as DapDocumentContentField);
        return fieldValue;
    }
}
