import { DapDocumentStatus } from '../dap-common/dap-status';

export class DapDocumentData {
    public status: DapDocumentStatus;
    public approvals: DapDocumentApproval[];
}

export class DapDocumentApproval {
    public id: number;
    public dapDocumentId: number;
    public role: string;
    public approvedBy: string;
    public approved: Date;
    public approvalComment: string;
}
