import { Observable } from 'rxjs';

// The PNG is the widely supported format. The embedded GIF is not showing in Outlook.
export function resizeImage(file: File, height: number, exportType = 'image/png'): Observable<any> {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Observable(observer => {
        reader.onload = ev => {
            const img = new Image();
            img.src = (ev.target as any).result;
            (img.onload = () => {
                const elem = document.createElement('canvas');
                const scaleFactor = height / img.height;
                elem.width = img.width * scaleFactor;
                elem.height = height;
                const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'high';
                ctx.drawImage(img, 0, 0, img.width * scaleFactor, height );
                ctx.canvas.toBlob(
                blob => {
                    observer.next(
                        new File([blob], file.name, {
                            type: exportType,
                            lastModified: Date.now(),
                        }),
                    );
                    observer.complete();
                },
                exportType);
            }),
            (reader.onerror = error => observer.error(error));
        };
    });
}
