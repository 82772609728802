import { ParameterType } from '../rule-parameter-types';
import { RuleParameterCategory } from '../rule-parameter-category';

export class RuleInstanceParameterWithOverrides {
    constructor(
        public orderId: number,
        public ruleInstanceId: number,
        public ruleInstanceParameterId: number,
        public ruleParameterDefinitionId: number,
        public subjectType: string,
        public subjectName: string,
        public monitor: string,
        public name: string,
        public type: ParameterType,
        public category: RuleParameterCategory,
        public uom: string,
        public defaultValue: number,
        public defaultValueString: string,
        public defaultValueBool: boolean,

        public activeOverrideValue: number,
        public activeOverrideValueString: string,
        public activeOverrideValueBool: boolean,

        public orderOverrideValue: number,
        public orderOverrideValueString: string,
        public orderOverrideValueBool: boolean,

        public globalOverrideValue: number,
        public globalOverrideValueString: string,
        public globalOverrideValueBool: boolean,

        public targetOverrides: RuleParameterTargetOverride[],

        public availableValues: string[]
    ) {}
}

export class RuleParameterTargetOverride {
    constructor(
        public targetId: number,

        public targetOverrideValue: number,
        public targetOverrideValueString: string,
        public targetOverrideValueBool: boolean,
    ) {}
}
