import { Action, Store } from '../ngrx-actions';
import { ClearDocumentStatusStoreAction, LoadDapDocumentContentAction,
    LoadHistoricalAverageInStoreAction, RequestFilterFieldsUpdateAction, ClearFilterFieldsUpdateRequestAction,
    UpdateFilterFromInStore, UpdateFilterInStoreAction, UpdateFilterPeriodInStore,
    UpdateFilterToInStore } from './dap-document-status.actions';
import { DapDocumentStatusState } from './dap-document-status.state';
import * as clone from 'clone';
import { DapDapHistoricalAverage, DapDapStatusData, DapDocumentStatusData } from '@/_models/dap-document-status';

function updateHistoricalScores(state: DapDocumentStatusData, scores: DapDapHistoricalAverage[]) {
    state?.sections?.forEach(s => {
        s.daps?.forEach(d => {
            const score = scores.find(sc => sc.id === d.id);
            if (score) {
                d.historicalScore = score.score;
                d.hasHistoricalFilter = score.hasFilter;
            }
        });
    });
}

@Store({} as DapDocumentStatusState)
export class DapDocumentStatusStore {

    @Action(LoadDapDocumentContentAction)
    public loadDocumentContent(state: DapDocumentStatusState, { documentStatus }: LoadDapDocumentContentAction) {
        // DAP historical score are obtained via LoadHistoricalAverageInStoreAction
        // in documentStatus these properties are null and should be retained form the current state
        const status = clone(documentStatus);
        if (state?.documentStatus) {
            const scores = state.documentStatus.sections.reduce<DapDapStatusData[]>((r, c) => r.concat(c.daps ?? []), [])
                                .map<DapDapHistoricalAverage>(d => ({
                                    id: d.id,
                                    hasFilter: d.hasHistoricalFilter,
                                    score: d.historicalScore
                                })) ?? [];
            updateHistoricalScores(status, scores);
        }
        return { ...state, documentStatus: status } as DapDocumentStatusState;
    }

    @Action(UpdateFilterInStoreAction)
    public updateFilterInStore(state: DapDocumentStatusState, { filter }: UpdateFilterInStoreAction) {
        return { ...state, filter } as DapDocumentStatusState;
    }

    @Action(UpdateFilterPeriodInStore)
    public updateFilterPeriodInStore(state: DapDocumentStatusState, { from, to }: UpdateFilterPeriodInStore) {
        const filter = {...state.filter};
        filter.from = from;
        filter.to = to;
        return { ...state, filter } as DapDocumentStatusState;
    }

    @Action(UpdateFilterFromInStore)
    public updateFilterFromInStore(state: DapDocumentStatusState, { date }: UpdateFilterFromInStore) {
        const filter = {...state.filter};
        filter.from = date;
        return { ...state, filter } as DapDocumentStatusState;
    }

    @Action(UpdateFilterToInStore)
    public updateFilterToInStore(state: DapDocumentStatusState, { date }: UpdateFilterToInStore) {
        const filter = {...state.filter};
        filter.to = date;
        return { ...state, filter } as DapDocumentStatusState;
    }

    @Action(LoadHistoricalAverageInStoreAction)
    public loadHistoricalAverageInStore(state: DapDocumentStatusState, { scores }: LoadHistoricalAverageInStoreAction) {
        const documentStatus = clone(state.documentStatus) as DapDocumentStatusData;
        updateHistoricalScores(documentStatus, scores);
        return { ...state, documentStatus } as DapDocumentStatusState;
    }

    @Action(ClearDocumentStatusStoreAction)
    public clearDocumentStatusStoreAction(state: DapDocumentStatusState) { // eslint-disable-line @typescript-eslint/no-unused-vars
        return {} as DapDocumentStatusState;
    }

    @Action(RequestFilterFieldsUpdateAction)
    public requestFilterFieldsUpdate(state: DapDocumentStatusState) {
        const filterFieldsUpdateRequested = true;
        return { ...state, filterFieldsUpdateRequested } as DapDocumentStatusState;
    }

    @Action(ClearFilterFieldsUpdateRequestAction)
    public clearFilterFieldsUpdateRequest(state: DapDocumentStatusState) {
        const filterFieldsUpdateRequested = false;
        return { ...state, filterFieldsUpdateRequested } as DapDocumentStatusState;
    }

}

