import { Action, Store } from '../ngrx-actions';
import {
    LoadAllActiveSectionsAction, LoadApplianceJobsAction, LoadApplianceServicesAction, LoadTransferFileDetailsAction, LoadTransferFilesAction, UpdateInStateApplianceServiceAction
} from './transfer-management.actions';
import { TransferManagementState } from './transfer-management.state';

@Store({} as TransferManagementState)
export class TransferManagementStore {

    @Action(LoadAllActiveSectionsAction)
    public loadAppliances(state: TransferManagementState, { activeSections }: LoadAllActiveSectionsAction) {
        return { ...state, activeSections } as TransferManagementState;
    }

    @Action(LoadApplianceJobsAction)
    public loadJobs(state: TransferManagementState, { jobs }: LoadApplianceJobsAction) {
        return { ...state, jobs } as TransferManagementState;
    }
    
    @Action(LoadApplianceServicesAction)
    public loadServices(state: TransferManagementState, { services }: LoadApplianceServicesAction) {
        return { ...state, services } as TransferManagementState;
    }

    @Action(LoadTransferFilesAction)
    public loadTransferFiles(state: TransferManagementState, { files }: LoadTransferFilesAction) {
        return { ...state, files } as TransferManagementState;
    }
    
    @Action(LoadTransferFileDetailsAction)
    public loadTransferFileDetails(state: TransferManagementState, { fileDetails }: LoadTransferFileDetailsAction) {
        return { ...state, fileDetails } as TransferManagementState;
    }

    @Action(UpdateInStateApplianceServiceAction)
    public updateService(state: TransferManagementState, { service }: UpdateInStateApplianceServiceAction) {
        if (state.services != null) {
            const index = state.services.findIndex(x => x.id === service.id);
            if (index >= 0) {
                const newServices = [...state.services];
                newServices[index] = { ...service };
                return { ...state, services: newServices } as TransferManagementState;
            }
        }
    }
}
