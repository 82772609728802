import { CommonModule} from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutModule } from '../shared/layout';

import { NgxHeaderComponent } from './ngx-header.component';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule
  ],
  exports: [
    NgxHeaderComponent,
    LayoutModule
  ],
  declarations: [
    NgxHeaderComponent
  ]
})
export class NgxHeaderModule {}
