import { CommonModule } from '@angular/common';
import { Component, forwardRef, NgModule, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { Measure, SiMeasurementSystemCompatibleUnits } from '@/_models';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfigurationService } from '@/_services/configuration.service';

@Component({
    selector: 'app-measure-input',
    template: `
<div class="d-flex">
    <input
        #valueInput
        class="form-control"
        type="number"
        [disabled]="isDisabled"
        [class.is-invalid]="isInvalid"
        (ngModelChange)="onMeasureValueChange($event)"
        [(ngModel)]="measureValue"
    />
    <ng-select
        *ngIf="orderedUnits != null && orderedUnits.length > 0"
        class="form-control flex-grow-1 ml-1 w-50"
        [class.is-invalid]="isInvalid"
        [title]="measureUoM"
        [disabled]="isDisabled || isUoMDisabled"
        [clearable]="false"
        [searchable]="false"
        [items]="orderedUnits"
        (ngModelChange)="onMeasureUomChange($event)"
        [(ngModel)]="measureUoM"
    >
        <ng-template ng-option-tmp let-item="item">
            <span title="{{item}}">{{item}}</span>
        </ng-template>
    </ng-select>
    <input
        *ngIf="!(orderedUnits != null && orderedUnits.length > 0)"
        type="text"
        class="form-control flex-grow-1 ml-1 w-50"
        [class.is-invalid]="isInvalid"
        [title]="measureUoM"
        [disabled]="isDisabled || isUoMDisabled"
        (ngModelChange)="onMeasureUomChange($event)"
        [(ngModel)]="measureUoM"
    />
</div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MeasureInputComponent),
            multi: true
        }
    ]
})
export class MeasureInputComponent implements OnInit, ControlValueAccessor {
    @Input() public isInvalid = false;
    @Input() public units: string[] = null;
    @Input() public isUoMDisabled = false;
    public measureValue: number;
    public measureUoM: string;
    public isDisabled = false;
    public orderedUnits: string[] = null;

    @ViewChild('valueInput', { read: ElementRef }) public valueInput!: ElementRef;

    constructor(private readonly configService: ConfigurationService) { }

    ngOnInit(): void {
        const useMetricSystem = this.configService.getSettings('unitSystem')?.toLocaleLowerCase() !== 'english';
        this.orderUnitsByConfiguredSystem(this.units, useMetricSystem);
    }

    public onMeasureValueChange(newMeasureValue: number) {
        if (this.orderedUnits != null && this.orderedUnits.length > 0 && this.measureValue == null) {
            this.measureUoM = this.orderedUnits[0];
        }
        this.onMeasureChange(newMeasureValue, this.measureUoM);
    }

    public onMeasureUomChange(newMeasureUom: string) {
        this.onMeasureChange(this.measureValue, newMeasureUom);
    }

    public onMeasureChange(measureValue: number, measureUom: string) {
        const newMeasure = measureValue == null ? null : new Measure(measureValue, measureUom);
        this.onChange(newMeasure);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    public onChange = (_: Measure) => { };

    writeValue(measure: Measure): void {
        if (measure != null) {
            this.measureValue = measure.value;
            this.measureUoM = measure.uom;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    orderUnitsByConfiguredSystem(units: string [], useMetricSystem: boolean) {
        this.orderedUnits = [];
        if (units?.length > 0) {
            if (units.length == 1) {
                this.orderedUnits = [units[0]];
            } else {
                const metric = [];
                const imperial = [];
                units.forEach(u => {
                    if (SiMeasurementSystemCompatibleUnits[u]) {
                        metric.push(u);
                    } else {
                        imperial.push(u);
                    }
                });
                
                this.orderedUnits = useMetricSystem ? metric.concat(imperial) : imperial.concat(metric);
            }
        }
    }
}

@NgModule({
    declarations: [MeasureInputComponent],
    exports: [MeasureInputComponent],
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule
    ]
})
export class MeasureInputModule { }
