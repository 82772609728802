export class KpiProblemTimeAggregationPoint {
    public timestamp: Date;

    /** Holds the time only issue data combined with overrides. */
    public timeCount: number;
    /** Holds the depth only issue data combined with overrides. */
    public depthCount: number;

    public issueTime: number;
    public issueDepth: number;

    public overrideTime: number;
    public overrideDepth: number;
}
