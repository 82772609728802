export enum DapDocumentStatus {
    Draft = 0,
    Open = 1,
    Approval = 2,
    Approved = 3,
    Active = 4,
    Completed = 5,
    Abandoned = 6,
    ReadyForApproval = 7,
}

export enum DapSectionStatus {
    Design = 0,
    Active = 1,
    Completed = 2,
    Abandoned = 3,
}

export enum DapDapStatus {
    Design = 0,
    Active = 1,
    Scored = 2,
    Completed = 3,
    Abandoned = 4,
}
