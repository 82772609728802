import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { NgxAlertService, getMessageFromError } from 'ngx-shared';

import { TransferManagementService } from '@/_services/transfer-management.service';
import {
    FetchAllActiveSectionsAction, FetchAllActiveSectionsFailedAction, FetchAllActiveSectionsOkAction, LoadAllActiveSectionsAction,
    FetchApplianceJobsAction, FetchApplianceJobsFailedAction, FetchApplianceJobsOkAction, LoadApplianceJobsAction,
    FetchApplianceServicesAction, FetchApplianceServicesFailedAction, FetchApplianceServicesOkAction, LoadApplianceServicesAction,
    ClearTransferManagementDataAction,
    RunServiceCommandAction, RunServiceCommandFailedAction, RunServiceCommandOkAction, UpdateInStateApplianceServiceAction,
    FetchTransferFilesAction, LoadTransferFilesAction, FetchTransferFilesOkAction, FetchTransferFilesFailedAction,
    FetchTransferFileDetailsAction,
    LoadTransferFileDetailsAction,
    FetchTransferFileDetailsOkAction,
    FetchTransferFileDetailsFailedAction
} from './transfer-management.actions';

@Injectable()
export class TransferManagementEffects {

    public onFetchAllActiveSections$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchAllActiveSectionsAction),
        switchMap(() => this.transferManagementService.getAll().pipe(
            switchMap(x => [new LoadAllActiveSectionsAction(x), new FetchAllActiveSectionsOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchAllActiveSections ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchAllActiveSectionsFailedAction(error));
            })
        ))
    ));

    public onFetchApplianceJobs$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchApplianceJobsAction),
        switchMap(x => this.transferManagementService.getJobs(x.slot).pipe(
            switchMap(jobs => [new LoadApplianceJobsAction(jobs), new FetchApplianceJobsOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchApplianceJobs ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchApplianceJobsFailedAction(error));
            })
        ))
    ));
    
    public onFetchApplianceServices$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchApplianceServicesAction),
        switchMap(x => this.transferManagementService.getServices(x.slot).pipe(
            switchMap(services => [new LoadApplianceServicesAction(services), new FetchApplianceServicesOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchApplianceServices ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchApplianceServicesFailedAction(error));
            })
        ))
    ));

    public onClearTransferManagementData$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearTransferManagementDataAction),
        switchMap(() => [new LoadApplianceJobsAction(), new LoadApplianceServicesAction(), new LoadTransferFilesAction()])
    ));

    public onFetchTransferFiles$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchTransferFilesAction),
        switchMap(x => this.transferManagementService.getTransferFiles(x.slot).pipe(
            switchMap(config => [new LoadTransferFilesAction(config), new FetchTransferFilesOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchTransferFiles  ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchTransferFilesFailedAction(error));
            })
        ))
    ));

    public onFetchTransferFileDetails$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchTransferFileDetailsAction),
        switchMap(x => this.transferManagementService.getTransferFileDetails(x.applianceId, x.fileNames).pipe(
            switchMap(config => [new LoadTransferFileDetailsAction(config), new FetchTransferFileDetailsOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchTransferFileDetails ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchTransferFileDetailsFailedAction(error));
            })
        ))
    ));
    
    public onRunServiceCommand$ = createEffect(() => this.actions$.pipe(
        ofAction(RunServiceCommandAction),
        switchMap(a => this.transferManagementService.runServiceCommand(a.slot, a.serviceId, a.commandName).pipe(
            switchMap(x => [new UpdateInStateApplianceServiceAction(x), new RunServiceCommandOkAction()]),
            catchError(error => {
                this.logger.error('Error in RunServiceCommand', error);
                this.alertService.error(getMessageFromError(error));
                return of(new RunServiceCommandFailedAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly transferManagementService: TransferManagementService
    ) { }
}
