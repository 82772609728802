import { Store, Action } from '../ngrx-actions';
import {
    LoadInStateServerConnectionsAction,
    CreateInStateServerConnectionAction,
    UpdateInStateServerConnectionAction,
    DeleteInStateServerConnectionAction,
    SetSelectedServerConnectionAction,
    ResetServerConnectionInStateAction,
    SetSoapTestStatusAction,
    LoadInStateSimpleConnectionAction,
} from './server-connection.actions';
import { ServerConnectionState, ConnectionTestStatus } from './server-connection.state';

@Store({} as ServerConnectionState)
export class ServerConnectionStore {

    @Action(LoadInStateServerConnectionsAction)
    public loadServerConnections(state: ServerConnectionState, { serverConnections }: LoadInStateServerConnectionsAction) {
        return {
            ...state,
            serverConnections
        } as ServerConnectionState;
    }

    @Action(CreateInStateServerConnectionAction)
    public createServerConnection(state: ServerConnectionState, { serverConnection }: CreateInStateServerConnectionAction) {
        if (!state.serverConnections) { // extract to contract check
            throw new Error('ServerConnection state is not defined.');
        }
        if (!serverConnection) { // extract to contract check
            throw new Error('ServerConnection is not defined.');
        }

        const newServerConnections = [...state.serverConnections];
        newServerConnections.push(serverConnection);
        return { ...state, serverConnections: newServerConnections } as ServerConnectionState;
    }

    @Action(SetSelectedServerConnectionAction)
    public setSelectedServerConnection(state: ServerConnectionState, { serverConnectionId }: SetSelectedServerConnectionAction) {
        return { ...state, selectedServerConnectionId: serverConnectionId } as ServerConnectionState;
    }

    @Action(UpdateInStateServerConnectionAction)
    public updateServerConnection(state: ServerConnectionState, { serverConnection }: UpdateInStateServerConnectionAction) {
        if (!state.serverConnections) { // extract to contract check
            throw new Error('ServerConnections state is not defined.');
        }
        if (!serverConnection) { // extract to contract check
            throw new Error('ServerConnection is not defined.');
        }

        const index = state.serverConnections.findIndex(x => x.id === serverConnection.id);

        if (index < 0) {
            throw new Error(`ServerConnection (id: ${serverConnection.id}) not found.`);
        }

        const newServerConnections = [...state.serverConnections];
        newServerConnections[index] = { ...serverConnection };
        return { ...state, serverConnections: newServerConnections } as ServerConnectionState;
    }

    @Action(DeleteInStateServerConnectionAction)
    public deleteServerConnection(state: ServerConnectionState, { serverConnectionId }: DeleteInStateServerConnectionAction) {
        if (!state.serverConnections) { // extract to contract check
            throw new Error('ServerConnection state is not defined.');
        }
        if (state.serverConnections.every(x => x.id !== serverConnectionId)) {
            throw new Error(`ServerConnection with id "${serverConnectionId}" does not exist in the state.`);
        }

        const newServerConnections = state.serverConnections.filter(x => x.id !== serverConnectionId);
        return { ...state, serverConnections: newServerConnections } as ServerConnectionState;
    }

    @Action(ResetServerConnectionInStateAction)
    public resetServerConnectionInState(state: ServerConnectionState, { serverConnectionId }: ResetServerConnectionInStateAction) {
        if (!state.serverConnections) { // extract to contract check
            throw new Error('ServerConnections state is not defined.');
        }

        const index = state.serverConnections.findIndex(x => x.id === serverConnectionId);

        if (index < 0) {
            throw new Error(`ServerConnection (id: ${serverConnectionId}) not found.`);
        }

        const newConnection = [...state.serverConnections];
        newConnection[index] = { ...state.serverConnections[index] };
        return { ...state, serverConnections: newConnection } as ServerConnectionState;
    }

    @Action(SetSoapTestStatusAction)
    public setSoapTestStatus(state: ServerConnectionState, { status, errorMessage }: SetSoapTestStatusAction) {
        // to reach Ok or Fail status you must go though Pending. Otherwise the status is considered reset/discarded
        if (state.soapTestStatus == null
            && (status === ConnectionTestStatus.Ok || status === ConnectionTestStatus.Fail || status == null)) {
            return state;
        }

        return { ...state, soapTestStatus: status, soapTestErrorMessage: errorMessage } as ServerConnectionState;
    }

    @Action(LoadInStateSimpleConnectionAction)
    public loadInStateSimpleConnection(state: ServerConnectionState, { connections }: LoadInStateSimpleConnectionAction) {
        return { ...state, simpleConnections: connections } as ServerConnectionState;
    }
}
