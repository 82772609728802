import { Action } from '@ngrx/store';
import { SystemConfiguration } from '@/_models/system-configuration';

export class LoadSystemConfigurationAction implements Action {
    public type = '[Admin] Load System Configuration action';

    constructor(public readonly data: SystemConfiguration) { }
}

export class FetchPublicSystemConfigurationAction implements Action {
    public type = '[Admin] Fetch Public System Configuration action';
}

export class FetchPublicOkSystemConfigurationAction implements Action {
    public type = '[Admin] Fetch OK Public System Configuration action';
}

export class FetchPublicFailedSystemConfigurationAction implements Action {
    public type = '[Admin] Fetch Cancel Public System Configuration action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchSystemConfigurationAction implements Action {
    public type = '[Admin] Fetch System Configuration action';
}

export class FetchOkSystemConfigurationAction implements Action {
    public type = '[Admin] Fetch OK System Configuration action';
}

export class FetchFailedSystemConfigurationAction implements Action {
    public type = '[Admin] Fetch Cancel System Configuration action';
    constructor(public readonly error: Error | string | any) { }
}

export class PersistSystemConfigurationAction implements Action {
    public type = '[Admin] Persist System Configuration action';
    constructor(public readonly config: SystemConfiguration) { }
}

export class PersistOkSystemConfigurationAction implements Action {
    public type = '[Admin] Persist OK System Configuration action';
}

export class PersistFailedSystemConfigurationAction implements Action {
    public type = '[Admin] Persist Cancel System Configuration action';
    constructor(public readonly error: Error | string | any) { }
}
