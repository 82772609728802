import { DapBaseTemplate } from '@/_models/dap-template-management';
import { Action } from '@ngrx/store';

export class LoadDapBaseTemplatesAction implements Action {
    readonly type = '[DapTemplateManagement] InState Load Dap Base Templates action';

    constructor(public readonly baseTemplates: DapBaseTemplate[]) { }
}

export class AddOrReplaceDapTemplateAction implements Action {
    readonly type = '[DapTemplateManagement] InState Add or Replace Dap Template action';

    constructor(public readonly template: DapBaseTemplate) { }
}

export class ClearDapTemplatesAction implements Action {
    public type = '[DapTemplateManagement] Clear Templates action';
}

//#region fetch

export class FetchDapBaseTemplatesAction implements Action {
    public type = '[DapTemplateManagement] Fetch DapBaseTemplates action';
}
export class FetchDapBaseTemplatesOkAction implements Action {
    public type = '[DapTemplateManagement] Fetch DapBaseTemplates Ok action';
}
export class FetchDapBaseTemplatesFailedAction implements Action {
    public type = '[DapTemplateManagement] Fetch DapBaseTemplates Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchDapTemplatesAction implements Action {
    public type = '[DapTemplateManagement] FetchDapTemplates action';
    constructor(public readonly baseTemplateName: string) { }
}
export class FetchDapTemplatesOkAction implements Action {
    public type = '[DapTemplateManagement] Fetch DapTemplates Ok action';
}
export class FetchDapTemplatesFailedAction implements Action {
    public type = '[DapTemplateManagement] Fetch DapTemplates Failed action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion fetch

//#region Deprecate

export class DeprecateDapTemplateAction implements Action {
    public type = '[DapTemplateManagement] Deprecate DapTemplate action';
    constructor(public readonly templateId: number) { }
}
export class DeprecateDapTemplateOkAction implements Action {
    public type = '[DapTemplateManagement] Deprecate DapTemplate Ok action';
}
export class DeprecateDapTemplateFailedAction implements Action {
    public type = '[DapTemplateManagement] Deprecate DapTemplate Failed action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion Deprecate

//#region Delete

export class DeleteDapTemplateAction implements Action {
    public type = '[DapTemplateManagement] Delete DapTemplate action';
    constructor(public readonly templateId: number) { }
}
export class DeleteDapTemplateOkAction implements Action {
    public type = '[DapTemplateManagement] Delete DapTemplate Ok action';
}
export class DeleteDapTemplateFailedAction implements Action {
    public type = '[DapTemplateManagement] Delete DapTemplate Failed action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion Delete
