import { AllValidationErrors } from '@/_helpers';
import { DapDocumentContentFieldValue, DapTemplateContentTable } from '@/_models/dap-document-details';
import { DapDispositionItem, DispositionResult } from './dap-disposition-item';
import { DapDocumentVM } from './dap-document-vm';

export declare type DapTableRowValue = { validationErrors: AllValidationErrors[] } & { [key: string]: DapDocumentContentFieldValue; };
export class DapTableColumnErrors {
    public validationErrors: AllValidationErrors[] ;
}

export class DapTableVM extends DapDispositionItem {
    public templateContent: DapTemplateContentTable;
    public rowData: DapTableRowValue[];
    public columnDispositionResults = new Map<string, DispositionResult>();
    public columnErrors: DapTableColumnErrors[];
    public tableEditorsDispositionResults: DispositionResult[] = [];
    // backwards reference to the doc VM
    public docVM: DapDocumentVM;
}
