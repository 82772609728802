import { ServerConnection, SimpleConnection } from '@/_models';

export enum ConnectionTestStatus {
    Ok = 'Ok',
    Fail = 'Fail',
    Pending = 'Pending'
}

export class ServerConnectionState {
    public serverConnections: ServerConnection[];
    public selectedServerConnectionId: number;
    public soapTestStatus?: ConnectionTestStatus;
    public soapTestErrorMessage?: string;
    public simpleConnections?: SimpleConnection[];
}
