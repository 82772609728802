import { CommonModule } from '@angular/common';
import { NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '../shared/layout';

// App Sidebar Component
import { NgxSidebarFooterComponent } from './ngx-sidebar-footer.component';
import { NgxSidebarFormComponent } from './ngx-sidebar-form.component';
import { NgxSidebarHeaderComponent } from './ngx-sidebar-header.component';
import { NgxSidebarMinimizerComponent } from './ngx-sidebar-minimizer.component';
import { NgxSidebarComponent } from './ngx-sidebar.component';
import {
  NgxSidebarNavComponent,
  AppSidebarNavDropdownComponent,
  AppSidebarNavItemComponent,
  AppSidebarNavLinkComponent,
  AppSidebarNavTitleComponent,
  NavDropdownDirective,
  NavDropdownToggleDirective,
  LinkAttributesDirective
} from './ngx-sidebar-nav.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutModule
  ],
  exports: [
    NgxSidebarFooterComponent,
    NgxSidebarFormComponent,
    NgxSidebarHeaderComponent,
    NgxSidebarMinimizerComponent,
    NgxSidebarComponent,
    NgxSidebarNavComponent,
    AppSidebarNavDropdownComponent,
    AppSidebarNavItemComponent,
    AppSidebarNavLinkComponent,
    AppSidebarNavTitleComponent,
    NavDropdownDirective,
    NavDropdownToggleDirective,
    LinkAttributesDirective,
    LayoutModule
  ],
  declarations: [
    NgxSidebarFooterComponent,
    NgxSidebarFormComponent,
    NgxSidebarHeaderComponent,
    NgxSidebarMinimizerComponent,
    NgxSidebarMinimizerComponent,
    NgxSidebarComponent,
    NgxSidebarNavComponent,
    AppSidebarNavDropdownComponent,
    AppSidebarNavItemComponent,
    AppSidebarNavLinkComponent,
    AppSidebarNavTitleComponent,
    NavDropdownDirective,
    NavDropdownToggleDirective,
    LinkAttributesDirective
  ]
})
export class NgxSidebarModule { }
