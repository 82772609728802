import { IndexedType } from './indexed-type';

export function evaluate(valueOrFunc: ((params: any) => any) | string | number | symbol | boolean | undefined | IndexedType | Array<any>, params: any) {
    if (typeof valueOrFunc === 'function') {
        const func = valueOrFunc as ((params: any) => any);
        return func(params);
    } else if (typeof valueOrFunc === 'string') {
        return valueOrFunc as string;
    } else if (typeof valueOrFunc === 'boolean') {
        return valueOrFunc as boolean;
    } else if (typeof valueOrFunc === 'number') {
        return valueOrFunc as number;
    } else if (typeof valueOrFunc === 'symbol') {
        return valueOrFunc as symbol;
    } else if (Array.isArray(valueOrFunc)) {
        return valueOrFunc as Array<any>;
    } else if (typeof valueOrFunc === 'undefined' || valueOrFunc == null) {
        return null;
    } else {
        throw new Error(`value should be either a string, boolean, number, symbol, Array or a function: (${valueOrFunc})`);
    }
}
