import { Action } from '@ngrx/store';
import { PickListItem, PickList, PickListType } from '@/_models';

export class LoadInStatePickListsAction implements Action {
    readonly type = '[PickList] InState Load pickLists action';

    constructor(public readonly pickLists: PickList[]) { }
}

//#region async Fetch PickLists

export class FetchPickListsAction implements Action {
    public type = '[PickList] Fetch PickLists action';
}
export class FetchOkPickListsAction implements Action {
    public type = '[PickList] Fetch OK PickLists action';
}
export class FetchFailedPickListsAction implements Action {
    public type = '[PickList] Fetch Cancel PickLists action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch PickLists

export class LoadInStatePickListItemsAction implements Action {
    readonly type = '[PickList] Load InState PickListItems action';

    constructor(public readonly pickListItems: PickListItem[]) { }
}

//#region async Fetch PickListItems

export class FetchPickListItemsAction implements Action {
    public type = '[PickList] Fetch PickListItems action';
    constructor(public readonly pickListIds: PickListType[]) { }
}
export class FetchOkPickListItemsAction implements Action {
    public type = '[PickList] Fetch OK PickListItems action';
}
export class FetchFailedPickListItemsAction implements Action {
    public type = '[PickList] Fetch Cancel PickListItems action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch PickListItems

export class AddPickListItemAction implements Action {
    readonly type = '[PickList] Add PickListItem action';

    constructor(public readonly pickListItem: PickListItem) { }
}
export class CancelAddPickListItemAction implements Action {
    readonly type = '[PickList] Cancel Add PickListItem action';

    constructor(public readonly pickListItemId: number) { }
}
export class CreateInStatePickListItemAction implements Action {
    readonly type = '[PickList] Create InState PickListItem action';

    constructor(public readonly pickListItem: PickListItem) { }
}

//#region async Create PickListItem

export class CreatePickListItemAction implements Action {
    public type = '[PickList] Create PickListItem action';
    constructor(public readonly pickListItem: PickListItem) { }
}
export class CreateOkPickListItemAction implements Action {
    public type = '[PickList] Create OK PickListItem action';
}
export class CreateFailedPickListItemAction implements Action {
    public type = '[PickList] Create Cancel PickListItem action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Create PickListItem

export class UpdateInStatePickListItemAction implements Action {
    readonly type = '[PickList] Update InState PickListItem action';

    constructor(public readonly pickListItem: PickListItem) { }
}

//#region async Update PickListItem

export class UpdatePickListItemAction implements Action {
    public type = '[PickList] Update PickListItem action';

    constructor(public readonly pickListItem: PickListItem) { }
}
export class UpdateOkPickListItemAction implements Action {
    public type = '[PickList] Update OK PickListItem action';
}
export class UpdateFailedPickListItemAction implements Action {
    public type = '[PickList] Update Cancel PickListItem action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Update PickListItem

export class DeleteInStatePickListItemAction implements Action {
    readonly type = '[PickList] Delete InState PickListItem action';

    constructor(public readonly pickListItemId: number) { }
}
export class DeprecateInStatePickListItemAction implements Action {
    readonly type = '[PickList] Deprecate InState PickListItem action';

    constructor(public readonly pickListItemId: number) { }
}

//#region async Delete PickListItem
export class DeletePickListItemAction implements Action {
    public type = '[PickList] Delete PickListItem action';

    constructor(public readonly pickListItemId: number) { }
}
export class DeleteOkPickListItemAction implements Action {
    public type = '[PickList] Delete OK PickListItem action';
}
export class DeleteFailedPickListItemAction implements Action {
    public type = '[PickList] Delete Cancel PickListItem action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion async Delete PickListItem

//#region async Deprecate PickListItem
export class DeprecatePickListItemAction implements Action {
    public type = '[PickList] Deprecate PickListItem action';
    constructor(public readonly pickListItemId: number, 
        public readonly pickListItemName: string) { }
}
export class DeprecateOkPickListItemAction implements Action {
    public type = '[PickList] Deprecate OK PickListItem action';
}
export class DeprecateFailedPickListItemAction implements Action {
    public type = '[PickList] Deprecate Cancel PickListItem action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion async Delete PickListItem

//#region async Update PickListItem
export class UpdatePickListItemsOrderAction implements Action {
    public type = '[PickList] Update PickListItemsOrder action';
    constructor(public readonly pickListItemsIds: Array<number> | any, public readonly picklistId: number) { }
}
export class UpdateInStatePickListItemsOrderAction implements Action {
    readonly type = '[PickList] Update InStatePickListItemsOrder action';

    constructor(public readonly pickListItems: PickListItem[]) { }
}
export class UpdateOkPickListItemsOrderAction implements Action {
    public type = '[PickList] Update OK PickListItemsOrderAction action';
}
export class UpdateFailedPickListItemsOrderAction implements Action {
    public type = '[PickList] Update Cancel PickListItem order action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion async Update PickListItem
