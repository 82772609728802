import { FormGroup } from '@angular/forms';
import { DapDispositionItem, DispositionResult } from './dap-disposition-item';
import { DapFieldVM } from './dap-field-vm';
import { DapGroupVM } from './dap-group-vm';
import { DapTableVM } from './dap-table-vm';

export class DapDataContainerVM extends DapDispositionItem {
    public groupVMs: DapGroupVM[] = [];
    public scoringForm: FormGroup;
    public scoringDispositionResult: DispositionResult;

    public getFieldVMs(): DapFieldVM[] {
        return [].concat(...this.groupVMs.map(x => x.getFieldVMs()));
    }

    public getTableVMs(): DapTableVM[] {
        return [].concat(...this.groupVMs.map(x => x.getTableVMs()));
    }
}
